import React from 'react';
import _ from 'lodash';

import { useSelector } from 'react-redux';

import MetamaskInstructions from '~/Components/MetamaskInstructions';
import PopupContainer from '../PopupContainer';
import MetaMask from '~/Services/MetaMask';
import Button from '~/Components/Button';

import { useStyles } from './styles';
import LabeledInputField from '~/Components/LabeledInputField';
import PopupManager from '~/Auxilary/PopupManager';

const MintReleasesPopup = (props) => {

    const classes = useStyles();

    const blockchainState = useSelector(state => state.Blockchain.stateChangedTrigger);

    const [isLoadingData, setIsLoadingData] = React.useState(false);
    const [isProcessingTransaction, setIsProcessingTransaction] = React.useState(false);
    const [isMetamaskEnabled, setIsMetamaskEnabled] = React.useState(false);
    const [isInNetwork, setIsInNetwork] = React.useState(false);
    const [isInAddress, setIsInAddress] = React.useState(false);
    const [selectedAddress, setSelectedAddress] = React.useState(false);
    const [releaseCount, setReleaseCount] = React.useState(1);

    React.useEffect(
        () => {
            setIsInNetwork(MetaMask.isInNetwork(props.networkId)); 
            setIsProcessingTransaction(MetaMask.isProcessingTransaction());
            setIsMetamaskEnabled(MetaMask.isAvailable());
            setIsInAddress(MetaMask.isCurrentAddress(props.address));
            setSelectedAddress(MetaMask.getCurrentAddress());
            setIsLoadingData(MetaMask.isLoading())
        }, 
        [blockchainState, props.networkId, props.address]
    )

    const handleClose = (confirm) => {
        if(_.isFunction(props.onClose))
            props.onClose(confirm);
    }

    const handleMint = () => {

        if(!props.mediaId || !selectedAddress || !MetaMask.listening || !isInNetwork)
            return;

        setIsProcessingTransaction(true);
        MetaMask
            .getTokenContract()
            .createDigitalMediaReleases(
                props.mediaId,
                releaseCount
            )
            .then(txData => {
                handleClose(true);
                MetaMask.addPendingTransaction(txData.hash);
                PopupManager.pushMessagePopup(
                    'Transaction sent',
                    [
                        'The transaction for publishing a new release has been sent. Based on the network load, it might take a while for the transaction to be submitted to the blockchain.',
                        'Please give this process a few minutes to complete. Once the transaction goes through, your newly published release will appear on your profile.'
                    ],
                    'PASS'
                );
            })
            .catch((error) => {
                setIsProcessingTransaction(false);
                setIsLoadingData(false);
                PopupManager.pushMessagePopup(
                    'Transaction failed',
                    [ `Failed to publish creation. ${(error?.error?.message || error?.message || '').split('MetaMask Tx Signature: ').join('')}` ],
                    'FAIL'
                );
            });
    }

    return (
        <PopupContainer
            isOpen={props.isOpen}
            onClose={handleClose.bind(null, false)}
            backdropColor="#0009">

            <div className={classes.container}>
                <p className={classes.title}>
                    Publish editions
                </p>

                {
                    !isMetamaskEnabled || !isInNetwork || isLoadingData || !isInAddress || isProcessingTransaction ?
                    <MetamaskInstructions
                        isEnabled={isMetamaskEnabled}
                        isInNetwork={isInNetwork}
                        isInAddress={isInAddress}
                        requiredAddress={props.address}
                        accountError={'The selected address is not the same as the address used to publish this creation. Please select the following address in order to continue:'}
                        isLoadingData={isLoadingData}
                        isProcessingTransaction={isProcessingTransaction}
                        requiredNetwork={MetaMask.getNetworkNameById(props.networkId)}/>
                    :
                    <div className={classes.bodyContents}>
                    
                        <p className={classes.infoMessage}>
                            You're about to publish new editions of this creation. This operation will submit additional copies of this creation to the blockchain.
                        </p>

                        <p className={classes.label}>
                            Owner's address:
                        </p>

                        <LabeledInputField
                            className={classes.labeledInputField}
                            labelText="Address"
                            value={selectedAddress}
                            disabled
                            labelFirst />

                        <p className={classes.label}>
                            Number of new editions (Max. {props.maxEditions}):
                        </p>

                        <LabeledInputField
                            className={classes.labeledInputField}
                            labelText="Editions"
                            type="number"
                            min={1}
                            max={props.maxEditions}
                            value={releaseCount}
                            onChange={setReleaseCount}
                            labelFirst />

                    </div>
                }            

                <div className={classes.buttonContainer}>
                    <Button
                        className={classes.button}
                        text="Publish"
                        disabled={!isMetamaskEnabled || !isInNetwork || !isInAddress || isProcessingTransaction || isLoadingData}
                        onClick={handleMint}/>
                </div>

            </div>

        </PopupContainer>
    );
}

export default MintReleasesPopup;