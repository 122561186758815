import React, { Fragment } from 'react';
import _ from 'lodash';

import * as API from '~/API';
import PopupContainer from '../PopupContainer';
import Button from '~/Components/Button';

import { useStyles } from './styles';
import QRCode from 'react-qr-code';
import Helpers from '~/helpers';

const TwoFactorPopup = (props) => {

    const classes = useStyles();

    const [shouldGenerateSecret, setShouldGenerateSecret] = React.useState(true);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [qrCodeContents, setQrCodeContents] = React.useState('');
    const [secret, setSecret] = React.useState('');
    const [code, setCode] = React.useState('');

    const handleClose = (loginOk) => {
        if(_.isFunction(props.onClose))
            props.onClose(!!loginOk);
    }

    const handleEnable2fa = () => {
        setIsProcessing(true);
        API.User
            .update2faSettings(true, code, secret)
            .then(() => {
                setIsProcessing(false);
                handleClose(true);
            })
            .catch(error => {
                setIsProcessing(false);
                setErrorMessage(error);
            })
    }

    const handleDisable2fa = () => {
        setIsProcessing(true);
        API.User
            .update2faSettings(false, code)
            .then(() => {
                setIsProcessing(false);
                handleClose(true);
            })
            .catch(error => {
                setIsProcessing(false);
                setErrorMessage(error);
            })
    }

    React.useEffect(
        () => {
            if(props.isEnabling && shouldGenerateSecret) {
                setShouldGenerateSecret(false);
                API.User
                    .generate2faSecret()
                    .then(data => {
                        setErrorMessage(null);
                        setSecret(data.secret);
                        setQrCodeContents(data.uri);
                    })
                    .catch(error => {
                        setErrorMessage(error);
                        setSecret(null);
                        setQrCodeContents(null);
                    })
            }
        },
        [shouldGenerateSecret, props.isEnabling]
    )

    return (
        <PopupContainer
            isOpen={props.isOpen}
            onClose={handleClose}
            backdropColor="#0009">

            <div className={classes.container}>
                <p className={classes.title}>
                    {
                        props.isEnabling ?
                        'Enable 2FA' :
                        'Disable 2FA'
                    }
                </p>

                {
                    props.isEnabling ?
                    <div className={classes.secretContainer}>

                        {
                            secret ?
                            <Fragment>
                                <p className={classes.infoMessage}>
                                    Scan the QR code below using your Authenticator App
                                </p>

                                <div className={classes.qrCodeContainer}>
                                    <QRCode 
                                        value={qrCodeContents}
                                        size={150}/>
                                </div>

                                <p className={classes.infoMessage}>
                                    Or copy the secret below directly to your App
                                </p>

                                <p className={classes.scretText}>
                                    {secret}
                                </p>
                            </Fragment> :
                            <p className={classes.infoMessage}>
                                Generating secret...
                            </p>
                        }
                    </div>
                    :
                    null
                }

                <p className={classes.label}>
                    Enter the code generated by your Authenticator app:
                </p>

                <input 
                    className={classes.inputField}
                    placeholder="2FA Code"
                    type="text"
                    value={code}
                    onChange={(event) => {
                        setCode(event.target.value)
                    }}
                    onKeyPress={(event) => { 
                        if(event.key === 'Enter') {
                            if(props.isEnabling)
                                handleEnable2fa()
                            else
                                handleDisable2fa();
                        }
                    }}
                    autoFocus/>

                <div className={classes.forgotPasswordContainer}>                    
                    {
                        errorMessage &&
                        <p className={classes.errorMessage}>
                            { Helpers.getRequestErrorMessage(errorMessage) }
                        </p>
                    }
                </div>

                <Button
                    className={classes.button}
                    isLoading={isProcessing}
                    text={
                        props.isEnabling && isProcessing ?
                        "Enabling..." :
                        props.isEnabling && !isProcessing ?
                        "Enable 2FA" :
                        !props.isEnabling && isProcessing ?
                        "Disabling..." :
                        !props.isEnabling && !isProcessing ?
                        "Disable 2FA" : ''
                    }
                    onClick={
                        props.isEnabling ?
                        handleEnable2fa : 
                        handleDisable2fa
                    }/>

            </div>

        </PopupContainer>
    );
}

export default TwoFactorPopup;