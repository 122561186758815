import React from 'react';
import * as API from '~/API';
import { useDispatch } from 'react-redux';
import * as UserActions from '~/Redux/User/actions';
import Helpers from '~/helpers';
import _ from 'lodash';

import PopupContainer from '../PopupContainer';
import Button from '~/Components/Button';

import { useStyles } from './styles';
import { Fragment } from 'react';

const ResetPasswordPopup = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [emailAddress, setEmailAddress] = React.useState('');
    const [isRequestingCode, setIsRequestingCode] = React.useState(false);
    const [isResettingPassword, setIsResettingPassword] = React.useState(false);
    const [isCodeRequested, setIsCodeRequested] = React.useState(false);
    const [requestError, setRequestError] = React.useState(null);

    const [requestCode, setRequestCode] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [repeatPassword, setRepeatPassword] = React.useState('');


    const handleClose = (loginOk) => {
        if(_.isFunction(props.onClose))
            props.onClose(!!loginOk);
    }

    const handleRequestCode = () => {
        if(isRequestingCode)
            return;
        
        setRequestError(null);
        setIsRequestingCode(true);

        API.User
            .requestPasswordReset(emailAddress)
            .then(() => {
                setIsCodeRequested(true);
                setIsRequestingCode(false);
            })
            .catch(err => {
                setIsRequestingCode(false);
                setRequestError(err);
            })
    }

    const handleResetPassword = () => {
        if(newPassword !== repeatPassword) {
            setRequestError('password:The passwords do not match')
            return;
        }

        setRequestError(null);
        setIsResettingPassword(true);

        API.User
            .resetPasswordWithCode(
                emailAddress, 
                requestCode,
                newPassword
            )
            .then(userData => {
                setIsCodeRequested(true);
                setIsResettingPassword(false);
                dispatch(UserActions.setUserData(userData))
                props.onClose(true);
            })
            .catch(err => {
                setIsResettingPassword(false);
                setRequestError(err);
            })
    }

    return (
        <PopupContainer
            isOpen={props.isOpen}
            onClose={handleClose}
            backdropColor="#0009">

            <div className={classes.container}>
                <p className={classes.title}>
                    Reset password
                </p>

                {
                    !isCodeRequested ?
                    <Fragment>
                        <input 
                            className={
                                Helpers.getRequestErrorField(requestError) === 'email' ?
                                classes.inputField + ' ' + classes.inputFieldInvalid :
                                classes.inputField
                            }
                            placeholder="Email address"
                            type="email"
                            value={emailAddress}
                            onChange={(event) => {
                                setEmailAddress(event.target.value)
                            }}
                            onKeyPress={(event) => { 
                                if(event.key === 'Enter') 
                                    handleRequestCode() 
                            }}
                            autoFocus/>

                        {
                            requestError &&
                            <p className={classes.errorMessage}>
                                { Helpers.getRequestErrorMessage(requestError) }
                            </p>
                        }

                        <Button
                            className={classes.button}
                            isLoading={isRequestingCode}
                            text={
                                isRequestingCode ?
                                "Requesting..." :
                                "Request code"
                            }
                            onClick={handleRequestCode}/>
                    </Fragment>
                    :
                    <Fragment>
                        <input 
                            className={
                                Helpers.getRequestErrorField(requestError) === 'code' ?
                                classes.inputField + ' ' + classes.inputFieldInvalid :
                                classes.inputField
                            }
                            placeholder="Reset code (sent by email)"
                            type="text"
                            value={requestCode}
                            onChange={(event) => {
                                setRequestCode(event.target.value)
                            }}
                            onKeyPress={(event) => { 
                                if(event.key === 'Enter') 
                                    handleResetPassword() 
                            }}
                            autoFocus/>

                        <input 
                            className={
                                Helpers.getRequestErrorField(requestError) === 'password' ?
                                classes.inputField + ' ' + classes.inputFieldInvalid :
                                classes.inputField
                            }
                            placeholder="New password"
                            type="password"
                            value={newPassword}
                            onChange={(event) => {
                                setNewPassword(event.target.value)
                            }}
                            onKeyPress={(event) => { 
                                if(event.key === 'Enter') 
                                    handleResetPassword() 
                            }}/>

                        <input 
                            className={
                                Helpers.getRequestErrorField(requestError) === 'password' ?
                                classes.inputField + ' ' + classes.inputFieldInvalid :
                                classes.inputField
                            }
                            placeholder="Repeat password"
                            type="password"
                            value={repeatPassword}
                            onChange={(event) => {
                                setRepeatPassword(event.target.value)
                            }}                            
                            onKeyPress={(event) => { 
                                if(event.key === 'Enter') 
                                    handleResetPassword() 
                            }}/>

                        {
                            requestError &&
                            <p className={classes.errorMessage}>
                                { Helpers.getRequestErrorMessage(requestError) }
                            </p>
                        }

                        <Button
                            className={classes.button}
                            isLoading={isResettingPassword}
                            text={
                                isResettingPassword ?
                                "Resetting..." :
                                "Reset password"
                            }
                            onClick={handleResetPassword}/>
                    </Fragment>
                }

            </div>

        </PopupContainer>
    );
}

export default ResetPasswordPopup;