import React, { Fragment } from 'react';
import PopupManager from './PopupManager';
import { useSelector } from 'react-redux';

const PopupInjector = () => {
    useSelector(state => state.UI.currentPopupStateChange);
    window.PM = PopupManager
    return (
        <Fragment>
            { PopupManager.getComponentList() }
        </Fragment>
    );
}

export default PopupInjector;