import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { STATE_DONE, STATE_NOT_PROCESSED } from '~/Redux/states';
import * as UserDataActions from '~/Redux/UserData/actions';
import * as UserActions from '~/Redux/User/actions';
import * as API from '~/API';
import Helpers from '~/helpers';

import PopupManager from '~/Auxilary/PopupManager';
import Button from '~/Components/Button';
import DisableArea from '~/Components/DisableArea';
import SettingsStateItem from './SettingsStateItem';

import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';

const CreatorRequest = () => {

    const classes = useStyles();    
    const history = useHistory();
    
    const dispatch = useDispatch();
    const userData = useSelector(state => state.User);
    const userAddressData = useSelector(state => state.UserData);

    const [initialValuesSet, setInitialValuesSet] = React.useState(false);
    const [isUpdatingData, setIsUpdatingData] = React.useState(false);
    const [isInReview, setIsInReview] = React.useState(false);
    const [isEmailVerified, setIsEmailVerified] = React.useState(false);
    const [isLinksProvided, setIsLinksProvided] = React.useState(false);
    const [isWalletConnected, setIsWalletConnected] = React.useState(false);
    const [bestWork, setBestWrok] = React.useState('');
    const [worksToPublish, setWorksToPublish] = React.useState('');
    const [achievements, setAchievements] = React.useState('');
    const [reviewComment, setReviewComment] = React.useState('');

    React.useEffect(
        () => {
            if (userData?.data && !initialValuesSet) {
                setInitialValuesSet(true);
                setIsEmailVerified(userData.data.verified);
                setIsLinksProvided(userData.data.about?.links?.length > 0);

                setIsInReview(!!userData.data.creatorRequest?.isInReview);
                setBestWrok(userData.data.creatorRequest?.bestWork || '');
                setWorksToPublish(userData.data.creatorRequest?.worksToPublish || '');
                setAchievements(userData.data.creatorRequest?.achievements || '');
                setReviewComment(userData.data.creatorRequest?.comment || '');

                if (userAddressData.userAddressListState === STATE_NOT_PROCESSED) {
                    dispatch(UserDataActions.getUserAddresses());
                }
            }
        },
        [userData, userAddressData.userAddressListState, initialValuesSet, dispatch]
    )

    React.useEffect(
        () => {
            if (userAddressData.userAddressListState === STATE_DONE) {
                setIsWalletConnected(userAddressData.userAddressList.length > 0)
            }
        },
        [userAddressData]
    );

    React.useEffect(
        () => {
            if (isInReview) {
                PopupManager.pushMessagePopup(
                    'Request in review',
                    [
                        `Your request to become a creator is currently in review. Please give our staff some time to review your work and profile data before we make a decision!`,
                        `Once the decision is made, your account will either switch to a creator's account, or you'll be provided with comments regarding the request denial. Comments about the denial will appear on this page.`
                    ]
                )
                .then(() => {
                    history.push('/');
                })
            }

            if(!isInReview && reviewComment) {
                PopupManager.pushMessagePopup(
                    'Review comments',
                    [
                        `Your request to become a creator has been reviewed, and unfortunately the provided data is not sufficient. Please follow the instructions below and resubmit your request!`,
                        reviewComment
                    ]
                )
                .then(() => {})
            }
        },
        [isInReview, reviewComment, history]
    );

    const handleSubmitData = () => {
        setIsUpdatingData(true);
        API.User.submitCreatorRequest(
            bestWork, 
            worksToPublish, 
            achievements
        )
        .then(() => {
            PopupManager.pushMessagePopup(
                'Request submitted',
                [
                    `Your request to become a creator has been submitted. Please give our staff some time to review your work and profile data before we make a decision!`,
                    `Once the decision is made, your account will either switch to a creator's account, or you'll be provided with comments regarding the request denial. Comments about the denial will appear on this page.`
                ],
                'PASS'
            )
            .then(() => {
                dispatch(UserActions.getData());
                history.push('/');
            })
            setIsUpdatingData(false);
        })
        .catch((error) => {
            PopupManager.pushMessagePopup(
                'Failed to submit',
                [
                    `Failed to submit your request to become a creator. Please follow the instructions below!`,
                    error
                ],
                'FAIL'
            )
            setIsUpdatingData(false);
        })
    }

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Become a Creator
            </p>

            <p className={classes.label}>
                To become a creator, you need to provide some information regarding your work, as well as complete the setup of your account. Before entering your personal data, please make sure your account configuration is correct.
            </p>

            <SettingsStateItem
                isPass={isEmailVerified}
                description="Have verified email address"
                link="/profile/settings"/>

            <SettingsStateItem
                isPass={isLinksProvided}
                description="Provide links to your social media accounts"
                link="/profile/about"/>

            <SettingsStateItem
                isPass={isWalletConnected}
                description="Connect your blockchain wallet to your profile"
                link="/profile/settings"/>
            

            <div style={{height: 20}}/>

            <DisableArea 
                className={classes.disableArea}
                disabled={!isEmailVerified || !isLinksProvided || !isWalletConnected || isInReview}>

                <p className={classes.label}>
                    Provide a link to examples of your best, past work:
                </p>

                <input
                    type="text"
                    className={classes.inputField}
                    value={bestWork}
                    onChange={(event) => {setBestWrok(event.target.value)}}
                    placeholder="Google Drive or Dropbox link"/>

                <p className={classes.label}>
                    Provide a link to three artworks that you plan to publish:
                </p>

                <input
                    type="text"
                    className={classes.inputField}
                    value={worksToPublish}
                    onChange={(event) => {setWorksToPublish(event.target.value)}}
                    placeholder="Google Drive or Dropbox link"/>

                <p className={classes.label}>
                    Your achievements and awards:
                </p>

                <textarea
                    className={classes.inputField}
                    style={{
                        minHeight: 150
                    }}
                    type="text"
                    value={achievements}
                    onChange={(event) => {setAchievements(event.target.value)}}
                    placeholder="A paragraph or two about your achievements, awards, etc."/>

                <div className={classes.buttonContainer}>

                    <p className={classes.updateErrorMessage}>
                        { Helpers.getRequestErrorMessage(userData.userAboutError) }
                    </p>

                    <Button
                        className={classes.updateButton}
                        text={
                            isUpdatingData ?
                            'Submitting...' : 
                            'Submit'
                        }
                        disabled={!isEmailVerified || !isLinksProvided || !isWalletConnected || isInReview}
                        isLoading={isUpdatingData}
                        onClick={handleSubmitData}
                        inverted/>

                </div>

            </DisableArea>

            <div style={{height: 50}}/>

        </div>
    );
}


export default CreatorRequest;