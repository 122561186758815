import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20
    },
    bodyContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#EDEDED',
        padding: 50,
        height: 400,
        margin: '-10px 50px 0 50px',
        boxShadow: '0px 16px 50px 0px rgb(0 0 0 / 10%)',
        '-webkit-box-shadow': '0px 16px 50px 0px rgb(0 0 0 / 10%)',
        '-moz-box-shadow': '0px 16px 50px 0px rgba(0,0,0,0.1)'
    },
    banner: {
        width: '100%'
    },
    textContainer: {
        flex: 1,
        margin: 0
    },
    bodyText: {
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        lineHeight: 2,
        marginTop: 0,
        fontWeight: 300,
        color: Theme.TextColor.Light,
    },
    linksContainer: {
        padding: '10px 0 0 50px'
    },
    '@media only screen and (max-width: 800px)': {
        bodyContainer: {
            padding: 20,
            margin: '-10px 10px 0 10px',
        },
        bodyText: {
            fontSize: 14
        }
    },
});