import React, { Fragment } from 'react';
import * as API from '~/API';
import PopupManager from '~/Auxilary/PopupManager';
import Signature from '~/Services/signature';
import MetaMask from '~/Services/MetaMask';
import Helpers from '~/helpers';
import Config from '~/config';

import * as UserDataActions from '~/Redux/UserData/actions';
import { useDispatch, useSelector } from 'react-redux';
import { STATE_DONE, STATE_NOT_PROCESSED } from '~/Redux/states';

import CreationTableHeader from '~/Components/CreationTableHeader';
import SettingsCreationItem from '~/Components/SettingsCreationItem';
import NoContentInfoBox from '~/Components/NoContentInfoBox';

import Images from '~/Assets/images';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';

const Offers = (props) => {

    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const userData = useSelector(state => state.UserData);

    const [processingOfferId, setProcessingOfferId] = React.useState(null);
    const [dataReloadRequired, setDataReloadRequired] = React.useState(true);

    React.useEffect(
        () => {
            if (userData.offersState === STATE_NOT_PROCESSED || 
               (userData.offersState === STATE_DONE && userData.showReceivedOffers !== props.showReceived) || 
               dataReloadRequired) {
                dispatch(
                    UserDataActions.getOffers(
                        props.showReceived, 
                        props.showReceived ? 
                        'PENDING' : 
                        'ACCEPTED'
                    )
                );
                setDataReloadRequired(false);
            }
        }, 
        [ userData, props.showReceived, dataReloadRequired, dispatch ]
    );

    const handleAcceptOffer = (offerId) => {
        setProcessingOfferId(offerId);

        MetaMask
            .startListening()
            .then(() => {

                let offerData = userData.offers?.find(item => item.offerId === offerId);

                if(!offerData)
                    return;
        
                let offerSignature = JSON.parse(offerData.offerSignature.message)
                let ownerAddress = offerData?.creation?.blockchain?.ownerAddress;
        
                if(!Helpers.isSameAddress(ownerAddress, MetaMask.getCurrentAddress())) {
                    PopupManager.pushMessagePopup(
                        'Switch account',
                        [ 
                            `The selected MetaMask account is not this creation's owner's address. Please select the following address in MetaMask in order to continue:`,
                            ownerAddress
                        ]
                    );
        
                    setProcessingOfferId(null);
                    return;
                }

                if(!MetaMask.isMarketApproved()) {
                    PopupManager.pushMessagePopup(
                        'Market not approved',
                        [ 
                            `You have not approved the Market contract to handle your tokens yet. In order to sell or gift tokens, you must approve the Market contract first.`,
                            `Do you want to do this now?`
                        ],
                        null, true, 'Yes', 'No', true
                    )
                    .then((confirmed) => {
                        if(confirmed)
                            history.push(`/profile/manage-collection/${offerData?.creation?.creationId}`)
                    })

                    setProcessingOfferId(null);
                    return;
                }
        
                Signature
                    .signSaleApproval(
                        offerSignature.buyer, 
                        offerSignature.tokenId, 
                        Helpers.weiToEth(offerSignature.value), 
                        offerData.creation.blockchain.approvalId, 
                        parseInt(Date.now() / 1000 + Config.Platform.OFFER_DEADLINE_HOURS * 60 * 60)
                    )
                    .then(signatureData => {
                        API.UserData.acceptOffer(offerId, signatureData)
                        .then((data) => {
                            PopupManager.pushMessagePopup(
                                'Offer accepted',
                                [ 
                                    `This offer has been accepted successfully. The user who placed the offer now has ${Config.Platform.OFFER_DEADLINE_HOURS} hours to purchase this creation for ${Helpers.formatEthPrice(Helpers.weiToEth(offerSignature.value))} ${Config.Blockchain.COIN}.`,
                                    `If you want to revoke this offer, invalidate the approvals for this creation in the Manage Collection page.`
                                ],
                                'PASS'
                            );
                            dispatch(UserDataActions.getOffers(true, 'PENDING'));
                            setProcessingOfferId(null);
                        })
                        .catch(() => {
                            setProcessingOfferId(null);
                        })
                    })
                    .catch((err) => {
                        setProcessingOfferId(null);
                    });
            })
    }

    const handleDeclineOffer = (offerId) => {
        setProcessingOfferId(offerId);
        PopupManager.pushMessagePopup(
            'Decline offer?',
            [ 
                `Are you sure you want to decline user's offer to purchase this creation?`
            ],
            null, true, 'Yes', 'No'
        )
        .then(response => {
            setProcessingOfferId(null);
            
            if(response) {
                API.UserData.declineOffer(offerId)
                dispatch(UserDataActions.getOffers(true, 'PENDING'));
            }
        });
    }

    const handlePurchaseOffer = (offerId) => {

        setProcessingOfferId(offerId);

        let offerData = userData.offers?.find(item => item.offerId === offerId);

        if(!offerData)
            return;

        let acceptanceSignature = offerData.acceptanceSignature 

        offerData.creation.offerSignature = acceptanceSignature;
        offerData.creation.price = acceptanceSignature.parameters[2].value;

        let buyer = acceptanceSignature.parameters[0].value;

        PopupManager.pushPurchasePopup(offerData.creation, true, buyer)
        .then(() => {
            setProcessingOfferId(null);
        })
        .catch(() => {
            setProcessingOfferId(null);
        })
    }

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                {
                    props.showReceived ?
                    'Received offers' :
                    'Sent offers'
                }
            </p>

            <div style={{height: 30}}/>

            {
                userData.offers &&
                userData.offers.length > 0 ?

                props.showReceived ?
                <Fragment>
                    <CreationTableHeader
                        itemStyle={classes.headerItem}
                        headerItems={[
                            { text: 'Creation', flex: 5},
                            { text: 'User', flex: 3},
                            { text: 'Offer', flex: 4}
                        ]}/>
                    
                    <div className={classes.itemsContainer}>
                        {
                            userData.offers.map(offerData => 
                                <SettingsCreationItem
                                    key={offerData.offerId}
                                    offerId={offerData.offerId}
                                    creationId={offerData.creation?.creationId}
                                    name={offerData.creation?.name}
                                    previewUrl={offerData.creation?.media?.image?.url}
                                    edition={offerData.creation?.release?.count}
                                    username={offerData.fromUser?.username}
                                    usersName={offerData.fromUser?.name}
                                    profilePictureUrl={offerData.fromUser?.profilePictureUrl}
                                    price={offerData.value}
                                    descriptionFlex={5}
                                    userColumnFlex={3}
                                    priceColumnFlex={4}
                                    onAcceptClicked={processingOfferId !== offerData.offerId ? handleAcceptOffer : null}
                                    onDeclineClicked={processingOfferId !== offerData.offerId ? handleDeclineOffer : null} />
                            )
                        }
                    </div>
                </Fragment> :

                <Fragment>
                    <CreationTableHeader
                        itemStyle={classes.headerItem}
                        headerItems={[
                            { text: 'Creation', flex: 5},
                            { text: 'Status', flex: 3},
                            { text: 'Action', flex: 2}
                        ]}/>

                    <div className={classes.itemsContainer}>
                        {
                            userData.offers.map(offerData => 
                                <SettingsCreationItem
                                    key={offerData.offerId}
                                    offerId={offerData.offerId}
                                    creationId={offerData.creation?.creationId}
                                    name={offerData.creation?.name}
                                    previewUrl={offerData.creation?.media?.image?.url}
                                    edition={offerData.creation?.release?.count}
                                    descriptionFlex={5}
                                    statusFlex={3}
                                    status={Config.OfferStatus[offerData.status] || 'Unknown'}
                                    price={offerData.status === 'ACCEPTED' ? undefined : 0}
                                    priceColumnFlex={2}
                                    onPurchaseClicked={
                                        processingOfferId !== offerData.offerId && offerData.status === 'ACCEPTED' ? 
                                        handlePurchaseOffer : 
                                        null
                                    }/>
                            )
                        }
                    </div>
                </Fragment> :

                <NoContentInfoBox
                    icon={Images.EmptyGallery}
                    text={
                        props.showReceived ?
                        `You have not received any offers yet` :
                        `You have not sent any offers yet`
                    }/>
            }

            <div style={{height: 50}}/>

        </div>
    );
}

export default Offers;