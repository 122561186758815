import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Config from '~/config';
import _ from 'lodash';

import Button from '../Button';
import ProfilePicture from '../ProfilePicture';
import ArrowUp from '~/Assets/Vectors/ArrowUp';
import ArrowDown from '~/Assets/Vectors/ArrowDown';

import { useStyles } from './styles';

const CommentItem = (props) => {
    const classes = useStyles();
    const [showReplyBox, setShowReplyBox] = React.useState(false);
    const [showReplies, setShowReplies] = React.useState(false);
    const [replyBody, setReplyBody] = React.useState('');

    const handlePostReply = () => {
        if(_.isFunction(props.onPostComment))
            props.onPostComment(props.commentId, replyBody);

        setReplyBody('');
        setShowReplyBox(false);
        setShowReplies(true);
    }

    const handleDeleteComment = () => {
        if(_.isFunction(props.onDeleteComment))
            props.onDeleteComment(props.commentId);
    }

    return (
        <div className={classes.container}>
            <div className={classes.userData}>
                <ProfilePicture
                    username={props.username}
                    imageUrl={props.profilePictureUrl} 
                    size={35}/>

                <Link
                    to={`/profiles/${props.username}/`}
                    className={classes.usersName}>
                    {props.name}
                </Link>

                <p className={classes.commentDate}>
                    {(new Date(props.createdAt)).toDateString()}
                </p>
            </div>

            <div className={classes.bodyContainer}>
                <p className={classes.commentBody}>
                    { props.body }
                </p>

                {
                    showReplyBox ?
                    <div className={classes.replyContainer}>
                        <textarea 
                            className={classes.commentBox}
                            value={replyBody}
                            onChange={(event) => { setReplyBody(event.target.value.substr(0, Config.UI.MAX_COMMENT_LENGTH)) }}
                            onKeyDown={(event) => { if(event.key === 'Enter') handlePostReply(); }}
                            placeholder="Share how you feel about the creation or even ask the creator a question."/>

                        <div className={classes.buttonHolder}>
                            <Button
                                className={classes.commentButton}
                                onClick={() => { setShowReplyBox(false) }}
                                style={{marginRight: 10}}
                                text="Cancel"
                                secondary/>

                            <Button
                                className={classes.commentButton}
                                text="Comment"
                                onClick={handlePostReply}/>
                        </div>
                    </div> :
                    <div className={classes.actionsContainer}>
                        <p 
                            className={classes.showReplyBoxButton}
                            onClick={() => { setShowReplyBox(true) }}>
                            REPLY
                        </p>

                        {
                            props.username === props.self || props.isAdmin ?
                            <p 
                                className={classes.showReplyBoxButton}
                                onClick={handleDeleteComment}>
                                DELETE
                            </p>
                            : null
                        }
                    </div>
                }

                {
                    (props.comments || [])
                        .filter(comment => comment.replyTo === props.commentId)
                        .length > 0 ?
                    <Fragment>
                        <div
                            className={classes.showReplyBoxButton}
                            style={{ fontSize: 12 }}
                            onClick={() => { setShowReplies(!showReplies) }}>
                            {
                                showReplies ?
                                'Hide Replies' : 
                                'Show all replies'
                            }
                            {
                                showReplies ?
                                <ArrowUp className={classes.arrowIcon}/> : 
                                <ArrowDown className={classes.arrowIcon}/>
                            }
                        </div>

                        {
                            showReplies ?
                            <div className={classes.commentReplyContainer}>
                                {
                                    (props.comments || [])
                                        .filter(comment => comment.replyTo === props.commentId)
                                        .map(comment => 
                                            <CommentItem
                                                key={comment.commentId}
                                                commentId={comment.commentId}
                                                name={comment.author.name}
                                                username={comment.author.username}
                                                profilePictureUrl={comment.author.profilePictureUrl}
                                                createdAt={comment.createdAt}
                                                body={comment.body}
                                                creator={props.creator}
                                                comments={props.comments}
                                                isOwner={props.creator === comment.author.username}
                                                self={props.self}
                                                isAdmin={props.isAdmin}
                                                onPostComment={props.onPostComment}
                                                onDeleteComment={props.onDeleteComment}/>           
                                    )
                                }
                            </div> : null
                        }
                    </Fragment> : null
                }
            </div>
        </div>
    );
}

export default CommentItem;