import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {},
    title: {
        fontFamily: Theme.Font.Primary,
        color: Theme.TextColor.Dark,
        fontSize: 19,
        fontWeight: 400,
        margin: '0 0 10px 0'
    },
    list: {
        margin: 0
    },
    listItem: {
        fontFamily: Theme.Font.Primary,
        color: Theme.TextColor.Dark,
        fontSize: 14,
        fontWeight: 300,
        margin: '5px 0',
        '& a': {
            fontFamily: Theme.Font.Primary,
            color: Theme.TextColor.Light,
            fontSize: 14,
            margin: 0,
            fontWeight: 300,
            textDecoration: 'none'
        },
        '& a:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    }
});