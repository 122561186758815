import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    pageContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '80px 50px 50px 50px',
    },
    dropsTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '30px 0 0 5px'
    },
    sectionTitle: {
        fontFamily: Theme.Font.Secondary,
        fontSize: 35,
        fontWeight: 300,
    },
    dropsIcon: {
        height: 50,
        marginRight: 5
    },
    liveDropsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: '20px 0px 20px 0px'
    },
    carousel: {
        margin: '50px 0 10px 10px'
    },
    '@media only screen and (max-width: 800px)': {
        pageContainer: {
            padding: '80px 10px 0 10px',
        },
        sectionTitle: {
            margin: '30px 0 0 10px'
        }
    },
});