import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '0 30px',
        maxWidth: 1000
    },
    mainSection: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    infoSection: {
        flex: 5,
        margin: '0 8px'
    },
    mediaPreview: {
        flex: 4,
        margin: '25px 0 0 10px'
    },
    title: {
        fontFamily: Theme.Font.Primary,
        fontSize: 40,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '0 0 10px 5px'
    },
    headerItem: {
        color: Theme.TextColor.Dark,
        fontSize: 14
    },
    creationsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: '20px 0px 20px 0px'
    },
    tableTitle: {
        fontFamily: Theme.Font.Primary,
        fontSize: 26,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        margin: '40px 10px 10px 10px'
    },
    publicationStatusContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: '10px 0px 10px 0px'
    },
    tableNote: {
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: 10
    },
    publishButton: {
        padding: '5px 20px',
        marginLeft: 10
    },
    defaultPriceContainer: {
        flex: 1,
        maxWidth: 650,
        display: 'flex',
        flexDirection: 'row',
        padding: 10
    },
    '@media only screen and (max-width: 800px)': {
        profilePreviewContainer: {
            flexDirection: 'column'
        }
    }
});