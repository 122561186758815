import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        padding: '0 100px'
    },
    list: {
        margin: 0,
        listStyleType: 'none'
    },
    listItem: {
        fontFamily: Theme.Font.Primary,
        color: Theme.TextColor.Dark,
        fontSize: 15,
        fontWeight: 300,
        margin: '20px 0',
        '& a': {
            fontFamily: Theme.Font.Primary,
            color: Theme.TextColor.Light,
            fontSize: 15,
            margin: 0,
            fontWeight: 300,
            textDecoration: 'none'
        },
        '& a:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    },
    '@media only screen and (max-width: 1024px)': {
        container: {
            padding: '0 40px'
        }
    },
    '@media only screen and (max-width: 800px)': {
        container: {
            padding: '0 10px'
        }
    },
});