class LocalStorage {

    static getUsername() {
        let loadedCredentials = LocalStorage.getCredentials();

        return (
            loadedCredentials ? 
            loadedCredentials.username : 
            null
        )
    }

    static getToken() {
        let loadedCredentials = LocalStorage.getCredentials();

        return (
            loadedCredentials ?
            loadedCredentials.token :
            null
        )
    }

    static getDecodedToken() {
        try {
            let token = LocalStorage.getToken();

            if(!token)
                return null;

            let tokenData = window.atob(token.split('.')[1]);

            return (
                tokenData ?
                JSON.parse(tokenData) :
                null
            )
        }
        catch(err) {
            return null
        }
    }

    static isCredentialsLoaded() {
        return LocalStorage.getToken() !== null;
    }
    
    static getCredentials() {
        try {
            return JSON.parse(window.localStorage['credentials']);
        }
        catch(ex) {
            return null;
        }
    }

    static setCredentials(credentials) {
        window.localStorage['credentials'] = JSON.stringify(credentials);
    }

    static clear() {
        window.localStorage['credentials'] = '';
    }
}

export default LocalStorage;