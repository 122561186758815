import React from 'react';
import Helpers from '~/helpers';
import Config from '~/config';

import ProfilePicture from '../ProfilePicture';

import { Link } from 'react-router-dom';
import { useStyles } from './styles';

const CreationTableActivityItem = (props) => {
    const classes = useStyles();
    const activity = 
        CreationTableActivityItem.ActivityType[props.activity] || {}

    return (
        <div className={classes.container}>
            <div className={classes.userData}>
                <ProfilePicture
                    username={props.username}
                    imageUrl={props.profilePictureUrl} 
                    size={35}/>

                {
                    props.username ?
                    <Link 
                        to={`/profiles/${props.username}/`}
                        className={classes.usersName}>
                        {props.name}
                    </Link> :
                    <div className={classes.usersName}>
                        {props.name}
                    </div>
                }
            </div>
            <div className={classes.activityData}>
                <p className={classes.textField}>
                    { activity.text }
                </p>
                <p 
                    className={classes.textField}
                    style={{fontWeight: 300}}>
                    { activity.link }
                </p>
                {
                    props.metadata && props.metadata.amount ?
                    <p className={classes.textField}>
                        {Helpers.formatEthPrice(Helpers.weiToEth(props.metadata.amount))} {Config.Blockchain.COIN} (${Helpers.formatUsdPrice(props.metadata.coinPrice * Helpers.weiToEth(props.metadata.amount))})
                    </p> :
                    null
                }
            </div>
            <div className={classes.timeData}>
                <p className={classes.textField}>
                    { Helpers.getElapsedTimeString(props.createdAt) }
                </p>
            </div>
        </div>
    );
}

CreationTableActivityItem.ActivityType = Object.freeze({
    PURCHASE: {
        text: 'Purchased',
        link: 'for'
    },
    OFFER: {
        text: 'Placed an offer',
        link: 'for'
    },
    GIFT: {
        text: 'Gifted this creation',
        link: ''
    },
})

export default CreationTableActivityItem;