import React from 'react';
import { useStyles } from './styles';
import Config from '~/config';

const CreationDescription = (props) => {
    const classes = useStyles();
    const [formattedText, setFormattedText] = React.useState('');
    const [isTruncated, setIsTruncated] = React.useState(true);
    const [truncationRequired, setTruncationRequired] = React.useState(false);

    React.useEffect(
        () => {
            setFormattedText(props.text || '');
        },
        [props.text]
    )

    React.useEffect(
        () => {
            setTruncationRequired(formattedText.length > Config.UI.DESCRIPTION_TRUNCATE_LENGTH)
            setIsTruncated(formattedText.length > Config.UI.DESCRIPTION_TRUNCATE_LENGTH)
        },
        [formattedText]
    )

    return (
        <div className={classes.container}>

            {
                !props.hideTitle &&
                <p className={classes.title}>
                    Description:
                </p>
            }

            <p className={classes.text}>
                {
                    isTruncated ?
                    (formattedText.substr(0, Config.UI.DESCRIPTION_TRUNCATE_LENGTH) + '...') :
                    formattedText 
                }
                
                {
                    !truncationRequired ?
                    null :

                    isTruncated ?
                    <span 
                        className={classes.showMoreButton}
                        onClick={() => {setIsTruncated(false)}}>
                        (read more)
                    </span> : 
                    
                    !isTruncated ?
                    <span 
                        className={classes.showMoreButton}
                        onClick={() => {setIsTruncated(true)}}>
                        (read less)
                    </span> : 
                    null
                }

            </p>

        </div>
    );
}

export default CreationDescription;