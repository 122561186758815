import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        margin: '30px 10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    tabItem: {
        color: Theme.TextColor.Light,
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            color: Theme.Palette.Primary.Foreground
        }
    },
    tabItemSelected: {
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        textDecoration: 'underline',
        '&:hover': {
            color: Theme.TextColor.Dark,
        }
    }
});