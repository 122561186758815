import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '0 30px',
        maxWidth: 1000
    },
    headerContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    title: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 40,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '0 0 10px 0',
    },
    createMintRequestButton: {
        margin: '15px 0',
        padding: '7px 15px'
    },
    headerItem: {
        color: Theme.TextColor.Dark,
        fontSize: 14
    },
    '@media only screen and (max-width: 800px)': {
        profilePreviewContainer: {
            flexDirection: 'column'
        }
    }
});