import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Theme.Background.Lighter,
        height: 400,
        marginTop: 30,
        padding: 30,
        boxShadow: '0px 16px 50px 0px rgb(0 0 0 / 10%)',
        '-webkit-box-shadow': '0px 16px 50px 0px rgb(0 0 0 / 10%)',
        '-moz-box-shadow': '0px 16px 50px 0px rgba(0,0,0,0.1)'
    },
    icon: {
        width: 120
    },
    text: {
        fontFamily: Theme.Font.Primary,
        fontSize: 18,
        marginTop: 10,
        fontWeight: 300,
        textAlign: 'center',
        color: Theme.TextColor.Light,
    },
    button: {
        padding: '7px 20px',
        margin: '10px'
    }
});