import React from 'react';

const CarouselArrowLeft = (props) => {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg" 
            version="1.1"
            viewBox="164.388 52.048 189.401 341.113"
            onClick={props.onClick}>
            <polygon points="190.054 52.048 239.914 52.048 239.947 91.245 277.399 91.245 277.399 128.888 315.908 128.888 315.908 167.421 353.789 167.421 353.789 279.035 315.883 279.035 315.883 316.825 277.739 316.825 277.739 355.158 239.155 355.158 239.155 393.161 164.388 393.161 164.388 354.942 202.322 354.942 202.322 316.599 240.425 316.599 240.425 278.815 277.354 278.815 277.354 166.099 239.677 166.099 239.677 128.395 202.311 128.395 202.311 91.442 164.787 91.442 164.787 52.122" transform="matrix(-1, 0, 0, -1, 518.177002, 445.209015)"/>
        </svg>
    )
}

export default CarouselArrowLeft;