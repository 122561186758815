import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATE_DONE, STATE_FAILED, STATE_PROCESSING } from '~/Redux/states';
import * as UserActions from '~/Redux/User/actions';
import Helpers from '~/helpers';
import _ from 'lodash';

import PopupContainer from '../PopupContainer';
import Button from '~/Components/Button';

import { useStyles } from './styles';
import PopupManager from '~/Auxilary/PopupManager';

const LoginPopup = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [twoFactorCode, setTwoFactorCode] = React.useState('');
    const [twoFactorRequired, setTwoFactorRequired] = React.useState(false);

    const loginState = useSelector(state => state.User.loginState);

    const loginError = useSelector(state => 
        Helpers.getRequestErrorMessage(state.User.loginError)
    );

    const loginErrorField = useSelector(state => 
        Helpers.getRequestErrorField(state.User.loginError)
    );

    const handleClose = (loginOk) => {
        dispatch(UserActions.clearLoginError())
        if(_.isFunction(props.onClose))
            props.onClose(!!loginOk);
    }

    const handleLogin = () => {
        if(loginState === STATE_PROCESSING)
            return;

        if(twoFactorRequired)
            dispatch(UserActions.login(username, password, twoFactorCode))
        else
            dispatch(UserActions.login(username, password))
    }

    const handleResetPassword = () => {
        handleClose(false);
        PopupManager.pushResetPasswordPopup()
    }

    const handleCloseCallback = React.useCallback(
        handleClose, 
        [props, dispatch]
    );

    React.useEffect(
        () => {
            if(loginState === STATE_DONE)
                handleCloseCallback(true);
        },
        [loginState, handleCloseCallback]
    )

    React.useEffect(
        () => {
            if(loginErrorField === '2FA')
                setTwoFactorRequired(true);
        },
        [setTwoFactorRequired, loginErrorField]
    )

    return (
        <PopupContainer
            isOpen={props.isOpen}
            onClose={handleClose}
            backdropColor="#0009">

            <div className={classes.container}>
                <p className={classes.title}>
                    {
                        twoFactorRequired ?
                        'Enter 2FA Code' :
                        'Account Login'
                    }
                </p>

                {
                    twoFactorRequired ?
                    <Fragment>
                        <input 
                            className={classes.inputField}
                            placeholder="2FA Code"
                            type="text"
                            value={twoFactorCode}
                            onChange={(event) => {
                                setTwoFactorCode(event.target.value)
                            }}
                            onKeyPress={(event) => { 
                                if(event.key === 'Enter') 
                                    handleLogin() 
                            }}
                            autoFocus/>

                        <div className={classes.forgotPasswordContainer}>                    
                            {
                                loginState === STATE_FAILED && 
                                loginError !== '2FA code not provided' &&
                                <p className={classes.errorMessage}>
                                    { loginError }
                                </p>
                            }
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <input 
                            className={
                                loginErrorField === 'username' ?
                                classes.inputField + ' ' + classes.inputFieldInvalid :
                                classes.inputField
                            }
                            placeholder="Email address"
                            type="email"
                            value={username}
                            onChange={(event) => {
                                setUsername(event.target.value)
                            }}
                            onKeyPress={(event) => { 
                                if(event.key === 'Enter') 
                                    handleLogin() 
                            }}
                            autoFocus/>

                        <input 
                            className={
                                loginErrorField === 'password' ?
                                classes.inputField + ' ' + classes.inputFieldInvalid :
                                classes.inputField
                            }
                            placeholder="Password"
                            type="password"
                            value={password}
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}
                            onKeyPress={(event) => { 
                                if(event.key === 'Enter') 
                                    handleLogin() 
                            }}/>

                        <div className={classes.forgotPasswordContainer}>                    
                            {
                                loginState === STATE_FAILED ?
                                <p className={classes.errorMessage}>
                                    { loginError }
                                </p> : null
                            }
                            <p 
                                className={classes.forgotPasswordLabel}
                                onClick={handleResetPassword}>
                                Forgot your password?
                            </p>
                        </div>
                    </Fragment>
                }

                <Button
                    className={classes.button}
                    isLoading={loginState === STATE_PROCESSING}
                    text={
                        loginState === STATE_PROCESSING ?
                        "Logging in..." :
                        "Sign in"
                    }
                    onClick={handleLogin}/>

            </div>

        </PopupContainer>
    );
}

export default LoginPopup;