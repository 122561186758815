import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        margin: '5px 0 5px 30px',
        alignItems: 'center'
    },
    icon: {
        width: 30,
        height: 30
    },
    description: {
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        color: 'inherit',
        margin: '5px 0px 5px 10px'
    },
    link: {
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        fontWeight: 400,
        color: 'inherit',
        margin: '2px 0 0 5px'
    }
});