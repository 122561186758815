import React from 'react';
import Images from '~/Assets/images';
import { useStyles } from './styles';
import Button from '../Button';
import MetaMask from '~/Services/MetaMask';
import { Link } from 'react-router-dom';

const MetamaskInstructions = (props) => {
    const classes = useStyles();

    React.useEffect(() => {
        MetaMask.listening = true
    }, []);

    return (
        (props.isLoadingData && props.isEnabled) || props.isProcessingTransaction ?
        <div className={classes.container}>

            <img
                alt=""
                src={Images.Metamask}
                className={classes.metamaskLogo}/>

            <div className={classes.bodyContainer}>

                <div className={classes.metamaskMessageTitleContainer}>
                    <div 
                        className={classes.loader}
                        style={{ backgroundImage: `url(${Images.Loader}`}}/>

                    <p className={classes.metamaskLoadingTitle}>
                        {
                            props.isProcessingTransaction ?
                            'Processing transaction...' :
                            'Loading Data...'
                        }
                    </p>
                </div>

                <p className={classes.metamaskMessage}>
                    {
                        props.isProcessingTransaction ?
                        'MetaMask is currently processing a transaction. Please wait!' :
                        'MetaMask is currently loading Blockchain data. Please wait!'
                    }
                </p>

            </div>
        </div> :

        !props.isEnabled || !props.isInNetwork || !props.isInAddress ?
        <div className={classes.container}>

            <img
                alt=""
                src={Images.Metamask}
                className={classes.metamaskLogo}/>

            {
                !props.isEnabled ?
                <div className={classes.bodyContainer}>

                    <p className={classes.metamaskMessageTitle}>
                        Enable MetaMask
                    </p>

                    <p className={classes.metamaskMessage}>
                        The MetaMask extension is currently not enabled. Please make sure that MetaMask is installed, and that the platform has access to it! 
                    </p>

                </div> :

                !props.isInNetwork ?
                <div className={classes.bodyContainer}>

                    <p className={classes.metamaskMessageTitle}>
                        Switch MetaMask Network
                    </p>

                    <p className={classes.metamaskMessage}>
                        The MetaMask extension is currently not operating in the required network. Please switch to <strong>{props.requiredNetwork}</strong> to continue! 
                    </p>

                </div>  :

                !props.isInAddress ?
                <div className={classes.bodyContainer}>

                    <p className={classes.metamaskMessageTitle}>
                        Switch MetaMask Account
                    </p>

                    <p className={classes.metamaskMessage}>
                        { props.accountError || `The MetaMask extension is currently not using the correct account. In order to continue, please switch to the account with the following address:`}
                    </p>

                    {
                        props.requiredAddress &&
                        <p className={classes.metamaskMessage}>
                            {
                                props.requiredAddress instanceof Array ?
                                props.requiredAddress.map(item => 
                                    <strong key={item}>{item}</strong>
                                )
                                :
                                <strong>{props.requiredAddress}</strong>
                            }
                        </p>
                    }

                </div>  : null

            }

            {
                props.isNoWalletConnectedError ?
                <Link
                    to="/profile/settings"
                    className={classes.metamaskInstructions}
                    onClick={() => {props.onClose()}}>
                    Connect your MetaMask wallet &gt;
                </Link>
                :
                <a 
                    href="https://metamask.io/faqs.html"
                    className={classes.metamaskInstructions}>
                    See MetaMask instructions &gt;
                </a>
            }

        </div> : 

        !props.isApprovedMarket ? 
        <div className={classes.container}>

            <img
                alt=""
                src={Images.Metamask}
                className={classes.metamaskLogo}/>

            {
                <div className={classes.bodyContainer}>

                    <p className={classes.metamaskMessageTitle}>
                        Approve Market
                    </p>

                    <p className={classes.metamaskMessage}>
                        The market contract has not been approved to handle your creations. In order to sell or gift creations, you need to approve the Market contract first! 
                    </p>

                    <div className={classes.buttonContainer}>
                        <Button
                            className={classes.button}
                            onClick={props.onApproveClicked}
                            isLoading={props.isApproving}
                            text={
                                props.isApproving ?
                                'Approving...' : 
                                'Approve'
                            }/>
                    </div>

                </div> 

            }

        </div> 
        :  null
    );
}

export default MetamaskInstructions;