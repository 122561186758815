const Images = {
    Logo: require('./Images/logo_tw.png').default,
    Loader: require('./Images/loader.gif').default,
    ProfilePicturePlaceholder: require('./Images/profile_placeholder.png').default,
    EmptyGallery: require('./Images/no_items.png').default,
    EmptyAbout: require('./Images/no_description.png').default,
    NoPreview: require('./Images/no_preview.png').default,
    Ethereum: require('./Images/ethereum.svg').default,
    Polygon: require('./Images/matic.svg').default,
    Metamask: require('./Images/metamask.svg').default,
    UnknownItem: require('./Images/unknown_item.png').default,
    Spice: require('./Images/spice.png').default,
    LiveDrops: require('./Images/live_drops.png').default,
    Verified: require('./Images/verified.png').default,
    Unverified: require('./Images/unverified.png').default,
    Icons: {
        Twitter: {
            Normal: require('./Images/icons/twitter_icon.png').default,
            Hover: require('./Images/icons/twitter_icon_hover.png').default,
        },
        Instagram: {
            Normal: require('./Images/icons/instagram_icon.png').default,
            Hover: require('./Images/icons/instagram_icon_hover.png').default,
        },
        Website: {
            Normal: require('./Images/icons/website_icon.png').default,
            Hover: require('./Images/icons/website_icon_hover.png').default,
        },
        Facebook: {
            Normal: require('./Images/icons/facebook_icon.png').default,
            Hover: require('./Images/icons/facebook_icon_hover.png').default,
        },
        Pinterest: {
            Normal: require('./Images/icons/pinterest_icon.png').default,
            Hover: require('./Images/icons/pinterest_icon_hover.png').default,
        },
        Behance: {
            Normal: require('./Images/icons/behance_icon.png').default,
            Hover: require('./Images/icons/behance_icon_hover.png').default,
        }
    },
    Platforms: {
        Email: require('./Images/platforms/email.png').default,
        Twitter: require('./Images/platforms/twitter.png').default,
        Discord: require('./Images/platforms/discord.png').default,
        YouTube: require('./Images/platforms/youtube.png').default,
        Instagram: require('./Images/platforms/instagram.png').default,
        TikTok: require('./Images/platforms/tiktok.png').default,    
        Reddit: require('./Images/platforms/reddit.png').default,
        Telegram: require('./Images/platforms/telegram.png').default,
        LinkedIn: require('./Images/platforms/linkedin.png').default,
        Linktree: require('./Images/platforms/linktree.png').default,
        Facebook: require('./Images/platforms/facebook.png').default,
    },
    Share: {
        Twitter: require('./Images/share/twitter.png').default,  
        Reddit: require('./Images/share/reddit.png').default,
        Telegram: require('./Images/share/telegram.png').default,
        LinkedIn: require('./Images/share/linkedin.png').default,
        Facebook: require('./Images/share/facebook.png').default,
        Messenger: require('./Images/share/messenger.png').default,
        Tumblr: require('./Images/share/tumblr.png').default,
        Pinterest: require('./Images/share/pinterest.png').default,
    }
}

export default Images;