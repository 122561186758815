import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '20px 10px'
    },
    item: {
        margin: '0 60px 0 0',
        paddingBottom: 10,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Light,
        cursor: 'pointer',
        '&:hover': {
            color: Theme.Palette.Primary.Foreground,
        }
    },
    itemDisablled: {
        margin: '0 60px 0 0',
        paddingBottom: 10,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Lighter
    },
    selectedItem: {
        fontWeight: 400,
        color: Theme.Palette.Primary.Foreground,
        borderBottom: 'solid 2px',
        borderBottomColor: Theme.Palette.Primary.Foreground
    },
    '@media only screen and (max-width: 800px)': {
        container: {
            margin: '20px 10px 10px 10px'
        },
        item: {
            margin: '0 15px',
        }
    },
});