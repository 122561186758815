import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 5,
        maxWidth: 1000
    },
    sectionTitle: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 23,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        margin: '10px 0 15px 0'
    },
    instructionText: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        textAlign: 'justify',
        margin: '10px 0'
    },
    instructionWarningText: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        textAlign: 'justify',
        margin: '10px 0 5px 0',
        color: '#a00'
    },
    priceField: {
        flex: 1,
        margin: '5px 0 10px 0'
    },
    inputField: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 'inherit',
        fontWeight: 300,
        color: Theme.TextColor.Dark,        
        border: '1px solid #ccc',
        outline: 'none',
        padding: 10,
        margin: 0,
        '&:disabled': {
            backgroundColor: '#fafafa'
        }
    },
    inputLabel: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        textAlign: 'justify',
        margin: '15px 0 5px 0'
    },
    invalidateControls: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 20
    },
    checkbox: {
        marginRight: 7
    },
    checkboxContainer: {
        flex: 1
    },
    submitButton: {
        padding: '10px 15px'
    }
});