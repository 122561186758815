import React from 'react';
import Config from '~/config';
import ProfilePicture from '../ProfilePicture';
import ProfileDetails from '../ProfileDetails';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';

const ProfileHeader = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <ProfilePicture
                size={ props.vertical ? 60 : 80 }
                imageUrl={props.profilePictureUrl}
                username={props.username}
                borderWidth={1.5}/>

            <ProfileDetails
                username={props.username}
                profileName={props.profileName}
                followers={props.followers}
                following={props.following}
                views={props.views}
                isFollowing={props.isFollowing} 
                vertical={props.vertical}
                isSelf={props.isSelf}
                onFollowClicked={props.onFollowClicked}/>
            
            <div className={classes.linksContainer}>
                {
                    ProfileHeader.Pages
                        .filter(page => props.userType === Config.User.Roles.CREATOR || !page.creatorOnly)    
                        .map(page => 
                            <Link 
                                key={page.type}
                                to={`/profiles/${props.username}/${page.link}`} 
                                className={
                                    props.currentPage === page.type ?
                                    classes.link + ' ' + classes.linkSelected :
                                    classes.link
                                }>
                                { page.name }
                            </Link>   
                        )
                }
            </div>
        </div>
    );
}

ProfileHeader.PageTypes = Object.freeze({
    CREATIONS: 'CREATIONS',
    COLLECTION: 'COLLECTION',
    ABOUT: 'ABOUT'
})

ProfileHeader.Pages = Object.freeze([
    {
        type: ProfileHeader.PageTypes.CREATIONS,
        name: 'Creations',
        link: '',
        creatorOnly: true
    },
    {
        type: ProfileHeader.PageTypes.COLLECTION,
        name: 'Collection',
        link: 'collection/',
        creatorOnly: false
    },
    {
        type: ProfileHeader.PageTypes.ABOUT,
        name: 'About',
        link: 'about/',
        creatorOnly: false
    }
]);

export default ProfileHeader;