import React from 'react';
import _ from 'lodash';

import PopupContainer from '../PopupContainer';
import Button from '~/Components/Button';

import { useStyles } from './styles';

const CreatorRequestDenyPopup = (props) => {

    const classes = useStyles();

    const [comments, setComments] = React.useState('');
    const [denyRequests, setDenyRequests] = React.useState(false);

    const handleClose = (confirm) => {
        if(_.isFunction(props.onClose))
            props.onClose(confirm);
    }

    return (
        <PopupContainer
            isOpen={props.isOpen}
            onClose={handleClose.bind(null, false)}
            backdropColor="#0009">

            <div className={classes.container}>
                <p className={classes.title}>
                    { 'Deny Request' }
                </p>

                <p className={classes.infoMessage}>
                    You're about to deny this user's request to become a creator. Before you do so, you need to provide a reason why the request is being denied.
                </p>

                <p className={classes.infoMessage}>
                    If the user is making too many unreasonable requests, please check the "Provide a reason why the request is being denied" box to deny this user the ability to make additional requests.
                </p>

                <div style={{height: 20}}/>

                <textarea
                    className={classes.inputField}
                    style={{
                        minHeight: 75
                    }}
                    type="text"
                    value={comments}
                    onChange={(event) => {setComments(event.target.value)}}
                    placeholder="Provide a reason why the request is being denied"/>

                <div className={classes.checkboxContainer}>
                    <input
                        type="checkbox"
                        id="deny-requests-checkbox"
                        className={classes.checkbox}
                        checked={denyRequests}
                        onChange={(event) => {setDenyRequests(event.target.checked)}}/>

                    <label
                        htmlFor="deny-requests-checkbox"
                        className={classes.checkboxLabel}>
                        Prevent this user from making other requests
                    </label>
                </div>

                <div className={classes.buttonContainer}>

                    <Button
                        className={classes.button}
                        disabled={!comments.trim()}
                        text={'Deny'}
                        onClick={handleClose.bind(null, {comments, denyRequests})}/>

                    <Button
                        className={classes.button}
                        text={'Cancel'}
                        secondary
                        onClick={handleClose.bind(null, null)}/>

                </div>

            </div>

        </PopupContainer>
    );
}

export default CreatorRequestDenyPopup;