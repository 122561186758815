import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '5px 0 20px 0'
    },
    item: {
        margin: '0px 10px',
        border: `1px solid ${Theme.TextColor.Lighter}`,
        padding: '7px 20px',
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Light,
        cursor: 'pointer',
        '&:hover': {
            color: Theme.TextColor.Dark,
            border: `1px solid ${Theme.TextColor.Dark}`,
        }
    },
    selectedItem: {
        fontWeight: 400,
        border: `1px solid ${Theme.TextColor.Dark}`,
        color: Theme.TextColor.Dark,
    },
    '@media only screen and (max-width: 800px)': {
        container: {
            margin: '10px 0',
            alignSelf: 'center'
        },
        item: {
            padding: '5px 15px',
            fontSize: 12,
        }
    },
});