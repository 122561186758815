import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        padding: '10px 0',
        borderBottomColor: Theme.TextColor.Lightest
    },
    userData: {
        flex: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    activityData: {
        flex: 3,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    timeData: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    usersName: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        margin: 0,
        fontSize: 12,
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        },
    },
    textField: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        margin: 2,
        fontSize: 12,
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});