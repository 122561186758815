import React from 'react';

import CreationAuthenticityDetails from '../CreationAuthenticityDetails';
import CreationDetailsList from '../CreationDetailsList';
import CreationDescription from '../CreationDescription';
import LabeledIcon from '../LabeledIcon';


import CommentIcon from '~/Assets/Vectors/CommentIcon';
import ViewIcon from '~/Assets/Vectors/ViewIcon';
import LikeIcon from '~/Assets/Vectors/LikeIcon';

import { useStyles } from './styles';
import CreationActions from './CreationActions';
import ShareIcon from '~/Assets/Vectors/ShareIcon';
import PopupManager from '~/Auxilary/PopupManager';
import Config from '~/config';

const CreationInfo = (props) => {
    const classes = useStyles();

    return (
        <div className={props.className || classes.container}>

            <p className={classes.title}>
                {props.name}
            </p>

            <CreationActions
                status={props.status}
                price={props.price}
                directPurchasePrice={props.directPurchasePrice}
                isOwner={props.isOwner}
                owner={props.owner}
                auction={props.auction}
                onMakeOfferClick={props.onMakeOfferClick}
                onPurchaseClick={props.onPurchaseClick}
                onBidClick={props.onBidClick}
                onFinishAuctionClick={props.onFinishAuctionClick}/>


            <div className={classes.iconsContainer}>

                <LabeledIcon
                    icon={LikeIcon}
                    text={props.likes}
                    active={props.liked}
                    clickable
                    onClick={props.onLikeClicked}/>

                <LabeledIcon
                    icon={CommentIcon}
                    text={props.comments}
                    clickable
                    onClick={() => {}}/>

                <LabeledIcon
                    icon={ViewIcon}
                    text={props.views}
                    clickable
                    onClick={() => {}}/>

                <LabeledIcon
                    icon={ShareIcon}
                    text={'Share'}
                    clickable
                    onClick={() => {
                        PopupManager.pushSharePopup(
                            `${Config.Server.API_BASE_URL}/creations/${props.creationId}/share`
                        );
                    }}/>

            </div>

            <CreationAuthenticityDetails
                creationId={props.creationId}
                creators={props.creators}
                owner={props.owner}
                release={props.release}
                contract={props.contract}
                tokenId={props.tokenId}/>

            <CreationDescription
                text={props.description}/>
            
            <div className={classes.divider}/>

            <CreationDetailsList
                detailsList={props.detailsList}/>

        </div>
    );
}

export default CreationInfo;