import _ from 'lodash';
import React from 'react';
import Button from '~/Components/Button';
import LabeledInputField from '~/Components/LabeledInputField';
import { useStyles } from './styles';

const GiftCreation = (props) => {

    const classes = useStyles();
    const [recipientAddress, setRecipientAddress] = React.useState("");
    const [understandRisk, setUnderstandRisk] = React.useState(false); 

    const handleUnderstandRiskChanged = (target) => {
        setUnderstandRisk(target.target.checked);
    }

    const handleGiftClicked = () => {
        if(understandRisk && _.isFunction(props.onGiftClicked))
            props.onGiftClicked(recipientAddress)
    }

    return (
        <div className={classes.container}>

            <p className={classes.sectionTitle}>
                Gift this creation
            </p>

            <p className={classes.instructionText}>
                In this tab, you can send this creation to another address. This operation is ireversible and therefore dangerous. Please make sure you completely understand what you're doing before you proceed!
            </p>

            <p className={classes.instructionWarningText}>
                Please double-check the address you're sending to!
            </p>

            <LabeledInputField
                className={classes.priceField}
                onChange={setRecipientAddress}
                placeholder="Recipient's address"
                labelFirst
                labelText="Address"
                value={recipientAddress}/>

            <div className={classes.invalidateControls}>
                <div className={classes.checkboxContainer}>
                    <input 
                        className={classes.checkbox}
                        onChange={handleUnderstandRiskChanged}
                        value={understandRisk}
                        disabled={props.disabled}
                        type="checkbox" 
                        id="understand-risks"/>
                    
                    <label 
                        className={classes.checkboxLabel} 
                        htmlFor="understand-risks">
                        I understand the risks involved
                    </label>
                </div>

                <Button
                    className={classes.submitButton}
                    onClick={handleGiftClicked}
                    disabled={!understandRisk}
                    text="Send creation"/>

            </div>
        </div>
    );
}

export default GiftCreation;