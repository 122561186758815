import * as API from '~/API';

import {
    DISCOVER_FETCH_START,
    DISCOVER_FETCH_FINISHED,
    DISCOVER_FETCH_FAILED,
    DISCOVER_SUGGESTIONS_FETCH_START,
    DISCOVER_SUGGESTIONS_FETCH_FINISHED,
    DISCOVER_SUGGESTIONS_FETCH_FAILED,
    SEARCH_FETCH_START,
    SEARCH_FETCH_FINISHED,
    SEARCH_FETCH_FAILED,
} from './types';

export const getDiscoverContents = (contentType, contentOrder) => {
    return (dispatch, getState) => {
        
        const { Discover } = getState();

        const isSameContent = 
            Discover.contentType === contentType && 
            Discover.contentOrder === contentOrder;

        if(isSameContent && Discover.isEverythingLoaded)
            return;

        const offset = isSameContent ? Discover.data.length : 0;

        dispatch({ 
            type: DISCOVER_FETCH_START, 
            payload: { 
                contentType, 
                contentOrder 
            } 
        });

        let callPromise = null;

        if(contentType === 'CREATION')
            callPromise = API.Discover.getCreations(contentOrder, offset);
        else 
        if(contentType === 'CREATOR' || contentType === 'COLLECTOR')
            callPromise = API.Discover.getProfiles(contentType, contentOrder, offset);
        else
            callPromise = API.Discover.getActivity(offset);

        callPromise.then(data => {
            dispatch({ 
                type: DISCOVER_FETCH_FINISHED, 
                payload: {
                    contentType, 
                    contentOrder,
                    data
                }
            });
        })
        .catch(error => {
            dispatch({ 
                type: DISCOVER_FETCH_FAILED, 
                payload: {
                    contentType, 
                    contentOrder,
                    error
                }
            });
        })
    };  
};

export const getSuggestedContents = (contentType, contentOrder) => {
    return (dispatch, getState) => {

        const { Discover } = getState();

        const isSameContent = 
            Discover.suggestionsContentType === contentType && 
            Discover.suggestionsContentOrder === contentOrder;

        if(isSameContent)
            return;

        dispatch({ 
            type: DISCOVER_SUGGESTIONS_FETCH_START, 
            payload: { 
                contentType, 
                contentOrder 
            } 
        });

        let callPromise = null;

        if(contentType === 'CREATION')
            callPromise = API.Discover.getCreations(contentOrder);
        else 
        if(contentType === 'CREATOR' || contentType === 'COLLECTOR')
            callPromise = API.Discover.getProfiles(contentType, contentOrder);
        else
            callPromise = API.Discover.getActivity();

        callPromise.then(data => {
            dispatch({ 
                type: DISCOVER_SUGGESTIONS_FETCH_FINISHED, 
                payload: {
                    contentType, 
                    contentOrder,
                    data
                }
            });
        })
        .catch(error => {
            dispatch({ 
                type: DISCOVER_SUGGESTIONS_FETCH_FAILED, 
                payload: {
                    contentType, 
                    contentOrder,
                    error
                }
            });
        })
    };  
};

export const search = (query) => {
    return (dispatch) => {

        dispatch({ 
            type: SEARCH_FETCH_START, 
            payload: { 
                contentType: 'CREATION', 
                contentOrder: null
            } 
        });

        API.Discover
            .search(query)
            .then(data => {
                console.log(data)
                dispatch({ 
                    type: SEARCH_FETCH_FINISHED, 
                    payload: {
                        contentType: 'CREATION', 
                        contentOrder: null,
                        data
                    }
                });
            })
            .catch(error => {
                dispatch({ 
                    type: SEARCH_FETCH_FAILED, 
                    payload: {
                        contentType: 'CREATION', 
                        contentOrder: null,
                        error
                    }
                });
            })
    };  
};
