import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        marginTop: 5
    },
    profileName: {
        margin: 0,
        fontFamily: Theme.Font.Secondary,
        textDecoration: 'none',
        fontSize: 26,
        color: Theme.TextColor.Dark,
        cursor: 'pointer'
    },
    detailContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '7px 0 7px 0',
        cursor: 'pointer'
    },
    detailText: {
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        fontWeight: 300,
        color: Theme.TextColor.Light,
        margin: 0,
        '&:hover': {
            textDecoration: 'underline',
            color: Theme.Palette.Primary.Foreground
        },
        transition: '0.2s ease'
    },
    detailTextSimple: {
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        fontWeight: 300,
        color: Theme.TextColor.Light,
        margin: 0,
        cursor: 'default'
    },
    detailSpacer: {
        fontFamily: Theme.Font.Primary,
        fontSize: 11,
        cursor: 'default',
        color: Theme.TextColor.Dark,
        margin: '0 2px 0 2px'
    },
    '@media only screen and (max-width: 1024px)': {
        container: {
            alignItems: 'center'
        }
    }
});