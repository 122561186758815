import React from 'react';
import * as API from '~/API';

import { useStyles } from './styles';
import Button from '~/Components/Button';
import LabeledInputField from '~/Components/LabeledInputField';
import OrderedItemList from '~/Components/OrderedItemList';
import FileInputField from '~/Components/FileInputField';
import PopupManager from '~/Auxilary/PopupManager';
import DisableArea from '~/Components/DisableArea';

const EditDrop = (props) => {

    const classes = useStyles();
    
    const [dataReloadRequired, setDataReloadRequired] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isSaving, setIsSaving] = React.useState(false);
    const [isChangingApproval, setIsChangingApproval] = React.useState(false);
    const [previewUploadProgress, setPreviewUploadProgress] = React.useState(null);
    const [bannerUploadProgress, setBannerUploadProgress] = React.useState(null);

    const [isApproved, setIsApproved] = React.useState(false);
    const [dropName, setDropName] = React.useState('');
    const [dropDescription, setDropDescription] = React.useState('');

    const [startTime, setStartTime] = React.useState('');
    const [endTime, setEndTime] = React.useState('');

    const [artistName, setArtistName] = React.useState('');
    const [artistDescription, setArtistDescription] = React.useState('');
    const [creatorsList, setCreatorsList] = React.useState([]);
    const [advastId, setAdvastId] = React.useState('');

    const [hasBanner, setHasBanner] = React.useState(true);
    const [bannerDescription, setBannerDescription] = React.useState('');

    const [showCreations, setShowCreations] = React.useState(true);
    const [creationsList, setCreationsList] = React.useState([]);

    React.useEffect(
        () => {
            if(dataReloadRequired && props.dropId) {
                setDataReloadRequired(false);
                setIsLoading(true);
                API.Drop
                    .getDrop(props.dropId)
                    .then(data => {

                        let startTimeValue = (new Date(data.startTime)).toISOString()
                        let endTimeValue = (new Date(data.endTime)).toISOString()

                        setIsApproved(!!data.approved);
                        setDropName(data.name || '');
                        setDropDescription(data.description || '');
                        setStartTime(startTimeValue.substr(0, startTimeValue.length-1));
                        setEndTime(endTimeValue.substr(0, endTimeValue.length-1));
                        setArtistName(data.artist.name || '');
                        setArtistDescription(data.artist.description || '');
                        setCreatorsList((data.creators || []).map(item => item.username));
                        setCreationsList((data.creations || []).map(item => item.creationId));
                        setHasBanner(!!data.banner.hasBanner);
                        setShowCreations(!!data.showCreations)
                        setBannerDescription(data.banner.description || '');
                        setAdvastId(data.banner?.advastId || '');

                        setIsLoading(false);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    })
            }
            
        }, 
        [ props.dropId, dataReloadRequired ]
    );

    const handleSaveChanges = () => {
        setIsSaving(true);
        API.Drop
            .patch(
                props.dropId, 
                {
                    name: dropName,
                    description: dropDescription,
                    creators: creatorsList,
                    showCreations: showCreations,
                    creations: creationsList,
                    startTime: startTime,
                    endTime: endTime,
                    artistName: artistName,
                    artistDescription: artistDescription,
                    hasBanner: hasBanner,
                    bannerDescription: bannerDescription,
                    advastId
                })
            .then(() => {
                setIsSaving(false);
                PopupManager.pushMessagePopup(
                    'Drop Saved',
                    ['The changes made to this drop have been saved. If the drop is currently public, the users will immediately see those changes.'],
                    'PASS'
                )
            })
            .catch((error) => {
                setIsSaving(false);
                PopupManager.pushMessagePopup(
                    'Save failed',
                    ['Failed to save the changes made to this drop.', error],
                    'FAIL'
                )
            })
    }

    const handleAddCreator = () => {
        PopupManager.pushAddCreatorPopup()
        .then(username => {
            if(username) {
                if(!creatorsList.find(item => item === username))
                    setCreatorsList([
                        ...creatorsList,
                        username
                    ])
            }
        })
    }

    const handleAddCreation = () => {
        PopupManager.pushAddCreationPopup()
        .then(creationId => {
            if(creationId) {
                if(!creatorsList.find(item => item === creationId))
                    setCreationsList([
                        ...creationsList,
                        creationId
                    ])
            }
        })
    }

    const handleChangeApproval = (approved) => {
        setIsChangingApproval(true);
        API.Drop
            .patch(
                props.dropId, 
                { approved }
            )
            .then(() => {
                setIsChangingApproval(false);
                setIsApproved(approved);
            })
            .catch(() => {
                setIsChangingApproval(false);
            })
    }

    const handleUploadPreview = (file) => {
        API.Drop
            .uploadPreview(
                props.dropId,
                file,
                setPreviewUploadProgress
            )
            .then(() => {
                setPreviewUploadProgress(null);
            })
            .catch(() => {
                setPreviewUploadProgress(null);
            })
    }

    const handleUploadBanner = (file) => {
        API.Drop
            .uploadBanner(
                props.dropId,
                file,
                setBannerUploadProgress
            )
            .then(() => {
                setBannerUploadProgress(null);
            })
            .catch(() => {
                setBannerUploadProgress(null);
            })
    }

    return (
        <div className={classes.container}>

            <div className={classes.headerContainer}>
                <p className={classes.title}>
                    Edit Drop
                </p>

                <Button
                    className={classes.approvalButton}
                    text={
                        isChangingApproval ? 
                        'Updating...' :
                        isApproved ?
                        'Make Private' :
                        'Make Public'
                    }
                    secondary={isApproved}
                    isLoading={isChangingApproval}
                    onClick={() => {
                        handleChangeApproval(!isApproved)
                    }}/>
            </div>

            <DisableArea 
                className={classes.disableArea}
                disabled={isLoading}>

                <div style={{height: 30}}/>

                <LabeledInputField
                    className={classes.usernameField}
                    labelText="Name"
                    placeholder="Drop name"
                    value={dropName}
                    onChange={setDropName}
                    labelFirst/>


                <p className={classes.label}>
                    Drop Description:
                </p>

                <textarea
                    className={classes.inputField}
                    style={{
                        minHeight: 200
                    }}
                    type="text"
                    value={dropDescription}
                    onChange={(event) => {setDropDescription(event.target.value)}}
                    placeholder="One or two paragraphs that highlights your background, story and achievements."/>

                <p className={classes.label}>
                    LIVE period:
                </p>

                <div className={classes.rowFieldsContainer}>
                    <input
                        className={classes.inputField}
                        style={{
                            resize: 'none'
                        }}
                        type="datetime-local"
                        value={startTime}
                        onChange={(event) => {setStartTime(event.target.value)}}/>
                    
                    <div className={classes.timeSpacer}>
                        through
                    </div>

                    <input
                        className={classes.inputField}
                        style={{
                            resize: 'none'
                        }}
                        type="datetime-local"
                        value={endTime}
                        min={startTime}
                        onChange={(event) => {setEndTime(event.target.value)}}/>
    
                </div>

                <div style={{height: 30}}/>

                <p className={classes.smallTitle}>
                    Artist
                </p>

                <LabeledInputField
                    className={classes.usernameField}
                    labelText="Name"
                    placeholder="Artist name"
                    value={artistName}
                    onChange={setArtistName}
                    labelFirst/>

                <p className={classes.label}>
                    Artist Description:
                </p>

                <textarea
                    className={classes.inputField}
                    style={{
                        minHeight: 200
                    }}
                    type="text"
                    value={artistDescription}
                    onChange={(event) => {setArtistDescription(event.target.value)}}
                    placeholder="One or two paragraphs that describe the artist."/>

                <p className={classes.label}>
                    Creators involved:
                </p>

                <OrderedItemList 
                    addText="Add Creator"
                    itemList={creatorsList}
                    setItemList={setCreatorsList}
                    onAddClicked={handleAddCreator}/>


                <div style={{height: 30}}/>

                <p className={classes.smallTitle}>
                    Preview
                </p>

                <FileInputField
                    className={classes.fileInputField}
                    maxSize={100}
                    fileType="VIDEO"
                    text={
                        previewUploadProgress ?
                        `Uploading preview: ${Math.round(previewUploadProgress * 100)}%` :
                        'Preview Video'
                    }
                    onFileSelected={handleUploadPreview}/>


                <div style={{height: 30}}/>

                <div className={classes.headerContainer}>
                    <input 
                        id="has-banner"
                        checked={hasBanner}
                        onChange={target => { setHasBanner(target.target.checked) }}
                        className={classes.titleCheckbox} 
                        type="checkbox"/>
                    
                    <label 
                        htmlFor="has-banner"
                        className={classes.smallTitle}>
                        Banner
                    </label>
                </div>

                <DisableArea 
                    className={classes.disableArea}
                    disabled={!hasBanner}>

                    <FileInputField
                        className={classes.fileInputField}
                        maxSize={100}
                        fileType="VIDEO"
                        text={
                            bannerUploadProgress ?
                            `Uploading banner: ${Math.round(bannerUploadProgress * 100)}%` :
                            'Banner Video'
                        }
                        onFileSelected={handleUploadBanner}/>

                    <textarea
                        className={classes.inputField}
                        style={{
                            minHeight: 100
                        }}
                        type="text"
                        value={bannerDescription}
                        onChange={(event) => {setBannerDescription(event.target.value)}}
                        placeholder="A single short paraghraph describing this drop."/>

                </DisableArea>


                <div style={{height: 30}}/>

                <div className={classes.headerContainer}>
                    <input 
                        id="show-creations"
                        checked={showCreations}
                        onChange={target => { setShowCreations(target.target.checked) }}
                        className={classes.titleCheckbox} 
                        type="checkbox"/>
                    
                    <label 
                        htmlFor="show-creations"
                        className={classes.smallTitle}>
                        Creations
                    </label>
                </div>

                <DisableArea 
                    className={classes.disableArea}
                    disabled={!showCreations}>
                    <OrderedItemList 
                        addText="Add Creation"
                        itemList={creationsList}
                        setItemList={setCreationsList}
                        onAddClicked={handleAddCreation}
                        disabled={!showCreations}/>
                </DisableArea>

                <div style={{height: 20}}/>

                <p className={classes.smallTitle}>
                    adVAST ID
                </p>

                <LabeledInputField
                    className={classes.usernameField}
                    labelText="ID"
                    placeholder="adVAST banner removed if left empty"
                    value={advastId}
                    onChange={setAdvastId}
                    labelFirst/>

                <div style={{height: 30}}/>

                <div className={classes.buttonContainer}>
                    <Button
                        className={classes.updateButton}
                        onClick={handleSaveChanges}
                        isLoading={isSaving}
                        inverted
                        text={
                            isSaving ? 
                            'Saving...' :
                            'Save Changes'
                        }/>


                </div>

            </DisableArea>

            <div style={{height: 50}}/>

        </div>
    );
}

export default EditDrop;