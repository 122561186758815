import React from 'react';
import _ from 'lodash';

import { useStyles } from './styles';

const TableHeader = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {
                (props.headerItems || [])
                    .map((item, index) =>
                        _.isObject(item) ? 
                        <div 
                            key={item.text}
                            className={classes.headerItem}
                            style={{
                                flex: item.flex
                            }}>
                            <p style={{ paddingLeft: index === 0 ? 15 : 0}}>
                                { item.text }
                            </p>
                        </div> :
                        <div 
                            key={item}
                            className={classes.headerItem}>
                            <p style={{ paddingLeft: index === 0 ? 15 : 0}}>
                                { item }
                            </p>
                        </div>
                    )
            }
        </div>
    );
}

export default TableHeader;