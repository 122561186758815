import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        backgroundColor: 'black',
        marginTop: 20,
        padding: '40px 40px 20px 40px'
    },
    footerBody: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
    },
    copyright: {
        flex: 1,
        textAlign: 'center',
        backgroundColor: 'black',
        fontFamily: Theme.Font.Secondary,
        color: Theme.TextColor.Lighter,
        fontSize: 13,
        marginTop: 10
    },
    column: {
        flex: 1,
        marginBottom: 20
    },
    logo: {
        width: 120,
        marginBottom: 10
    },
    message: {
        fontFamily: Theme.Font.Secondary,
        color: Theme.TextColor.Lighter,
        fontSize: 15,
        margin: 5
    },
    platformsTitle: {
        fontFamily: Theme.Font.Secondary,
        color: Theme.TextColor.Lightest,
        textAlign: 'right',
        margin: '15px 5px',
        fontSize: 21,
    },
    platformsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    platformItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#222',
        borderRadius: 5,
        padding: 8,
        marginLeft: 10,
        marginBottom: 10,
        '&:hover': {
            backgroundColor: '#333',
        }
    },
    platformIcon: {
        width: 20,
        height: 20
    },
    linksContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10
    },
    link: {
        fontFamily: Theme.Font.Secondary,
        color: Theme.TextColor.Light,
        fontSize: 15,
        margin: 5,
        '&:hover': {
            color: Theme.TextColor.Lighter
        }
    },
    detailSpacer: {
        fontFamily: Theme.Font.Secondary,
        color: Theme.TextColor.Lighter,
        fontSize: 14,
        margin: 5,
    },
    '@media only screen and (max-width: 1024px)': {
        footerBody: {
            flexDirection: 'column'
        },
        platformsTitle: {
            textAlign: 'left',
            marginTop: 0
        },
        platformsContainer: {
            flex: 1,
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
        },
        platformItem: {
            marginLeft: 5,
            marginRight: 5
        }
    }
});