import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    backdrop: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 10000,
        transition: '0.2s ease',
    },
    messageContainer: {
        padding: '0 40px',
        display: 'flex',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff8',
    },
    loader: {
        width: 30,
        height: 30,
        marginRight: 15,
        opacity: 0.8,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    },
    loadingText: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        fontSize: 25
    }
});