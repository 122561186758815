import API from './api';

class Admin {
    
    static async getUser(username) {
        return API.get(
            `/admin/users/${username}`, 
            true
        );
    }

    static async updateCreatorRequest(username, makeCreator, denyRequests, comments) {
        return API.patch(
            `/admin/users/${username}/creator-request`,
            { makeCreator, denyRequests, comments },
            true
        );
    }

    static async deleteUser(username) {
        return API.delete(
            `/admin/users/${username}`,
            true
        );
    }

    static async getCreatorRequests() {
        return API.get(
            `/admin/creator-requests`,
            true
        );
    }

    static async makeFeaturedCreation(creationId) {
        return API.patch(
            `/admin/creations/${creationId}/make-featured`,
            { },
            true
        );
    }

    static async makeFeaturedCreator(username) {
        return API.patch(
            `/admin/users/${username}/make-featured`,
            { },
            true
        );
    }
}

export default Admin;