import React from 'react';
import Helpers from '~/helpers';
import Config from '~/config';

import PixelatedButton from '../../PixelatedButton';

import { useStyles } from './styles';

const AuctionActions = ({status, isOwner, owner, auction, onBidClick, onFinishAuctionClick}) => {
    const classes = useStyles();

    const [timeRemaining, setTimeRemaining] = React.useState(0);
    const [isAuctionEnded, setIsAuctionEnded] = React.useState(false);
    const [currentBidPrice, setCurrentBidPrice] = React.useState(0);

    React.useEffect(
        () => {
            setIsAuctionEnded(
                new Date(auction?.startTime).getTime() > 0 && 
                new Date(auction?.endTime).getTime() < Date.now() && 
                new Date(auction?.lastBidTime).getTime() + 15 * 60 * 1000 < Date.now()
            )

            setCurrentBidPrice(
                Helpers.weiToEth(auction?.lastBidAmount) ?
                Helpers.weiToEth(auction?.lastBidAmount) * 1.05 :
                Helpers.weiToEth(auction?.startPrice)
            )
        },
        [auction, timeRemaining]
    );

    React.useEffect(
        () => {
            const timer = setInterval(() => {
                let startTime = new Date(auction?.startTime).getTime();
                let endTime = new Date(auction?.endTime).getTime();
                let lastBidTime = new Date(auction?.lastBidTime).getTime();

                let newAuctionEnded = 
                    startTime > 0 && 
                    endTime < Date.now() && 
                    lastBidTime + Config.Platform.AUCTION_EXTEND_TIME < Date.now();

                let auctionEndTime = 
                    endTime > lastBidTime + Config.Platform.AUCTION_EXTEND_TIME ?
                    endTime : lastBidTime + Config.Platform.AUCTION_EXTEND_TIME;

                setTimeRemaining(
                    newAuctionEnded ? 0 :
                    startTime === 0 && endTime === 0 ? 0 :
                    auctionEndTime > Date.now() ? auctionEndTime - Date.now() : 0
                );

                setIsAuctionEnded(newAuctionEnded);
            }, 1000);
            return () => clearInterval(timer);
        }, 
        [setIsAuctionEnded, auction]
    );

    const getFormattedAuctionTime = (time) => {
        let days = parseInt(time / 24 / 60 / 60 / 1000);
        let hours = parseInt(time / 60 / 60 / 1000 % 24);
        let minutes = parseInt(time  / 60 / 1000 % 60);
        let seconds = parseInt(time / 1000 % 60);

        let minutesString = minutes > 9 ? minutes : '0' + minutes;
        let secondsString = seconds > 9 ? seconds : '0' + seconds;

        return (
            days ?
            `${days}d ${hours}h ${minutesString}m ${secondsString}s` :
            hours ?
            `${hours}h ${minutesString}m ${secondsString}s` :
            `${minutesString}m ${secondsString}s`
        )
    }

    return (
        status === 'AUCTION' && auction ?
        <div className={classes.container}>

            {
                !!timeRemaining &&
                <p className={classes.auctionTime}>Auction closes in: {getFormattedAuctionTime(timeRemaining)}</p>
            }

            {
                !timeRemaining &&
                <p className={classes.auctionTime}>Auction time: {getFormattedAuctionTime(auction?.time)}</p>
            }

            <p className={classes.price}>
                {Helpers.formatEthPrice(currentBidPrice)} {Config.Blockchain.COIN} (
                <strong>${Helpers.formatUsdPrice(Helpers.getEthPrice() * currentBidPrice)}</strong>
                )
            </p>

            {
                isAuctionEnded ?
                <PixelatedButton
                    text="FINISH AUCTION"
                    style={{
                        width: 200,
                        height: 45,
                        marginRight: 15
                    }}
                    foregroundColor='#EE5326'
                    onClick={onFinishAuctionClick}
                    inverted/>
                : 
                !isOwner && owner.username !== null ?
                <PixelatedButton
                    text="BID"
                    style={{
                        width: 200,
                        height: 45,
                        marginRight: 15
                    }}
                    foregroundColor='#69BD45'
                    onClick={onBidClick}
                    inverted/> 
                :
                null
            }

        </div>
        :
        null
    );
}

export default AuctionActions;