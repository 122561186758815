import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATE_PROCESSING } from '~/Redux/states';
import * as UserActions from '~/Redux/User/actions';
import * as ProfileActions from '~/Redux/Profile/actions';

import PopupContainer from '../PopupContainer';
import Button from '~/Components/Button';

import { useStyles } from './styles';
import FollowerItem from './FollowerItem';

const FollowerPopup = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const dataList = useSelector(state => 
        props.isFollowing ?
        state.Profile.following :
        state.Profile.followers
    );

    const dataState = useSelector(state => 
        props.isFollowing ?
        state.Profile.followingState :
        state.Profile.followersState
    );

    const dataLoaded = useSelector(state => 
        props.isFollowing ?
        state.Profile.followingLoaded :
        state.Profile.followersLoaded
    );

    const userFollowing = useSelector(state => 
        state.User.data?.following || []
    );

    const selfUsername = useSelector(state => 
        state.User.data?.username
    );

    const handleLoadMore = () => {
        if(dataState === STATE_PROCESSING)
            return;

        dispatch(
            props.isFollowing ?
            ProfileActions.getFollowing(props.username) :
            ProfileActions.getFollowers(props.username)
        );
    }

    const handleFollowUser = (username, follow) => {
        dispatch(UserActions.setFollowing(username, follow));
    }

    React.useEffect(
        () => {
            dispatch(
                props.isFollowing ?
                ProfileActions.getFollowing(props.username) :
                ProfileActions.getFollowers(props.username)
            );
        }, 
        [dispatch, props.username, props.isFollowing]
    )

    return (
        <PopupContainer
            isOpen={props.isOpen}
            onClose={props.onClose}
            backdropColor="#0009">

            <div className={classes.container}>
                <p className={classes.title}>
                    {`User ${props.isFollowing ? 'Following' : 'Followers'}`}
                </p>

                <div className={classes.followerListContainer}>
                    {
                        dataList.length === 0 ?
                        <div className={classes.noUsers}>
                            { 
                                props.isFollowing ? 
                                'User does not follow any profiles' :
                                'User has no followers'
                            }
                        </div> 
                        :
                        dataList.map(item => 
                            <FollowerItem
                                key={item.username}
                                username={item.username}
                                name={item.name}
                                profilePictureUrl={item.profilePictureUrl}
                                isYou={item.username === selfUsername}
                                isFollowing={userFollowing.includes(item.username)}
                                onFollowUserAction={handleFollowUser}
                                onItemClicked={props.onClose}/>
                        )
                    }

                    {
                        dataLoaded ? null :
                        <div className={classes.buttonContainer}>
                            <Button
                                className={classes.button}
                                isLoading={dataState === STATE_PROCESSING}
                                text={
                                    dataState === STATE_PROCESSING ?
                                    "Loading..." :
                                    "Load more"
                                }
                                onClick={handleLoadMore}/>
                        </div>
                    }
                </div>

            </div>

        </PopupContainer>
    );
}

export default FollowerPopup;