import React, { useState } from 'react';
import { User } from '~/API';
import PopupManager from '~/Auxilary/PopupManager';
import Button from '../Button';
import { useStyles } from './styles';

const IDOBar = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSignUp = () => {
        setIsSubmitting(true);
        User.subscribeToNewsletter(email)
        .then(() => {
            setIsSubmitting(false);
            PopupManager.pushMessagePopup(
                'Subscribed',
                ['Thank you for subscribing to our newsletter! We will keep you up to date with our IDO progress.']
            )
        })
        .catch(() => {
            setIsSubmitting(false);
            PopupManager.pushMessagePopup(
                'Failed to subscribe',
                ["Something went wrong and we weren't able to subscribe you to our newsletter. Please inform the tech support team about this issue!"], 
                'FAIL'
            )
        })
    }

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                🔥 Initial DEX Offering 🔥
            </div>

            <div className={classes.message}>
                Subscribe to receive news, updates, and launch dates for our upcoming IDO.
            </div>

            <div className={classes.inputContainer}>
                <input 
                    className={classes.input}
                    type="email" 
                    value={email}
                    onChange={(event) => {setEmail(event.target.value)}}
                    placeholder="Enter your email"/>

                <Button 
                    className={classes.signupButton} 
                    text="SIGN UP"
                    onClick={handleSignUp}
                    isLoading={isSubmitting}
                    secondary/>
            </div>

            <a 
                href="https://ido.vast.app/"
                className={classes.pageLink}
                style={{marginRight: 20}}>
                🦄 IDO Page
            </a>

            <div className={classes.disclaimer}>
                **By signing up you agree to receiving emails from <span style={{color: 'white'}}>V A S T</span>
            </div>
        </div>
    );
}

export default IDOBar;