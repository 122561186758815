import React from 'react';
import { Link } from 'react-router-dom';
import Helpers from '~/helpers';
import Config from '~/config';
import _ from 'lodash';

import Button from '~/Components/Button';
import ProfilePicture from '~/Components/ProfilePicture';

import { useStyles } from './styles';

const SettingsCreationItem = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.container}>

            <div 
                className={classes.descriptionContainer}
                style={{flex: props.descriptionFlex}}>
                <Link to={`/creations/${props.creationId}`}>
                    <div 
                        className={classes.itemPreview}
                        style={{
                            background: `url('${props.previewUrl}')`
                        }}>
                    </div>
                </Link>

                <div className={classes.column}>

                    <Link
                        to={`/creations/${props.creationId}`} 
                        className={classes.itemName}>
                        {props.name}
                    </Link>

                    <p className={classes.itemEdition}>
                        Edition: #{props.edition}
                    </p>

                </div>
            </div>

            {
                props.userColumnFlex &&
                <div 
                    className={classes.column}
                    style={{flex: props.userColumnFlex}}>

                    <div className={classes.userDataContainer}>
                        <ProfilePicture
                            style={{
                                margin: '5px 15px 5px 0'
                            }}
                            username={props.username}
                            imageUrl={props.profilePictureUrl} 
                            size={35}/>

                        <Link 
                            to={`/profiles/${props.username}/`}
                            className={classes.linkedField}>
                            {props.usersName}
                        </Link>
                    </div>

                </div>
            }

            {
                props.statusFlex &&
                <div 
                    className={classes.column}
                    style={{flex: props.statusFlex}}>

                    <p className={classes.status}>
                        { props.status }
                    </p>

                </div>
            }

            <div 
                className={classes.columnHorizontal}
                style={{flex: props.priceColumnFlex}}>

                {
                    !_.isUndefined(props.price) &&
                    <p className={classes.price}>
                        {
                            Helpers.weiToEth(props.price) ? 
                            `${Helpers.formatEthPrice(Helpers.weiToEth(props.price))} ${Config.Blockchain.COIN}` :
                            `--`
                        }
                    </p>
                }

                {
                    props.onAcceptClicked &&
                    <Button
                        className={classes.button}
                        onClick={props.onAcceptClicked.bind(null, props.offerId)}
                        text="Accept"/>                
                }

                {
                    props.onDeclineClicked &&
                    <Button
                        className={classes.button}
                        secondary
                        onClick={props.onDeclineClicked.bind(null, props.offerId)}
                        text="Decline"/>
                }

                {
                    props.onManageClicked &&
                    <Button
                        className={classes.button}
                        onClick={props.onManageClicked.bind(null, props.creationId)}
                        text="Manage"/>
                }

                {
                    props.onPurchaseClicked &&
                    <Button
                        className={classes.button}
                        onClick={props.onPurchaseClicked.bind(null, props.offerId)}
                        text="Purchase"/>
                }

                {
                    props.onEditClicked &&
                    <Button
                        className={classes.button}
                        onClick={props.onEditClicked}
                        text="Edit request"/>
                }

            </div>

        </div>
    );
}

export default SettingsCreationItem;