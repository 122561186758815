import React from 'react';
import Helpers from '~/helpers';
import Config from '~/config';

import PopupManager from '~/Auxilary/PopupManager';
import Button from '~/Components/Button';
import { useStyles } from './styles';

const PurchaseSection = (props) => {

    const classes = useStyles();

    const handleBuyCrypto = () => {
        PopupManager.pushBuyCryptoPopup(
            props.userAddress.address, 
            props.userAddress.addressSignature
        )
    }

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Purchase details
            </p>

            <p className={classes.purchaseDescription}>
                We will use your digital wallet to submit your offer. You'll be asked to confirm your offer amount with your digital wallet.
            </p>

            <div className={classes.priceDetailsContainer}>
                <p className={classes.paymentAmountLabel}>
                    Payment Amount:
                </p>

                <p className={classes.price}>
                    {Helpers.formatEthPrice(Helpers.weiToEth(props.price))} {Config.Blockchain.COIN}
                </p>
            </div>

            <Button
                className={classes.purchaseButton}
                onClick={props.onPurchaseClicked}
                isLoading={props.isPurchasing}
                text={
                    props.isPurchasing ?
                    "Purchasing..." :
                    "Purchase"
                }
                inverted/>

            <p className={classes.whatAreYouPurchasing} onClick={handleBuyCrypto}>
                Not enough funds? <strong>Buy {Config.Blockchain.COIN}!</strong>
            </p>
        </div>
    );
}

export default PurchaseSection;