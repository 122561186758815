import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    container: {
        width: 900,
        display: 'flex',
        flexDirection: 'column'
    },
    iframe: {
        minHeight: 630,
        border: 'none'
    },
    '@media only screen and (max-width: 500px)': {
        container: {
            width: '100%'
        }
    },
});