import React from 'react';
import Images from '~/Assets/images';
import Config from '~/config';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Footer = (props) => {
    const classes = useStyles();

    const pageHeight = useSelector(state => state.UI.pageHeight);
    const windowHeight = useSelector(state => state.UI.windowHeight);

    return (
            <div 
                className={classes.container}
                style={{
                    position: pageHeight < windowHeight ? 'fixed' : 'relative',
                    left: 0,
                    right: 0,
                    bottom: 0
                }}
                >
                <div className={classes.footerBody}>
                    <div className={classes.column}>
                        <img 
                            alt="" 
                            className={classes.logo}
                            src={Images.Logo}/>

                        <p className={classes.message}>
                            The first multimedia marketplace for buying and selling NFTs.
                        </p>
                        <p className={classes.message}>
                            This is made possible through the use of blockchain technology.
                        </p>

                        <div className={classes.linksContainer}>
                            <Link to="/termsofservice" className={classes.link}>
                                Terms of Service
                            </Link>

                            <div className={classes.detailSpacer}>•</div>

                            <Link to="/privacypolicy" className={classes.link}>
                                Privacy Policy
                            </Link>

                            <div className={classes.detailSpacer}>•</div>

                            <Link to="/faq" className={classes.link}>
                                FAQ
                            </Link>

                            <div className={classes.detailSpacer}>•</div>

                            <Link to="/giveaway" className={classes.link}>
                                Giveaway
                            </Link>

                        </div>
                    </div>
                    <div className={classes.column}>
                        <p className={classes.platformsTitle}>
                            Join our NFT Squad.
                        </p>
                        <div className={classes.platformsContainer}>
                            {
                                (Config.Platform.Community || [])
                                .map(item =>
                                    <a 
                                        key={item.type}
                                        href={item.url}
                                        className={classes.platformItem}>
                                        <img 
                                            alt=""
                                            className={classes.platformIcon}
                                            src={Images.Platforms[item.type]}/>
                                    </a>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className={classes.copyright}>
                    © {new Date().getFullYear()} GetVASTapp, LLC | All rights reserved
                </div>
            </div>
    );
}

export default Footer;