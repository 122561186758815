import React from 'react';
import _ from 'lodash';

import PopupContainer from '../PopupContainer';
import { useStyles } from './styles';
import PopupManager from '~/Auxilary/PopupManager';
import Config from '~/config';

const BuyCryptoPopup = (props) => {

    const classes = useStyles();

    const handleBackdropClicked = (confirm) => {
        if(_.isFunction(props.onClose)) {
            PopupManager.pushMessagePopup(
                'Cancel purchase?', 
                [
                    `Are you sure you want to cancel the process of buying ${Config.Blockchain.COIN}? If you do so, you will have to start over.`
                ], null, true, 'Yes', 'No'
            )
            .then(confirmed => {
                if(confirmed)
                    props.onClose(confirm);
            })
        }
    }

    return (
        <PopupContainer
            isOpen={props.isOpen}
            onBackdropClicked={handleBackdropClicked.bind()}
            disableClosing
            backdropColor="#0009">

            <div className={classes.container}>
                <iframe 
                    className={classes.iframe} 
                    title='Buy Crypto' 
                    src={
                        `${Config.Platform.Coinify.BASE_URL}` +
                        `?partnerId=${Config.Platform.Coinify.PARTNER_ID}` +
                        `&primaryColor=${Config.Platform.Coinify.PRIMARY_COLOR}` +
                        `&cryptoCurrencies=${Config.Platform.Coinify.CRYPTOCURRENCY}` +
                        `&defaultFiatCurrency=${Config.Platform.Coinify.DEFAULT_FIAT}` +
                        `&address=${props.address}` +
                        `&addressSignature=${props.addressSignature}` +
                        `&buyAmount=${props.buyAmount}`
                    }>    
                </iframe>
            </div>

        </PopupContainer>
    );
}

export default BuyCryptoPopup;