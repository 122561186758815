export const USER_DATA_CREATIONS_FETCH_START = 'USER_DATA_CREATIONS_FETCH_START';
export const USER_DATA_CREATIONS_FETCH_FINISHED = 'USER_DATA_CREATIONS_FETCH_FINISHED';
export const USER_DATA_CREATIONS_FETCH_FAILED = 'USER_DATA_CREATIONS_FETCH_FAILED';

export const USER_DATA_ADDRESS_FETCH_START = 'USER_DATA_ADDRESS_FETCH_START';
export const USER_DATA_ADDRESS_FETCH_FINISHED = 'USER_DATA_ADDRESS_FETCH_FINISHED';
export const USER_DATA_ADDRESS_FETCH_FAILED = 'USER_DATA_ADDRESS_FETCH_FAILED';

export const USER_DATA_OFFERS_FETCH_START = 'USER_DATA_OFFERS_FETCH_START';
export const USER_DATA_OFFERS_FETCH_FINISHED = 'USER_DATA_OFFERS_FETCH_FINISHED';
export const USER_DATA_OFFERS_FETCH_FAILED = 'USER_DATA_OFFERS_FETCH_FAILED';

export const USER_DATA_SALES_FETCH_START = 'USER_DATA_SALES_FETCH_START';
export const USER_DATA_SALES_FETCH_FINISHED = 'USER_DATA_SALES_FETCH_FINISHED';
export const USER_DATA_SALES_FETCH_FAILED = 'USER_DATA_SALES_FETCH_FAILED';

export const USER_DATA_MEDIA_FETCH_START = 'USER_DATA_MEDIA_FETCH_START';
export const USER_DATA_MEDIA_FETCH_FINISHED = 'USER_DATA_MEDIA_FETCH_FINISHED';
export const USER_DATA_MEDIA_FETCH_FAILED = 'USER_DATA_MEDIA_FETCH_FAILED';

export const USER_DATA_MEDIA_DETAILS_FETCH_START = 'USER_DATA_MEDIA_DETAILS_FETCH_START';
export const USER_DATA_MEDIA_DETAILS_FETCH_FINISHED = 'USER_DATA_MEDIA_DETAILS_FETCH_FINISHED';
export const USER_DATA_MEDIA_DETAILS_FETCH_FAILED = 'USER_DATA_MEDIA_DETAILS_FETCH_FAILED';