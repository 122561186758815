import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    pageContainer: {
        padding: '80px 0 0 0'
    },
    sideMenuContainer: {
        width: 200,
        backgroundColor: '#EDEDED',
        position: 'fixed',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRight: '1px solid #ccc',
    },
    menuContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 15
    },
    menuItem: {
        fontFamily: Theme.Font.Primary,
        fontSize: 13,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '10px 30px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: Theme.Palette.Primary.Foreground,
        }
    },
    menuItemSelected: {
        fontWeight: 400,
        textDecoration: 'underline'
    },
    menuDividerItem: {
        borderBottom: '1px solid #eee',
        margin: '10px 20px'
    },
    contentsPage: {
        flex: 1,
        paddingLeft: 200,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    '@media only screen and (max-width: 1024px)': {
        sideMenuContainer: {
            display: 'none'
        },
        contentsPage: {
            paddingLeft: 0
        }
    }
});