import {
    DROP_DETAILS_FETCH_START,
    DROP_DETAILS_FETCH_FINISHED,
    DROP_DETAILS_FETCH_FAILED
} from './types';

import {
    STATE_NOT_PROCESSED,
    STATE_PROCESSING,
    STATE_DONE,
    STATE_FAILED
} from '../states'

const INITIAL_STATE = {
    currentDropId: null,
    contents: null,
    contentsState: STATE_NOT_PROCESSED,
}

const HomePageReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case DROP_DETAILS_FETCH_START:
            return DropDetailsReducers.contentsFetchStart(state);
            
        case DROP_DETAILS_FETCH_FINISHED:
            return DropDetailsReducers.contentsFetchFinished(state, action.payload);

        case DROP_DETAILS_FETCH_FAILED:
            return DropDetailsReducers.contentsFetchFailed(state);

        default:
            return state;
    }
}

class DropDetailsReducers {

    static contentsFetchStart(state) {
        return { 
            ...state, 
            contentsState: STATE_PROCESSING
        }
    }

    static contentsFetchFinished(state, data) {

        return { 
            ...state, 
            contents: data,
            currentDropId: data.dropId,
            contentsState: STATE_DONE
        }
    }

    static contentsFetchFailed(state) {
        return { 
            ...state, 
            contentsState: STATE_FAILED
        }
    }
}

export default HomePageReducer;