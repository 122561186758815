import React from 'react';
import _ from 'lodash';

import Button from '../Button';
import CommentItem from '../CommentItem';

import { useStyles } from './styles';
import Config from '~/config';

const CommentSection = (props) => {

    const classes = useStyles();
    const [commentBody, setCommentBody] = React.useState('');

    const handlePostComment = () => {
        if(_.isFunction(props.onPostComment))
            props.onPostComment(undefined, commentBody);

        setCommentBody('');
    }

    return (
        <div className={classes.container}>
            <p className={classes.title}>
                Comments
            </p>

            <textarea 
                className={classes.commentBox}
                value={commentBody}
                onChange={(event) => { setCommentBody(event.target.value.substr(0, Config.UI.MAX_COMMENT_LENGTH)) }}
                placeholder="Share how you feel about the creation or even ask the creator a question."/>

            <div className={classes.buttonHolder}>
                <Button
                    className={classes.commentButton}
                    onClick={handlePostComment}
                    text="Comment"/>
            </div>

            <div className={classes.commentContainer}>
                {
                    (props.comments || [])
                        .filter(comment => comment.replyTo === null)
                        .map(comment => 
                            <CommentItem
                                key={comment.commentId}
                                commentId={comment.commentId}
                                name={comment.author.name}
                                username={comment.author.username}
                                profilePictureUrl={comment.author.profilePictureUrl}
                                createdAt={comment.createdAt}
                                body={comment.body}
                                creator={props.creator}
                                comments={props.comments}
                                isOwner={props.creator === comment.author.username}
                                self={props.self}
                                isAdmin={props.isAdmin}
                                onPostComment={props.onPostComment}
                                onDeleteComment={props.onDeleteComment}/>           
                        )
                }
            </div>
        </div>
    );
}

export default CommentSection;