import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import Button from '~/Components/Button';
import ProfilePicture from '~/Components/ProfilePicture';

import { useStyles } from './styles';

const FollowerItem = (props) => {

    const classes = useStyles();

    const handleFollowUser = () => {
        if(_.isFunction(props.onFollowUserAction))
            props.onFollowUserAction(props.username, !props.isFollowing)
    }

    return (
        <div className={classes.container}>
    
            <Link 
                to={`/profiles/${props.username}/`}
                className={classes.profileInfoContainer}
                onClick={props.onItemClicked}>

                <ProfilePicture
                    imageUrl={props.profilePictureUrl}
                    size={35}/>

                <p className={classes.name}>
                    {props.name}
                </p>
            </Link>

            {
                !props.isYou &&
                <Button
                    className={classes.followButton}
                    text={
                        props.isFollowing ?
                        'Unfollow' : 
                        'Follow'    
                    }
                    secondary={props.isFollowing}
                    onClick={handleFollowUser} />
            }

        </div>
    );
}

export default FollowerItem;