import {
    CREATION_DATA_FETCH_START,
    CREATION_DATA_FETCH_FINISHED,
    CREATION_DATA_FETCH_FAILED,
    CREATION_COMMENTS_FETCH_START,
    CREATION_COMMENTS_FETCH_FINISHED,
    CREATION_COMMENTS_FETCH_FAILED,
    CREATION_ACTIVITY_FETCH_START,
    CREATION_ACTIVITY_FETCH_FINISHED,
    CREATION_ACTIVITY_FETCH_FAILED,
    CREATION_EDITIONS_FETCH_START,
    CREATION_EDITIONS_FETCH_FINISHED,
    CREATION_EDITIONS_FETCH_FAILED,
    CREATION_LIKE_STATE_CHANGED,
    CREATION_OWNER_FOLLOWING_STATE_CHANGED
} from './types';

import {
    STATE_NOT_PROCESSED,
    STATE_PROCESSING,
    STATE_DONE,
    STATE_FAILED
} from '../states'

const INITIAL_STATE = {
    creationId: null,
    
    creationData: null,
    creationDataState: STATE_NOT_PROCESSED,
    
    comments: null,
    commentsState: STATE_NOT_PROCESSED,

    activity: null,
    activityState: STATE_NOT_PROCESSED,

    editions: null,
    editionsState: STATE_NOT_PROCESSED,
}

const CreationDataReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case CREATION_DATA_FETCH_START:
            return CreationDataReducers.fetchStart(state);
            
        case CREATION_DATA_FETCH_FINISHED:
            return CreationDataReducers.fetchFinished(state, action.payload);

        case CREATION_DATA_FETCH_FAILED:
            return CreationDataReducers.fetchFailed(state);

        case CREATION_LIKE_STATE_CHANGED:
            return CreationDataReducers.likeStateChanged(state, action.payload);
            
        case CREATION_OWNER_FOLLOWING_STATE_CHANGED:
            return CreationDataReducers.updateFollowState(state, action.payload);


        case CREATION_COMMENTS_FETCH_START:
            return CreationCommentsReducers.fetchStart(state);
            
        case CREATION_COMMENTS_FETCH_FINISHED:
            return CreationCommentsReducers.fetchFinished(state, action.payload);

        case CREATION_COMMENTS_FETCH_FAILED:
            return CreationCommentsReducers.fetchFailed(state);


        case CREATION_ACTIVITY_FETCH_START:
            return CreationActivityReducers.fetchStart(state);
            
        case CREATION_ACTIVITY_FETCH_FINISHED:
            return CreationActivityReducers.fetchFinished(state, action.payload);

        case CREATION_ACTIVITY_FETCH_FAILED:
            return CreationActivityReducers.fetchFailed(state);


        case CREATION_EDITIONS_FETCH_START:
            return CreationEditionsReducers.fetchStart(state);
            
        case CREATION_EDITIONS_FETCH_FINISHED:
            return CreationEditionsReducers.fetchFinished(state, action.payload);

        case CREATION_EDITIONS_FETCH_FAILED:
            return CreationEditionsReducers.fetchFailed(state);

        default:
            return state;
    }
}

class CreationDataReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            creationData: null,
            creationDataState: STATE_PROCESSING
        }
    }

    static fetchFinished(state, data) {

        let isExistingCreation = 
            state.creationId === data.creationId;

        return { 
            ...state, 
            creationId: data.creationId,
            
            creationData: data,
            creationDataState: STATE_DONE,
            
            activity: isExistingCreation ? state.activity : null,
            activityState: isExistingCreation ? state.activityState : STATE_NOT_PROCESSED,
            
            comments: isExistingCreation ? state.comments : null,
            commentsState: isExistingCreation ? state.commentsState : STATE_NOT_PROCESSED,
        
            editions: isExistingCreation ? state.editions : null,
            editionsState: isExistingCreation ? state.editionsState : STATE_NOT_PROCESSED,
        }
    }

    static fetchFailed(state) {
        return { 
            ...state, 
            creationDataState: STATE_FAILED
        }
    }

    static likeStateChanged(state, data) {

        if(state.creationData?.creationId !== data.creationId)
            return state;

        const likesCount = 
            state.creationData.stats.likesCount + (data.liked ? 1 : -1)
        
        return {
            ...state,
            creationData: {
                ...state.creationData,
                stats: {
                    ...state.creationData.stats,
                    likesCount
                }
            }
        }
    }

    static updateFollowState(state, data) {
        
        if(state.creationData?.owner.username !== data.username)
            return state;

        let followers = state.creationData.owner.stats.followers + (data.isFollowing ? 1 : -1);
        followers = followers < 0 ? 0 : followers;

        return {
            ...state,
            creationData: {
                ...state.creationData,
                owner: {
                    ...state.creationData.owner,
                    stats: {
                        ...state.creationData.owner.stats,
                        followers
                    }
                }
            }
        }
    }
}

class CreationCommentsReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            commentsState: STATE_PROCESSING
        }
    }

    static fetchFinished(state, data) {
        return { 
            ...state, 
            comments: data,
            commentsState: STATE_DONE
        }
    }

    static fetchFailed(state) {
        return { 
            ...state, 
            commentsState: STATE_FAILED
        }
    }
}

class CreationActivityReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            activityState: STATE_PROCESSING
        }
    }

    static fetchFinished(state, data) {
        return { 
            ...state, 
            activity: data.filter(item => ['GIFT', 'OFFER', 'PURCHASE'].includes(item.action)),
            activityState: STATE_DONE
        }
    }

    static fetchFailed(state) {
        return { 
            ...state, 
            activityState: STATE_FAILED
        }
    }
}

class CreationEditionsReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            editionsState: STATE_PROCESSING
        }
    }

    static fetchFinished(state, data) {
        return { 
            ...state, 
            editions: data,
            editionsState: STATE_DONE
        }
    }

    static fetchFailed(state) {
        return { 
            ...state, 
            editionsState: STATE_FAILED
        }
    }
}

export default CreationDataReducer;