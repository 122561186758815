import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        margin: 0,
        maxWidth: 700
    },
    address: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '10px 0',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    disconnectButton: {
        padding: '8px 10px',
        marginLeft: 15,
        fontSize: 12
    }
});