import React from 'react';
import MetaMask from '~/Services/MetaMask';
import { Link } from 'react-router-dom';
import Helpers from '~/helpers';
import Config from '~/config';

import ProfilePicture from '~/Components/ProfilePicture';
import { useStyles } from './styles';

const SaleItem = (props) => {

    const classes = useStyles();
    const tokenDetails = MetaMask.getTokenDetails();

    return (
        <div className={classes.container}>

            <div 
                className={classes.descriptionContainer}
                style={{flex: props.descriptionFlex || 1}}>
                <Link to={`/creations/${props.creationId}`}>
                    <div 
                        className={classes.itemPreview}
                        style={{
                            background: `url('${props.previewUrl}')`
                        }}>
                    </div>
                </Link>

                <div className={classes.column}>

                    <Link
                        to={`/creations/${props.creationId}`} 
                        className={classes.itemName}>
                        {props.name}
                    </Link>

                    <p className={classes.itemEdition}>
                        Edition: #{props.edition}
                    </p>

                </div>
            </div>

            <div 
                className={classes.column}
                style={{flex: props.userColumnFlex || 1}}>

                <div className={classes.userDataContainer}>
                    <ProfilePicture
                        style={{
                            margin: '5px 15px 5px 0'
                        }}
                        username={props.username}
                        imageUrl={props.profilePictureUrl} 
                        size={35}/>

                    <Link 
                        to={`/profiles/${props.username}/`}
                        className={classes.linkedField}>
                        {props.usersName}
                    </Link>
                </div>

            </div>

            <div 
                className={classes.column}
                style={{flex: props.dateFlex || 1}}>

                <p className={classes.date}>
                    { props.date }
                </p>

            </div>

            <div 
                className={classes.columnHorizontal}
                style={{
                    flex: props.priceColumnFlex || 1,
                    justifyContent: 'space-between'
                }}>

                <p className={classes.price}>
                    {Helpers.formatEthPrice(Helpers.weiToEth(props.amount))} {Config.Blockchain.COIN} (<strong>${Helpers.formatUsdPrice(props.coinPrice * Helpers.weiToEth(props.amount))}</strong>)
                </p>

                <a 
                    href={
                        tokenDetails?.explorerUrl ?
                        `${tokenDetails?.explorerUrl}/tx/${props.txid}` : 
                        '#'
                    }
                    className={classes.proofLink}>
                    View transaction
                </a>

            </div>

        </div>
    );
}

export default SaleItem;