import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '0 30px',
        maxWidth: 800
    },
    title: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 40,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '0 0 10px 0'
    },
    headerItem: {
        color: Theme.TextColor.Dark,
        fontSize: 14
    },
    createDropButton: {
        margin: '15px 0',
        padding: '7px 15px'
    },
    smallTitle: {
        fontFamily: Theme.Font.Primary,
        fontSize: 30,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '20px 0 10px 0'
    },
    label: {
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '20px 0 10px 0'
    },
    fileInputField: {
        margin: '10px 0'
    },
    labeledInputField: {
        marginBottom: 15
    },
    inputField: {   
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        padding: 10,
        border: '1px solid #ccc',
        margin: '3px 0',
        resize: 'vertical'
    },
    rowFieldsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
    },
    timeSpacer: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        padding: 15,
    },
    buttonContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    updateButton: {
        padding: '10px 15px',
        marginTop: 10,
        fontSize: 15
    },
    headerContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    approvalButton: {
        margin: '15px 0',
        padding: '7px 15px'
    },
    titleCheckbox: {
        margin: '37px 10px 0 0'
    },
    disableArea: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    '@media only screen and (max-width: 800px)': {
        profilePreviewContainer: {
            flexDirection: 'column'
        }
    }
});