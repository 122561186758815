import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        margin: '0px 5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    profileInfoContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        textDecoration: 'none'
    },
    name: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 17,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: 0
    },
    followButton: {
        padding: 7,
        width: 80,
        fontSize: 12,
        margin: 15
    }
});