import React from 'react';
import { useStyles } from './styles';

const DisableArea = (props) => {
    const classes = useStyles();

    return (
        <div 
            className={props.className}
            style={{position: 'relative'}}>

            {
                props.disabled &&
                <div className={classes.overlay}/>
            }

            { props.children }
        
        </div>
    );
}

export default DisableArea;