import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1
    },
    title: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 22,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        margin: '5px 0'
    },
    purchaseDescription: {
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        fontWeight: 300,
        color: Theme.TextColor.Dark
    },
    offerDetailsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '35px 0 15px 0',
        border: '1px solid #999',
        backgroundColor: '#eee'
    },
    offerValue: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        border: 'none',
        outline: 'none',
        padding: 10,
        margin: 0,
    },
    priceUsd: {
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '5px 10px'
    },
    purchaseButton: {
        width: '100%',
        padding: 7,
        fontSize: 16
    },
    whatAreYouPurchasing: {
        fontFamily: Theme.Font.Primary,
        fontSize: 15,
        fontWeight: 300,
        textAlign: 'center',
        color: Theme.TextColor.Dark,
        margin: 20,
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});