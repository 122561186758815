import React from 'react';
import Helpers from '~/helpers';
import Config from '~/config';

import PopupManager from '~/Auxilary/PopupManager';
import { MintRequest } from '~/API';
import { useDispatch } from 'react-redux';
import * as MintRequestActions from '~/Redux/MintRequest/actions';

import Button from '~/Components/Button';
import CreationMediaView from '~/Components/CreationMediaView';
import FileInputField from '~/Components/FileInputField';
import LabeledInputField from '~/Components/LabeledInputField';

import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';

const MintRequestDetails = () => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    const [name, setName] = React.useState('');
    const [editions, setEditions] = React.useState('1');
    const [description, setDescription] = React.useState('');

    const [uploadProgress, setUploadProgress] = React.useState(null);
    const [mediaFile, setMediaFile] = React.useState(null);
    const [mediaUrl, setMediaUrl] = React.useState('');
    const [mediaType, setMediaType] = React.useState('');

    const handleMediaFileSelected = (file) => {
        setMediaUrl(URL.createObjectURL(file))
        setMediaType(file.type)
        setMediaFile(file);
    }

    const handleSubmitCreation = () => {

        let formattedEditions = parseInt(editions);

        if (!mediaFile) {
            PopupManager.pushMessagePopup(
                'Failed to submit',
                [`Please select a media file in order to submit your creation!`],
                'FAIL'
            );
            return;
        }

        if (Number.isNaN(formattedEditions) || 
            formattedEditions <=0 || 
            formattedEditions > Config.Platform.MAX_EDITIONS) {

            PopupManager.pushMessagePopup(
                'Failed to submit',
                [`The number of editions must be an integer from 1 to ${Config.Platform.MAX_EDITIONS}`],
                'FAIL'
            );
            return;
        }

        if (name.trim().length === 0) {
            PopupManager.pushMessagePopup(
                'Failed to submit',
                [`The creation name cannot be empty`],
                'FAIL'
            );
            return;
        }

        if (description.trim().length === 0) {
            PopupManager.pushMessagePopup(
                'Failed to submit',
                [`The creation description cannot be empty`],
                'FAIL'
            );
            return;
        }

        setUploadProgress('0%')
        MintRequest
            .create(name, description, formattedEditions, mediaFile, (progress) => {
                setUploadProgress(`${Math.floor(100 * progress)}%`)
            })
            .then(() => {
                setUploadProgress(null);
                dispatch(MintRequestActions.listMintRequests());
                history.push(`/profile/upload-creations`);
            })
            .catch((error) => {
                PopupManager.pushMessagePopup('Failed to submit', [`Failed to submit your creation. ${error}`], 'FAIL')
                setUploadProgress(null)
            })
    }

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Upload new media
            </p>

            <div className={classes.bodyContainer}>

                <div className={classes.previewContainer}>

                    <p className={classes.label}>
                        Media preview:
                    </p>

                    {
                        mediaUrl ?
                        <CreationMediaView
                            className={classes.mediaPreview}
                            imageUrl={mediaType.startsWith('image') ? mediaUrl : null}
                            videoUrl={mediaType.startsWith('video') ? mediaUrl : null}
                            isVideo={mediaType.startsWith('video')}
                            videoControls/>
                        :
                        <div className={classes.previewPlaceholder}>
                            <p className={classes.mediaPreviewTopText}>
                                Selected media preview here
                            </p>
                            <p className={classes.mediaPreviewText}>
                                Upload limit: 100MB
                            </p>
                        </div>
                    }

                </div>

                <div className={classes.fieldsContainer}>
                    <p className={classes.label}>
                        Digital Media File:
                    </p>

                    <FileInputField
                        className={classes.fileInputField}
                        disabled={uploadProgress}
                        maxSize={100}
                        fileType="ANY"
                        text={
                            uploadProgress ?
                            `Submitting media: ${uploadProgress}` :
                            'Click to select media file'
                        }
                        onFileSelected={handleMediaFileSelected}/>

                    <div style={{height: 20}}/>

                    <input
                        className={classes.inputField}
                        placeholder="The title of your creation"
                        value={name}
                        disabled={uploadProgress}
                        onChange={(event) => { setName(event.target.value) }}/>

                    <textarea
                        className={classes.inputField}
                        style={{
                            minHeight: 200,
                            margin: '10px 0'
                        }}
                        type="text"
                        value={description}
                        disabled={uploadProgress}
                        onChange={(event) => {setDescription(event.target.value)}}
                        placeholder="One or two paragraphs that describe this creation."/>
                
                    <LabeledInputField
                        className={classes.labeledInputField}
                        labelText="Number of Editions"
                        placeholder="Number of editions"
                        type="number"
                        min="1"
                        value={editions}
                        onChange={setEditions}
                        disabled={uploadProgress}
                        labelFirst />

                    <div className={classes.buttonContainer}>

                        <p className={classes.updateErrorMessage}>
                            { Helpers.getRequestErrorMessage('') }
                        </p>

                        <Button
                            className={classes.updateButton}
                            onClick={handleSubmitCreation}
                            text={
                                uploadProgress ?
                                'Submitting creation...' : 
                                'Submit creation'
                            }
                            isLoading={uploadProgress}
                            inverted/>

                    </div>

                    <div style={{flex: 1}}/>
                </div>

            </div>

            
            <div style={{height: 50}}/>

        </div>
    );
}

export default MintRequestDetails;