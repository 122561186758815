import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '0 30px',
        maxWidth: 1000
    },
    title: {
        fontFamily: Theme.Font.Primary,
        fontSize: 40,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '0 0 10px 0'
    },
    headerItem: {
        color: Theme.TextColor.Dark,
        fontSize: 14
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    previewContainer: {
        flex: 1,
        padding: '0 0 0 20px',
    },
    purchaseContainer: {
        flex: 1,
        padding: '0 20px 0 0',
    },
    creationTitle: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 22,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        margin: '5px 0'
    },
    descriptionContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        margin: '10px 0'
    },
    descriptionAuthor: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark
    },
    descriptionEdition: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark
    },
    '@media only screen and (max-width: 800px)': {
        bodyContainer: {
            width: 500
        },
        previewContainer: {
            display: 'none'
        }
    },
    '@media only screen and (max-width: 500px)': {
        bodyContainer: {
            width: '100%'
        }
    }
});