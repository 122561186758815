import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as UserActions from '~/Redux/User/actions';
import * as DiscoverActions from '~/Redux/Discover/actions';
import { STATE_DONE, STATE_NOT_PROCESSED, STATE_PROCESSING } from '~/Redux/states';

import MainHeader from '~/Components/MainHeader';
import CreationItemList from '~/Components/CreationItemList';
import UserCollections from '~/Components/UserCollections';
import ToggleButtonList from '~/Components/ToggleButtonList';
import ProfileItem from '~/Components/ProfileItem';
import DiscoverItem from '~/Components/DiscoverItem';
import NoContentInfoBox from '~/Components/NoContentInfoBox';

import { useStyles } from './styles';
import Images from '~/Assets/images';
import Button from '~/Components/Button';
import Footer from '~/Components/Footer';

const Discover = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { discoverPage } = useParams();

    const [canLoadMore, setCanLoadMore] = React.useState(true);
    const [contentType, setContentType] = React.useState(0);
    const [contentOrder, setContentOrder] = React.useState(0);
    const [profileItemSize, setProfileItemSize] = React.useState(1);
    const [searchQuery, setSearchQuery] = React.useState('');

    const userData = useSelector(state => state.User);
    const discoverData = useSelector(state => state.Discover);
    const shouldLoadContent = useSelector(state => state.UI.isPageScrollEnd);

    const pageWidth = useSelector(state => state.UI.pageWidth);
    const windowHeight = useSelector(state => state.UI.windowHeight);

    const loadContent = React.useCallback(
        () => {
            if(contentOrder === null)
                return;

            dispatch(
                DiscoverActions.getDiscoverContents(
                    Discover.ContentTypeList[contentType]?.value,
                    Discover.ContentOrderList[contentOrder]?.value
                )
            );

            dispatch(
                DiscoverActions.getSuggestedContents(
                    Discover.ContentTypeList[contentType]?.suggestion,
                    Discover.ContentOrderList[contentOrder]?.value
                )
            );
        },
        [contentOrder, contentType, dispatch]
    );

    React.useEffect(
        () => {
            if (!userData.data && userData.token && userData.fetchDataState === STATE_NOT_PROCESSED)
                dispatch(UserActions.getData());
        },
        [userData, dispatch]
    )

    React.useEffect(
        () => {
            const requestedPage = discoverPage?.trim().toLowerCase();

            if(requestedPage) {
                const pageDetails = Discover.ContentTypeList.find(
                    content => requestedPage.includes(content.text.toLowerCase())
                );
    
                if(pageDetails)
                    setContentType(Discover.ContentTypeList.indexOf(pageDetails));
            }
            else {
                setContentType(0);
            }
        },
        [discoverPage]
    )

    React.useEffect(
        () => {
            loadContent();
        },
        [contentType, contentOrder, loadContent, dispatch]
    )

    React.useEffect(
        () => {
            if (shouldLoadContent && canLoadMore && !discoverData.isEverythingLoaded && 
                discoverData.dataState === STATE_DONE && 
                discoverData.dataExtensionState !== STATE_PROCESSING) {
                setCanLoadMore(false);
                loadContent();
            }
        },
        [shouldLoadContent, discoverData, canLoadMore, loadContent]
    );

    React.useEffect(
        () => {
            if(!shouldLoadContent)
                setCanLoadMore(true);
        },
        [shouldLoadContent]
    );

    React.useEffect(
        () => {
            const containerMargins = pageWidth > 1024 ? 160 : 20;
            const itemMargins = 10;

            if(pageWidth < 400)
                setProfileItemSize((pageWidth - containerMargins - 2 * itemMargins) / 1);
            else if(400 <= pageWidth && pageWidth < 800)
                setProfileItemSize((pageWidth - containerMargins - 4 * itemMargins) / 2);
            else if(800 <= pageWidth && pageWidth < 1200)
                setProfileItemSize((pageWidth - containerMargins - 6 * itemMargins) / 3);
            else
                setProfileItemSize((pageWidth - containerMargins - 8 * itemMargins) / 4);
        },
        [pageWidth]
    )

    const handleSearch = () => {
        if(searchQuery.trim()) {
            dispatch(DiscoverActions.search(searchQuery));
            setContentType(0)
            setContentOrder(null)
        }
        else {
            setContentOrder(0)
        }
    }

    const handleContentTypeChanged = (type) => {
        setContentType(type);

        if(contentOrder === null) {
            setContentOrder(0);
            setSearchQuery('');
        }
    }

    return (
        <Fragment>
            
            <MainHeader
                username={userData.data?.username}
                profilePictureUrl={userData.data?.profilePictureUrl}
                userType={userData.data?.role}
                isLoggedIn={!!userData.data}
                isMobile={pageWidth < 800}/>

            <div className={classes.pageContainer} style={{minHeight: windowHeight - 150}}>

                <div className={classes.filterContainer}>
                    <div className={classes.filterLeftContainer}>
                        <UserCollections
                            onSelectionChanged={handleContentTypeChanged}
                            defaultItem={contentType}
                            collectionItems={
                                Discover.ContentTypeList.map(item => item.text)
                            } />

                        {
                            discoverData?.contentType !== Discover.ContentType.ACTIVITY && contentOrder !== null ?
                            <ToggleButtonList
                                onSelectionChanged={setContentOrder}
                                collectionItems={
                                    Discover.ContentOrderList.map(item => item.text)
                                } />
                            : null
                        }
                    </div>

                    <div className={classes.searchContainer}>
                        <input
                            type="text"
                            className={classes.searchField}
                            placeholder="Search creations..."
                            disabled={discoverData.isSearching}
                            onChange={(event) => setSearchQuery(event.target.value)}
                            value={searchQuery}
                            onKeyPress={(event) => { if(event.key === 'Enter') handleSearch() }}/>

                        <Button
                            className={classes.searchButton}
                            text={
                                discoverData.isSearching ?
                                'Searching...' :
                                'Search'
                            }
                            onClick={handleSearch}
                            isLoading={discoverData.isSearching}
                            inverted/>
                    </div>
                    
                </div>

                {
                    discoverData?.suggestionsDataState === STATE_DONE ?
                    <Fragment>
                        <p className={classes.suggestionDescription}>
                            {
                                (Discover.ContentOrderList[contentOrder]?.text || 'Suggested') + ' ' +
                                Discover.ContentTypeList[contentType]?.suggestion.toLowerCase() + 's'
                            }
                        </p>

                        <div className={classes.suggestionContainer}>
                            {
                                discoverData.suggestionsData.map(item => 
                                    item.username ?
                                    <DiscoverItem
                                        key={item.username}
                                        name={item.name}
                                        smallImage={item.profilePictureUrl}
                                        largeImage={item.preview.backgroundImageUrl}
                                        url={`/profiles/${item.username}/`}/>
                                    :
                                    <DiscoverItem
                                        key={item.creationId}
                                        name={item.name}
                                        smallImage={item.creators[0]?.profilePictureUrl}
                                        largeImage={item.media.image.url}
                                        url={`/creations/${item.creationId}/`}/>
                                )
                            }
                        </div>
                    </Fragment>
                    : null
                }


                {
                    discoverData?.data.length > 0 &&
                    discoverData?.contentType === Discover.ContentType.CREATION ?
                    <div className={classes.activityContainer}>
                        <CreationItemList
                            className={classes.activityList}
                            itemList={discoverData.data}
                            columns={Math.floor(pageWidth / 500)} /> 

                    </div>
                    : null
                }

                {
                    discoverData?.data.length > 0 &&
                    discoverData?.contentType === Discover.ContentType.ACTIVITY ?
                    <div className={classes.activityContainer}>
                        <CreationItemList
                            className={classes.activityList}
                            itemList={discoverData.data}
                            isActivityList
                            columns={Math.floor(pageWidth / 500)} /> 

                    </div>
                    : null
                }

                {
                    discoverData?.data.length > 0 &&
                    (
                        discoverData?.contentType === Discover.ContentType.CREATOR ||
                        discoverData?.contentType === Discover.ContentType.COLLECTOR
                    ) ?
                    <div className={classes.profileContainer}>
                        {
                            discoverData.data.map(item =>
                                <ProfileItem
                                    key={item.username}
                                    username={item.username}
                                    name={item.name}
                                    profilePictureUrl={item.profilePictureUrl}
                                    description={item.preview.description}
                                    backgroundImage={item.preview.backgroundImageUrl}
                                    isCreator={item.isCreator}
                                    squareSize={profileItemSize}/>  
                            )
                        }
                    </div>
                    : null
                }

                {
                    discoverData &&
                    discoverData.data &&
                    discoverData.data.length === 0 &&
                    discoverData.contentType === Discover.ContentType.CREATION &&
                    discoverData.contentOrder === null &&
                    !discoverData.isSearching &&
                    <NoContentInfoBox
                        icon={Images.EmptyGallery}
                        text={`No matches found for your search query`}/>
                }

            </div>
            <Footer/>
        </Fragment>
    );
}

Discover.ContentType = {
    CREATION: 'CREATION',
    CREATOR: 'CREATOR',
    COLLECTOR: 'COLLECTOR',
    ACTIVITY: 'ACTIVITY'
}

Discover.ContentOrder = {
    FEATURED: 'FEATURED',
    POPULAR: 'POPULAR',
    NEW: 'NEW'
}

Discover.ContentTypeList = [
    {
        text: 'Artworks',
        value: Discover.ContentType.CREATION,
        suggestion: Discover.ContentType.CREATOR
    },
    {
        text: 'Creators',
        value: Discover.ContentType.CREATOR,
        suggestion: Discover.ContentType.CREATION
    },
    {
        text: 'Collectors',
        value: Discover.ContentType.COLLECTOR,
        suggestion: Discover.ContentType.CREATION
    },
    {
        text: 'Activity',
        value: Discover.ContentType.ACTIVITY,
        suggestion: Discover.ContentType.CREATOR
    }
]

Discover.ContentOrderList = [
    {
        text: 'Featured',
        value: Discover.ContentOrder.FEATURED
    },
    {
        text: 'Popular',
        value: Discover.ContentOrder.POPULAR
    },
    {
        text: 'New',
        value: Discover.ContentOrder.NEW
    }
]

export default Discover;