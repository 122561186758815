import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '0 10px',
        maxWidth: 700
    },
    title: {
        fontFamily: Theme.Font.Primary,
        fontSize: 40,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '0 0 10px 0'
    },
    smallTitle: {
        fontFamily: Theme.Font.Primary,
        fontSize: 30,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '20px 0 10px 0'
    },
    label: {
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '20px 0 10px 0'
    },
    fileInputField: {
        margin: '10px 0'
    },
    labeledInputField: {
        marginBottom: 15
    },
    inputField: {   
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        padding: 10,
        border: '1px solid #ccc',
        margin: '3px 0',
        resize: 'vertical'
    },
    buttonContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    updateButton: {
        padding: '10px 15px',
        marginTop: 10,
        fontSize: 15
    },
    updateErrorMessage: {
        flex: 1,
        paddingTop: 10,
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        fontWeight: 300,
        color: '#f00',
    },
    profilePreviewContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        marginLeft: -10
    },
    profilePreviewFields: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15
    },
    bannerPreview: {
        flex: 1,
        marginBottom: 10
    },
    '@media only screen and (max-width: 800px)': {
        profilePreviewContainer: {
            flexDirection: 'column'
        }
    }
});