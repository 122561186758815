import React from 'react';
import { useStyles } from './styles';

const SocialMediaLink = (props) => {
    
    const classes = useStyles();
    const [icon, setIcon] = React.useState(props.icon.Normal);

    return (
        <div className={classes.container}>
            <a href={props.url}>
                <img
                    alt=""
                    className={classes.iconImage}
                    onMouseEnter={() => {setIcon(props.icon.Hover)}}
                    onMouseLeave={() => {setIcon(props.icon.Normal)}}
                    src={icon}/>
            </a>
        </div>
    );
}

export default SocialMediaLink;