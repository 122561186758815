import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        color: Theme.TextColor.Light,
        fill: Theme.TextColor.Light,
        marginRight: 30
    },
    clickable: {
        cursor: 'pointer',
        '&:hover': {
            color: Theme.Palette.Primary.Foreground,
            fill: Theme.Palette.Primary.Foreground
        }
    },
    icon: {
        width: 20,
        height: 25,
        fill: 'inherit'
    },
    activeIcon: {
        fill: Theme.Palette.Primary.Foreground
    },
    text: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: 'inherit',
        margin: '5px 0 0 10px'
    }
});