import React from 'react';

const ArrowDown = (props) => {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg" 
            version="1.1"
            viewBox="97.904 106.419 23.665 11.837"
            onClick={props.onClick}>
            <polyline points="97.904 106.419 109.741 118.256 121.569 106.428"/>
        </svg>
    )
}

export default ArrowDown;