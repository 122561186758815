import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: 'black',
        margin: '80px 0 50px 0',
        padding: '40px 40px 20px 40px',
    },
    title: {
        flex: 1,
        textAlign: 'center',
        fontFamily: Theme.Font.Secondary,
        fontSize: 35,
        fontWeight: 200,
        color: 'white'
    },
    message: {
        flex: 1,
        textAlign: 'center',
        fontFamily: Theme.Font.Secondary,
        fontSize: 14,
        fontWeight: 200,
        marginTop: 5,
        color: Theme.TextColor.Lightest
    },
    disclaimer: {
        flex: 1,
        textAlign: 'center',
        fontFamily: Theme.Font.Secondary,
        fontSize: 12,
        fontWeight: 200,
        color: Theme.TextColor.Lighter,
        marginBottom: 20
    },
    inputContainer: {
        width: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '30px 0 15px 0',
        border: '1px solid white'
    },
    input: {
        border: 'none',
        height: 32,
        background: 'none',
        outline: 'none',
        color: 'white',
        padding: '3px 10px',
        minWidth: 300
    },
    signupButton: {
        padding: '7px 15px'
    },
    pageLink: {
        flex: 1,
        textAlign: 'center',
        fontFamily: Theme.Font.Secondary,
        fontSize: 19,
        fontWeight: 200,
        marginTop: 5,
        marginBottom: 20,
        color: Theme.TextColor.Lightest,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    '@media only screen and (max-width: 500px)': {
        title: {
            fontSize: 25
        },
        input: {
            minWidth: 200
        }
    },
    '@media only screen and (max-width: 300px)': {
        title: {
            fontSize: 19
        },
        message: {
            fontSize: 12,
        },
        disclaimer: {
            fontSize: 10
        },
        input: {
            minWidth: 150
        }
    },
});