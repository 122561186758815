import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as UserDataActions from '~/Redux/UserData/actions';
import { STATE_NOT_PROCESSED } from '~/Redux/states';
import { useHistory } from 'react-router-dom';

import NoContentInfoBox from '~/Components/NoContentInfoBox';
import MediaItem from '~/Components/MediaItem';

import Images from '~/Assets/images';
import { useStyles } from './styles';

const YourCreations = () => {

    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const userData = useSelector(state => state.UserData);
    const pageWidth = useSelector(state => state.UI.pageWidth);
    
    const [profileItemSize, setProfileItemSize] = React.useState(1);
    const [dataReloadRequired, setDataReloadRequired] = React.useState(true);

    React.useEffect(
        () => {
            if (userData.mediaListState === STATE_NOT_PROCESSED || dataReloadRequired) {
                dispatch(UserDataActions.listMedia());
                setDataReloadRequired(false);
            }
        }, 
        [ userData, dataReloadRequired, dispatch ]
    );

    React.useEffect(
        () => {
            const itemMargins = 10;
            
            if(pageWidth > 1260) {
                setProfileItemSize((1000 - 8 * itemMargins) / 4);
            }
            else {
                const containerMargins = pageWidth > 1024 ? 260 : 60;

                if(pageWidth < 300)
                    setProfileItemSize((pageWidth - containerMargins - 2 * itemMargins) / 1);
                else if(300 <= pageWidth && pageWidth < 600)
                    setProfileItemSize((pageWidth - containerMargins - 4 * itemMargins) / 2);
                else if(600 <= pageWidth && pageWidth < 900)
                    setProfileItemSize((pageWidth - containerMargins - 6 * itemMargins) / 3);
                else if(900 <= pageWidth)
                    setProfileItemSize((pageWidth - containerMargins - 8 * itemMargins) / 4);
            }
        },
        [pageWidth]
    )

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Your creations
            </p>

            {
                userData.mediaList && 
                userData.mediaList.length > 0 ? 
                <div className={classes.creationsContainer}>
                    {
                        userData.mediaList.map(item =>
                            <MediaItem
                                key={item.mediaId}
                                mediaId={item.mediaId}
                                itemName={item.name}
                                imageUrl={item.media.image.url}
                                videoUrl={item.media.video.url}
                                defaultOpen
                                squareSize={profileItemSize}
                                status={`Published ${item.release.number} of ${item.release.count}`}/>  
                        )
                    }
                </div>
                :
                <NoContentInfoBox
                    icon={Images.EmptyGallery}
                    text={'You have not published any creations yet'}
                    buttonText="Publish a new creation"
                    onButtonClick={() => {
                        history.push('/profile/upload-creations')
                    }}/>
            }

            <div style={{height: 50}}/>

        </div>
    );
}

export default YourCreations;