import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 10
    },
    bodyContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    metamaskLogo: {
        width: 130,
        margin: '10px 0'
    },
    metamaskMessageTitle: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 25,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        margin: '10px 0 30px 0'
    },
    metamaskMessage: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        textAlign: 'center',
        padding: 0,
        margin: '0 20px',
        color: Theme.TextColor.Dark,
        lineHeight: 2
    },
    metamaskInstructions: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        textAlign: 'center',
        margin: 20,
        color: Theme.TextColor.Dark,
        cursor: 'pointer',
        '&:hover': {
            color: Theme.Palette.Primary.Foreground,
            textDecoration: 'underline'
        }
    },
    loader: {
        width: 25,
        height: 25,
        marginRight: 10,
        marginTop: 2,
        opacity: 0.8,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    },
    metamaskMessageTitleContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '10px 0 30px 0'
    },
    metamaskLoadingTitle: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 25,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        margin: 0
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 20
    },
    button: {
        width: 150,
        padding: 10,
        fontSize: 15,
        margin: '5px 10px'
    },
});