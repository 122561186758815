import React from 'react';
import * as API from '~/API';
import Helpers from '~/helpers';
import _ from 'lodash';

import PopupContainer from '../PopupContainer';
import Button from '~/Components/Button';

import { useStyles } from './styles';

const AddCreatorPopup = (props) => {

    const classes = useStyles();

    const [isRequesting, setIsRequesting] = React.useState(false);
    const [requestError, setRequestError] = React.useState(null);
    const [username, setUsername] = React.useState('');

    const handleClose = (loginOk) => {
        if(_.isFunction(props.onClose))
            props.onClose(!!loginOk);
    }

    const handleFindUser = () => {
        if(isRequesting || username.trim().length === 0)
            return;
        
        setRequestError(null);
        setIsRequesting(true);

        API.User
            .getStatus(username)
            .then((statusData) => {
                setIsRequesting(false);

                if(statusData.exists)
                    props.onClose(statusData.username);
                else
                    setRequestError('username:No users exist with the given username');
            })
            .catch(err => {
                setIsRequesting(false);
                setRequestError(err);
            })
    }

    return (
        <PopupContainer
            isOpen={props.isOpen}
            onClose={handleClose}
            backdropColor="#0009">

            <div className={classes.container}>
                <p className={classes.title}>
                    Add Creator
                </p>

                    <input 
                        className={
                            Helpers.getRequestErrorField(requestError) === 'username' ?
                            classes.inputField + ' ' + classes.inputFieldInvalid :
                            classes.inputField
                        }
                        placeholder="Username"
                        type="text"
                        value={username}
                        onChange={(event) => {
                            setUsername(event.target.value)
                        }}
                        onKeyPress={(event) => { 
                            if(event.key === 'Enter') 
                                handleFindUser() 
                        }}
                        autoFocus/>

                    {
                        requestError &&
                        <p className={classes.errorMessage}>
                            { Helpers.getRequestErrorMessage(requestError) }
                        </p>
                    }

                    <Button
                        className={classes.button}
                        isLoading={isRequesting}
                        text={
                            isRequesting ?
                            "Searching..." :
                            "Add user"
                        }
                        onClick={handleFindUser}/>

            </div>

        </PopupContainer>
    );
}

export default AddCreatorPopup;