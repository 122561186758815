import React, { useRef } from 'react';
import { useStyles } from './styles';

import { Link } from 'react-router-dom';
import CarouselArrowRight from '~/Assets/Vectors/CarouselArrowRight';
import CarouselArrowLeft from '~/Assets/Vectors/CarouselArrowLeft';

const Carousel = (props) => {
    const classes = useStyles();
    const carouselRef = useRef();
    const [scrollPosition, setScrollPosition] = React.useState(0);
    const [canMoveBack, setCanMoveBack] = React.useState(false);
    const [canMoveForward, setCanMoveForward] = React.useState(false);

    const itemWidth = props.itemWidth || 300;

    React.useEffect(
        () => {
            let scrollValue = 
                Math.floor(carouselRef.current.offsetWidth / itemWidth) * itemWidth;

            const canScroll = carouselRef.current.offsetWidth < carouselRef.current.scrollWidth;

            setCanMoveBack(canScroll && scrollPosition - scrollValue >= 0);
            setCanMoveForward(canScroll && scrollPosition + scrollValue < carouselRef.current.scrollWidth);
        }, 
        [carouselRef, scrollPosition, itemWidth]
    );

    const handleMoveForward = () => {
        
        if(!canMoveForward)
            return;

        let scrollValue = 
            Math.floor(carouselRef.current.offsetWidth / itemWidth) * itemWidth;

        setScrollPosition(scrollPosition + scrollValue);
        
        if(carouselRef.current)
            carouselRef.current.scrollTo(scrollPosition + scrollValue, 0);
    }

    const handleMoveBack = () => {

        if(!canMoveBack)
            return;

        let scrollValue = 
            Math.floor(carouselRef.current.offsetWidth / itemWidth) * itemWidth;

        setScrollPosition(scrollPosition - scrollValue);
        
        if(carouselRef.current)
            carouselRef.current.scrollTo(scrollPosition - scrollValue, 0);
    }

    return (
        <div 
            className={
                props.className ?
                classes.container + ' ' + props.className :
                classes.container
            }>

            <div className={classes.header}>
                <p className={classes.title}>
                    {props.title}
                </p>
                
                {
                    (canMoveBack || canMoveForward) &&
                    <div className={classes.buttonContainer} style={props.isMobile ? {marginRight: 20} : {}}>

                        {
                            props.viewAllUrl &&
                            <Link
                                to={props.viewAllUrl}
                                className={classes.viewAllText}>
                                (View All)
                            </Link>
                        }

                        <CarouselArrowLeft
                            onClick={handleMoveBack}
                            className={
                                canMoveBack ?
                                classes.button :
                                classes.button + ' ' + classes.buttonDisabled
                            }/>

                        <CarouselArrowRight 
                            onClick={handleMoveForward}
                            className={
                                canMoveForward ?
                                classes.button :
                                classes.button + ' ' + classes.buttonDisabled
                            }/>
                    </div>
                }
            </div>

            <div 
                ref={carouselRef}
                className={classes.carouselContainer}>
                { props.children }
            </div>
        </div>
    );
}

export default Carousel;