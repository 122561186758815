import React from 'react';
import _ from 'lodash';

import { useStyles } from './styles';

const ToggleButtonList = (props) => {
    const classes = useStyles();
    const [selectedItem, setSelectedItem] = React.useState(props.defaultItem || 0);

    const handleItemChanged = (item) => {
        setSelectedItem(item);
        if(_.isFunction(props.onSelectionChanged))
            props.onSelectionChanged(item)
    }

    return (
        <div className={classes.container}>
            {
                (props.collectionItems || [])
                    .map((item, index) =>
                        <div 
                            key={item}
                            onClick={handleItemChanged.bind(null, index)}
                            className={
                                selectedItem === index ?
                                classes.item + ' ' + classes.selectedItem :
                                classes.item
                            }>
                            { item }
                        </div>
                    )
            }
        </div>
    );
}

export default ToggleButtonList;