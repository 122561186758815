import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        position: 'relative',
        margin: 10,
        height: 'min-content'
    },
    squareContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        margin: 10,
    },
    fullWidth: {
        width: '100%'
    },
    fullHeight: {
        height: '100%'
    },
    infoOverlayContainer: {
        display: 'flex',
        position: 'absolute',
        float: 'left',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        padding: 0,
        opacity: 0,
        transition: '0.2s ease',
        '&:hover': {
            opacity: 0.95
        }
    },
    infoContainer: {
        backgroundColor: '#EDEDED',
        width: '100%',
        height: '100%',
        padding: 0,
        cursor: 'zoom-in'
    },
    infoMarginContainer: {
        margin: 20,
    },
    title: {
        fontFamily: Theme.Font.Primary,
        fontSize: 24,
        fontWeight: 300,
        margin: 0,
        cursor: 'pointer',
        textDecoration: 'none',
        color: Theme.TextColor.Dark,
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    authorContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        margin: '5px 0'
    },
    author: {
        fontFamily: Theme.Font.Primary,
        fontSize: 13,
        fontWeight: 300,
        margin: '0 5px 0 0',
        cursor: 'pointer',
        textDecoration: 'none',
        color: Theme.TextColor.Dark,
        '&:hover': {
            color: Theme.Palette.Primary.Foreground,
            textDecoration: 'underline'
        }
    },
    detailsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '30px 0 0 0'
    },
    leftDetailsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    priceText: {
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '-4px 0 10px 0',
        '& strong': {
            marginLeft: 5,
            fontWeight: 400
        }
    },
    viewDetails: {
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        fontWeight: 300,
        color: Theme.Palette.Primary.Foreground,
        margin: 0
    },
    edition: {
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        fontWeight: 300,
        color: Theme.TextColor.Light,
        margin: 0
    },
    playOnHoverContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        position: 'absolute',
        float: 'left',
        width: '100%',
        height: '100%',
        padding: 0,
        left: 0,
        top: 0,
    },
    playOnHoverIcon: {
        width: 25,
        height: 25,
        fill: 'white',
        margin: 10
    }
});