import React from 'react';
import _ from 'lodash';

import { useSelector } from 'react-redux';

import Button from '~/Components/Button';
import PopupContainer from '../PopupContainer';
import MetamaskInstructions from '~/Components/MetamaskInstructions';

import MetaMask from '~/Services/MetaMask';
import Config from '~/config';

import { useStyles } from './styles';

const CreatorRequestApprovePopup = (props) => {

    const classes = useStyles();

    const blockchainState = useSelector(state => state.Blockchain.stateChangedTrigger);

    const [isLoadingData, setIsLoadingData] = React.useState(false);
    const [isProcessingTransaction, setIsProcessingTransaction] = React.useState(false);
    const [isMetamaskEnabled, setIsMetamaskEnabled] = React.useState(false);
    const [selectedAddress, setSelectedAddress] = React.useState('');
    const [isInNetwork, setIsInNetwork] = React.useState(false);
    const [isMaintainerAddress, setIsMaintainerAddress] = React.useState(false);

    const handleClose = (confirm) => {
        if(isProcessingTransaction)
            return;

        if(_.isFunction(props.onClose))
            props.onClose(confirm);
    }

    const handleApproveAddress = () => {
        setIsProcessingTransaction(true)
        MetaMask
            .getTokenContract()
            .setApprovedCreator(props.address, true)
            .then(() => {
                handleClose(true);
            })
            .catch(() => {
                handleClose(false);
            })
    }

    React.useEffect(
        () => {
            setIsInNetwork(MetaMask.isInNetwork(Config.Blockchain.NETWORK)); 
            setIsProcessingTransaction(MetaMask.isProcessingTransaction());
            setSelectedAddress(MetaMask.getCurrentAddress());
            setIsMetamaskEnabled(MetaMask.isAvailable());
            setIsLoadingData(MetaMask.isLoading())
        },
        [blockchainState]
    );

    React.useEffect(
        () => {
            if(MetaMask.getMarketContract() && isMetamaskEnabled && isInNetwork) {
                MetaMask
                    .getTokenContract()
                    .approvedSystemAccounts(selectedAddress)
                    .then(isApproved => {
                        setIsMaintainerAddress(isApproved)
                    })
                    .catch(() => {})
            }
        },
        [selectedAddress, isInNetwork, isMetamaskEnabled]
    );

    return (
        <PopupContainer
            isOpen={props.isOpen}
            disableClosing={isProcessingTransaction}
            onClose={handleClose.bind(null, false)}
            backdropColor="#0009">

            <div className={classes.container}>
                <p className={classes.title}>
                    { 'Approve Request' }
                </p>

                <p className={classes.infoMessage}>
                    You're about to approve this user's request to become a creator. Before you do so, you need to enable MetaMask and select the Contract Owner address.
                </p>

                {
                    !isMetamaskEnabled || !isInNetwork || !isMaintainerAddress || isProcessingTransaction ?
                    <MetamaskInstructions
                        isEnabled={isMetamaskEnabled}
                        isInNetwork={isInNetwork}
                        isInAddress={isMaintainerAddress}
                        accountError={
                            !isMaintainerAddress ?
                            `The selected address is not an approved administrator address. Please select an approved address in order to proceed!` :
                            null
                        }
                        isLoadingData={isLoadingData}
                        isProcessingTransaction={isProcessingTransaction}
                        requiredNetwork={MetaMask.getNetworkNameById(Config.Blockchain.NETWORK)}/> 
                    :
                    <p className={classes.infoMessage}>
                        To approve this user's address for minting tokens, please click the Approve button below! Once the transaction is completed, the user will e able to mint new tokens.
                    </p>
                }

                <div className={classes.buttonContainer}>

                    <Button
                        className={classes.button}
                        disabled={!isMetamaskEnabled || !isInNetwork || !isMaintainerAddress}
                        isLoading={isProcessingTransaction}
                        text={
                            isProcessingTransaction ?
                            'Approving...' :
                            'Approve'
                        }
                        onClick={handleApproveAddress}/>

                    <Button
                        className={classes.button}
                        text={'Cancel'}
                        secondary
                        disabled={isProcessingTransaction}
                        onClick={handleClose.bind(null, null)}/>

                </div>

            </div>

        </PopupContainer>
    );
}

export default CreatorRequestApprovePopup;