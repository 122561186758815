import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    pageContainer: {
        padding: '100px 100px 0px 100px'
    },
    title: {
        fontFamily: Theme.Font.Primary,
        fontSize: 34,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '15px 20px'
    },
    description: {
        fontFamily: Theme.Font.Primary,
        fontSize: 15,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '15px 20px'
    },
    infoContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    authenticityInfo: {
        flex: 3,
        margin: 20
    },
    mediaView: {
        flex: 4,
        margin: 20
    },
    tableTitle: {
        fontFamily: Theme.Font.Primary,
        fontSize: 28,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        margin: '15px 20px'
    },
    tableNote: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '15px 20px'
    },
    '@media only screen and (max-width: 1024px)': {
        infoContainer: {
            flexDirection: 'column-reverse'
        }
    },
    '@media only screen and (max-width: 800px)': {
        pageContainer: {
            padding: '80px 10px 0px 10px'
        },
    },
});