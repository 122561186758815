import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as UserActions from '~/Redux/User/actions';
import { STATE_NOT_PROCESSED, STATE_PROCESSING } from '~/Redux/states';

import { useStyles } from './styles';
import FileInputField from '~/Components/FileInputField';
import LabeledInputField from '~/Components/LabeledInputField';
import Button from '~/Components/Button';
import Helpers from '~/helpers';
import ProfileItem from '~/Components/ProfileItem';

const ProfileAbout = () => {

    const classes = useStyles();    
    
    const dispatch = useDispatch();
    const userData = useSelector(state => state.User);

    const [initialValuesSet, setInitialValuesSet] = React.useState(false);
    const [previewDescription, setPreviewDescription] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [linkValues, setLinkValues] = React.useState({});

    const handleUploadBannerPicture = (file) => {
        dispatch(UserActions.uploadHeaderPicture(file));
    }

    const handleUpdatePreviewDescription = () => {
        dispatch(UserActions.updateData({previewDescription}));
    }

    const handleUploadProfilePreviewPicture = (file) => {
        dispatch(UserActions.uploadProfilePreview(file));
    }

    const handleUpdateAboutData = () => {
        let links = [];

        for(let linkName in linkValues) {
            let linkDetails = 
                ProfileAbout.Links.find(item => item.name === linkName);

            links.push({
                icon: linkName,
                url: linkDetails.prefix + linkValues[linkName]
            })
        }

        dispatch(UserActions.updateAbout(description, links));
    }

    const handleFieldChanged = (field, value) => {
        let newLinkValues = {
            ...linkValues,
            [field]: value
        };

        if(value.trim() === '')
            delete newLinkValues[field];
        
        setLinkValues(newLinkValues);
    }

    const parseLinks = (links) => {
        let initialLinkValues = {};
        
        for(let link of ProfileAbout.Links) {

            let existingLink = 
                links.find(item => item.icon === link.name);

            if(existingLink)
                initialLinkValues[link.name] = existingLink.url.substr(link.prefix.length);
        }

        setLinkValues(initialLinkValues);
    }

    React.useEffect(
        () => {
            if (!userData.data && userData.token && 
                userData.fetchDataState === STATE_NOT_PROCESSED) {       
                dispatch(UserActions.getData());
            }

            if(userData.data && !initialValuesSet) {
                setPreviewDescription(userData.data.preview.description);
                setDescription(userData.data.about.body);
                parseLinks(userData.data.about?.links || []);
                setInitialValuesSet(true);
            }
        },
        [userData, initialValuesSet, dispatch]
    );

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                About you
            </p>


            <FileInputField
                className={classes.fileInputField}
                maxSize={1}
                fileType="IMAGE"
                aspectRatio={5}
                text={
                    userData.aboutHeaderUploadState === STATE_PROCESSING ?
                    `Uploading Banner: ${Math.round(userData.aboutHeaderUploadProgress * 100)}%` :
                    'Banner Image'
                }
                onFileSelected={handleUploadBannerPicture}/>

            {
                userData?.data?.about?.headerUrl &&
                <img
                    alt=""
                    className={classes.bannerPreview}
                    src={userData.data.about.headerUrl}/>
            }

            <p className={classes.label}>
                Description:
            </p>

            <textarea
                className={classes.inputField}
                style={{
                    minHeight: 200
                }}
                type="text"
                value={description}
                onChange={(event) => {setDescription(event.target.value)}}
                placeholder="One or two paragraphs that highlights your background, story and achievements."/>
            

            <p className={classes.label}>
                External links:
            </p>

            {
                ProfileAbout.Links.map(item =>
                    <LabeledInputField
                        className={classes.labeledInputField}
                        key={item.label}
                        labelText={item.label}
                        placeholder={item.placeholder}
                        value={linkValues[item.name] || ''}
                        onChange={handleFieldChanged.bind(null, item.name)}
                        labelFirst />
                )

            }

            <div className={classes.buttonContainer}>

                <p className={classes.updateErrorMessage}>
                    { Helpers.getRequestErrorMessage(userData.userAboutError) }
                </p>

                <Button
                    className={classes.updateButton}
                    text={
                        userData.userAboutState === STATE_PROCESSING ?
                        'Updating data...' : 'Update'
                    }
                    isLoading={userData.userAboutState === STATE_PROCESSING}
                    onClick={handleUpdateAboutData}
                    inverted/>

            </div>


            <p className={classes.smallTitle}>
                Profile preview
            </p>


            <div className={classes.profilePreviewContainer}>

                <ProfileItem
                    profilePictureUrl={userData.data?.profilePictureUrl}
                    description={userData.data?.preview?.description}
                    backgroundImage={userData.data?.preview?.backgroundImageUrl}
                    squareSize={250} />

                <div className={classes.profilePreviewFields}>
                    <FileInputField
                        className={classes.fileInputField}
                        maxSize={1}
                        fileType="IMAGE"
                        text={
                            userData.profilePreviewUploadState === STATE_PROCESSING ?
                            `Uploading Profile Preview: ${Math.round(userData.profilePreviewUploadProgress * 100)}%` :
                            'Profile Preview Image'
                        }
                        onFileSelected={handleUploadProfilePreviewPicture}/>

                    <p className={classes.label}>
                        Short Description:
                    </p>

                    <textarea
                        className={classes.inputField}
                        style={{
                            minHeight: 120
                        }}
                        type="text"
                        value={previewDescription}
                        onChange={(event) => {setPreviewDescription(event.target.value)}}
                        placeholder="One or two sentences that highlights your background and style of work.."/>
                    

                    <div className={classes.buttonContainer}>

                        <p className={classes.updateErrorMessage}>
                            { Helpers.getRequestErrorMessage(userData.updateDataError) }
                        </p>

                        <Button
                            className={classes.updateButton}
                            text={
                                userData.updateDataState === STATE_PROCESSING ?
                                'Updating data...' : 'Update Short Description'
                            }
                            isLoading={userData.updateDataState === STATE_PROCESSING}
                            onClick={handleUpdatePreviewDescription}
                            inverted/>

                    </div>
                </div>

            </div>
            
            <div style={{height: 50}}/>

        </div>
    );
}

ProfileAbout.Links = [
    {
        name: 'Facebook',
        prefix: 'https://facebook.com/',
        label: 'https://facebook.com/',
        placeholder: 'username'
    },
    {
        name: 'Instagram',
        prefix: 'https://instagram.com/',
        label: 'https://instagram.com/',
        placeholder: 'username'
    },
    {
        name: 'Twitter',
        prefix: 'https://twitter.com/',
        label: 'https://twitter.com/',
        placeholder: 'username'
    },
    {
        name: 'Pinterest',
        prefix: 'https://pinterest.com/',
        label: 'https://pinterest.com/',
        placeholder: 'username'
    },
    {
        name: 'Website',
        prefix: '',
        label: 'Website',
        placeholder: 'yourwebsite.com'
    },
    {
        name: 'Behance',
        prefix: 'https://behance.net/',
        label: 'https://behance.net/',
        placeholder: 'username'
    },
]

export default ProfileAbout;