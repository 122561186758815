import React from 'react';
import Helpers from '~/helpers';
import Config from '~/config';

import Button from '../Button';
import ProfilePicture from '../ProfilePicture';

import { Link } from 'react-router-dom';
import { useStyles } from './styles';

const CreationTableEditionActivityItem = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.userData}>
                <ProfilePicture
                    username={props.username}
                    imageUrl={props.profilePictureUrl} 
                    size={35}/>

                {
                    props.username ?
                    <Link 
                        to={`/profiles/${props.username}/`}
                        className={classes.usersName}>
                        {props.name}
                    </Link> :
                    <div className={classes.usersName}>
                        {props.name}
                    </div>
                }
            </div>

            <div className={classes.editionData}>
                <Link 
                    to={
                        props.editionId ? 
                        `/creations/${props.editionId}/` : null
                    }
                    className={
                        props.editionId ? 
                        classes.editionField :
                        classes.textField
                    }>
                    #{ props.edition }
                </Link>
            </div>

            <div className={classes.detailsData}>
                <div className={classes.detailsDataRow}>
                    <p className={classes.textField}>
                        Created
                    </p>
                </div>

                <p className={classes.textField + ' ' + classes.dateText}>
                    { Helpers.getElapsedTimeString(props.createdAt) }
                </p>
            </div>

            <div className={classes.actionData}>
                <div className={classes.actionDataStatus}>
                    <p className={classes.textField}>
                        {
                            props.editionId === null || props.username === null ?
                            'Unavailable' :
                            props.price ?
                            `${Helpers.formatEthPrice(Helpers.weiToEth(props.price))} ${Config.Blockchain.COIN}` :
                            'Accepting offers'
                        }
                    </p>
                </div>

                <div className={classes.actionDataView}>
                    {
                        props.editionId ?
                        <Button
                            text="View"
                            style={{
                                fontSize: 12, 
                                padding: '7px 15px'
                            }}
                            secondary 
                            href={`/creations/${props.editionId}/`}/> :
                        null
                    }
                </div>
            </div>
        </div>
    );
}

export default CreationTableEditionActivityItem;