import API from './api';

class Drop {

    static async listDrops(filter, offset, limit) {
        let queryParams = 
        API.buildQueryParamString(
            {
                filter,
                offset,
                limit
            }
        );
        return API.get(`/drops${queryParams}`);
    }

    static async getDrop(dropId) {
        return API.get(`/drops/${dropId}`);
    }

    static async getDropPageContents() {
        return API.get(`/home/drops`);
    }

    static async create(dropName) {
        return API.post(
            `/drops`,
            { name: dropName },
            true
        );
    }

    static async patch(dropId, fields) {
        return API.patch(
            `/drops/${dropId}`,
            fields,
            true
        );
    }

    static async delete(dropId) {
        return API.delete(
            `/drops/${dropId}`,
            true
        );
    }

    static async uploadBanner(dropId, file, progressCallback) {
        return API.upload(
            `/drops/${dropId}/banner`,
            { file },
            progressCallback,
            true
        );
    }

    static async uploadPreview(dropId, file, progressCallback) {
        return API.upload(
            `/drops/${dropId}/preview`,
            { file },
            progressCallback,
            true
        );
    }
}

export default Drop;