import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row'
    },
    column: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    loadingArea: {
        display: 'none'
    },
    loaderContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    loader: {
        width: 50,
        height: 50
    }
});