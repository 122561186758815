import React from 'react';
import { useStyles } from './styles';
import _ from 'lodash';

const OrderedItemListItem = (props) => {
    const classes = useStyles();

    const handleShiftLeft = () => {
        if(_.isFunction(props.onShiftLeft))
            props.onShiftLeft(props.itemId);
    }

    const handleShiftRight = () => {
        if(_.isFunction(props.onShiftRight))
            props.onShiftRight(props.itemId);
    }

    const handleDelete = () => {
        if(_.isFunction(props.onDelete))
            props.onDelete(props.itemId);
    }

    return (
        <div className={classes.container}>
            
            <div 
                className={
                    classes.buttonItem + ' ' + 
                    classes.leftButton
                }
                onClick={handleShiftLeft}>
                &lt;
            </div>

            <div 
                className={
                    classes.buttonItem + ' ' + 
                    classes.leftButton
                }
                onClick={handleShiftRight}>
                &gt;
            </div>

            <div className={classes.textItem}>
                { props.text || props.itemId }
            </div>

            <div 
                className={
                    classes.buttonItem + ' ' + 
                    classes.rightButton + ' ' + 
                    classes.deleteButton
                }
                onClick={handleDelete}>
                X
            </div>

        </div>
    );
}

export default OrderedItemListItem;