import React from 'react';
import _ from 'lodash';

import PopupContainer from '../PopupContainer';
import { useStyles } from './styles';

import {
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
  } from "react-share";
import Images from '~/Assets/images';

const SharePopup = ({url, onClose, isOpen}) => {

    const classes = useStyles();

    const handleClose = (confirm) => {
        if(_.isFunction(onClose))
            onClose(confirm);
    }

    return (
        <PopupContainer
            isOpen={isOpen}
            onClose={handleClose.bind(null, false)}
            backdropColor="#0009">

            <div className={classes.container}>
                <div className={classes.titleContainer}>
                    <p className={classes.title}>
                        Share creation
                    </p>
                </div>

                <div className={classes.iconContainer}>
                    <TwitterShareButton url={url}>
                        <img alt='' className={classes.shareIcon} src={Images.Share.Twitter}/>
                    </TwitterShareButton>

                    <FacebookShareButton url={url}>
                        <img alt='' className={classes.shareIcon} src={Images.Share.Facebook}/>
                    </FacebookShareButton>

                    <LinkedinShareButton url={url}>
                        <img alt='' className={classes.shareIcon} src={Images.Share.LinkedIn}/>
                    </LinkedinShareButton>

                </div>

                <div className={classes.iconContainer}>
                    <RedditShareButton url={url}>
                        <img alt='' className={classes.shareIcon} src={Images.Share.Reddit}/>
                    </RedditShareButton>

                    <TelegramShareButton url={url}>
                        <img alt='' className={classes.shareIcon} src={Images.Share.Telegram}/>
                    </TelegramShareButton>

                    <TumblrShareButton url={url}>
                        <img alt='' className={classes.shareIcon} src={Images.Share.Tumblr}/>
                    </TumblrShareButton>
                </div>

            </div>

        </PopupContainer>
    );
}

export default SharePopup;