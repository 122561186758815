import React from 'react';
import { useStyles } from './styles';

const CreationMediaView = (props) => {
    const classes = useStyles();

    return (
        <div className={props.className}>
        {
            props.isVideo ?
            <video 
                className={classes.videoPlayback} 
                onContextMenu={(e) => {e.preventDefault()}}
                controls={props.videoControls}
                controlsList="nodownload"
                disablePictureInPicture 
                playsInline 
                autoPlay 
                muted
                loop>
                <source src={props.videoUrl} type="video/mp4"/>
            </video> :
            <img
                className={classes.imagePreview}
                src={props.imageUrl}
                alt=""/>   
        }
        </div>
    );
}

export default CreationMediaView;