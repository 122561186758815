import * as API from '~/API';

import {
    DROPS_PAGE_FETCH_START,
    DROPS_PAGE_FETCH_FINISHED,
    DROPS_PAGE_FETCH_FAILED
} from './types';

export const getDropsPageContents = () => {
    return (dispatch) => {
        
        dispatch({ type: DROPS_PAGE_FETCH_START });

        API.Drop.getDropPageContents()
        .then(data => {
            dispatch({ type: DROPS_PAGE_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: DROPS_PAGE_FETCH_FAILED, payload: error });
        })
    };  
};
