import React from 'react';
import { useStyles } from './styles';
import _ from 'lodash';
import Config from '~/config';

const FileInputField = (props) => {

    const classes = useStyles();
    const inputFileRef = React.useRef(null);
    const [error, setError] = React.useState(null);

    const handleInputClicked = () => {
        if (inputFileRef.current)
            inputFileRef.current.click();
    }

    const handleFileChanged = (event) => {

        let selectedFile = event.target.files[0];

        if(selectedFile && !isFileOfType(selectedFile, props.type)) {
            setError(
                props.fileType === 'IMAGE' ? 
                'The selected file is not a supported image':
                props.fileType === 'VIDEO' ? 
                'The selected file is not a supported video':
                'The selected file is not supported'
            )
            inputFileRef.current.value = '';
            return;
        }

        if(selectedFile && props.maxSize && props.maxSize * 1024 * 1024 < selectedFile.size) {
            setError(`File exceeds the ${props.maxSize}MB limit.`)
            inputFileRef.current.value = '';
            return;
        } 

        setError(null)

        if(props.fileType === 'IMAGE' && props.aspectRatio) {
            getImageAspectRatio(selectedFile)
            .then(aspectRatio => {
                if(aspectRatio < props.aspectRatio) {
                    setError(`The image width must be at least ${props.aspectRatio} times its height`);
                    inputFileRef.current.value = '';
                    return;
                }
                else {
                    if (_.isFunction(props.onFileSelected) && selectedFile)
                        props.onFileSelected(selectedFile)
                }
            })
        }
        else {
            if (_.isFunction(props.onFileSelected) && selectedFile)
               props.onFileSelected(selectedFile)
        }
    }

    const getImageAspectRatio = async (file) => {
        return new Promise((resolve) => {
            try {
                let fileReader = new FileReader();

                fileReader.onload = () => {
                    var image = new Image();
                    image.onload = () => {
                        resolve(image.width / image.height);
                    };
                    image.src = fileReader.result; 
                };
        
                fileReader.readAsDataURL(file);
            }
            catch(ex) {
                resolve(0)
            }
        })
    }

    const getExtensionsForType = (type) => {
        switch(type) {
            case 'IMAGE':
                return Config.FileTypes.IMAGE;
            case 'VIDEO':
                return Config.FileTypes.VIDEO;
            default:
                return [...Config.FileTypes.IMAGE, ...Config.FileTypes.VIDEO];
        }
    }

    const isFileOfType = (file, type) => {
        return getExtensionsForType(type).includes(file?.type);
    }

    return (
        <div
            className={
                props.className ?
                    classes.container + ' ' + props.className :
                    classes.container
            }
            onChange={handleFileChanged}
            onClick={handleInputClicked}>

            <input
                className={classes.fileInputFiled}
                ref={inputFileRef}
                type="file" />

            <div 
                className={classes.inputText} 
                style={error && {color: '#f00'}}>
                {error || props.text}
            </div>

            <div className={classes.inputButton}>
                {props.buttonText || 'Browse'}
            </div>

        </div>
    );
}

export default FileInputField;