import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as HomePageActions from '~/Redux/HomePage/actions';
import { STATE_NOT_PROCESSED } from '~/Redux/states';
import * as API from '~/API';

import LabeledInputField from '~/Components/LabeledInputField';
import CollectionItem from '~/Components/CollectionItem';
import ProfileItem from '~/Components/ProfileItem';
import Carousel from '~/Components/Carousel';
import Button from '~/Components/Button';

import { useStyles } from './styles';

const ManageFeatured = () => {

    const classes = useStyles();    
    const dispatch = useDispatch();

    const [isUpdatingCreations, setIsUpdatingCreations] = React.useState(false);
    const [isUpdatingCreators, setIsUpdatingCreators] = React.useState(false);

    const [featuredCreationId, setFeaturedCreationId] = React.useState('');
    const [featuredCreator, setFeaturedCreator] = React.useState('');

    const contents = useSelector(state => state.HomePage.contents);
    const contentsState = useSelector(state => state.HomePage.contentsState);

    React.useEffect(
        () => {
            if(contentsState === STATE_NOT_PROCESSED)
                dispatch(HomePageActions.getHomePageContents());
        }, 
        [contentsState, dispatch]
    );


    const handleUpdateCreators = () => {
        setIsUpdatingCreators(true);
        API.Admin.makeFeaturedCreator(featuredCreator)
            .then(() => {
                setIsUpdatingCreators(false);
                dispatch(HomePageActions.getHomePageContents());
            })
            .catch(error => {
                setIsUpdatingCreators(false);
                alert(error)
            });
    }

    const handleUpdateCreations = () => {
        setIsUpdatingCreations(true);
        API.Admin.makeFeaturedCreation(featuredCreationId)
            .then(() => {
                setIsUpdatingCreations(false);
                dispatch(HomePageActions.getHomePageContents());
            })
            .catch(error => {
                setIsUpdatingCreations(false);
                alert(error)
            });
    }

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Manage Featured
            </p>

            <p className={classes.infoMessage}>
                This page enables administrators to set the Featured ranking for Creations and Creators. When setting a new item as featured, the item will always appear at the top of the list.
            </p>

            <p className={classes.warningMessage}>
                CAUTION: Please make sure the configuration you set is correct! Changes take effect immediately.
            </p>

            <div style={{height: 20}}/>


            <p className={classes.smallTitle}>
                Creators
            </p>

            <p className={classes.infoMessage}>
                The feald below enables you to set a creator as featured. The creator will appear at the top of the featured list once the update is done.
            </p>

            <div className={classes.buttonContainer}>

                <LabeledInputField
                    className={classes.inputField}
                    labelText="Creator"
                    placeholder="Creator's Username"
                    type="text"
                    min="0"
                    value={featuredCreator}
                    onChange={setFeaturedCreator}
                    labelFirst />

                <Button
                    className={classes.updateButton}
                    text={
                        isUpdatingCreators ?
                        'Updating...' : 'Make featured'
                    }
                    isLoading={isUpdatingCreators}
                    onClick={handleUpdateCreators}
                    inverted/>

            </div>

            {
                contents && contents.featuredCreators ?
                <Carousel
                    className={classes.carousel}
                    itemWidth={300}>
                    {
                        contents.featuredCreators
                            .map(item =>
                                <ProfileItem
                                    key={item.username}
                                    username={item.username}
                                    name={item.name}
                                    profilePictureUrl={item.profilePictureUrl}
                                    description={item.preview.description}
                                    backgroundImage={item.preview.backgroundImageUrl}
                                    isCreator={item.isCreator}
                                    squareSize={220}/>           
                            )
                    }
                </Carousel> : null
            }

            <div style={{height: 50}}/>

            <p className={classes.smallTitle}>
                Creations
            </p>

            <p className={classes.infoMessage}>
                The feald below enables you to set a creation as featured. The creation will appear at the top of the featured list once the update is done.
            </p>

            <div className={classes.buttonContainer}>

                <LabeledInputField
                    className={classes.inputField}
                    labelText="Creation"
                    placeholder="Creation ID"
                    type="text"
                    min="0"
                    isLoading={isUpdatingCreations}
                    value={featuredCreationId}
                    onChange={setFeaturedCreationId}
                    labelFirst />

                <Button
                    className={classes.updateButton}
                    text={
                        isUpdatingCreations ?
                        'Updating...' : 'Make featured'
                    }
                    isLoading={isUpdatingCreations}
                    onClick={handleUpdateCreations}
                    inverted/>

            </div>

            {
                contents && contents.featuredCreations ?
                <Carousel
                    className={classes.carousel}
                    itemWidth={300}>
                    {
                        contents.featuredCreations
                            .map(item =>
                                <CollectionItem
                                    key={item.creationId}
                                    creationId={item.creationId}
                                    creationName={item.name}
                                    creatorList={item.creators}
                                    releaseNumber={item.release.number}
                                    releaseCount={item.release.count}
                                    status={item.status}
                                    price={item.price}
                                    directPurchasePrice={item.directPurchasePrice}
                                    imageUrl={item.media.image.url}
                                    videoUrl={item.media.video.url} 
                                    playOnHover={!!item.media.video.url}
                                    squareSize={220} />
                            )
                    }
                </Carousel> : null
            }

            <div style={{height: 30}}/>

        </div>
    );
}

export default ManageFeatured;