import API from './api';

class Discover {
    
    static async getHomePageContents() {
        return API.get(`/home`);
    }

    static async getActivity(offset, limit) {
        let queryParams = 
            API.buildQueryParamString(
                {
                    offset,
                    limit
                }
            );

        return API.get(`/discover/activity${queryParams}`);
    }

    static async getCreations(order, offset, limit) {
        let queryParams = 
            API.buildQueryParamString(
                {
                    order,
                    offset,
                    limit
                }
            );

        return API.get(`/discover/creations${queryParams}`);
    }

    static async getProfiles(role, order, offset, limit) {
        let queryParams = 
            API.buildQueryParamString(
                {
                    role, 
                    order,
                    offset,
                    limit
                }
            );

        return API.get(`/discover/profiles${queryParams}`);
    }

    static async search(query) {
        let queryParams = 
            API.buildQueryParamString(
                { query }
            );

        return API.get(`/discover/search${queryParams}`);
    }
}

export default Discover;