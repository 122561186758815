import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    boxContainer: {
        flex: 1,
        padding: 25,
        display: 'flex',
        flexDirection: 'column',
        webkitBoxShadow: '0px 13px 40px 0px rgb(0 0 0 / 8%)',
        backgroundImage: '0px 13px 40px 0px rgba(0,0,0,0.08)',
        boxShadow: '0px 13px 40px 0px rgb(0 0 0 / 8%)',
        backgroundColor: Theme.Background.Lighter
    },
    titleContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
    },
    authenticityIcon: {
        width: 55,
        height: 55
    },
    title: {
        flex: 1,
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        fontSize: 28,
        fontWeight: 400,
        margin: 5
    },
    networkContainer: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    networkIcon: {
        height: 35,
        margin: '5px'
    },
    attributeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        margin: 3
    },
    attributeLabel: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        margin: 5
    },
    attributeValue: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        fontSize: 17,
        fontWeight: 400,
        margin: 5,
        cursor: 'default',
        textDecoration: 'none',
    },
    attributeValueLink: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        fontSize: 17,
        fontWeight: 400,
        margin: 5,
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            color: Theme.Palette.Primary.Foreground
        }
    },
    proofLink: {
        color: Theme.Palette.Primary.Foreground,
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        margin: 10,
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    '@media only screen and (max-width: 800px)': {
        pageContainer: {
            padding: '80px 10px 0px 10px'
        },
        authenticityIcon: {
            display: 'none'
        },
        title: {
            fontSize: 28,
        },
        networkIcon: {
            height: 30,
        },
        attributeLabel: {
            fontSize: 12
        },
        attributeValue: {
            fontSize: 15
        },
        attributeValueLink: {
            fontSize: 15
        },
    },
});