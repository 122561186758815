import React from 'react';
import * as API from '~/API';
import { useDispatch } from 'react-redux';
import * as UserActions from '~/Redux/User/actions';
import Helpers from '~/helpers';
import _ from 'lodash';

import PopupContainer from '../PopupContainer';
import Button from '~/Components/Button';

import { useStyles } from './styles';
import { Fragment } from 'react';

const VerificationPopup = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [isRequestingCode, setIsRequestingCode] = React.useState(false);
    const [isCodeRequested, setIsCodeRequested] = React.useState(false);
    const [isVerifying, setIsVerifying] = React.useState(false);
    const [requestError, setRequestError] = React.useState(null);

    const [requestCode, setRequestCode] = React.useState('');

    const handleClose = (loginOk) => {
        if(_.isFunction(props.onClose))
            props.onClose(!!loginOk);
    }

    const handleRequestCode = () => {
        if(isRequestingCode)
            return;
        
        setRequestError(null);
        setIsRequestingCode(true);

        API.User
            .requestEmailVerification()
            .then(() => {
                setIsCodeRequested(true);
                setIsRequestingCode(false);
            })
            .catch(err => {
                if(err.includes('rate limit')) {
                    setIsCodeRequested(true);
                    setIsRequestingCode(false);
                }
                else {
                    setIsRequestingCode(false);
                    setRequestError(err);
                }

            })
    }

    const handleVerifyEmail = () => {
        setRequestError(null);
        setIsVerifying(true);

        API.User
            .verifyEmailWithCode(requestCode)
            .then(() => {
                setIsCodeRequested(true);
                setIsVerifying(false);
                dispatch(UserActions.setUserVerified())
                props.onClose(true);
            })
            .catch(err => {
                setIsVerifying(false);
                setRequestError(err);
            })
    }

    return (
        <PopupContainer
            isOpen={props.isOpen}
            onClose={handleClose}
            backdropColor="#0009">

            <div className={classes.container}>
                <p className={classes.title}>
                    Verify email
                </p>

                {
                    !isCodeRequested ?
                    <Fragment>
                        <p className={classes.infoMessage}>
                            To verify your email address, click the "Request code" button below. You'll then receive a code in your email address.
                        </p>

                        <p className={classes.infoMessage}>
                            If you dont receive the code, please check your "Spam" or "Junk" folders!
                        </p>

                        <Button
                            className={classes.button}
                            isLoading={isRequestingCode}
                            text={
                                isRequestingCode ?
                                "Requesting..." :
                                "Request code"
                            }
                            onClick={handleRequestCode}/>
                    </Fragment>
                    :
                    <Fragment>
                        <input 
                            className={
                                Helpers.getRequestErrorField(requestError) === 'code' ?
                                classes.inputField + ' ' + classes.inputFieldInvalid :
                                classes.inputField
                            }
                            placeholder="Verification code (sent by email)"
                            type="text"
                            value={requestCode}
                            onChange={(event) => {
                                setRequestCode(event.target.value)
                            }}
                            onKeyPress={(event) => { 
                                if(event.key === 'Enter') 
                                    handleVerifyEmail() 
                            }}
                            autoFocus/>

                        {
                            requestError &&
                            <p className={classes.errorMessage}>
                                { Helpers.getRequestErrorMessage(requestError) }
                            </p>
                        }

                        <Button
                            className={classes.button}
                            isLoading={isVerifying}
                            text={
                                isVerifying ?
                                "Verifying..." :
                                "Verify"
                            }
                            onClick={handleVerifyEmail}/>
                    </Fragment>
                }

            </div>

        </PopupContainer>
    );
}

export default VerificationPopup;