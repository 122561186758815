import _ from 'lodash';
import React from 'react';
import Button from '~/Components/Button';
import { useStyles } from './styles';

const InvalidateApproval = (props) => {

    const classes = useStyles();
    const [ignored, setIgnored] = React.useState(false);

    const handleIgnoreChanged = (target) => {
        const ignoredValue = target.target.checked;
        setIgnored(ignoredValue);

        if(_.isFunction(props.onIgnoreChanged))
            props.onIgnoreChanged(ignoredValue)
    }

    return (
        <div className={classes.container}>

            <p className={classes.instructionText} style={{opacity: ignored ? 0.5 : 1}}>
                You have already signed an approval for the sale of this creation that is still valid. We recommend invalidating this approval before you sign a new one.
            </p>

            <p className={classes.instructionText} style={{opacity: ignored ? 0.5 : 1}}>
                If you do not invalidate the older approvals, a malicious party will be able to purchase the creation at the old price.
            </p>

            <div className={classes.invalidateControls}>
                <div className={classes.checkboxContainer}>
                    <input 
                        className={classes.checkbox}
                        onChange={handleIgnoreChanged}
                        value={ignored}
                        type="checkbox" 
                        id="ignore-invalidate"/>
                    
                    <label 
                        className={classes.checkboxLabel} 
                        htmlFor="ignore-invalidate">
                        Ignore this step! I know what I'm doing.
                    </label>
                </div>

                <Button
                    className={classes.invalidateButton}
                    onClick={props.onInvalidateClicked}
                    disabled={ignored || props.isInvalidatingApproval}
                    text={
                        props.isInvalidatingApproval ?
                        "Invalidating..." :   
                        "Invalidate"
                    }/>

            </div>

        </div>
    );
}

export default InvalidateApproval;