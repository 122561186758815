import * as API from '~/API';

import {
    CREATION_DATA_FETCH_START,
    CREATION_DATA_FETCH_FINISHED,
    CREATION_DATA_FETCH_FAILED,
    CREATION_COMMENTS_FETCH_START,
    CREATION_COMMENTS_FETCH_FINISHED,
    CREATION_COMMENTS_FETCH_FAILED,
    CREATION_ACTIVITY_FETCH_START,
    CREATION_ACTIVITY_FETCH_FINISHED,
    CREATION_ACTIVITY_FETCH_FAILED,
    CREATION_EDITIONS_FETCH_START,
    CREATION_EDITIONS_FETCH_FINISHED,
    CREATION_EDITIONS_FETCH_FAILED
} from './types';

export const getCreationData = (creationId) => {
    return (dispatch) => {
        dispatch({ type: CREATION_DATA_FETCH_START });

        API.Creations.get(creationId)
        .then(data => {
            dispatch({ type: CREATION_DATA_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: CREATION_DATA_FETCH_FAILED, payload: error });
        })
    };  
};

export const getCreationActivity = (creationId) => {
    return (dispatch) => {
        dispatch({ type: CREATION_ACTIVITY_FETCH_START });

        API.Creations.getActivity(creationId)
        .then(data => {
            dispatch({ type: CREATION_ACTIVITY_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: CREATION_ACTIVITY_FETCH_FAILED, payload: error });
        })
    };  
};

export const getCreationComments = (creationId) => {
    return (dispatch) => {
        dispatch({ type: CREATION_COMMENTS_FETCH_START });

        API.Creations.getComments(creationId)
        .then(data => {
            dispatch({ type: CREATION_COMMENTS_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: CREATION_COMMENTS_FETCH_FAILED, payload: error });
        })
    };  
};

export const getCreationEditions = (creationId) => {
    return (dispatch) => {
        dispatch({ type: CREATION_EDITIONS_FETCH_START });

        API.Creations.getEditions(creationId)
        .then(data => {
            dispatch({ type: CREATION_EDITIONS_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: CREATION_EDITIONS_FETCH_FAILED, payload: error });
        })
    };  
};

export const postComment = (creationId, body, replyTo) => {
    return (dispatch) => {
        API.Creations.postComment(creationId, body, replyTo)
        .then(() => {
            API.Creations.getComments(creationId)
            .then(data => {
                dispatch({ type: CREATION_COMMENTS_FETCH_FINISHED, payload: data });
            })
            .catch(() => {})
        })
    };  
};

export const deleteComment = (creationId, commentId) => {
    return (dispatch) => {
        API.Creations.deleteComment(creationId, commentId)
        .then(() => {
            API.Creations.getComments(creationId)
            .then(data => {
                dispatch({ type: CREATION_COMMENTS_FETCH_FINISHED, payload: data });
            })
            .catch(() => {})
        })
    };  
};