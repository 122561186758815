import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    contentBody: {
        display: 'flex',
        flexDirection: 'row',
        padding: '15px 0px'
    },
    fieldsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    attributeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        margin: '3px 0'
    },
    attributeLabel: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Secondary,
        fontSize: 17,
        fontWeight: 300,
        margin: '10px 0'
    },
    attributeValue: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        fontSize: 22,
        fontWeight: 400,
        margin: 5,
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            color: Theme.Palette.Primary.Foreground
        }
    },
    proofLink: {
        color: Theme.Palette.Primary.Foreground,
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        margin: '15px 5px',
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    authenticityIcon: {
        width: 55,
        height: 55
    },
    ribbonCorner: {
        width: 55,
        height: 55,
        alignSelf: 'flex-end',
        marginTop: -1
    }
});