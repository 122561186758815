import * as API from '~/API';

import {
    DROP_DETAILS_FETCH_START,
    DROP_DETAILS_FETCH_FINISHED,
    DROP_DETAILS_FETCH_FAILED
} from './types';

export const getDrop = (dropId) => {
    return (dispatch) => {
        
        dispatch({ type: DROP_DETAILS_FETCH_START });

        API.Drop.getDrop(dropId)
        .then(data => {
            dispatch({ type: DROP_DETAILS_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: DROP_DETAILS_FETCH_FAILED, payload: error });
        })
    };  
};
