import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        position: 'relative',
        fontFamily: Theme.Font.Secondary,
        cursor: 'pointer',
        transition: '0.2s ease'
    },
    bodyContainer: ({darkBackground}) => ({
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: darkBackground ? '#0002' : '#fff3',
        opacity: 0.8,
        '&:hover': {
            opacity: 1,
            backgroundColor: 'transparent',
        },
    }),
    loadingContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Theme.Palette.Secondary.Background,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: Theme.Palette.Secondary.Foreground,
        color: Theme.Palette.Secondary.Foreground,
        fontFamily: Theme.Font.Secondary,
        cursor: 'default',
    },
    loader: {
        width: 20,
        height: 20,
        marginRight: 10,
        opacity: 0.8,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    },
    horizontalBg: ({pixelSize, borderColor}) => ({
        position: 'absolute',
        width: '100%',
        height: `calc(100% - ${4 * pixelSize}px)`,
        marginTop: 2 * pixelSize,
        backgroundColor: borderColor,
    }),
    verticalBg: ({pixelSize, borderColor}) => ({
        position: 'absolute',
        width: `calc(100% - ${4 * pixelSize}px)`,
        height: '100%',
        marginLeft: 2 * pixelSize,
        backgroundColor: borderColor,
    }),
    centerBg: ({pixelSize, borderColor}) => ({
        position: 'absolute',
        width: `calc(100% - ${2 * pixelSize}px)`,
        height: `calc(100% - ${2 * pixelSize}px)`,
        marginLeft: pixelSize,
        marginTop: pixelSize,
        backgroundColor: borderColor,
    }),
    horizontalFg: ({pixelSize, foregroundColor}) => ({
        position: 'absolute',
        width: `calc(100% - ${2 * pixelSize}px)`,
        height: `calc(100% - ${4 * pixelSize}px)`,
        marginLeft: pixelSize,
        marginTop: 2 * pixelSize,
        backgroundColor: foregroundColor,
    }),
    verticalFg: ({pixelSize, foregroundColor}) => ({
        position: 'absolute',
        width: `calc(100% - ${4 * pixelSize}px)`,
        height: `calc(100% - ${2 * pixelSize}px)`,
        marginLeft: 2 * pixelSize,
        marginTop: pixelSize,
        backgroundColor: foregroundColor,
    })
})