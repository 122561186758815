import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as UserDataActions from '~/Redux/UserData/actions';
import { STATE_NOT_PROCESSED } from '~/Redux/states';

import CreationTableHeader from '~/Components/CreationTableHeader';
import NoContentInfoBox from '~/Components/NoContentInfoBox';
import SaleItem from '~/Components/SaleItem';

import Images from '~/Assets/images';
import { useStyles } from './styles';

const PurchaseHistory = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const userData = useSelector(state => state.UserData);

    React.useEffect(
        () => {
            if (userData.salesState === STATE_NOT_PROCESSED || 
                userData.showPurchaseSales !== true)
                dispatch(UserDataActions.getSales(true));
        }, 
        [ userData, dispatch ]
    );

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Purchase History
            </p>

            <div style={{height: 30}}/>

            {
                userData.sales &&
                userData.sales.length > 0 ?
                <Fragment>
                    <CreationTableHeader
                        itemStyle={classes.headerItem}
                        headerItems={[
                            { text: 'Creation', flex: 5},
                            { text: 'Seller', flex: 3},
                            { text: 'Date', flex: 2},
                            { text: 'Value', flex: 3}
                        ]}/>
                    
                    <div className={classes.itemsContainer}>
                        {
                            userData.sales.map(saleData => 
                                <SaleItem
                                    creationId={saleData.creation?.creationId}
                                    name={saleData.creation?.name}
                                    previewUrl={saleData.creation?.media?.image?.url}
                                    edition={saleData.creation?.release?.count}
                                    username={saleData.seller?.username}
                                    usersName={saleData.seller?.name}
                                    profilePictureUrl={saleData.seller?.profilePictureUrl}
                                    amount={saleData.value.amount}
                                    date={(new Date(saleData.createdAt)).toDateString()}
                                    coinPrice={saleData.value.coinPrice}
                                    tokenContract={'0xea88ca175c38ab5c5a18c9cd28d08283b72b4745'}
                                    txid={saleData.txid}
                                    descriptionFlex={5}
                                    dateFlex={2}
                                    userColumnFlex={3}
                                    priceColumnFlex={3}/>
                            )
                        }
                    </div>
                </Fragment> :
                <NoContentInfoBox
                    icon={Images.EmptyGallery}
                    text={'You have not made any purchases yet'}/>
            }

            <div style={{height: 50}}/>

        </div>
    );
}

export default PurchaseHistory;