import React from 'react';
import { Link } from 'react-router-dom';

import Button from '~/Components/Button';
import ProfilePicture from '~/Components/ProfilePicture';
import { useStyles } from './styles';

const CreatorRequestItem = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.container}>

            <div 
                className={classes.descriptionContainer}
                style={{flex: props.descriptionFlex}}>
                <ProfilePicture
                    username={props.username}
                    imageUrl={props.profilePictureUrl} />

                <div className={classes.column}>

                    <Link
                        to={`/profiles/${props.username}`} 
                        className={classes.itemName}>
                        {props.name}
                    </Link>

                    <p className={classes.itemEdition}>
                        { props.email }
                    </p>

                </div>
            </div>

            <div 
                className={classes.column}
                style={{flex: props.dateFlex}}>

                <p className={classes.status}>
                    {
                        props.creatorRequest?.updatedAt ?
                        new Date(props.creatorRequest?.updatedAt).toDateString() :
                        '-'
                    }
                </p>

            </div>

            <div 
                className={classes.columnHorizontal}
                style={{flex: props.statusFlex}}>

                <p className={classes.status}>
                    { props.creatorRequest?.requestCount }
                </p>

                {
                    props.onViewRequest &&
                    <Button
                        className={classes.button}
                        onClick={props.onViewRequest.bind(null, props.username)}
                        text="View Request"/>
                }

            </div>

        </div>
    );
}

export default CreatorRequestItem;