import React from 'react';

const ViewIcon = (props) => {
    return (
        <svg
            className={ props.className }
            xmlns="http://www.w3.org/2000/svg" 
            version="1.1"
            viewBox="0 0 32 32">
            <path d="M 16,6 C 6,6 0,15.938 0,15.938 0,15.938 6,26 16,26 26,26 32,16 32,16 32,16 26,6 16,6 z m 0,18 C 7.25,24 2.5,16 2.5,16 2.5,16 7.25,8 16,8 c 8.75,0 13.5,8 13.5,8 0,0 -4.75,8 -13.5,8 z m 6,-8 c 0,3.313708 -2.686292,6 -6,6 -3.313708,0 -6,-2.686292 -6,-6 0,-3.313708 2.686292,-6 6,-6 3.313708,0 6,2.686292 6,6 z" />
        </svg>
    )
}

export default ViewIcon;