import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopupManager from '~/Auxilary/PopupManager';
import Config from '~/config';

import * as MintRequestActions from '~/Redux/MintRequest/actions';
import { STATE_NOT_PROCESSED } from '~/Redux/states';
import { useHistory } from 'react-router-dom';

import CreationTableHeader from '~/Components/CreationTableHeader';
import NoContentInfoBox from '~/Components/NoContentInfoBox';
import MintRequestItem from './MintRequestItem';
import Button from '~/Components/Button';

import Images from '~/Assets/images';
import { useStyles } from './styles';
import * as API from '~/API';
import MetaMask from '~/Services/MetaMask';

const MintRequest = () => {

    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const mintRequests = useSelector(state => state.MintRequest);

    const [dataReloadRequired, setDataReloadRequired] = React.useState(true);
    const [unavailableResources, setUnavailableResources] = React.useState([]);
    const [lastProcessingCount, setLastProcessingCount] = React.useState(0);

    React.useEffect(
        () => {
            if (mintRequests.mintRequestListState === STATE_NOT_PROCESSED || dataReloadRequired) {
                setDataReloadRequired(false);
                setUnavailableResources(MetaMask.getUnavailableResources('MINTS'));
                dispatch(MintRequestActions.listMintRequests());
            }
        }, 
        [ mintRequests, dataReloadRequired, dispatch ]
    );

    React.useEffect(
        () => {
            const timer = setInterval(() => {
                dispatch(MintRequestActions.listMintRequests());
            }, 5000);
            return () => clearInterval(timer);
        }, 
        [dispatch]
    );

    React.useEffect(
        () => {
            let processingRequests = mintRequests
                .mintRequestList
                ?.filter(mintRequestData => 
                    unavailableResources.includes(mintRequestData.mintRequestId)
                );

            if(processingRequests && lastProcessingCount > processingRequests.length)
                history.push('/profile/manage-creations')

            if(processingRequests)
                setLastProcessingCount(processingRequests.length)
        },
        [
            mintRequests.mintRequestList, 
            unavailableResources, 
            lastProcessingCount,
            history
        ]
    )

    const handleCreateMintRequest = () => {
        history.push(`/profile/upload-creation`)
    }

    const handleMintItem = (itemId) => {

        let mintRequestData = mintRequests
            .mintRequestList
            .find(item => item.mintRequestId === itemId);

        if(!mintRequestData)
            return;

        PopupManager
            .pushMintPopup(
                mintRequestData, 
                mintRequestData.editions, 
                mintRequestData.blockchain.networkId
            )
            .then(() => {
                setUnavailableResources(MetaMask.getUnavailableResources('MINTS'));
            })
    }

    const handleDeleteItem = (itemId) => {
        (async () => {

            let promptResult = await PopupManager.pushMessagePopup(
                'Delete Upload',
                ['Are you sure you want to delete this creation upload? If you proceed to do so, you will not be able to publish this creation.'],
                null, true, 'Yes', 'No', true
            );

            if(promptResult) {
                await API.MintRequest.delete(itemId);
                dispatch(MintRequestActions.listMintRequests());
            }

        })().catch(() => {})
    }

    return (
        <div className={classes.container}>

            <div className={classes.headerContainer}>
                <p className={classes.title}>
                    Manage uploads
                </p>

                <Button
                    className={classes.createMintRequestButton}
                    text="Upload new media"
                    onClick={handleCreateMintRequest}/>
            </div>

            <div style={{height: 30}}/>

            {
                mintRequests.mintRequestList && 
                mintRequests.mintRequestList.length > 0 ? 
                <Fragment>
                    <CreationTableHeader
                        itemStyle={classes.headerItem}
                        headerItems={[
                            { text: 'Creation', flex: 12},
                            { text: 'Status', flex: 6},
                            { text: 'Action', flex: 5}
                        ]}/>
                    
                    <div className={classes.itemsContainer}>
                        {
                            mintRequests.mintRequestList.map(mintRequestData =>
                                <MintRequestItem
                                    key={mintRequestData.mintRequestId}
                                    mintRequestId={mintRequestData.mintRequestId}
                                    name={mintRequestData.name}
                                    previewUrl={mintRequestData?.media?.image?.url}
                                    editions={mintRequestData?.editions}
                                    status={
                                        unavailableResources.includes(mintRequestData.mintRequestId) ?
                                        Config.MintStatus.PUBLISHING :
                                        (Config.MintStatus[mintRequestData.approval.status] || 'Unknown')
                                    }
                                    descriptionFlex={12}
                                    statusFlex={6}
                                    priceColumnFlex={5}
                                    onMintClicked={
                                        mintRequestData.approval.status === 'PROCESSED' &&
                                        !unavailableResources.includes(mintRequestData.mintRequestId) ?
                                        handleMintItem : null
                                    }
                                    onDeleteClicked={
                                        mintRequestData.approval.status === 'PROCESSED' &&
                                        !unavailableResources.includes(mintRequestData.mintRequestId) ?
                                        handleDeleteItem : null
                                    }/>
                            )
                        }
                    </div>
                </Fragment> :
                <NoContentInfoBox
                    icon={Images.EmptyGallery}
                    text={'You have no active publication requests'}                   
                    buttonText="Upload new media"
                    onButtonClick={handleCreateMintRequest}/>
            }

            <div style={{height: 50}}/>

        </div>
    );
}

export default MintRequest;