const Config = {
    Server: {
        API_BASE_URL: process.env.REACT_APP_VAST_API_BASE_URL,
        BASE_URL: '/'
    },
    Platform: {
        NAME: 'VAST',
        DOMAIN: 'https://vast.app',
        OFFER_DEADLINE_HOURS: 2 * 24,
        MAX_EDITIONS: 1500,
        AUCTION_EXTEND_TIME: 15 * 60 * 1000,
        MAX_AUCTION_TIME_DAYS: 7,
        Coinify: {
            PARTNER_ID: '601b63ef-2433-49ae-8d67-37b0a14f56a4',
            BASE_URL: 'https://trade-ui.coinify.com/widget',
            PRIMARY_COLOR: 'black',
            CRYPTOCURRENCY: 'POLYGON',
            DEFAULT_FIAT: 'USD'
        },
        Community: [
            {
                type: 'Telegram',
                url: 'https://t.me/thefutureisvastapp'
            },
            {
                type: 'Twitter',
                url: 'https://twitter.com/VAST_app'
            },
            {
                type: 'Instagram',
                url: 'https://www.instagram.com/vast.app'
            },
            {
                type: 'TikTok',
                url: 'https://vm.tiktok.com/ZML8Q1c6b'
            },
            {
                type: 'Reddit',
                url: 'https://www.reddit.com/r/VASTapp'
            },
            {
                type: 'Facebook',
                url: 'https://www.facebook.com/getVASTapp'
            },
            {
                type: 'LinkedIn',
                url: 'https://www.linkedin.com/company/vastapp'
            },
            {
                type: 'Linktree',
                url: 'https://linktr.ee/VAST.app'
            },
            {
                type: 'Discord',
                url: 'https://discord.gg/3ZWARJqrbm'
            }
        ]
    },
    Blockchain: {
        NETWORK: parseInt(process.env.REACT_APP_VAST_BLOCKCHAIN_NETWORK || '1'),
        COIN: process.env.REACT_APP_VAST_BLOCKCHAIN_COIN,
        TOKEN: process.env.REACT_APP_VAST_BLOCKCHAIN_TOKEN,
        MARKET: process.env.REACT_APP_VAST_BLOCKCHAIN_MARKET,
        NETWORK_DATA: {
            chainId: '0x89',
            chainName: 'Polygon',
            nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18
            },
            rpcUrls: ['https://polygon-rpc.com/'],
            blockExplorerUrls: ['https://polygonscan.com/'],
        }
    },
    FileTypes: {
        IMAGE: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
        VIDEO: ['video/mp4', 'video/quicktime']
    },
    UI: {
        DESCRIPTION_TRUNCATE_LENGTH: 300,
        PAGE_SCROLL_END_THRESHOLD: 300,
        MAX_COMMENT_LENGTH: 300,
        PAGINATION_COUNT: 5,
        ProfilePages: {
            CREATIONS: 'CREATIONS',
            COLLECTION: 'COLLECTION',
            ABOUT: 'ABOUT',
        }
    },
    User: {
        Roles: {
            ADMIN: 'ADMIN',
            CREATOR: 'CREATOR',
            COLLECTOR: 'COLLECTOR',
            GUEST: 'GUEST'
        }
    },
    OrderType: {
        NEW: 'NEW',
        FEATURED: 'FEATURED',
        POPULAR: 'POPULAR'
    },
    CreationStatus: {
        ACCEPTING_OFFERS: 'Accepting offers',
        FIXED_PRICE: 'Fixed price',
        AUCTION: 'In Auction',
        NOT_FOR_SALE: 'Not for sale'
    },
    MintStatus: {
        PROCESSING: 'Processing media...',
        PROCESSED: 'Ready to publish',
        PROCESSING_FAILED: 'Media processing failed',
        PENDING: 'Waiting for approval',
        APPROVED: 'Ready to publish',
        PUBLISHING: 'Publishing...',
        DENIED: 'Denied',
        MINTED: 'Minted'
    },
    OfferStatus: {
        PENDING: 'Waiting for approval...',
        ACCEPTED: 'Accepted',
        REJECTED: 'Denied'
    }
}

export default Config;