import React from 'react';
import { Link } from 'react-router-dom';
import Images from '~/Assets/images';

import Button from '~/Components/Button';
import { useStyles } from './styles';

const DropListItem = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.container}>

            <div 
                className={classes.descriptionContainer}
                style={{flex: props.descriptionFlex}}>
                <Link to={`/drops/${props.dropId}`}>
                    <div 
                        className={classes.itemPreview}
                        style={{
                            background: 
                                props.previewUrl ?
                                `url('${props.previewUrl}')` :
                                `url('${Images.NoPreview}')`
                        }}>
                    </div>
                </Link>

                <div className={classes.column}>

                    <Link
                        to={`/drops/${props.dropId}`} 
                        className={classes.itemName}>
                        {props.name}
                    </Link>

                    <p className={classes.itemEdition}>
                        Creators: {props.creatorCount}
                    </p>

                </div>
            </div>

            <div 
                className={classes.column}
                style={{flex: props.dateFlex}}>

                <p className={classes.status}>
                    { props.date }
                </p>

            </div>

            <div 
                className={classes.columnHorizontal}
                style={{flex: props.statusFlex}}>

                <p className={classes.status}>
                    { props.status }
                </p>

                {
                    props.onEditClicked &&
                    <Button
                        className={classes.button}
                        onClick={props.onEditClicked.bind(null, props.dropId)}
                        text="Edit Drop"/>
                }

                {
                    props.onDeleteClicked &&
                    <Button
                        className={classes.button}
                        secondary
                        onClick={props.onDeleteClicked.bind(null, props.dropId)}
                        text="Delete"/>
                }

            </div>

        </div>
    );
}

export default DropListItem;