import React from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import _ from 'lodash';

import ProfilePicture from '../ProfilePicture';
import ArrowDown from '~/Assets/Vectors/ArrowDown';

import { useStyles } from './styles';

const HeaderProfileMenu = (props) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.container}>
            <ProfilePicture
                username={props.username}
                imageUrl={props.profilePictureUrl}
                highlightOnHover
                size={30}
                style={{ margin: 5 }} />

            <Menu
                menuButton={
                    <div className={classes.arrowContainer}>
                        <ArrowDown className={classes.arrowIcon}/>
                    </div>
                }
                onClick={(event) => {
                    if(event.value === '/logout/') {
                        history.push('/');
                        if(_.isFunction(props.onLogout))
                            props.onLogout();
                    }
                    else {
                        history.push(event.value)
                    }
                }}>
                
                {
                    props.role === 'COLLECTOR' &&
                    <MenuItem 
                        className={classes.menuItem} 
                        value={`/profiles/${props.username}/`}>
                        Your Profile
                    </MenuItem>
                }
                
                {
                    HeaderProfileMenu.MenuItems
                        .filter(item => item[props.role])
                        .map(item =>
                            <MenuItem 
                                className={classes.menuItem} 
                                key={item.url}
                                value={item.url}>
                                {item.text}
                            </MenuItem>
                        )
                }
                
                <MenuDivider />
                
                <MenuItem 
                    className={classes.menuItem} 
                    value={`/logout/`}>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
}

HeaderProfileMenu.MenuItems = [
    {
        text: 'Your Creations',
        url: '/profile/manage-creations',
        CREATOR: true,
    },
    {
        text: 'Received Offers',
        url: '/profile/received-offers',
        COLLECTOR: true,
        CREATOR: true,
    },
    {
        text: 'Sent Offers',
        url: '/profile/sent-offers',
        COLLECTOR: true,
        CREATOR: true,
    },    
    {
        text: 'Edit about section',
        url: '/profile/about',
        COLLECTOR: true,
        CREATOR: true,
    },    
    {
        text: 'Manage Drops',
        url: '/admin/drops',
        ADMIN: true
    },    
    {
        text: 'Creator requests',
        url: '/admin/creator-requests',
        ADMIN: true
    },    
    {
        text: 'Settings',
        url: '/profile/settings',
        COLLECTOR: true,
        CREATOR: true,
        ADMIN: true
    }
]

export default HeaderProfileMenu;