import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    pageContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '50px 0 0 0',
    },
    artistSectionContainer: {
        flex: 1,
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    artistSection: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '50px 0',
        maxWidth: 850
    },
    artistName: {
        fontFamily: Theme.Font.Secondary,
        fontSize: 25,
        fontWeight: 300,
        textAlign: 'center',
        color: 'white',
        marginTop: 30,
        padding: '0 40px'
    },
    dropName: {
        fontFamily: Theme.Font.Secondary,
        fontSize: 60,
        fontWeight: 400,
        textAlign: 'center',
        color: 'white',
        padding: '0 40px'
    },
    artistItemsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '50px 0'
    },
    artistDescription: {
        fontFamily: Theme.Font.Primary,
        fontSize: 15,
        fontWeight: 300,
        lineHeight: 2,
        textAlign: 'justify',
        color: '#eee',
        marginTop: 20,
        padding: '0 40px'
    },
    dropSectionContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    dropSection: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 800
    },
    dropTitle: {
        fontFamily: Theme.Font.Secondary,
        fontSize: 60,
        fontWeight: 300,
        textAlign: 'center',
        color: Theme.TextColor.Dark,
        margin: '50px 0 30px 0',
        padding: '0 40px'
    },
    dropDescription: {
        fontFamily: Theme.Font.Primary,
        fontSize: 15,
        fontWeight: 300,
        lineHeight: 2,
        textAlign: 'center',
        color: Theme.TextColor.Dark,
        marginTop: 20,
        padding: '0 40px'
    },
    dropItemsTitle: {
        fontFamily: Theme.Font.Primary,
        fontSize: 30,
        fontWeight: 400,
        textAlign: 'center',
        color: Theme.TextColor.Dark,
        margin: '50px 0 30px 0'
    },
    dropItemsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        margin: '40px 0'
    },
    dropItem: {
        marginTop: 20
    },
    dropsInLabelText: {
        fontFamily: Theme.Font.Secondary,
        fontSize: 23,
        fontWeight: 400,
        textAlign: 'center',
        color: Theme.TextColor.Light,
        margin: 0
    },
    dropsInText: {
        fontFamily: Theme.Font.Secondary,
        fontSize: 40,
        fontWeight: 400,
        textAlign: 'center',
        color: Theme.TextColor.Lighter,
        margin: '10px 0 30px 0'
    },
    '@media only screen and (max-width: 800px)': {
        artistSectionContainer: {
            width: '100%',
        },
        artistSection: {
            width: '100%',
        },
        artistName: {
            padding: 0,
            marginBottom: 10
        },
        dropName: {
            fontSize: 50,
            padding: 0
        },
        artistItemsContainer: {
            margin: '50px 10px'
        },
        artistDescription: {
            padding: '0 25px'
        },
        dropTitle: {
            padding: 0
        },
        dropDescription: {
            padding: '0 25px'
        },
        dropItemsContainer: {
            padding: 0,
            margin: 20
        }
    },
});