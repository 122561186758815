import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    arrowContainer: {
        padding: '10px 7px 7px 7px',
        cursor: 'pointer',
        stroke: Theme.TextColor.Lighter,
        '&:hover': {
            stroke: Theme.TextColor.Lightest
        }
    },
    arrowIcon: {
        width: 12,
        height: 12,
        fill: 'transparent',
        strokeWidth: 3,
        stroke: 'inherit'
    },
    menuItem: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        transition: 'none',
        '&:hover': {
            color: 'white',
            backgroundColor: Theme.Palette.Primary.Foreground
        }
    }
});