import * as API from '~/API';

import {
    PROFILE_FETCH_START,
    PROFILE_FETCH_FINISHED,
    PROFILE_FETCH_FAILED,
    PROFILE_CREATIONS_FETCH_START,
    PROFILE_CREATIONS_FETCH_FINISHED,
    PROFILE_CREATIONS_FETCH_FAILED,
    PROFILE_COLLECTION_FETCH_START,
    PROFILE_COLLECTION_FETCH_FINISHED,
    PROFILE_COLLECTION_FETCH_FAILED,
    PROFILE_FOLLOWER_FETCH_START,
    PROFILE_FOLLOWER_FETCH_FINISHED,
    PROFILE_FOLLOWER_FETCH_FAILED,
    PROFILE_FOLLOWING_FETCH_START,
    PROFILE_FOLLOWING_FETCH_FINISHED,
    PROFILE_FOLLOWING_FETCH_FAILED
} from './types';

export const getProfileData = (username) => {
    return (dispatch) => {
        
        dispatch({ type: PROFILE_FETCH_START });

        API.Profiles.get(username)
        .then(data => {
            dispatch({ type: PROFILE_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: PROFILE_FETCH_FAILED, payload: error });
        })
    };  
};

export const getCreations = (username, offset, limit) => {
    return (dispatch) => {
        
        dispatch({ type: PROFILE_CREATIONS_FETCH_START });

        API.Profiles.getCreations(username, offset, limit)
        .then(data => {
            dispatch({ type: PROFILE_CREATIONS_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: PROFILE_CREATIONS_FETCH_FAILED, payload: error });
        })
    };  
};

export const getCollection = (username, offset, limit) => {
    return (dispatch) => {
        
        dispatch({ type: PROFILE_COLLECTION_FETCH_START });

        API.Profiles.getCollection(username, offset, limit)
        .then(data => {
            dispatch({ type: PROFILE_COLLECTION_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: PROFILE_COLLECTION_FETCH_FAILED, payload: error });
        })
    };  
};


export const getFollowers = (username) => {
    return (dispatch, getState) => {
        
        let offset = getState().Profile.followers.length;
        dispatch({ type: PROFILE_FOLLOWER_FETCH_START });

        API.Profiles.getFollowers(username, offset)
        .then(data => {
            dispatch({ type: PROFILE_FOLLOWER_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: PROFILE_FOLLOWER_FETCH_FAILED, payload: error });
        })
    };  
};

export const getFollowing = (username) => {
    return (dispatch, getState) => {
        
        let offset = getState().Profile.following.length;
        dispatch({ type: PROFILE_FOLLOWING_FETCH_START });

        API.Profiles.getFollowing(username, offset)
        .then(data => {
            dispatch({ type: PROFILE_FOLLOWING_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: PROFILE_FOLLOWING_FETCH_FAILED, payload: error });
        })
    };  
};