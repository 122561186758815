import React from 'react';
import { useStyles } from './styles';
import Button from '../Button';

const NoContentInfoBox = (props) => {
    const classes = useStyles();

    return (
        <div 
            className={ 
                props.clickable ? 
                classes.container + ' ' + classes.clickable :
                classes.container
            }
            style={props.style}>
            
            <img
                className={classes.icon}
                src={props.icon}
                alt=""/>

            <p className={classes.text}>
                {props.text}
            </p>

            {
                props.buttonText &&
                <Button
                    className={classes.button}
                    secondary
                    text={props.buttonText}
                    onClick={props.onButtonClick}/>
            }
        </div>
    );
}

export default NoContentInfoBox;