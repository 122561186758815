import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '20px 5px 20px 5px',
        paddingBottom: 20,
        borderBottom: '1px solid #eee',
        maxWidth: 1000
    },
    instructionText: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        textAlign: 'justify',
        margin: '5px 0'
    },
    checkboxLabel: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        margin: '5px 0'
    },
    invalidateControls: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 15
    },
    checkbox: {
        marginRight: 7
    },
    checkboxContainer: {
        flex: 1
    },
    invalidateButton: {
        padding: '7px 15px'
    }
});