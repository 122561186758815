import * as API from '~/API';

import {
    HOME_PAGE_FETCH_START,
    HOME_PAGE_FETCH_FINISHED,
    HOME_PAGE_FETCH_FAILED
} from './types';

export const getHomePageContents = () => {
    return (dispatch) => {
        
        dispatch({ type: HOME_PAGE_FETCH_START });

        API.Discover.getHomePageContents()
        .then(data => {
            dispatch({ type: HOME_PAGE_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: HOME_PAGE_FETCH_FAILED, payload: error });
        })
    };  
};
