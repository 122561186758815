import {
    UI_PAGE_SCROLL_POSITION_CHANGED,
    UI_WINDOW_SIZE_CHANGED,
    UI_PAGE_SIZE_CHANGED,
    UI_POPUP_STATE_CHANGED
} from './types';

export const updatePageScrollPosition = (value) => {
    return (dispatch) => {
        dispatch({ type: UI_PAGE_SCROLL_POSITION_CHANGED, payload: value });
    };  
};

export const updateWindowSize = (value) => {
    return (dispatch) => {
        dispatch({ type: UI_WINDOW_SIZE_CHANGED, payload: value });
    };  
};

export const updatePageSize = (value) => {
    return (dispatch) => {
        dispatch({ type: UI_PAGE_SIZE_CHANGED, payload: value });
    };  
};

export const triggerPupupUpdate = () => {
    return (dispatch) => {
        dispatch({ type: UI_POPUP_STATE_CHANGED });
    };  
};