import API from './api';

class Profiles {
    
    static async list(role, order = Profiles.OrderType.NEW, offset, limit) {
        let queryParams = 
            API.buildQueryParamString(
                {
                    role,
                    order,
                    offset,
                    limit
                }
            );

        return API.get(`/profiles${queryParams}`);
    }

    static async get(username) {
        return API.get(`/profiles/${username}`);
    }

    static async getCreations(username, offset, limit) {
        let queryParams = 
            API.buildQueryParamString(
                {
                    offset,
                    limit
                }
            );

        return API.get(`/profiles/${username}/creations${queryParams}`);
    }

    static async getCollection(username) {
        return API.get(`/profiles/${username}/collection`);
    }

    static async getFollowing(username, offset) {
        let queryParams = API.buildQueryParamString({offset});
        return API.get(`/profiles/${username}/following${queryParams}`);
    }

    static async getFollowers(username, offset) {
        let queryParams = API.buildQueryParamString({offset});
        return API.get(`/profiles/${username}/followers${queryParams}`);
    }

    static async setFollowing(username, follow) {
        return (
            API.patch(
                `/profiles/${username}/followers`, 
                {
                    follow
                },
                true
            )
        );
    }
}

Profiles.OrderType = Object.freeze({
    NEW: 'NEW',
    FEATURED: 'FEATURED',
    POPULAR: 'POPULAR'
})

export default Profiles;