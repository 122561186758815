import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as HomePageActions from '~/Redux/HomePage/actions';
import * as UserActions from '~/Redux/User/actions';
import { STATE_DONE, STATE_NOT_PROCESSED } from '~/Redux/states';

import MainHeader from '~/Components/MainHeader';

import { useStyles } from './styles';
import LoadingCover from '~/Popups/LoadingCover';
import Footer from '~/Components/Footer';

const PrivacyPolicy = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const userData = useSelector(state => state.User);
    const contentsState = useSelector(state => state.HomePage.contentsState);
    const pageWidth = useSelector(state => state.UI.pageWidth);

    React.useEffect(
        () => {
            if (!userData.data && userData.token &&
                userData.fetchDataState === STATE_NOT_PROCESSED) {
                dispatch(UserActions.getData());
            }
        },
        [userData, dispatch]
    )

    React.useEffect(
        () => {
            if (contentsState === STATE_NOT_PROCESSED)
                dispatch(HomePageActions.getHomePageContents());
        },
        [contentsState, dispatch]
    );

    return (
        <Fragment>
            <LoadingCover visible={contentsState !== STATE_DONE} />

            <MainHeader
                username={userData.data?.username}
                profilePictureUrl={userData.data?.profilePictureUrl}
                userType={userData.data?.role}
                isLoggedIn={!!userData.data}
                isMobile={pageWidth < 800} />

            <div className={classes.pageContainer}>
                <div className={classes.contentBody}>
                    <p><strong>Privacy Policy</strong></p>
                    <p><span style={{fontWeight: 400}}>GetVASTapp, LLC ("</span><strong>Company</strong><span style={{fontWeight: 400}}>," &ldquo;</span><strong>VAST</strong><span style={{fontWeight: 400}}>,&rdquo; "</span><strong>we</strong><span style={{fontWeight: 400}}>" or "</span><strong>us</strong><span style={{fontWeight: 400}}>") is committed to protecting your privacy. We have prepared this Privacy Policy to describe to you our practices regarding the Personal Data (as defined below) we collect from users of our website, located at</span><a href="https://vast.app/"> <span style={{fontWeight: 400}}>vast.app</span></a><span style={{fontWeight: 400}}> (the "</span><strong>Site</strong><span style={{fontWeight: 400}}>"), and online services ("</span><strong>Services</strong><span style={{fontWeight: 400}}>"). If you see an undefined term in this Privacy Policy, it has the same definition as in our Terms of Service, found at</span><a href="https://vast.app/termsofservice"> <span style={{fontWeight: 400}}>vast.app/termsofservice</span></a><span style={{fontWeight: 400}}>.&nbsp;</span></p>
                    <ol>
                    <li style={{fontWeight: 400}} aria-level="1"><strong>Questions; Contacting Company; Reporting Violations</strong><span style={{fontWeight: 400}}>. If you have any questions, concerns, or complaints about our Privacy Policy, data collection, or processing practices, or if you want to report any security violations to us, please contact us at the following address: GetVASTapp, LLC, 2140 S. Dupont Hwy., Camden, DE USA 19934.</span></li>
                    <li style={{fontWeight: 400}} aria-level="1"><strong>User Consent</strong><span style={{fontWeight: 400}}>. By submitting Personal Data through our Site or Services, you agree to the terms of this Privacy Policy and you expressly consent to the collection, use, and disclosure of your Personal Data in accordance with this Privacy Policy. For conducting cryptocurrency transactions we use third-party electronic wallet extensions such as (but not limited to) MetaMask; your interactions with MetaMask and/or any third-party electronic wallet extensions are governed by the applicable privacy policies. In the case of MetaMask, its privacy policy is available at </span><a href="https://metamask.io/privacy.html"><span style={{fontWeight: 400}}>metamask.io/privacy.html</span></a><span style={{fontWeight: 400}}>.</span></li>
                    <li style={{fontWeight: 400}} aria-level="1"><strong>A Note About Children</strong><span style={{fontWeight: 400}}>. We do not intentionally gather Personal Data from visitors who are under the age of 13. If a child under 13 submits Personal Data to Company and we learn that the Personal Data is the information of a child under 13, we will attempt to delete the information as soon as possible. If you believe that we might have any Personal Data from a child under 13, please contact us at the address indicated above.</span></li>
                    <li style={{fontWeight: 400}} aria-level="1"><strong>A Note to Users Outside of the United States. If you are a non-U.S. user of the Site, by visiting the Site and providing us with data, you acknowledge and agree that your Personal Data may be processed for the purposes identified in the Privacy Policy. In addition, your Personal Data may be processed in the country in which it was collected and in other countries, including the United States, where laws regarding processing of Personal Data may be less stringent than the laws in your country. By providing your data, you consent to such transfer.</strong></li>
                    <li style={{fontWeight: 400}} aria-level="1"><strong>Types of Data We Collect</strong><span style={{fontWeight: 400}}>. "Personal Data" means data that allows someone to identify or contact you, including, for example, your name, address, telephone number, e-mail address, and government issued identification documents (such as a passport or driver&rsquo;s license), as well as any other non-public information about you that is associated with or linked to any of the foregoing data. "</span><strong>Anonymous Data</strong><span style={{fontWeight: 400}}>" means data, including aggregated and de-identified data, that is not associated with or linked to your Personal Data; Anonymous Data does not, by itself, permit the identification of individual persons. We collect Personal Data and Anonymous Data, as described below.</span></li>
                    <ol>
                    <li style={{fontWeight: 400}} aria-level="2"><strong>Information You Provide to Us.</strong></li>
                    <ol>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>We may collect Personal Data from you, such as your first and last name, e-mail and mailing addresses, payment details, and password when you create an account to log in to our network ("</span><strong>Account</strong><span style={{fontWeight: 400}}>").</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>If you use our Services on your mobile device, we may collect your phone number and the unique device ID number.</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>Our Site lets you store preferences like how your content is displayed, your location, safe search settings, and favorite widgets. We may associate these choices with your ID, browser or the mobile device, and you can edit these preferences at any time.</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>When connecting to our Services via a service provider that uniquely identifies your mobile device, we may receive this identification and use it to offer extended services and/or functionality.</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>Certain Services, such as two-factor authentication, may require our collection of your phone number. We may associate that phone number to your mobile device identification information.</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>If you provide us feedback or contact us via e-mail, we will collect your name and e-mail address, as well as any other content included in the e-mail, in order to send you a reply.</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>We also collect other types of Personal Data that you provide to us voluntarily, such as your operating system and version, product registration number, and other requested information if you contact us via e-mail regarding support for the Services.</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>We may also collect Personal Data at other points in our Site that state that Personal Data is being collected.</span></li>
                    </ol>
                    <li style={{fontWeight: 400}} aria-level="2"><strong>Information Collected via Technology</strong><span style={{fontWeight: 400}}>. As you navigate through and interact with our Site, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:</span></li>
                    <ol>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>Information Collected by Our Servers</span><span style={{fontWeight: 400}}>. To make our Site and Services more useful to you, our servers (which may be hosted by a third-party service provider) collect information from you, including your browser type, operating system, Internet Protocol ("</span><strong>IP</strong><span style={{fontWeight: 400}}>") address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), domain name, and/or a date/time stamp for your visit.</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>Log Files</span><span style={{fontWeight: 400}}>. As is true of most websites, we gather certain information automatically and store it in log files. This information includes IP addresses, browser type, Internet service provider ("</span><strong>ISP</strong><span style={{fontWeight: 400}}>"), referring/exit pages, operating system, date/time stamp, and clickstream data. We use this information to analyze trends, administer the Site, track users&rsquo; movements around the Site, gather demographic information about our user base as a whole, and better tailor our Services to our users&rsquo; needs. For example, some of the information may be collected so that when you visit the Site or the Services again, it will recognize you and the information could then be used to serve advertisements and other information appropriate to your interests.</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>Cookies</span><span style={{fontWeight: 400}}>. Like many online services, we may use cookies to collect information. "Cookies" are small pieces of information that a website sends to your computer&rsquo;s hard drive while you are viewing the website. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Site. This type of information is collected to make the Site more useful to you and to tailor the experience with us to meet your special interests and needs.</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>Analytics Services</span><span style={{fontWeight: 400}}>. In addition to the tracking technologies we place, other companies may set their own cookies or similar tools when you visit our Site. This includes third-party analytics services, including but not limited to Google Analytics ("</span><strong>Analytics Services</strong><span style={{fontWeight: 400}}>"), that we engage to help analyze how users use the Site. We may receive reports based on these parties&rsquo; use of these tools on an individual or aggregate basis. We use the information we get from Analytics Services only to improve our Site and Services. The information generated by the Cookies or other technologies about your use of our Site and Services (the "</span><strong>Analytics Information</strong><span style={{fontWeight: 400}}>") is transmitted to the Analytics Services. The Analytics Services use Analytics Information to compile reports on user activity. The Analytics Services may also transfer information to third-parties where required to do so by law, or where such third-parties process Analytics Information on their behalf. Each Analytics Services&rsquo; ability to use and share Analytics Information is restricted by such Analytics Services&rsquo; terms of use and privacy policy. By using our Site and Services, you consent to the processing of data about you by Analytics Services in the manner and for the purposes set out above. For a full list of Analytics Services, please contact us at the address given above.</span></li>
                    </ol>
                    <li style={{fontWeight: 400}} aria-level="2"><strong>Information Collected from You About Others. Information Collected from Third-party Companies.</strong><span style={{fontWeight: 400}}> We may receive Personal and/or Anonymous Data about you from companies that provide our Services by way of a co-branded or private-labeled website or companies that offer their products and/or services on our Site. In particular, MetaMask provides us with your Ethereum address and certain other information you choose to share with MetaMask. These third-party companies may supply us with Personal Data. We may add this to the information we have already collected from you via our Site in order to improve the Services we provide. We do not collect Personal Data automatically, but we may tie the information that we collect automatically to Personal Data about you that we collect from other sources or that you provide to us.</span></li>
                    </ol>
                    <li style={{fontWeight: 400}} aria-level="1"><strong>Use of Your Personal Data</strong></li>
                    <ol>
                    <li style={{fontWeight: 400}} aria-level="2"><strong>General Use</strong><span style={{fontWeight: 400}}>. In general, Personal Data you submit to us is used either to respond to requests that you make, or to aid us in serving you better. We use your Personal Data in the following ways:</span></li>
                    <ol>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>facilitate the creation of and secure your Account on our network;</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>identify you as a user in our system;</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>provide improved administration of our Site and Services;</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>provide the Services you request, including but not limited to facilitating your cryptocurrency transactions through MetaMask (</span><a href="https://metamask.io/"><span style={{fontWeight: 400}}>metamask.io</span></a><span style={{fontWeight: 400}}>);</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>improve the quality of experience when you interact with our Site and Services;</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>send you a welcome e-mail to verify ownership of the e-mail address provided when your Account was created;</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>protect you and other users from any conduct that violates our Terms of Service or to prevent abuse or harassment of any user;</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>display your full name (if available) next to the digital assets you created and own;</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>verify your identity as the creator and owner of your digital assets, and display a secure-hashed format of this information;</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>send you administrative e-mail notifications, such as security or support and maintenance advisories;</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>respond to your inquiries related to employment opportunities or other requests;</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>make telephone calls to you, from time to time, as a part of secondary fraud protection or to solicit your feedback;</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>in any other way we may describe when you provide the Personal Data; and</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>send newsletters, surveys, offers, and other promotional materials related to our Services and for other marketing purposes of Company.</span></li>
                    </ol>
                    <li style={{fontWeight: 400}} aria-level="2"><strong>Promotional Contact. </strong><span style={{fontWeight: 400}}>We may use your Personal Data to contact you about our own and third-parties&rsquo; goods and services that may be of interest to you.</span></li>
                    <li style={{fontWeight: 400}} aria-level="2"><strong>Creation of Anonymous Data</strong><span style={{fontWeight: 400}}>. We may create Anonymous Data records from Personal Data by excluding information (such as your name) that makes the data personally identifiable to you. We use this Anonymous Data to analyze request and usage patterns so that we may enhance the content of our Services and improve Site navigation. We reserve the right to use Anonymous Data for any purpose and to disclose Anonymous Data to third-parties without restriction.</span></li>
                    </ol>
                    <li style={{fontWeight: 400}} aria-level="1"><strong>Disclosure of Your Personal Data</strong><span style={{fontWeight: 400}}>. We disclose your Personal Data as described below and as described elsewhere in this Privacy Policy.</span></li>
                    <ol>
                    <li style={{fontWeight: 400}} aria-level="2"><strong>Third-party Service Providers</strong><span style={{fontWeight: 400}}>. We may share your Personal Data with third-party service providers to provide you with the Services that we offer you through our Site, conduct quality assurance testing, facilitate creation of accounts, provide technical support, and/or provide other services to Company.</span></li>
                    <li style={{fontWeight: 400}} aria-level="2"><strong>Affiliates</strong><span style={{fontWeight: 400}}>. We may share some or all of your Personal Data with our parent company, subsidiaries, joint ventures, or other companies under a common control ("</span><strong>Affiliates</strong><span style={{fontWeight: 400}}>"), in which case we will require our Affiliates to honor this Privacy Policy.</span></li>
                    <li style={{fontWeight: 400}} aria-level="2"><strong>Corporate Restructuring</strong><span style={{fontWeight: 400}}>. We may share some or all of your Personal Data in connection with or during negotiation of any merger, financing, acquisition, or dissolution transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets. In the event of an insolvency, bankruptcy, or receivership, Personal Data may also be transferred as a business asset. If another company acquires our company, business, or assets, that company will possess the Personal Data collected by us and will assume the rights and obligations regarding your Personal Data as described in this Privacy Policy.</span></li>
                    <li style={{fontWeight: 400}} aria-level="2"><strong>As Legally Required</strong><span style={{fontWeight: 400}}>. Regardless of any choices you make regarding your Personal Data (as described below), Company may disclose Personal Data if it believes in good faith that such disclosure is necessary (a) in connection with any legal investigation, (b) to comply with relevant laws or to respond to subpoenas or warrants served on Company, (c) to protect or defend the rights or property of Company or users of the Site or Services, and/or (d) to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or our Terms of Service.</span></li>
                    <li style={{fontWeight: 400}} aria-level="2"><strong>Other Disclosures</strong><span style={{fontWeight: 400}}>. We may also disclose your Personal Data to fulfill the purpose for which you provide it, for any other purpose disclosed by us when you provide it, or with your consent.</span></li>
                    </ol>
                    <li style={{fontWeight: 400}} aria-level="1"><strong>How We Protect Your Information</strong><span style={{fontWeight: 400}}>. We take reasonable technical and organizational measures to guard against unauthorized or unlawful processing of your personal data and against accidental loss or destruction of, or damage to, your personal data. While no system is completely secure, we believe the measures implemented by the Site reduce our vulnerability to security problems to a level appropriate to the type of data involved. We have security measures in place to protect our user database and access to this database is restricted internally. However, it remains your responsibility:</span></li>
                    <ol>
                    <li style={{fontWeight: 400}} aria-level="2"><span style={{fontWeight: 400}}>To protect against unauthorized access to your use of the Site and/or Services;</span></li>
                    <li style={{fontWeight: 400}} aria-level="2"><span style={{fontWeight: 400}}>To ensure no one else uses the Site and/or Services while your machine is logged on to the Site (including by logging on to your machine through a mobile, Wi-Fi, or shared access connection you are using);</span></li>
                    <li style={{fontWeight: 400}} aria-level="2"><span style={{fontWeight: 400}}>To log off or exit from the Site and/or Services when not using it;</span></li>
                    <li style={{fontWeight: 400}} aria-level="2"><span style={{fontWeight: 400}}>Where relevant, to keep your password or other access information secret. Your password and log in details are personal to you and should not be given to anyone else or used to provide shared access for example over a network; and</span></li>
                    <li style={{fontWeight: 400}} aria-level="2"><span style={{fontWeight: 400}}>To maintain good internet security.</span></li>
                    </ol>
                    </ol>
                    <p><span style={{fontWeight: 400}}>You should keep all of your account details secure. If you think that any of your accounts has been compromised you should change your account credentials with us, and in particular make sure any compromised account does not allow access to your account with us. You should also tell us as soon as you can, so that we can try to help you keep your account secure and, if necessary, warn anyone else who could be affected.</span></p>
                    <ol>
                    <li style={{fontWeight: 400}} aria-level="1"><strong>Third-party Websites</strong><span style={{fontWeight: 400}}>. Our Site may contain links to third-party websites. When you click on a link to any other website or location, you will leave our Site and go to another site, and another entity may collect Personal Data or Anonymous Data from you. We have no control over, do not review, and cannot be responsible for these outside websites or their content. Please be aware that the terms of this Privacy Policy do not apply to these outside websites or content, or to any collection of your Personal Data after you click on links to such outside websites. We encourage you to read the privacy policies of every website you visit. The links to third-party websites or locations are for your convenience and do not signify our endorsement of such third-parties or their products, content or websites.</span></li>
                    <li style={{fontWeight: 400}} aria-level="1"><strong>Your Choices Regarding Information</strong><span style={{fontWeight: 400}}>. You have several choices regarding the use of information on our Services:</span></li>
                    <ol>
                    <li style={{fontWeight: 400}} aria-level="2"><strong>Changing or Deleting Your Personal Data</strong><span style={{fontWeight: 400}}>. All users may review, update, correct, or delete the Personal Data furnished by a user in their user account (including any imported contacts) by contacting us, or by editing their profile via the Services. If you completely delete all of your Personal Data, then your user account may become deactivated. We will use commercially reasonable efforts to honor your request. We may retain an archived copy of your records as required by law or for legitimate business purposes, such as information stored in blockchain technology for the purpose of verifying authenticity and value of digital assets.</span></li>
                    <ol>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>You may access, correct, amend, or delete your User Materials by accessing your user account (including any imported contacts). You control all User Materials you upload. User Materials that you delete (including User Materials containing personal information) may be retained in archived or backup copies in order to enable you to use certain features like revision history and base snapshots. For instructions on how to permanently delete User Materials from your account, please contact us at support@vast.app. Please note that permanent deletion of your User Materials through this process will impair or disable those features with respect to those User Materials.</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>You may access, correct, amend, or delete Personal Data we have about you by logging into your account and navigating to your account page. If you wish to cancel your account, you may do so through your account page. If you do, personally identifiable information associated with your account will be deleted as soon as is reasonably practical or as required by applicable law. Please note that we may retain information that is otherwise deleted in anonymized and aggregated form, in archived or backup copies as required pursuant to records retention obligations, or otherwise as required by law.</span></li>
                    <li style={{fontWeight: 400}} aria-level="3"><span style={{fontWeight: 400}}>We may use some of the information we collect for marketing purposes, including to send you promotional communications about new VAST features, products, events, or other opportunities. If you wish to stop receiving these communications or to opt out of use of your information for these purposes, please follow the opt-out instructions by clicking "Unsubscribe" (or similar opt-out language) in those communications. You can also contact us at support@VAST.app to opt out.</span></li>
                    </ol>
                    <li style={{fontWeight: 400}} aria-level="2"><strong>Email Communications</strong><span style={{fontWeight: 400}}>. We will periodically send you free newsletters and e-mails that directly promote the use of our Site or Services. If you wish to stop receiving these communications, please follow the opt-out instructions by clicking "Unsubscribe" (or similar opt-out language) in those communications. You can also contact us at support@vast.app to opt out. Despite your indicated e-mail preferences, we may send you service related communications, including notices of any updates to our Terms of Service or Privacy Policy.</span></li>
                    <li style={{fontWeight: 400}} aria-level="2"><span style={{fontWeight: 400}}>If you decide at any time that you no longer wish to accept Cookies from our Service for any of the purposes described above, then you can instruct your browser, by changing its settings, to stop accepting Cookies or to prompt you before accepting a Cookie from the websites you visit. Consult your browser&rsquo;s technical information. If you do not accept Cookies, however, you may not be able to use all portions of the Service or all functionality of the Service. If you have any questions about how to disable or modify Cookies, please let us know at the contact information provided below.</span></li>
                    </ol>
                    <li style={{fontWeight: 400}} aria-level="1"><strong>Changes to This Privacy Policy</strong><span style={{fontWeight: 400}}>. This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy at</span><a href="https://vast.app/privacy"> <span style={{fontWeight: 400}}>vast.app/privacy</span></a><span style={{fontWeight: 400}}>. The date the Privacy Policy was last revised is identified below. You are responsible for ensuring we have an up-to-date active and deliverable email address for you and for periodically visiting our Site and this Privacy Policy to check for any changes.</span></li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>This Privacy Policy was last updated on October 18, 2021.</strong></p>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default PrivacyPolicy;