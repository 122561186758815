export const PROFILE_FETCH_START = 'PROFILE_FETCH_START';
export const PROFILE_FETCH_FINISHED = 'PROFILE_FETCH_FINISHED';
export const PROFILE_FETCH_FAILED = 'PROFILE_FETCH_FAILED';

export const PROFILE_CREATIONS_FETCH_START = 'PROFILE_CREATIONS_FETCH_START';
export const PROFILE_CREATIONS_FETCH_FINISHED = 'PROFILE_CREATIONS_FETCH_FINISHED';
export const PROFILE_CREATIONS_FETCH_FAILED = 'PROFILE_CREATIONS_FETCH_FAILED';

export const PROFILE_COLLECTION_FETCH_START = 'PROFILE_COLLECTION_FETCH_START';
export const PROFILE_COLLECTION_FETCH_FINISHED = 'PROFILE_COLLECTION_FETCH_FINISHED';
export const PROFILE_COLLECTION_FETCH_FAILED = 'PROFILE_COLLECTION_FETCH_FAILED';

export const PROFILE_FOLLOWING_STATE_CHANGED = 'PROFILE_FOLLOWING_STATE_CHANGED';

export const PROFILE_FOLLOWER_FETCH_START = 'PROFILE_FOLLOWER_FETCH_START';
export const PROFILE_FOLLOWER_FETCH_FINISHED = 'PROFILE_FOLLOWER_FETCH_FINISHED';
export const PROFILE_FOLLOWER_FETCH_FAILED = 'PROFILE_FOLLOWER_FETCH_FAILED';

export const PROFILE_FOLLOWING_FETCH_START = 'PROFILE_FOLLOWING_FETCH_START';
export const PROFILE_FOLLOWING_FETCH_FINISHED = 'PROFILE_FOLLOWING_FETCH_FINISHED';
export const PROFILE_FOLLOWING_FETCH_FAILED = 'PROFILE_FOLLOWING_FETCH_FAILED';