import React, { Fragment } from 'react';
import Images from '~/Assets/images';
import Helpers from '~/helpers';
import Config from '~/config';

import { useDispatch, useSelector } from 'react-redux';
import * as UserDataActions from '~/Redux/UserData/actions';
import { STATE_DONE, STATE_NOT_PROCESSED } from '~/Redux/states';

import CreationTableHeader from '~/Components/CreationTableHeader';
import NoContentInfoBox from '~/Components/NoContentInfoBox';
import SaleItem from '~/Components/SaleItem';

import { useStyles } from './styles';

const SaleHistory = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const userData = useSelector(state => state.UserData);

    const [dataReloadRequired, setDataReloadRequired] = React.useState(true);
    const [totalSaleValueEth, setTotalSaleValueEth] = React.useState(0);
    const [totalSaleValueUsd, setTotalSaleValueUsd] = React.useState(0);
    const [totalSaleCommissionEth, setTotalSaleCommissionEth] = React.useState(0);
    const [totalSaleCommissionUsd, setTotalSaleCommissionUsd] = React.useState(0);

    React.useEffect(
        () => {
            if (userData.salesState === STATE_NOT_PROCESSED || 
                dataReloadRequired ||
                userData.showPurchaseSales !== false) {
                setDataReloadRequired(false);
                dispatch(UserDataActions.getSales(false));
            }

            if (userData.salesState === STATE_DONE) {

                let totalSalesEth = 0;
                let totalSalesUsd = 0;

                let totalSaleCommissionEth = 0;
                let totalSaleCommissionUsd = 0;

                for(let sale of userData.sales) {
                    totalSalesEth += Helpers.weiToEth(sale.value.amount);
                    totalSalesUsd += Helpers.weiToEth(sale.value.amount) * sale.value.coinPrice;
                    totalSaleCommissionEth += Helpers.weiToEth(sale.value.commission);
                    totalSaleCommissionUsd += Helpers.weiToEth(sale.value.commission) * sale.value.coinPrice;
                }

                setTotalSaleValueEth(totalSalesEth);
                setTotalSaleValueUsd(totalSalesUsd);
                setTotalSaleCommissionEth(totalSaleCommissionEth)
                setTotalSaleCommissionUsd(totalSaleCommissionUsd)
            }
        }, 
        [ userData, dataReloadRequired, dispatch ]
    );

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Sale History
            </p>

            <p className={classes.totalValue}>
                Total sale value: <strong>{Helpers.formatEthPrice(totalSaleValueEth)} {Config.Blockchain.COIN} (${Helpers.formatUsdPrice(totalSaleValueUsd)})</strong>{totalSaleCommissionEth > 0 ? '*' : ''}
            </p>

            {
                totalSaleCommissionEth > 0 &&
                <p className={classes.commissionDisclaimer}>
                    * {Helpers.formatEthPrice(totalSaleCommissionEth)} {Config.Blockchain.COIN} (${Helpers.formatUsdPrice(totalSaleCommissionUsd)}) was paid in sales and creator commission fees
                </p>
            }

            <div style={{height: 30}}/>

            {
                userData.sales &&
                userData.sales.length > 0 ?
                <Fragment>
                    <CreationTableHeader
                        itemStyle={classes.headerItem}
                        headerItems={[
                            { text: 'Creation', flex: 5},
                            { text: 'Buyer', flex: 3},
                            { text: 'Date', flex: 2},
                            { text: 'Value', flex: 3}
                        ]}/>
                    
                    <div className={classes.itemsContainer}>
                        {
                            userData.sales.map(saleData => 
                                <SaleItem
                                    creationId={saleData.creation?.creationId}
                                    name={saleData.creation?.name}
                                    previewUrl={saleData.creation?.media?.image?.url}
                                    edition={saleData.creation?.release?.count}
                                    username={saleData.buyer?.username}
                                    usersName={saleData.buyer?.name}
                                    profilePictureUrl={saleData.buyer?.profilePictureUrl}
                                    amount={saleData.value.amount}
                                    date={(new Date(saleData.createdAt)).toDateString()}
                                    coinPrice={saleData.value.coinPrice}
                                    tokenContract={'0xea88ca175c38ab5c5a18c9cd28d08283b72b4745'}
                                    txid={saleData.txid}
                                    descriptionFlex={5}
                                    dateFlex={2}
                                    userColumnFlex={3}
                                    priceColumnFlex={3}/>
                            )
                        }
                    </div>
                </Fragment> :
                <NoContentInfoBox
                    icon={Images.EmptyGallery}
                    text={'You have not made any sales yet'}/>
            }

            <div style={{height: 50}}/>

        </div>
    );
}

export default SaleHistory;