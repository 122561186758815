import {
    MINT_REQUEST_FETCH_START,
    MINT_REQUEST_FETCH_FINISHED,
    MINT_REQUEST_FETCH_FAILED,
    MINT_REQUEST_LIST_FETCH_START,
    MINT_REQUEST_LIST_FETCH_FINISHED,
    MINT_REQUEST_LIST_FETCH_FAILED
} from './types';

import {
    STATE_NOT_PROCESSED,
    STATE_PROCESSING,
    STATE_DONE,
    STATE_FAILED
} from '../states'

const INITIAL_STATE = {
    currentMintRequestId: null,

    mintRequestData: null,
    mintRequestDataError: null,
    mintRequestDataState: STATE_NOT_PROCESSED,

    mintRequestList: null,
    mintRequestListError: null,
    mintRequestListState: STATE_NOT_PROCESSED,
}

const MintRequestReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case MINT_REQUEST_FETCH_START:
            return MintRequestReducers.fetchStart(state);
            
        case MINT_REQUEST_FETCH_FINISHED:
            return MintRequestReducers.fetchFinished(state, action.payload);

        case MINT_REQUEST_FETCH_FAILED:
            return MintRequestReducers.fetchFailed(state, action.payload);


        case MINT_REQUEST_LIST_FETCH_START:
            return MintRequestListReducers.fetchStart(state);
            
        case MINT_REQUEST_LIST_FETCH_FINISHED:
            return MintRequestListReducers.fetchFinished(state, action.payload);

        case MINT_REQUEST_LIST_FETCH_FAILED:
            return MintRequestListReducers.fetchFailed(state, action.payload);

        default:
            return state;
    }
}

class MintRequestReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            mintRequestDataError: null,
            mintRequestDataState: STATE_PROCESSING
        }
    }

    static fetchFinished(state, data) {

        return { 
            ...state, 
            currentMintRequestId: data.mintRequestId,
            mintRequestData: data,
            mintRequestDataError: null,
            mintRequestDataState: STATE_DONE
        }
    }

    static fetchFailed(state, error) {
        return { 
            ...state, 
            currentMintRequestId: null,
            mintRequestData: null,
            mintRequestDataError: error,
            mintRequestDataState: STATE_FAILED
        }
    }
}

class MintRequestListReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            mintRequestListError: null,
            mintRequestListState: STATE_PROCESSING
        }
    }

    static fetchFinished(state, data) {

        return { 
            ...state, 
            mintRequestList: data,
            mintRequestListError: null,
            mintRequestListState: STATE_DONE
        }
    }

    static fetchFailed(state, error) {
        return { 
            ...state, 
            mintRequestList: null,
            mintRequestListError: error,
            mintRequestListState: STATE_FAILED
        }
    }
}

export default MintRequestReducer;