import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px'
    },
    profileDetailsContainer: {
        flex: 1,
        minWidth: 300,
        backgroundColor: 'yellow'
    },
    linksContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    link: {
        fontSize: 18,
        fontWeight: 300,
        fontFamily: Theme.Font.Secondary,
        textTransform: 'uppercase',
        color: Theme.TextColor.Light,
        textDecoration: 'none',
        margin: 20,
        transition: '0.2s ease',
        '&:hover': {
            color: Theme.TextColor.Dark,
        }
    },
    linkSelected: {
        color: Theme.TextColor.Dark,
        fontWeight: 400,
    },
    '@media only screen and (max-width: 1024px)': {
        container: {
            flexDirection: 'column'
        }
    }
});