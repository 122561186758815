import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: ({secondary, inverted}) => {
        const selectedPalette = secondary ? Theme.Palette.Secondary : Theme.Palette.Secondary;

        return {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 
                inverted ? 
                selectedPalette.Foreground : 
                selectedPalette.Background,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: selectedPalette.Foreground,
            color: 
                inverted ? 
                selectedPalette.Background : 
                selectedPalette.Foreground,
            fontFamily: Theme.Font.Primary,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: selectedPalette.Foreground,
                borderColor: selectedPalette.Background,
                color: selectedPalette.Background,
                opacity: inverted ? 0.9 : 1
            },
            transition: '0.2s ease'
        }
    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Theme.Palette.Secondary.Background,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: Theme.Palette.Secondary.Foreground,
        color: Theme.Palette.Secondary.Foreground,
        fontFamily: Theme.Font.Primary,
        cursor: 'default',
    },
    loader: {
        width: 20,
        height: 20,
        marginRight: 10,
        opacity: 0.8,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    }
});