import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    container: {
        marginBottom: 5
    },
    iconImage: {
        width: 35,
        heigh: 35
    }
});