import React from 'react';
import Helpers from '~/helpers';
import Config from '~/config';

import PixelatedButton from '../../PixelatedButton';

import { useStyles } from './styles';
import AuctionActions from '../AuctionActions';
import DirectPurchaseActions from '../DirectPurchaseActions';

const CreationActions = (props) => {
    const classes = useStyles();

    return (
        <div className={props.className || classes.container}>

            {
                !props.isOwner && props.owner.username !== null &&
                (props.status === 'FIXED_PRICE' ||  
                (props.status === 'ACCEPTING_OFFERS' && Helpers.weiToEth(props.price) > 0)) ?
                <p className={classes.price}>
                    {Helpers.formatEthPrice(Helpers.weiToEth(props.price))} {Config.Blockchain.COIN} (
                    <strong>${Helpers.formatUsdPrice(Helpers.getEthPrice() * Helpers.weiToEth(props.price))}</strong>
                    )
                </p>
                : 
                !props.isOwner && !Helpers.weiToEth(props.directPurchasePrice) ?
                <div style={{height: 20}}/>
                :null
            }
    
            <div className={classes.buttonContainer}>
            {
                !props.isOwner && 
                props.owner.username !== null && 
                props.status === 'ACCEPTING_OFFERS' &&
                (!Helpers.weiToEth(props.directPurchasePrice) || Helpers.weiToEth(props.price)) ?
                <PixelatedButton
                    text="MAKE OFFER"
                    style={{
                        width: 200,
                        height: 45,
                        marginRight: 15
                    }}
                    foregroundColor='#EE5326'
                    onClick={props.onMakeOfferClick}
                    secondary/>
                : null
            }
                        
            {
                !props.isOwner && props.owner.username !== null &&
                (props.status === 'FIXED_PRICE' ||  
                (props.status === 'ACCEPTING_OFFERS' && Helpers.weiToEth(props.price) > 0)) ?
                <PixelatedButton
                    text="PURCHASE"
                    style={{
                        width: 200,
                        height: 45,
                        marginRight: 15
                    }}
                    foregroundColor='#69BD45'
                    onClick={props.onPurchaseClick}
                    inverted/>
                : null
            }

            <DirectPurchaseActions
                status={props.status}
                isOwner={props.isOwner}
                owner={props.owner}
                price={props.price}
                directPurchasePrice={props.directPurchasePrice}
                onDirectPurchase={props.onPurchaseClick}/>

            <AuctionActions
                status={props.status}
                auction={props.auction}
                isOwner={props.isOwner}
                owner={props.owner}
                onBidClick={props.onBidClick}
                onFinishAuctionClick={props.onFinishAuctionClick}/>

            </div>
        </div>
    );
}

export default CreationActions;