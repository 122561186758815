import React from 'react';

import { useDispatch } from 'react-redux';
import { 
    updatePageScrollPosition, 
    updateWindowSize, 
    updatePageSize 
} from '~/Redux/UI/actions';

const UIEventMonitor = (props) => {

    const dispatch = useDispatch();
    const bodyResizeObserverRef = React.useRef(null);

    const onWindowResizeHandler = () => {
        dispatch(
            updateWindowSize({
                width: window.innerWidth, 
                height: window.innerHeight
            })
        );
    }

    const onWindowScrollPositionChangedHandler = () => {
        dispatch(updatePageScrollPosition(window.scrollY))
    }

	React.useEffect(() => {
        
        bodyResizeObserverRef.current = new ResizeObserver(entries => {
            dispatch(
                updatePageSize({
                    width: entries[0].target.clientWidth, 
                    height: entries[0].target.clientHeight
                })
            )
        });
        
        bodyResizeObserverRef.current.observe(window.document.body)

        onWindowResizeHandler();
        onWindowScrollPositionChangedHandler();

		window.addEventListener('resize', onWindowResizeHandler);
        window.document.addEventListener('scroll', onWindowScrollPositionChangedHandler);

        return () => {

            if(bodyResizeObserverRef.current)
                bodyResizeObserverRef.current.disconnect();

            window.removeEventListener('resize', onWindowResizeHandler);
            window.document.removeEventListener('scroll', onWindowScrollPositionChangedHandler);
        }
	})

    return props.children;
}

export default UIEventMonitor;