import React from 'react';
import { useStyles } from './styles';
import OrderedItemListItem from './OrderedItemListItem';
import _ from 'lodash';

const OrderedItemList = ({itemList, setItemList, ...props}) => {
    const classes = useStyles();

    const handleShiftLeft = (itemId) => {
        let indexOfItem = itemList.indexOf(itemId);
        if(indexOfItem <= 0)
            return;

        setItemList([
            ...itemList.slice(0, indexOfItem - 1),
            itemList[indexOfItem],
            itemList[indexOfItem - 1],
            ...itemList.slice(indexOfItem + 1)
        ])
    }

    const handleShiftRight = (itemId) => {
        let indexOfItem = itemList.indexOf(itemId);
        if(indexOfItem === itemList.length - 1)
            return;

        setItemList([
            ...itemList.slice(0, indexOfItem),
            itemList[indexOfItem + 1],
            itemList[indexOfItem],
            ...itemList.slice(indexOfItem + 2)
        ])
    }

    const handleDelete = (itemId) => {
        setItemList(itemList.filter(item => item !== itemId))
    }

    const handleAddClicked = () => {
        if(_.isFunction(props.onAddClicked))
            props.onAddClicked()
    }

    return (
        <div className={classes.container}>
            
            <div className={classes.itemsList}>

                {
                    itemList.map(itemId => 
                        <OrderedItemListItem 
                            key={itemId}
                            itemId={itemId}
                            onShiftLeft={handleShiftLeft}
                            onShiftRight={handleShiftRight}
                            onDelete={handleDelete}/>
                    )
                }

            </div>

            <div 
                className={classes.addItemButtonContainer} 
                onClick={handleAddClicked}>
                <p className={classes.addItemButton} >
                    { props.addText || 'Add' }
                </p>
            </div>
        </div>
    );
}

export default OrderedItemList;