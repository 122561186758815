import React from 'react';
import _ from 'lodash';

import { useStyles } from './styles';

const LabeledInputField = (props) => {

    const classes = useStyles();

    const handleValueChanged = (event) => {
        if (_.isFunction(props.onChange))
            props.onChange(event.target.value);
    }

    return (
        <div
            className={
                props.className ?
                    classes.container + ' ' + props.className :
                    classes.container
            }>

            {
                props.labelFirst &&
                <p className={classes.label}>
                    { props.labelText }
                </p>
            }

            <input 
                className={classes.input}
                style={{
                    borderLeft: props.labelFirst ? '1px solid #ccc' : 'none',
                    borderRight: !props.labelFirst ? '1px solid #ccc' : 'none',
                }}
                type={props.type || 'text'}
                min={props.min}
                max={props.max}
                value={props.value}
                disabled={props.disabled}
                placeholder={props.placeholder}
                onChange={handleValueChanged} />

            {
                !props.labelFirst &&
                <p className={classes.label}>
                    { props.labelText }
                </p>
            }

        </div>
    );
}

export default LabeledInputField;