import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    activityContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '0 5px 15px 5px'
    },
    activityDetails: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 5
    },
    activityTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    activityText: {
        fontFamily: Theme.Font.Secondary,
        fontSize: 15,
        fontWeight: 300,
        margin: '0 5px 0 0',
    },
    activityLink: {
        fontFamily: Theme.Font.Secondary,
        fontSize: 15,
        fontWeight: 400,
        textDecoration: 'none',
        color: Theme.TextColor.Dark,
        cursor: 'pointer',
        margin: '0 5px 0 0',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    timeText: {
        fontFamily: Theme.Font.Secondary,
        color: Theme.TextColor.Light,
        fontSize: 12,
        fontWeight: 300,
        margin: '3px 0 0 0'
    }
});