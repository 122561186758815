import Config from '~/config';
import API from './api';

class Creations {
    
    static async list(order = Config.OrderType.NEW, offset, limit) {
        let queryParams = 
            API.buildQueryParamString(
                {
                    order,
                    offset,
                    limit
                }
            );

        return API.get(`/creations${queryParams}`);
    }

    static async get(creationId) {
        return API.get(`/creations/${creationId}`, true);
    }

    static async getActivity(creationId) {
        return API.get(`/creations/${creationId}/activity`);
    }

    static async getEditions(creationId) {
        return API.get(`/creations/${creationId}/editions`);
    }

    static async getComments(creationId) {
        return API.get(`/creations/${creationId}/comments`);
    }

    static async postComment(creationId, body, replyTo = undefined) {
        return (
            API.post(
                `/creations/${creationId}/comments`, 
                {
                    body,
                    replyTo
                },
                true
            )
        );
    }

    static async deleteComment(creationId, commentId) {
        return API.delete(`/creations/${creationId}/comments/${commentId}`, true);
    }

    static async setLike(creationId, like) {
        return (
            API.patch(
                `/creations/${creationId}/likes`, 
                { like },
                true
            )
        );
    }

    static async makeOffer(creationId, signatureData) {
        return (
            API.post(
                `/creations/${creationId}/offer`, 
                {
                    signatureData
                },
                true
            )
        );
    }
}

export default Creations;