import React from 'react';
import _ from 'lodash';

import { useStyles } from './styles';

const CreationDetailTabs = (props) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = React.useState(props.defaultTab || 0);

    const handleTabChanged = (tabIndex) => {
        setSelectedTab(tabIndex);
        if(_.isFunction(props.onTabChanged))
            props.onTabChanged(tabIndex);
    }

    return (
        <div className={classes.container}>
            {
                (props.tabList || [])
                    .map((item, index) => 
                        <div 
                            key={item}
                            className={
                                index === selectedTab ?
                                classes.tabItem + ' ' + classes.tabItemSelected :
                                classes.tabItem
                            }
                            onClick={handleTabChanged.bind(null, index)}>
                            { item }
                        </div>  
                    )
            }
        </div>
    );
}

export default CreationDetailTabs;