import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as HomePageActions from '~/Redux/HomePage/actions';
import * as UserActions from '~/Redux/User/actions';
import { STATE_DONE, STATE_NOT_PROCESSED } from '~/Redux/states';

import MainHeader from '~/Components/MainHeader';

import { useStyles } from './styles';
import LoadingCover from '~/Popups/LoadingCover';
import Footer from '~/Components/Footer';

const FAQ = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const userData = useSelector(state => state.User);
    const contentsState = useSelector(state => state.HomePage.contentsState);
    const pageWidth = useSelector(state => state.UI.pageWidth);

    React.useEffect(
        () => {
            if (!userData.data && userData.token &&
                userData.fetchDataState === STATE_NOT_PROCESSED) {
                dispatch(UserActions.getData());
            }
        },
        [userData, dispatch]
    )

    React.useEffect(
        () => {
            if (contentsState === STATE_NOT_PROCESSED)
                dispatch(HomePageActions.getHomePageContents());
        },
        [contentsState, dispatch]
    );

    return (
        <Fragment>
            <LoadingCover visible={contentsState !== STATE_DONE} />

            <MainHeader
                username={userData.data?.username}
                profilePictureUrl={userData.data?.profilePictureUrl}
                userType={userData.data?.role}
                isLoggedIn={!!userData.data}
                isMobile={pageWidth < 800} />

            <div className={classes.pageContainer}>
                <div className={classes.contentBody}>
                    <p><strong>General Information</strong></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What&rsquo;s VAST?</span></p>
                    <p><span style={{fontWeight: 400}}>VAST is a marketplace to discover and collect truly unique digital creations by the world's most creative minds. We empower digital creators with the tools to protect and sell their digital creations to their fans and collectors. Artists, photographers, writers, and more use VAST to create and sell their work online through the use of blockchain technology.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What&rsquo;s a blockchain and how can it help creators?</span></p>
                    <p><span style={{fontWeight: 400}}>A blockchain is a publicly accessible online ledger (database), that&rsquo;s not owned by any central authority. Once anything is set in this ledger, it cannot be modified or censored by any single authority. Blockchain technology provides the following benefits to creators:</span></p>
                    <ul>
                        <li style={{fontWeight: 400}} aria-level="1"><span style={{fontWeight: 400}}>Scarcity: When you upload your creation to the blockchain, you can define the number of editions you want to release. The control and distribution of the editions is automated on the blockchain, so you can tightly manage the scarcity of your creations. No more than the defined number of editions, will ever be released.</span></li>
                        <li style={{fontWeight: 400}} aria-level="1"><span style={{fontWeight: 400}}>Authenticity: When you upload your creation to the blockchain, it cannot be changed thereafter. Buyers can trust that the artwork they&rsquo;re purchasing is the original and has not been tampered with, because the publicly accessible data on the blockchain is always available to verify it. Even if other people copy it (as many tend to do online with digital content), the value of your artwork will not degrade, as long as the specific editions can be verified on the blockchain.</span></li>
                        <li style={{fontWeight: 400}} aria-level="1"><span style={{fontWeight: 400}}>Ownership: Every transfer/purchase of your creations, is recorded on the blockchain. This means that there&rsquo;s a publicly accessible ownership history for your creations. Provenance is automated and accurate. Having a publicly accessible way to verify ownership will create more value for your creations since it'll be easier to identify infringing use of it. It'll also make it easier for you to support any DMCA takedown requests.</span></li>
                    </ul>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Each of these benefits is inherently available to you, just by using the blockchain. Even if VAST ceases to exist, your digital creations will forever remain on the blockchain, and forever be transferable/purchasable. Having said that, interacting with the blockchain is still quite complex. What we&rsquo;re offering is a service that makes blockchain technology simple, and provide its benefits to all creators.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Like any technology however, blockchain isn't immune to risks. Please reference our Terms of Service, found at</span><a href="https://vast.app/termsofservice"> <span style={{fontWeight: 400}}>vast.app/termsofservice</span></a><span style={{fontWeight: 400}}>, if you'd like to learn more.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What does VAST offer?</span></p>
                    <ul>
                        <li style={{fontWeight: 400}} aria-level="1"><span style={{fontWeight: 400}}>Proof of ownership and authenticity on the blockchain. We offer simple-to-use tools to help you digitally sign and upload your work onto the blockchain, creating verifiable proof that you&rsquo;re the creator and owner of your work.</span></li>
                        <li style={{fontWeight: 400}} aria-level="1"><span style={{fontWeight: 400}}>Custom storefront. We provide tools to help you easily create a customized storefront that you can then share with your audience to sell your work.</span></li>
                        <li style={{fontWeight: 400}} aria-level="1"><span style={{fontWeight: 400}}>Distribution. We've partnered with the largest online marketplaces to help you syndicate your work and reach a broader audience to sell your work.</span></li>
                    </ul>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>How much does it cost?</span></p>
                    <p><span style={{fontWeight: 400}}>VAST is currently free to use for all members. However, all transaction fees associated with blockchain interaction are paid by the end user.&nbsp;</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>On successful sales, VAST takes a 2.5% commission on all original purchases and 7.5% on secondary market purchases (2.5% paid to VAST and 5% paid to the original creator).</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>How do I get started?</span></p>
                    <p><span style={{fontWeight: 400}}>VAST has a vetting process for artists, however we'd love for you to join us. Please visit </span><a href="/profile/become-creator"><span style={{fontWeight: 400}}>Become a Creator</span></a><span style={{fontWeight: 400}}> for more details.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What do I need to use VAST?</span></p>
                    <p><span style={{fontWeight: 400}}>VAST is a web-based service that you can use on both desktop and mobile. Customers must use third-party wallets (MetaMask) to purchase digital creations, but otherwise no additional software is needed to use VAST.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Contact and support</span></p>
                    <p><span style={{fontWeight: 400}}>You can reach us at support@vast.app with any questions or issues.</span></p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>Blockchain and Digital Wallets</strong></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What&rsquo;s a digital wallet?</span></p>
                    <p><span style={{fontWeight: 400}}>A digital wallet is software that manages your cryptocurrencies and also allows you to interact with blockchain technologies like Polygon. VAST uses your digital wallet to publish and sell your digital creations using the Polygon blockchain.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What&rsquo;s a blockchain wallet?</span></p>
                    <p><span style={{fontWeight: 400}}>Your blockchain wallet is your ID on the Polygon blockchain and is used to digitally sign all of your creations on VAST.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>If you need to create a personal wallet, we recommend using MetaMask (</span><a href="https://metamask.io/"><span style={{fontWeight: 400}}>metamask.io</span></a><span style={{fontWeight: 400}}>). See below for more information on MetaMask.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Does VAST support multiple addresses?</span></p>
                    <p><span style={{fontWeight: 400}}>At this time we don't support multiple addresses. Having multiple addresses can lead to confusion because all creations are signed using the address of the creator. Please contact us at support@vast.app if you have any questions and we can assist you.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What&rsquo;s Polygon?</span></p>
                    <p><span style={{fontWeight: 400}}>VAST uses Polygon, a protocol and a framework for building and connecting Ethereum-compatible blockchain networks using smart contracts.&nbsp;</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Polygon combines the best of Ethereum and sovereign blockchains into a full-fledged multi-chain system. Polygon solves pain points associated with Blockchains, like high gas fees and slow speeds, without sacrificing on security.&nbsp;</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Many projects are exploring Ethereum-compatible blockchains as a way to mitigate these limitations while still leveraging Ethereum&rsquo;s thriving ecosystem. However, there's no specialized framework to build such blockchains nor a protocol to connect them. This introduces significant development challenges and causes ecosystem fragmentation.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>The solution is Polygon - a protocol and a framework for building and connecting Ethereum-compatible blockchain networks.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What&rsquo;s MetaMask?</span></p>
                    <p><span style={{fontWeight: 400}}>MetaMask is a browser plug-in that allows users to purchase digital creations.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What&rsquo;s a seedphrase and why should I save it?</span></p>
                    <p><span style={{fontWeight: 400}}>When using a personal wallet for creating your artwork, it's important for you to save the 12-word seed phrase associated with your wallet and store it in a secure place. If you ever need to recover your wallet, this is the only way to do it.</span></p>
                    <p>&nbsp;</p>
                    <p><strong><em>If you lose your 12-word seed phrase associated with your wallet, all of your artworks will be lost forever.</em></strong></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Click</span><a href="https://metamask.zendesk.com/hc/en-us/articles/360015290032-How-to-Reveal-Your-Seed-Phrase#_blank"> <span style={{fontWeight: 400}}>here</span></a><span style={{fontWeight: 400}}> to learn how to reveal your seed phrase using MetaMask.&nbsp;</span></p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>How VAST Works</strong></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What&rsquo;s a unique digital creation?</span></p>
                    <p><span style={{fontWeight: 400}}>A unique digital creation is a digital creation (such as an artwork, photograph, song, etc.) that's been digitally signed by the creator and uniquely identified on the blockchain. In a world where anything digital can be infinitely copied, a unique digital creation can only owned by a single individual.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Non-fungible tokens</span></p>
                    <p><span style={{fontWeight: 400}}>VAST uniquely generates a Non-Fungible Token (NFT) for every creation published. This token is what provides proof of ownership and authenticity of your creation.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>We also use an open standard (ERC-721), which means that any creation is immediately available on third-party digital marketplaces, giving your work additional visibility. This is the same standard that&rsquo;s used by the majority of blockchain-based digital assets like CryptoKitties, CryptoPunks, and others.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Who owns my creations?</span></p>
                    <p><span style={{fontWeight: 400}}>VAST is a platform for creators and has no rights over your creations. We&rsquo;re committed to ensuring that any digital creation published through VAST is owned by the creator. You can find more details in our Terms of Service (vast.app/termsofservice).</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Should I upload my high resolution digital files?</span></p>
                    <p><span style={{fontWeight: 400}}>Yes. We highly recommend uploading only high resolution digital files where possible. Collectors are most interested in purchasing the highest quality version of your work, to display and print after they purchase.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Every digital creation uploaded on VAST is stored in a secure location that's only available to you and collectors, upon purchase.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Where are my creations stored?</span></p>
                    <p><span style={{fontWeight: 400}}>When publishing your creations to the blockchain, VAST stores your files in a secure location only the creator and future owners can access upon purchase. We only display previews of your image and also allow creators to upload watermarked images for the public to view.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Can I gift my creation?</span></p>
                    <p><span style={{fontWeight: 400}}>Yes! You can send any creation that you upload or own to another digital wallet.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Please note. </span><strong><em>Always double check that you're sending your creation to the right address. Once you send a creation through the blockchain, there&rsquo;s no turning back.</em></strong><span style={{fontWeight: 400}}> VAST can't retrieve a creation that was sent to the wrong address.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Some actions takes a while, is that normal?</span></p>
                    <p><span style={{fontWeight: 400}}>Yes, due to way the blockchain works, it may take some time for your creations to publish. Similar to printing a real physical creation, uploading your creations to the blockchain requires work by hundreds of computers worldwide.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>If you have additional questions, you can contact us at support@vast.app.</span></p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>NFTs are listed on VAST in a reserve auction format</strong></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Once the reserve price has been met, a 24-hour countdown for the auction begins.</span></p>
                    <p><span style={{fontWeight: 400}}>If a bid is placed within the last 15 minutes of an auction, the countdown will reset back to 15 minutes. Time extensions can go on indefinitely until no other bids are placed within the last 15 minutes of an auction.</span></p>
                    <p><span style={{fontWeight: 400}}>The addition of 15-minute extensions give each buyer enough time to place a bid, and for the artwork to find its true market value.</span></p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>Selling on VAST</strong></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What am I selling?</span></p>
                    <p><span style={{fontWeight: 400}}>You're selling a signed and limited edition copy of your digital creation. Upon purchase, the buyer will be given the right to use, distribute, and display the creation for </span><a href="https://en.wikipedia.org/wiki/Non-commercial#_blank"><span style={{fontWeight: 400}}>non-commercial</span></a><span style={{fontWeight: 400}}> purposes only. Since the buyer owns this unique copy, they can also re-sell the creation on a secondary market or even directly on VAST.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Similar to a physical creation, the buyer will be able to do what they'd normally be able to do once they purchase a physical piece. This capability is what makes your digital creations more valuable.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>How do I sell my creation?</span></p>
                    <p><span style={{fontWeight: 400}}>VAST helps you sell your creations by allowing you to set a price or accept offers from buyers to purchase directly from your store or through one of our partner marketplaces. Besides needing to meet the minimum sales price (the cost of generating your art on the blockchain), you&rsquo;re free to set the price as you please.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>There are two ways to sell your creations:</span></p>
                    <p>&nbsp;</p>
                    <ol>
                        <li><span style={{fontWeight: 400}}> Setting a fixed price. When you're ready to put your creation up for sale, click on </span><a href="/profile/manage-collection"><span style={{fontWeight: 400}}>Manage Collection</span></a><span style={{fontWeight: 400}}> in the header and click the Set a Price button next to your creation to set a price for your creation.</span></li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol start="2">
                        <li><span style={{fontWeight: 400}}> Accepting an offer. If you're unsure about how to price your creation you can leave the price empty and wait to receive an offer.</span></li>
                    </ol>
                    <p><span style={{fontWeight: 400}}>We always recommend setting a price as well as accepting offers, since it helps the buyer gauge the value of your work.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What&rsquo;s a digital edition?</span></p>
                    <p><span style={{fontWeight: 400}}>A digital edition is synonymous to the popular concept in (edition) printmaking. When you upload your digital creations on VAST, you're uploading it as a limited edition. This ensures that only a limited number of copies of your digital creation can ever be owned by collectors.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>This is strictly enforced via blockchain technology.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Limited editions equate to value</span></p>
                    <p><span style={{fontWeight: 400}}>The number of editions you issue has an important impact on the value of your work. A smaller number of editions will increase the rarity and attractiveness to a collector, since collectors place enormous value owning a unique asset over something that's more commonly attainable. A larger number of editions, however, allows your work to be owned by more collectors at a lower price point, but may not be attractive to collectors who place more value on rarity.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>A guide to setting your editions</span></p>
                    <p><span style={{fontWeight: 400}}>Here&rsquo;s a simple guide that we recommend following:</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>1 - A one-of-a-kind creation. Creations with 1 edition can only be owned by one collector at any given time, making it the most attractive to a collector. These creations should command the highest sale price.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>10 - A rare digital creation. Creations with 10 editions may be sold and owned by up to 10 collectors at any given time. Although still rare, because it can be owned by multiple collectors, each edition should command a slightly lower sale price than an edition of one.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>100+ - A limited digital creation. Creations with 100+ editions may be owned by many collectors at a given time. Although still limited, because it can be owned by many collectors, collectors will expect the price of each edition to be much lower.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>VAST gives you the flexibility to set your own editions, however we highly discourage setting a very large edition count.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Why is the sale price in MATIC?</span></p>
                    <p><span style={{fontWeight: 400}}>VAST requires setting your sale price in MATIC. Setting your price in MATIC allows customers worldwide to be able to purchase your creations using Polygon MATIC, and doesn't incur any transaction fees.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Why is VAST enforcing a minimum sale price?</span></p>
                    <p><span style={{fontWeight: 400}}>There are production costs associated with creating and selling a unique creation, and VAST is currently paying for those costs. Because of this, we may require a minimum price (especially on limited editions) to ensure that your creation sells for enough to cover these costs.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>If I sell using VAST, can I sell the same creations elsewhere?</span></p>
                    <p><span style={{fontWeight: 400}}>You own the rights to your work, so you can sell your creations elsewhere. However, once you've tokenized your creation as a limited edition, it's important that you do not issue more tokens of the same creations which may negatively affect the value of your creation.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>How does escrow work?</span></p>
                    <p><span style={{fontWeight: 400}}>In order to sell your creation, VAST requires the permission to escrow your creation to ensure that when a sale occurs the buyer receives your work.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Every interaction with the blockchain incurs a small transaction fee which you will need to pay in the form of Polygon MATIC.&nbsp;</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>I received an offer for my creation, now what?</span></p>
                    <p><span style={{fontWeight: 400}}>Congratulations! A collector has expressed interest in owning your unique digital creation by submitting an offer. There are three options available to you:</span></p>
                    <p>&nbsp;</p>
                    <ol>
                        <li><span style={{fontWeight: 400}}> Accept the Offer. If you're happy with the offer, go to your </span><a href="/profile/received-offers"><span style={{fontWeight: 400}}>Received Offers</span></a><span style={{fontWeight: 400}}> and click Accept next to the offer. We'll take care of everything else.</span></li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol start="2">
                        <li><span style={{fontWeight: 400}}> Decline the Offer. If the offer is too low, go to your </span><a href="/profile/received-offers"><span style={{fontWeight: 400}}>Received Offers</span></a><span style={{fontWeight: 400}}> and click Reject next to the offer. This will immediately let the collector know that their offer was declined. Sometimes the collector will offer a higher amount, but there are no guarantees.</span></li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol start="3">
                        <li><span style={{fontWeight: 400}}> Wait for a Higher Offer. If you believe you can receive a higher offer, you can try to wait. If the original offer was reasonable, we suggest not waiting more than a few days since the collector may lose interest.</span></li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Beware of accepting offers that are considerably lower than your asking price. It may be tempting to sell your work quickly, but remember that selling your works at a low price could de-value your existing and future works as well. If you already have collectors who paid for your previous works, they also won't be excited to learn that you've sold your new works at a significant discount.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>If you have any questions, reach out to the support team at support@vast.app.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Why can't I perform certain actions as a collaborator of a creation?</span></p>
                    <p><span style={{fontWeight: 400}}>When an artwork is created in collaboration with another creator, the creator who uploaded the artwork is appointed as the primary creator. Only the primary creator is allowed to set prices and accept offers on the creation.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>How do I receive payment?</span></p>
                    <p><span style={{fontWeight: 400}}>Purchases are made through the Polygon Network. We will issue payments to you directly to your digital wallet.&nbsp;</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>You can view all purchases and payments issued to you by going to your Purchase History in the store manager. You can set your preferred payment options by going to your Store Settings page in the store manager.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What can I do with the MATIC I earn?</span></p>
                    <p><span style={{fontWeight: 400}}>Polygon MATIC is a digital currency that&rsquo;s becoming widely adopted worldwide as a global currency. If you've earned MATIC for selling your work, there are a number of options available for you, including spending your MATIC online, converting to a local currency, or holding (HODL).</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>We suggest you support your fellow creators by exploring, collecting, and investing in unique artworks and creations from other leading digital creators on VAST. </span><a href="/discover"><span style={{fontWeight: 400}}>Start discovering!</span></a></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Why do I need to bid at least 10% more than a previous offer?</span></p>
                    <p><span style={{fontWeight: 400}}>All creators on VAST require that you place a minimum bid of 10% above the most recent offer. This is meant to encourage a healthy exchange between collectors when vying for the same artwork.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Why does an artwork have a minimum bid amount?</span></p>
                    <p><span style={{fontWeight: 400}}>If you&rsquo;re seeing this, it means that the artist has set a minimum offer amount that they&rsquo;re willing to accept for their creation.</span></p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>Reselling on VAST</strong></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>How do I resell the artworks that I purchase on VAST?</span></p>
                    <p><span style={{fontWeight: 400}}>Any artwork that you purchase on VAST can be sold on the secondary market to other collectors. You have the option to set a reserve price. If you don't set a price, anyone can still submit an offer to purchase your artwork.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What are the fees associated with reselling on VAST?</span></p>
                    <p><span style={{fontWeight: 400}}>On successful sales, VAST takes a 2.5% commission on all original purchases and 7.5% on secondary market purchases (2.5% paid to VAST and 5% paid to the original creator).</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Why am I being asked to give Escrow permissions?</span></p>
                    <p><span style={{fontWeight: 400}}>In order to guarantee each sale between you and the buyer, VAST requires you to give approval to a smart contract with the sole purpose to faciliate sales and offers. No one except you can access your artwork even when giving this permission.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>If you wish to revoke this permission, you can do so any time by going to your </span><a href="/profile/settings"><span style={{fontWeight: 400}}>account settings</span></a><span style={{fontWeight: 400}}>. Please note that revoking this permission will cancel all of your sales and you won't be able to accept any offers for your artworks.</span></p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>Buying on VAST</strong></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What am I buying?</span></p>
                    <p><span style={{fontWeight: 400}}>You're buying a limited edition digital creation, signed by the creator. Upon purchase, you'll be given the right to use, distribute, and display the creation for </span><a href="https://en.wikipedia.org/wiki/Non-commercial#_blank"><span style={{fontWeight: 400}}>non-commercial</span></a><span style={{fontWeight: 400}}> purposes. Since you own this unique creation, you can also re-sell the same non-commercial use rights, to the creation, on a secondary market or even directly on VAST.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Upon purchase, you can access the high resolution digital file which you can display on any digital device or even print out, for personal use. All the while, knowing that you have the authentic piece, verifiable on the blockchain.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>How does bidding work?</span></p>
                    <p><span style={{fontWeight: 400}}>Whether a creation is priced outside of your comfort range or doesn't have a price tag, bidding is a great way to try to acquire a creation for a particular price.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Once you've provided an offer amount and confirmed with your wallet, your bid will be submitted for the creator to review. If the creator accepts your offer, the creation will be transferred to your VAST collection.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Submitting a higher offer on the same creation will automatically cancel your previous offer.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What&rsquo;s a committed offer?</span></p>
                    <p><span style={{fontWeight: 400}}>A committed offer is an offer that cannot be cancelled and will only be refunded if the offer is outbid or a higher offer is made.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>All offers made during the closing time of an auction will be committed. This is done to prevent unfair cancelling of offers during the waning moments of an auction.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>What's a reserve price?</span></p>
                    <p><span style={{fontWeight: 400}}>A reserve price indicates the minimum amount the creator is willing to sell their artwork for. When the reserve price is met, the creator agrees to accept the highest offer within a 4-day period.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>You may still make an offer below the reserve price, in case the creator decides to lower their reserve price.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Where do my purchased creations live?</span></p>
                    <p><span style={{fontWeight: 400}}>Because you purchased your digital creation with Polygon MATIC, your creation resides in your digital wallet. After you register with VAST, you can view, showcase, and share your creations with the world by connecting your wallet.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Drop purchase restrictions</span></p>
                    <p><span style={{fontWeight: 400}}>A &ldquo;drop&rdquo; is the release date that an NFT becomes available to buy. Artists may choose to place certain restrictions and limitations on the artworks that are offered as part of a drop. For example, when an NFT has a purchase limit, you may only purchase at most one edition of a multi-edition artwork.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{fontWeight: 400}}>Refund Policy</span></p>
                    <p><span style={{fontWeight: 400}}>Since you're purchasing a digital creation, all sales are final.</span></p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>These FAQs were last updated on October 18, 2021.</strong></p>
                    <p>&nbsp;</p>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default FAQ;