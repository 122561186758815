import React from 'react';
import MetaMask from '~/Services/MetaMask';
import Signature from '~/Services/signature';
import Creations from '~/API/creations';
import PopupManager from '~/Auxilary/PopupManager';
import Helpers from '~/helpers';
import Config from '~/config';

import * as UserDataActions from '~/Redux/UserData/actions';
import { useDispatch, useSelector } from 'react-redux';

import OfferSection from './OfferSection';
import PurchaseSection from './PurchaseSection';
import PopupContainer from '../PopupContainer';
import CreationMediaView from '~/Components/CreationMediaView';

import { useStyles } from './styles';
import MetamaskInstructions from '~/Components/MetamaskInstructions';
import BidSection from './BidSection';

const PurchasePopup = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const userData = useSelector(state => state.UserData);
    const blockchainState = useSelector(state => state.Blockchain.stateChangedTrigger);
    
    const [isLoadingData, setIsLoadingData] = React.useState(false);
    const [addressLoadRequired, setAddressLoadRequired] = React.useState(true);
    const [isProcessingTransaction, setIsProcessingTransaction] = React.useState(false);
    const [isMetamaskEnabled, setIsMetamaskEnabled] = React.useState(false);
    const [isInNetwork, setIsInNetwork] = React.useState(false);
    const [isInAddress, setIsInAddress] = React.useState(false);
    const [isPurchasing, setIsPurchasing] = React.useState(false);
    const [selectedAddress, setSelectedAddress] = React.useState(false);
    const [isProfileAddress, setIsProfileAddress] = React.useState(false);
    const [isSendingOffer, setIsSendingOffer] = React.useState(false);

    React.useEffect(
        () => {
            setIsInNetwork(MetaMask.isInNetwork(props.creation?.blockchain?.network)); 
            setIsProcessingTransaction(MetaMask.isProcessingTransaction());
            setIsMetamaskEnabled(MetaMask.isAvailable());
            setSelectedAddress(MetaMask.getCurrentAddress());
            setIsLoadingData(MetaMask.isLoading());

            setIsInAddress(
                props.buyer ?
                MetaMask.isCurrentAddress(props.buyer) :
                true
            );

            if(addressLoadRequired) {
                setAddressLoadRequired(false);
                dispatch(UserDataActions.getUserAddresses())
            }
        }, 
        [
            blockchainState, 
            userData, 
            props.creation?.blockchain?.network, 
            addressLoadRequired, 
            props.buyer, 
            dispatch
        ]
    )

    React.useEffect(
        () => {
            setIsProfileAddress(
                userData.userAddressList &&
                userData.userAddressList.find(item => item.address === selectedAddress)
            )
        },
        [userData.userAddressList, selectedAddress]
    )

    const handlePurchase = () => {

        MetaMask
            .getBalance()
            .then(addressBalance => {

                let itemPrice = Helpers.weiToEth(props.creation?.price || props.creation?.directPurchasePrice);
                if(itemPrice > parseFloat(addressBalance)) {
                    PopupManager.pushMessagePopup(
                        "Insufficient funds",
                        ["You do not have enough funds in your account to purchase this item."],
                        'FAIL', true, `Buy ${Config.Blockchain.COIN}`, 'Close', false, true
                    )
                    .then(buy => {
                        if(buy) {
                            let purchaseAmount = parseInt((itemPrice - parseFloat(addressBalance)) * 1.1);
                            PopupManager.pushBuyCryptoPopup(
                                isProfileAddress.address, 
                                isProfileAddress.addressSignature, 
                                Helpers.getEthPrice() * purchaseAmount
                            )
                        }
                    })
                    return;
                }

                if(props.creation?.price) {
                    let signatureData = props.creation?.offerSignature;

                    setIsPurchasing(true);
                    MetaMask
                        .getMarketContract()
                        .purchase(
                            signatureData.parameters[0].value,
                            signatureData.parameters[1].value,
                            signatureData.parameters[2].value,
                            signatureData.parameters[3].value,
                            signatureData.parameters[4].value,
                            signatureData.signature.v,
                            signatureData.signature.r,
                            signatureData.signature.s,
                            {
                                value: signatureData.parameters[2].value
                            }
                        )
                        .then(txData => {
                            MetaMask.addPendingTransaction(txData.hash);
                            setIsPurchasing(false);
                            PopupManager.pushMessagePopup(
                                'Transaction sent',
                                [ 
                                    `The transaction for the purchase of this creation has been sent. Please wait for the transaction to go through!`,
                                    `Once the transaction has successfully gone through, the ${Config.Platform.NAME} platform will require up to a minute to fully process the transfer. Please refresh your collection once the transfer has successfully gone through!`
                                ],
                                'PASS'
                            )
                            .then(() => {
                                props.onClose(true);
                            })
                        })
                        .catch((error) => {
                            let errorMessage = error?.error?.message || error?.message || '';
                            PopupManager.pushMessagePopup(
                                'Transaction failed',
                                [
                                    'The submitted transaction has failed. Please check the following message for more information!',
                                    errorMessage.split('MetaMask Tx Signature: ').join('')
                                ],
                                'FAIL'
                            );
                            setIsPurchasing(false);
                        });
                }
                else {
                    setIsPurchasing(true);
                    MetaMask
                        .getMarketContract()
                        .directPurchase(
                            props.creation?.blockchain?.tokenId,
                            {
                                value: props.creation?.directPurchasePrice
                            }
                        )
                        .then(txData => {
                            MetaMask.addPendingTransaction(txData.hash);
                            setIsPurchasing(false);
                            PopupManager.pushMessagePopup(
                                'Transaction sent',
                                [ 
                                    `The transaction for the purchase of this creation has been sent. Please wait for the transaction to go through!`,
                                    `Once the transaction has successfully gone through, the ${Config.Platform.NAME} platform will require a couple of minutes to fully process the transfer. Please refresh your collection once the transfer has successfully gone through!`
                                ],
                                'PASS'
                            )
                            .then(() => {
                                props.onClose(true);
                            })
                        })
                        .catch((error) => {
                            let errorMessage = error?.error?.message || error?.message || '';
                            PopupManager.pushMessagePopup(
                                'Transaction failed',
                                [
                                    'The submitted transaction has failed. Please check the following message for more information!',
                                    errorMessage.split('MetaMask Tx Signature: ').join('')
                                ],
                                'FAIL'
                            );
                            setIsPurchasing(false);
                        });
                }
            });
    }

    const handleMakeOffer = (offerValue) => {

        if(parseFloat(offerValue) <= 0) {
            PopupManager.pushMessagePopup(
                "Offer value too low",
                ["The offer value must be greater than zero"],
                'FAIL'
            )
            return;
        }

        MetaMask
            .getBalance()
            .then(addressBalance => {

                if(parseFloat(offerValue) > parseFloat(addressBalance)) {
                    PopupManager.pushMessagePopup(
                        "Insufficient funds",
                        ["You do not have enough funds in your account to make this offer."],
                        'FAIL', true, `Buy ${Config.Blockchain.COIN}`, 'Close', false, true
                    )
                    .then(buy => {
                        if(buy) {
                            let purchaseAmount = parseInt((parseFloat(offerValue) - parseFloat(addressBalance)) * 1.1);
                            PopupManager.pushBuyCryptoPopup(
                                isProfileAddress.address, 
                                isProfileAddress.addressSignature, 
                                Helpers.getEthPrice() * purchaseAmount
                            )
                        }
                    })
                    return;
                }
        
                setIsSendingOffer(true);
                Signature
                    .signOffer(props.creation?.blockchain.tokenId, offerValue)
                    .then(signatureData => {
                        Creations.makeOffer(props.creation?.creationId, signatureData)
                        .then((data) => {
                            setIsSendingOffer(false);
                            PopupManager.pushMessagePopup(
                                'Offer sent',
                                [ 
                                    `Your offer for the purchase of this creation has been successfully sent.`,
                                    `If the owner chooses to accept your offer, you will be able to purchase this creation for ${Helpers.formatEthPrice(offerValue)} ${Config.Blockchain.COIN}.`
                                ],
                                'PASS'
                            )
                            .then(() => {
                                props.onClose(true);
                            });
                        })
                        .catch((error) => {
                            setIsSendingOffer(false);
                            PopupManager.pushMessagePopup(
                                'Offer failed',
                                [ `Failed to send the offer. ${error}` ],
                                'FAIL'
                            );
                        })
                    })
                    .catch((error) => {
                        setIsSendingOffer(false);
                        PopupManager.pushMessagePopup(
                            'Offer failed',
                            [ 
                                `Failed to digitally sign the offer. ${
                                    error.message?.startsWith('MetaMask Message Signature: ') ?
                                    error.message.substr('MetaMask Message Signature: '.length) :
                                    error.message
                                }`
                            ],
                            'FAIL'
                        );
                    });
            })
    }

    const handleBid = (tokenId, amount) => {

        MetaMask
            .getBalance()
            .then(addressBalance => {
                if(parseFloat(amount) > parseFloat(addressBalance)) {
                    PopupManager.pushMessagePopup(
                        "Insufficient funds",
                        ["You do not have enough funds in your account to purchase this item."],
                        'FAIL', true, `Buy ${Config.Blockchain.COIN}`, 'Close', false, true
                    )
                    .then(buy => {
                        if(buy) {
                            let purchaseAmount = parseInt((parseFloat(amount) - parseFloat(addressBalance)) * 1.1);
                            PopupManager.pushBuyCryptoPopup(
                                isProfileAddress.address, 
                                isProfileAddress.addressSignature, 
                                Helpers.getEthPrice() * purchaseAmount
                            )
                        }
                    })
                    return;
                }

                setIsSendingOffer(true);
                MetaMask
                    .getMarketContract()
                    .bid(
                        tokenId,
                        {
                            value: window.ethers.utils.parseEther(amount.toString())
                        }
                    )
                    .then(txData => {
                        MetaMask.addPendingTransaction(txData.hash);
                        setIsSendingOffer(false);
                        PopupManager.pushMessagePopup(
                            'Transaction sent',
                            [ 
                                `The transaction for bidding on this creation has been sent. The platform will require a couple of minutes to synchronize with the blockchain.`
                            ],
                            'PASS'
                        )
                        .then(() => {
                            props.onClose(true);
                        })
                    })
                    .catch((error) => {
                        let errorMessage = error?.error?.message || error?.message || '';
                        PopupManager.pushMessagePopup(
                            'Transaction failed',
                            [
                                `Failed to bid on this creation. ${errorMessage.split('MetaMask Tx Signature: ').join('')}`
                            ],
                            'FAIL'
                        );
                        setIsSendingOffer(false);
                    });
            });
    }

    return (
        <PopupContainer
            isOpen={props.isOpen}
            onClose={props.onClose}
            backdropColor="#0009">

            <div className={classes.container}>

                <div className={classes.previewContainer}>

                    <p className={classes.title}>
                        {props.creation?.name}
                    </p>

                    <div className={classes.descriptionContainer}>

                        <div className={classes.descriptionAuthor}>
                            {props.creation?.creators?.map(item => item.name).join(', ')}
                        </div>

                        <div className={classes.descriptionEdition}>
                            Edition: {props.creation?.release.number || 0} of {props.creation?.release.count || 0}
                        </div>

                    </div>

                    <CreationMediaView
                        imageUrl={props.creation?.media.image.url}
                        videoUrl={props.creation?.media.video.url}
                        isVideo={!!props.creation?.media.video.url}/>

                </div>

                <div className={classes.purchaseContainer}>
                {
                    !isMetamaskEnabled || !isInNetwork || !isInAddress || !isProfileAddress ?
                    <MetamaskInstructions
                        isEnabled={isMetamaskEnabled}
                        isInNetwork={isInNetwork}
                        isInAddress={isInAddress && isProfileAddress}
                        accountError={
                            !isInAddress ?
                            `The selected address is not approved for this purchase. Make sure the selected address is:` :
                            userData.userAddressList && userData.userAddressList.length === 0 ?
                            'You currently do not have any blockchain wallets connected to your profile. Please connect a wallet account from your profile settings in order to continue!' :
                            !isProfileAddress ?
                            'The selected address is not connected to your profile. Please select one of the following addresses:' :
                            null
                        }
                        isNoWalletConnectedError={isInAddress && userData.userAddressList && userData.userAddressList.length === 0}
                        onClose={props.onClose}
                        requiredAddress={
                            props.isPurchase && props.buyer ?
                            props.buyer :
                            userData.userAddressList && 
                            userData.userAddressList.map(item => item.address)
                        }
                        isLoadingData={isLoadingData}
                        isProcessingTransaction={isProcessingTransaction}
                        requiredNetwork={MetaMask.getNetworkNameById(props.creation?.blockchain?.network)}/> 
                    :
                    props.isAuction ? 
                    <BidSection
                        creationData={props.creation}
                        lastBidAmount={props.creation?.auction?.lastBidAmount}
                        isSendingOffer={isSendingOffer}
                        userAddress={isProfileAddress}
                        onBidClicked={handleBid}/> :

                    props.isPurchase ?
                    <PurchaseSection 
                        price={
                            props.creation?.price ||
                            props.creation?.directPurchasePrice
                        }
                        userAddress={isProfileAddress}
                        isPurchasing={isPurchasing}
                        onPurchaseClicked={handlePurchase}/>
                    :
                    <OfferSection 
                        creationId={props.creation?.creationId}
                        tokenId={props.creation?.blockchain?.tokenId}
                        userAddress={isProfileAddress}
                        isSendingOffer={isSendingOffer}
                        onMakeOfferClicked={handleMakeOffer}/>
                }
                </div>

            </div>

        </PopupContainer>
    );
}

export default PurchasePopup;