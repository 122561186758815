import {
    DISCOVER_FETCH_START,
    DISCOVER_FETCH_FINISHED,
    DISCOVER_FETCH_FAILED,
    DISCOVER_SUGGESTIONS_FETCH_START,
    DISCOVER_SUGGESTIONS_FETCH_FINISHED,
    DISCOVER_SUGGESTIONS_FETCH_FAILED,
    SEARCH_FETCH_START,
    SEARCH_FETCH_FINISHED,
    SEARCH_FETCH_FAILED
} from './types';

import {
    STATE_NOT_PROCESSED,
    STATE_PROCESSING,
    STATE_DONE,
    STATE_FAILED
} from '../states'

const INITIAL_STATE = {
    contentType: null,
    contentOrder: null,
    
    data: [],
    dataState: STATE_NOT_PROCESSED,
    dataExtensionState: STATE_NOT_PROCESSED,
    isEverythingLoaded: false,

    suggestionsContentType: null,
    suggestionsContentOrder: null,
    suggestionsData: [],
    suggestionsDataState: STATE_NOT_PROCESSED,

    isSearching: false
}

const DiscoverReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case DISCOVER_FETCH_START:
            return DiscoverDataReducers.fetchStart(state, action.payload);
            
        case DISCOVER_FETCH_FINISHED:
            return DiscoverDataReducers.fetchFinished(state, action.payload);

        case DISCOVER_FETCH_FAILED:
            return DiscoverDataReducers.fetchFailed(state, action.payload);


        case DISCOVER_SUGGESTIONS_FETCH_START:
            return DiscoverSuggestionsDataReducers.fetchStart(state, action.payload);

        case DISCOVER_SUGGESTIONS_FETCH_FINISHED:
            return DiscoverSuggestionsDataReducers.fetchFinished(state, action.payload);

        case DISCOVER_SUGGESTIONS_FETCH_FAILED:
            return DiscoverSuggestionsDataReducers.fetchFailed(state, action.payload);


        case SEARCH_FETCH_START:
            return SearchReducers.fetchStart(state, action.payload);

        case SEARCH_FETCH_FINISHED:
            return SearchReducers.fetchFinished(state, action.payload);

        case SEARCH_FETCH_FAILED:
            return SearchReducers.fetchFailed(state, action.payload);

        default:
            return state;
    }
}

class DiscoverDataReducers {

    static fetchStart(state, data) {

        const isExtending = 
            state.contentType !== null &&
            state.contentOrder !== null &&
            data.contentType === state.contentType &&
            data.contentOrder === state.contentOrder;

        if(isExtending) {
            return { 
                ...state, 
                dataExtensionState: STATE_PROCESSING
            }
        }
        else {
            return { 
                ...state,
                contentType: null,
                contentOrder: null, 
                data: [],
                dataState: STATE_PROCESSING,
                dataExtensionState: STATE_NOT_PROCESSED,
                isEverythingLoaded: false
            }
        }
    }

    static fetchFinished(state, data) {

        const isExtending = 
            state.contentType !== null &&
            state.contentOrder !== null &&
            data.contentType === state.contentType &&
            data.contentOrder === state.contentOrder;

        if(isExtending) {
            return { 
                ...state, 
                contentType: data.contentType,
                contentOrder: data.contentOrder,
                data: [...state.data, ...data.data],
                dataExtensionState: STATE_DONE,
                isEverythingLoaded: data.data.length === 0
            }
        }
        else {
            return { 
                ...state, 
                contentType: data.contentType,
                contentOrder: data.contentOrder,
                data: data.data,
                dataState: STATE_DONE,
                isEverythingLoaded: data.data.length === 0
            }
        }
    }

    static fetchFailed(state, data) {
        const isExtending = 
            state.contentType !== null &&
            state.contentOrder !== null &&
            data.contentType === state.contentType &&
            data.contentOrder === state.contentOrder;

        if(isExtending) {
            return { 
                ...state, 
                dataExtensionState: STATE_FAILED
            }
        }
        else {
            return { 
                ...state, 
                dataState: STATE_FAILED,
            }
        }
    }
}


class DiscoverSuggestionsDataReducers {

    static fetchStart(state, data) {

        const contentChanged = 
            data.contentType !== state.suggestionsContentType ||
            data.contentOrder !== state.suggestionsContentOrder;

        if(!contentChanged)
            return state;

        return { 
            ...state,
            suggestionsContentType: data.contentType,
            suggestionsContentOrder: data.contentOrder,
            suggestionsData: [],
            suggestionsDataState: STATE_PROCESSING,
        }
    }

    static fetchFinished(state, data) {

        return { 
            ...state,
            suggestionsData: data.data,
            suggestionsDataState: STATE_DONE,
        }
    }

    static fetchFailed(state) {
        return { 
            ...state,
            suggestionsDataState: STATE_FAILED,
        }
    }
}

class SearchReducers {

    static fetchStart(state) {
        return { 
            ...state,
            contentType: null,
            contentOrder: null, 
            data: [],
            dataState: STATE_PROCESSING,
            dataExtensionState: STATE_NOT_PROCESSED,
            isEverythingLoaded: false,
            isSearching: true
        }
    }

    static fetchFinished(state, data) {
        console.log(data)
        return { 
            ...state, 
            contentType: data.contentType,
            contentOrder: data.contentOrder,
            data: data.data,
            dataState: STATE_DONE,
            isEverythingLoaded: true,
            isSearching: false
        }
    }

    static fetchFailed(state) {
        return { 
            ...state, 
            dataState: STATE_FAILED,
            isSearching: false
        }
    }
}

export default DiscoverReducer;