import React, { Fragment } from 'react';
import * as API from '~/API';

import CreationTableHeader from '~/Components/CreationTableHeader';
import NoContentInfoBox from '~/Components/NoContentInfoBox';
import PopupManager from '~/Auxilary/PopupManager';
import DropListItem from './DropListItem';

import Images from '~/Assets/images';
import { useStyles } from './styles';
import Button from '~/Components/Button';
import { useHistory } from 'react-router-dom';

const ManageDrops = (props) => {

    const ITEMS_LOAD_LIMIT = 10;
    const classes = useStyles();
    const history = useHistory();
    
    const [dropsFilter] = React.useState('ALL');
    const [dataReloadRequired, setDataReloadRequired] = React.useState(true);
    const [isLoadingDrops, setIsLoadingDrops] = React.useState(false);
    const [canLoadMore, setCanLoadMore] = React.useState(true);
    const [dropsList, setDropsList] = React.useState([]);

    const handleLoadMoreDrops = (resetList) => {
        if(isLoadingDrops)
            return;

        setIsLoadingDrops(true);
        API.Drop
            .listDrops(
                dropsFilter, 
                resetList ? 0 : dropsList.length,
                ITEMS_LOAD_LIMIT    
            )
            .then(data => {
                setDropsList(
                    resetList ? 
                    data :
                    [
                        ...dropsList, 
                        ...data
                    ]
                );
                setCanLoadMore(data.length >= ITEMS_LOAD_LIMIT)
                setIsLoadingDrops(false);
            })
            .catch(err => {
                setIsLoadingDrops(false);
            })
    }

    const handleLoadMoreDropsCallback = React.useCallback(
        handleLoadMoreDrops, 
        [
            dropsFilter,
            dropsList,
            isLoadingDrops
        ]
    );

    const handleCreateDrop = () => {
        PopupManager.pushCreateDropPopup();
    }

    const handleEditDrop = (dropId) => {
        history.push(`/admin/drops/${dropId}`)
    }

    const handleDeleteDrop = (dropId) => {
        PopupManager
            .pushMessagePopup(
                'Delete Drop',
                ['Are you sure you want to delete this Drop? If you proceed, this drop\'s description and details will be permanently deleted.'],
                null, true, 'Yes', 'No', true
            )
            .then(confirmed => {
                if(confirmed) {
                    API.Drop
                        .delete(dropId)
                        .then(() => {
                            handleLoadMoreDrops(true);
                        })
                }
            })
    }

    React.useEffect(
        () => {
            if(dataReloadRequired) {
                setDataReloadRequired(false);
                handleLoadMoreDropsCallback();
            }
            
        }, 
        [ dropsFilter, dropsList, handleLoadMoreDropsCallback, dataReloadRequired ]
    );

    return (
        <div className={classes.container}>

            <div className={classes.headerContainer}>
                <p className={classes.title}>
                    Manage Drops
                </p>

                <Button
                    className={classes.createDropButton}
                    text="Create Drop"
                    onClick={handleCreateDrop}/>
            </div>

            <div style={{height: 30}}/>

            {
                dropsList &&
                dropsList.length > 0 ?

                <Fragment>
                    <CreationTableHeader
                        itemStyle={classes.headerItem}
                        headerItems={[
                            { text: 'Drop', flex: 5},
                            { text: 'Created at', flex: 3},
                            { text: 'Status', flex: 5}
                        ]}/>
                    
                    <div className={classes.itemsContainer}>
                        {
                            dropsList.map(dropData => 
                                <DropListItem
                                    key={dropData.dropId}
                                    dropId={dropData.dropId}
                                    name={dropData?.name}
                                    previewUrl={dropData?.preview?.imageUrl}
                                    creatorCount={dropData.creators?.length}
                                    date={(new Date(dropData.createdAt)).toDateString()}
                                    status={
                                        dropData.approved ?
                                        'Published' : 'Not published'
                                    }
                                    descriptionFlex={5}
                                    dateFlex={3}
                                    statusFlex={5}
                                    onEditClicked={handleEditDrop}
                                    onDeleteClicked={handleDeleteDrop} />
                            )
                        }
                    </div>

                    {
                        canLoadMore &&
                        <Button
                            text={
                                isLoadingDrops ?
                                'Loading...' :
                                'Load more'
                            }
                            isLoading={isLoadingDrops}
                            onClick={handleLoadMoreDrops} />
                    }
                </Fragment> :

                <NoContentInfoBox
                    icon={Images.EmptyGallery}
                    text={
                        props.showReceived ?
                        `You have not received any offers yet` :
                        `You have not sent any offers yet`
                    }/>
            }

            <div style={{height: 50}}/>

        </div>
    );
}

export default ManageDrops;