import React from 'react';

const ShareIcon = (props) => {
    return (
        <svg
            className={ props.className }
            xmlns="http://www.w3.org/2000/svg" 
            version="1.1"
            viewBox="0 0 48 48">
            <path d="M36 32.17c-1.52 0-2.89.59-3.93 1.54l-14.25-8.31c.11-.45.18-.92.18-1.4s-.07-.95-.18-1.4l14.1-8.23c1.07 1 2.5 1.62 4.08 1.62 3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6c0 .48.07.95.18 1.4l-14.1 8.23c-1.07-1-2.5-1.62-4.08-1.62-3.31 0-6 2.69-6 6s2.69 6 6 6c1.58 0 3.01-.62 4.08-1.62l14.25 8.31c-.1.42-.16.86-.16 1.31 0 3.22 2.61 5.83 5.83 5.83s5.83-2.61 5.83-5.83-2.61-5.83-5.83-5.83z"/>
        </svg>
    )
}

export default ShareIcon;