import React, { Fragment } from 'react';

import * as API from '~/API';
import NoContentInfoBox from '~/Components/NoContentInfoBox';

import { useStyles } from './styles';
import CreatorRequestItem from './CreatorRequestItem';
import Images from '~/Assets/images';
import CreationTableHeader from '~/Components/CreationTableHeader';
import { useHistory } from 'react-router-dom';

const CreatorRequests = () => {

    const classes = useStyles();
    const history = useHistory();

    const [isDataFetched, setIsDataFetched] = React.useState(false);
    const [creatorRequestList, setCreatorRequestList] = React.useState(null);

    React.useEffect(
        () => {
            if(!isDataFetched) {
                API.Admin.getCreatorRequests()
                .then((data) => {
                    setCreatorRequestList(data);
                    setIsDataFetched(true);
                })
                .catch(() => {
                    setIsDataFetched(true);
                })
            }
        },
        [isDataFetched, creatorRequestList]
    )

    const handleViewRequest = (username) => {
        history.push(`/admin/creator-requests/${username}`)
    }

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Creator requests
            </p>

            <div style={{height: 30}}/>

            {
                creatorRequestList &&
                creatorRequestList.length > 0 ?
                <Fragment>
                    <CreationTableHeader
                        itemStyle={classes.headerItem}
                        headerItems={[
                            { text: 'User', flex: 5},
                            { text: 'Request date', flex: 3},
                            { text: 'Request count', flex: 5}
                        ]}/>
                    
                    <div className={classes.itemsContainer}>
                        {
                            creatorRequestList.map(item =>
                                <CreatorRequestItem
                                    key={item.username}
                                    username={item.username}
                                    name={item.name}
                                    email={item.email}
                                    profilePictureUrl={item.profilePictureUrl}
                                    about={item.about}
                                    creatorRequest={item.creatorRequest}
                                    verified={item.verified}
                                    descriptionFlex={5}
                                    dateFlex={3}
                                    statusFlex={5}
                                    onViewRequest={handleViewRequest} />
                            )
                        }
                    </div>
                </Fragment>
                :
                <NoContentInfoBox
                    icon={Images.EmptyGallery}
                    text={`There are currently no creator requests to process`}/>
            }

            <div style={{height: 50}}/>

        </div>
    );
}

export default CreatorRequests;