import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as UserActions from '~/Redux/User/actions';
import * as DropDetailsActions from '~/Redux/DropDetails/actions';
import { STATE_NOT_PROCESSED, STATE_PROCESSING } from '~/Redux/states';
import { useParams } from 'react-router-dom';

import MainHeader from '~/Components/MainHeader';
import ProfilePicture from '~/Components/ProfilePicture';

import { useStyles } from './styles';
import DropItem from '~/Components/DropItem';
import Footer from '~/Components/Footer';

const DropDetails = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { dropId } = useParams(); 
    const userData = useSelector(state => state.User);
    const dropDetails = useSelector(state => state.DropDetails);
    const pageWidth = useSelector(state => state.UI.pageWidth);

    const [timeRemaining, setTimeRemaining] = React.useState(0);

    React.useEffect(
        () => {
            if (!userData.data && userData.token && 
                userData.fetchDataState === STATE_NOT_PROCESSED) {
                dispatch(UserActions.getData());
            }
        },
        [userData, dispatch]
    );

    React.useEffect(
        () => {
            if (dropDetails.contentsState !== STATE_PROCESSING &&
                dropDetails.currentDropId !== dropId) {
                dispatch(DropDetailsActions.getDrop(dropId));
            }

            if(dropDetails?.contents) {
                let startTime = new Date(dropDetails?.contents?.startTime).getTime();

                setTimeRemaining(
                    startTime > Date.now() ? 
                    startTime - Date.now() : 
                    0
                );
            }
        },
        [dropId, dropDetails, dispatch]
    );

    React.useEffect(
        () => {
            const timer = setInterval(() => {
                let startTime = new Date(dropDetails?.contents?.startTime).getTime();

                setTimeRemaining(
                    startTime > Date.now() ? 
                    startTime - Date.now() : 
                    0
                );
            }, 1000);
            return () => clearInterval(timer);
        }, 
        [dropDetails]
    );

    const getFormattedDropTime = () => {
        let days = parseInt(timeRemaining / 24 / 60 / 60 / 1000);
        let hours = parseInt(timeRemaining / 60 / 60 / 1000 % 24);
        let minutes = parseInt(timeRemaining  / 60 / 1000 % 60);
        let seconds = parseInt(timeRemaining / 1000 % 60);

        let minutesString = minutes > 9 ? minutes : '0' + minutes;
        let secondsString = seconds > 9 ? seconds : '0' + seconds;

        return (
            days ?
            `${days}d ${hours}h ${minutesString}m ${secondsString}s` :
            hours ?
            `${hours}h ${minutesString}m ${secondsString}s` :
            `${minutesString}m ${secondsString}s`
        )
    }

    return (
        <Fragment>
            
            <MainHeader
                username={userData.data?.username}
                profilePictureUrl={userData.data?.profilePictureUrl}
                userType={userData.data?.role}
                isLoggedIn={!!userData.data}
                isMobile={pageWidth < 800}/>

            <div className={classes.pageContainer}>

                <div className={classes.artistSectionContainer}>

                    <div className={classes.artistSection}>
                        <div className={classes.artistName}>
                            {dropDetails.contents?.artist?.name}
                        </div>

                        <div className={classes.dropName}>
                            {dropDetails.contents?.name}
                        </div>

                        <div className={classes.artistItemsContainer}>
                            {
                                dropDetails.contents?.creators?.map(item => 
                                    <ProfilePicture
                                        key={item.username}    
                                        username={item.username}
                                        imageUrl={item.profilePictureUrl}
                                        style={{margin: '0 5px'}}
                                        size={50} />
                                )
                            }
                        </div>

                        {
                            timeRemaining > 0 &&
                            <div>
                                <p className={classes.dropsInLabelText}>Drops in:</p>
                                <p className={classes.dropsInText}>{getFormattedDropTime()}</p>
                            </div>
                        }

                        {
                            dropDetails.contents?.artist.description
                                ?.split('\n')
                                .filter(item => !!item)
                                .map((descriptionLine, index) =>
                                    <div key={index} className={classes.artistDescription}>
                                        {descriptionLine}
                                    </div>
                                )
                        }

                    </div>

                </div>


                <div className={classes.dropSectionContainer}>
                    
                    <div className={classes.dropSection}>

                        <div className={classes.dropTitle}>
                            THE DROP
                        </div>

                        {
                            dropDetails.contents?.description
                                ?.split('\n')
                                .filter(item => !!item)
                                .map((descriptionLine, index) =>
                                    <div key={index} className={classes.dropDescription}>
                                        {descriptionLine}
                                    </div>
                                )
                        }

                        {
                            dropDetails.contents?.itemsTitle &&
                            <div className={classes.dropItemsTitle}>
                                { dropDetails.contents?.itemsTitle }
                            </div>
                        }

                        {
                            dropDetails.contents?.itemsDescription &&
                            <div className={classes.dropDescription}>
                                { dropDetails.contents?.itemsDescription }
                            </div>
                        }

                        <div className={classes.dropItemsContainer}>
                            {
                                dropDetails.contents?.creations?.map(creation => 
                                    <DropItem
                                        key={creation.creationId}
                                        className={classes.dropItem}
                                        itemId={creation.creationId}
                                        itemName={creation.name}
                                        imageUrl={creation.media.image.url}
                                        videoUrl={creation.media.video.url}
                                        creatorList={[creation.creator]}
                                        releaseCount={creation.release.count}
                                        playOnHover={!!creation.media.video.url}
                                        defaultOpen
                                        showAuthor
                                        squareSize={350} />
                                )
                            }
                        </div>

                    </div>

                </div>

            </div>
            <Footer/>
        </Fragment>
    );
}

export default DropDetails;