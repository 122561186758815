import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #ccc',
        margin: '3px 0'
    },
    itemsList: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 10
    },
    addItemButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#eee',
        padding: 10,
        borderLeft: '1px solid #ccc',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ddd'
        }
    },
    addItemButton: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark
    }
});