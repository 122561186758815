import React from 'react';
import _ from 'lodash';

import { useStyles } from './styles';

const UserCollections = (props) => {
    const classes = useStyles();
    const [selectedItem, setSelectedItem] = React.useState(0);

    React.useEffect(
        () => {
            setSelectedItem(props.defaultItem)
        },
        [props.defaultItem]
    )

    const handleItemChanged = (item) => {
        if(props.disabled)
            return;

        setSelectedItem(item);
        if(_.isFunction(props.onSelectionChanged))
            props.onSelectionChanged(item)
    }

    return (
        <div 
            className={classes.container}
            style={props.style}>
            {
                (props.collectionItems || [])
                    .map((item, index) =>
                        <div 
                            key={item}
                            onClick={handleItemChanged.bind(null, index)}
                            className={
                                selectedItem === index ?
                                classes.item + ' ' + classes.selectedItem :
                                props.disabled ?
                                classes.itemDisablled :
                                classes.item
                            }>
                            { item }
                        </div>
                    )
            }
        </div>
    );
}

export default UserCollections;