import React from 'react';
import { Link } from 'react-router-dom';
import Images from '~/Assets/images';
import { useStyles } from './styles';

const SettingsStateItem = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <img 
                alt=""
                className={classes.icon}
                src={ props.isPass ? Images.Verified : Images.Unverified } />

            
            <p className={classes.description}>
                { props.description }
            </p>

            {
                props.link &&
                <Link 
                    to={props.link}
                    className={classes.link}>
                    (Go to settings)
                </Link>
            }
        </div>
    );
}

export default SettingsStateItem;