import {
    HOME_PAGE_FETCH_START,
    HOME_PAGE_FETCH_FINISHED,
    HOME_PAGE_FETCH_FAILED
} from './types';

import {
    STATE_NOT_PROCESSED,
    STATE_PROCESSING,
    STATE_DONE,
    STATE_FAILED
} from '../states'

const INITIAL_STATE = {
    contents: null,
    bannerDrop: null,
    contentsState: STATE_NOT_PROCESSED,
}

const HomePageReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case HOME_PAGE_FETCH_START:
            return HomePageReducers.contentsFetchStart(state);
            
        case HOME_PAGE_FETCH_FINISHED:
            return HomePageReducers.contentsFetchFinished(state, action.payload);

        case HOME_PAGE_FETCH_FAILED:
            return HomePageReducers.contentsFetchFailed(state);

        default:
            return state;
    }
}

class HomePageReducers {

    static contentsFetchStart(state) {
        return { 
            ...state, 
            contentsState: STATE_PROCESSING
        }
    }

    static contentsFetchFinished(state, data) {

        let dropsWithBanners = data.liveDrops.filter(item => item.banner.hasBanner);
        
        let bannerDrop =  
            dropsWithBanners.length === 0 ? null :
            dropsWithBanners[Math.floor(Math.random() * dropsWithBanners.length)];

        return { 
            ...state, 
            bannerDrop,
            contents: data,
            contentsState: STATE_DONE
        }
    }

    static contentsFetchFailed(state) {
        return { 
            ...state, 
            contentsState: STATE_FAILED
        }
    }
}

export default HomePageReducer;