import LoginPopup from "~/Popups/LoginPopup";
import { triggerPupupUpdate } from '~/Redux/UI/actions';
import { store } from '~/Redux';
import RegisterPopup from "~/Popups/RegisterPopup";
import FollowerPopup from "~/Popups/FollowerPopup";
import PurchasePopup from "~/Popups/PurchasePopup";
import MessagePopup from "~/Popups/MessagePopup";
import MintPopup from "~/Popups/MintPopup";
import MintReleasesPopup from "~/Popups/MintReleasesPopup";
import ResetPasswordPopup from "~/Popups/ResetPasswordPopup";
import VerificationPopup from "~/Popups/VerificationPopup";
import CreateDropPopup from "~/Popups/CreateDropPopup";
import AddCreatorPopup from "~/Popups/AddCreatorPopup";
import AddCreationPopup from "~/Popups/AddCreationPopup";
import CreatorRequestDenyPopup from "~/Popups/CreatorRequestDenyPopup";
import TwoFactorPopup from "~/Popups/TwoFactorPopup";
import CreatorRequestApprovePopup from "~/Popups/CreatorRequestApprovePopup";
import BuyCryptoPopup from "~/Popups/BuyCryptoPopup";
import SharePopup from "~/Popups/SharePopup";

class PopupManager {
    constructor() {
        this.componentList = [];
        this.nextKey = 1;
    }

    getComponentList() {
        return this.componentList;
    }

    pop() {
        this.componentList.pop();
        this.triggerUpdate();
    }

    push(component) {
        this.componentList.push(component);
        this.triggerUpdate();
    }

    triggerUpdate() {
        triggerPupupUpdate()(store.dispatch);
    }

    async pushFollowerPopup(username, isFollowing) {
        return new Promise((resolve) => {
            this.push(
                <FollowerPopup
                    key={`popup_${this.nextKey++}`}
                    username={username}
                    isFollowing={isFollowing}
                    onClose={(loginOk) => {
                        this.pop();
                        resolve(loginOk);
                    }}
                    isOpen />
            );
        });
    }

    async pushLogin() {
        return new Promise(resolve => {
            this.push(
                <LoginPopup
                    key={`popup_${this.nextKey++}`}
                    onClose={(loginOk) => {
                        this.pop();
                        resolve(loginOk);
                    }}
                    isOpen />
            );
        });
    }

    async pushRegister() {
        return new Promise((resolve, reject) => {
            this.push(
                <RegisterPopup
                    key={`popup_${this.nextKey++}`}
                    onClose={(registerOk) => {
                        this.pop();
                        resolve(registerOk);
                    }}
                    isOpen />
            );
        });
    }

    async pushPurchasePopup(creationData, isPurchase, buyer, isAuction) {
        return new Promise((resolve, reject) => {
            this.push(
                <PurchasePopup
                    key={`popup_${this.nextKey++}`}
                    creation={creationData}
                    isPurchase={isPurchase}
                    isAuction={isAuction}
                    buyer={buyer}
                    onClose={(registerOk) => {
                        this.pop();
                        resolve(registerOk);
                    }}
                    isOpen />
            );
        });
    }

    async pushMessagePopup(title, messageLines, icon, isPrompt, acceptText, denyText, denyIsSecondary, primaryConfirm) {
        return new Promise((resolve) => {
            this.push(
                <MessagePopup
                    key={`popup_${this.nextKey++}`}
                    title={title}
                    messageLines={messageLines}
                    icon={icon}
                    isPrompt={isPrompt}
                    acceptText={acceptText}
                    denyText={denyText}
                    denySecondary={denyIsSecondary}
                    primaryConfirm={primaryConfirm}
                    onClose={(result) => {
                        this.pop();
                        resolve(result);
                    }}
                    isOpen />
            );
        });
    }

    async pushMintPopup(mintRequestData, maxEditions, networkId) {
        return new Promise((resolve) => {
            this.push(
                <MintPopup
                    key={`popup_${this.nextKey++}`}
                    mintRequestData={mintRequestData}
                    maxEditions={maxEditions}
                    networkId={networkId}
                    onClose={(result) => {
                        this.pop();
                        resolve(result);
                    }}
                    isOpen />
            );
        });
    }

    async pushMintReleasesPopup(mediaId, maxEditions, address, networkId) {
        return new Promise((resolve) => {
            this.push(
                <MintReleasesPopup
                    key={`popup_${this.nextKey++}`}
                    mediaId={mediaId}
                    maxEditions={maxEditions}
                    address={address}
                    networkId={networkId}
                    onClose={(result) => {
                        this.pop();
                        resolve(result);
                    }}
                    isOpen />
            );
        });
    }

    async pushResetPasswordPopup() {
        return new Promise((resolve) => {
            this.push(
                <ResetPasswordPopup
                    key={`popup_${this.nextKey++}`}
                    onClose={(result) => {
                        this.pop();
                        resolve(result);
                    }}
                    isOpen />
            );
        });
    }

    async pushVerificationPopup() {
        return new Promise((resolve) => {
            this.push(
                <VerificationPopup
                    key={`popup_${this.nextKey++}`}
                    onClose={(result) => {
                        this.pop();
                        resolve(result);
                    }}
                    isOpen />
            );
        });
    }

    async pushCreateDropPopup() {
        return new Promise((resolve) => {
            this.push(
                <CreateDropPopup
                    key={`popup_${this.nextKey++}`}
                    onClose={(result) => {
                        this.pop();
                        resolve(result);
                    }}
                    isOpen />
            );
        });
    }

    async pushAddCreatorPopup() {
        return new Promise((resolve) => {
            this.push(
                <AddCreatorPopup
                    key={`popup_${this.nextKey++}`}
                    onClose={(result) => {
                        this.pop();
                        resolve(result);
                    }}
                    isOpen />
            );
        });
    }

    async pushAddCreationPopup() {
        return new Promise((resolve) => {
            this.push(
                <AddCreationPopup
                    key={`popup_${this.nextKey++}`}
                    onClose={(result) => {
                        this.pop();
                        resolve(result);
                    }}
                    isOpen />
            );
        });
    }

    async pushCreatorRequestDenyPopup() {
        return new Promise((resolve) => {
            this.push(
                <CreatorRequestDenyPopup
                    key={`popup_${this.nextKey++}`}
                    onClose={(response) => {
                        this.pop();
                        resolve(response);
                    }}
                    isOpen />
            );
        });
    }

    async pushCreatorRequestApprovePopup(address) {
        return new Promise((resolve) => {
            this.push(
                <CreatorRequestApprovePopup
                    key={`popup_${this.nextKey++}`}
                    address={address}
                    onClose={(response) => {
                        this.pop();
                        resolve(response);
                    }}
                    isOpen />
            );
        });
    }


    async pushTwoFactorPopup(isEnabling) {
        return new Promise((resolve) => {
            this.push(
                <TwoFactorPopup
                    key={`popup_${this.nextKey++}`}
                    isEnabling={isEnabling}
                    onClose={(response) => {
                        this.pop();
                        resolve(response);
                    }}
                    isOpen />
            );
        });
    }

    async pushBuyCryptoPopup(address, addressSignature, buyAmount) {
        return new Promise((resolve) => {
            this.push(
                <BuyCryptoPopup
                    key={`popup_${this.nextKey++}`}
                    address={address}
                    addressSignature={addressSignature}
                    buyAmount={buyAmount}
                    onClose={(result) => {
                        this.pop();
                        resolve(result);
                    }}
                    isOpen />
            );
        });
    }

    async pushSharePopup(url) {
        return new Promise((resolve) => {
            this.push(
                <SharePopup
                    key={`popup_${this.nextKey++}`}
                    url={url}
                    onClose={(result) => {
                        this.pop();
                        resolve(result);
                    }}
                    isOpen />
            );
        });
    }
}

export default new PopupManager();