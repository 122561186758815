import React from 'react';
import _ from 'lodash';

import * as UserDataActions from '~/Redux/UserData/actions';
import { useDispatch, useSelector } from 'react-redux';

import MetamaskInstructions from '~/Components/MetamaskInstructions';
import PopupContainer from '../PopupContainer';
import MetaMask from '~/Services/MetaMask';
import Button from '~/Components/Button';

import { useStyles } from './styles';
import LabeledInputField from '~/Components/LabeledInputField';
import PopupManager from '~/Auxilary/PopupManager';

const MintPopup = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const userData = useSelector(state => state.UserData);
    const blockchainState = useSelector(state => state.Blockchain.stateChangedTrigger);

    const [addressLoadRequired, setAddressLoadRequired] = React.useState(true);
    const [isLoadingData, setIsLoadingData] = React.useState(true);
    const [isProcessingTransaction, setIsProcessingTransaction] = React.useState(false);
    const [isMetamaskEnabled, setIsMetamaskEnabled] = React.useState(false);
    const [isInNetwork, setIsInNetwork] = React.useState(false);
    const [selectedAddress, setSelectedAddress] = React.useState(false);
    const [isApprovedCreator, setIsApprovedCreator] = React.useState(false);
    const [isProfileAddress, setIsProfileAddress] = React.useState(false);
    const [releaseCount, setReleaseCount] = React.useState(1);

    React.useEffect(
        () => {
            setIsInNetwork(MetaMask.isInNetwork(props.networkId)); 
            setIsProcessingTransaction(MetaMask.isProcessingTransaction());
            setIsMetamaskEnabled(MetaMask.isAvailable());
            setSelectedAddress(MetaMask.getCurrentAddress());

            if(addressLoadRequired) {
                setAddressLoadRequired(false);
                dispatch(UserDataActions.getUserAddresses())
            }
        }, 
        [blockchainState, userData, addressLoadRequired, props.networkId, dispatch]
    )

    React.useEffect(
        () => {
            if(!selectedAddress || !MetaMask.listening || !isInNetwork) {
                setIsLoadingData(MetaMask.isLoading());
                return;
            }

            setIsLoadingData(true);
            MetaMask
                .getTokenContract()
                .approvedCreators(selectedAddress)
                .then(isApproved => {
                    setIsApprovedCreator(isApproved)
                    setIsLoadingData(MetaMask.isLoading());
                })
        }, 
        [selectedAddress, isInNetwork]
    )

    React.useEffect(
        () => {
            setIsProfileAddress(
                userData.userAddressList &&
                userData.userAddressList.find(item => item.address === selectedAddress)
            )
        },
        [userData.userAddressList, selectedAddress]
    )

    const handleClose = (confirm) => {
        if(_.isFunction(props.onClose))
            props.onClose(confirm);
    }

    const handleMint = () => {

        if(!props.mintRequestData || !selectedAddress || !MetaMask.listening || !isInNetwork)
            return;

        setIsLoadingData(true);
        MetaMask
            .getTokenContract()
            .createDigitalMediaAndReleases(
                selectedAddress,
                props.mintRequestData.editions,
                props.mintRequestData.media.metadata.ipfsName,
                releaseCount
            )
            .then(txData => {
                MetaMask.addPendingTransaction(
                    txData.hash, 
                    'Mint Creation', 
                    'MINTS', 
                    props.mintRequestData.mintRequestId,
                    30 * 60 * 1000
                );
                
                handleClose(true);
                PopupManager.pushMessagePopup(
                    'Transaction sent',
                    [
                        'The transaction for publishing your creation has been sent. Based on the network load, it might take a while for the transaction to be submitted to the blockchain.',
                        'Please give this process a few minutes to complete. Once the transaction goes through, your newly published creation will appear on your profile.'
                    ],
                    'PASS'
                );
            })
            .catch((error) => {
                setIsLoadingData(false);
                PopupManager.pushMessagePopup(
                    'Transaction failed',
                    [ `Failed to publish creation. ${(error?.error?.message || error?.message || '').split('MetaMask Tx Signature: ').join('')}` ],
                    'FAIL'
                );
            });
    }

    return (
        <PopupContainer
            isOpen={props.isOpen}
            onClose={handleClose.bind(null, false)}
            backdropColor="#0009">

            <div className={classes.container}>
                <p className={classes.title}>
                    Publish creation
                </p>

                {
                    !isMetamaskEnabled || !isInNetwork || !isApprovedCreator || !isProfileAddress || isProcessingTransaction || isLoadingData ?
                    <MetamaskInstructions
                        isEnabled={isMetamaskEnabled}
                        isInNetwork={isInNetwork}
                        isInAddress={
                            isApprovedCreator && 
                            isProfileAddress
                        }
                        accountError={
                            !isApprovedCreator ?
                            'The selected address is not approved for publishing creations. Please select the approved address in order to continue!' :
                            userData.userAddressList && userData.userAddressList.length === 0 ?
                            'You currently do not have any blockchain wallets connected to your profile. Please connect a wallet account from your profile settings in order to continue!' :
                            !isProfileAddress ?
                            'The selected address is not connected to your profile. Please select one of the following addresses:' :
                            null
                        }
                        isNoWalletConnectedError={isApprovedCreator && userData.userAddressList && userData.userAddressList.length === 0}
                        onClose={props.onClose}
                        requiredAddress={
                            isApprovedCreator &&
                            userData.userAddressList &&
                            userData.userAddressList.map(item => item.address)
                        }
                        isLoadingData={isLoadingData}
                        isProcessingTransaction={isProcessingTransaction}
                        requiredNetwork={MetaMask.getNetworkNameById(props.networkId)}/>
                    :
                    <div className={classes.bodyContents}>
                    
                        <p className={classes.infoMessage}>
                            You're about to publish a new creation. This operation will submit the uploaded media and its metadata to the blockchain.
                        </p>

                        <p className={classes.infoMessage}>
                            Please make sure you have the desired owner's address selected before proceeding!
                        </p>

                        <p className={classes.label}>
                            Owner's address:
                        </p>

                        <LabeledInputField
                            className={classes.labeledInputField}
                            labelText="Address"
                            value={selectedAddress}
                            disabled
                            labelFirst />

                        <p className={classes.label}>
                            Initial release count (Max. {props.maxEditions}):
                        </p>

                        <LabeledInputField
                            className={classes.labeledInputField}
                            labelText="Releases"
                            type="number"
                            min={0}
                            max={props.maxEditions}
                            value={releaseCount}
                            onChange={setReleaseCount}
                            labelFirst />

                    </div>
                }            

                <div className={classes.buttonContainer}>
                    <Button
                        className={classes.button}
                        text="Publish"
                        disabled={!isMetamaskEnabled || !isInNetwork || !isApprovedCreator || !isProfileAddress || isProcessingTransaction || isLoadingData}
                        onClick={handleMint}/>
                </div>

            </div>

        </PopupContainer>
    );
}

export default MintPopup;