import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '15px 0'
    },
    itemPreview: {
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center center !important',
        backgroundSize: 'cover !important',
        width: 80,
        height: 80
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    columnHorizontal: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    descriptionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        margin: '0 -15px 0 15px'
    },
    itemName: {
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        margin: '3px 15px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: Theme.Palette.Primary.Foreground
        }
    },
    itemEdition: {
        fontFamily: Theme.Font.Primary,
        fontSize: 13,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '3px 15px'
    },
    userDataContainer: {
        flex: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    linkedField: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        margin: 0,
        fontSize: 13,
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        },
    },
    price: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 15,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: 0
    },
    status: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 15,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: 0
    },
    button: {
        padding: '5px 10px',
        marginRight: 15,
        width: 'max-content'
    }
});