import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATE_DONE, STATE_FAILED, STATE_PROCESSING } from '~/Redux/states';
import * as UserActions from '~/Redux/User/actions';
import Helpers from '~/helpers';
import _ from 'lodash';

import PopupContainer from '../PopupContainer';
import Button from '~/Components/Button';

import { useStyles } from './styles';
import * as API from '~/API';
import ViewIcon from '~/Assets/Vectors/ViewIcon';

const RegisterPopup = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [shouldLoadCaptcha, setShouldLoadCaptcha] = React.useState(true);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [repeatPassword, setRepeatPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [name, setName] = React.useState('');
    const [captchaId, setCaptchaId] = React.useState('');
    const [captchaImage, setCaptchaImage] = React.useState(null);
    const [captcha, setCaptcha] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    const registerState = useSelector(state => state.User.registerState);

    const registerError = useSelector(state => 
        Helpers.getRequestErrorMessage(state.User.registerError)
    );

    const registerErrorField = useSelector(state => 
        Helpers.getRequestErrorField(state.User.registerError)
    );

    const handleClose = (registerOk) => {
        if(_.isFunction(props.onClose))
            props.onClose(!!registerOk);
    }

    const handleRegister = () => {
        if(password !== repeatPassword) {
            setPasswordError(true);
            return;
        }
        else {
            setPasswordError(false);
        }

        if(registerState === STATE_PROCESSING)
            return;

        dispatch(
            UserActions.register(
                username, 
                password,
                email,
                name,
                captchaId,
                captcha
            )
        );
    }

    const handleCloseCallback = React.useCallback(
        handleClose, 
        [props]
    );

    React.useEffect(
        () => {
            if(registerState === STATE_DONE)
                handleCloseCallback(true);
        },
        [registerState, handleCloseCallback]
    )

    React.useEffect(
        () => {
            if(shouldLoadCaptcha) {
                setShouldLoadCaptcha(false);
                API.User
                    .getCaptcha()
                    .then(data => {
                        setCaptchaId(data.id);
                        setCaptchaImage(data.svg);
                    })
            }
        },
        [shouldLoadCaptcha]
    )
    
    return (
        <PopupContainer
            isOpen={props.isOpen}
            onClose={handleClose}
            backdropColor="#0009">

            <div className={classes.container}>
                <p className={classes.title}>
                    Register account
                </p>

                <input 
                    className={
                        registerErrorField === 'name' ?
                        classes.inputField + ' ' + classes.inputFieldInvalid :
                        classes.inputField
                    }
                    placeholder="Full name"
                    type="email"
                    value={name}
                    onChange={(event) => {
                        setName(event.target.value)
                    }}
                    onKeyPress={(event) => { 
                        if(event.key === 'Enter') 
                            handleRegister() 
                    }}
                    autoFocus/>

                <input 
                    className={
                        registerErrorField === 'username' ?
                        classes.inputField + ' ' + classes.inputFieldInvalid :
                        classes.inputField
                    }
                    placeholder="Username"
                    type="text"
                    value={username}
                    onChange={(event) => {
                        setUsername(event.target.value)
                    }}
                    onKeyPress={(event) => { 
                        if(event.key === 'Enter') 
                            handleRegister() 
                    }}/>

                <input 
                    className={
                        registerErrorField === 'email' ?
                        classes.inputField + ' ' + classes.inputFieldInvalid :
                        classes.inputField
                    }
                    placeholder="Email address"
                    type="email"
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value)
                    }}
                    onKeyPress={(event) => { 
                        if(event.key === 'Enter') 
                            handleRegister() 
                    }}/>

                <div style={{height: 15}}/>

                <div className={classes.fieldContainer}>
                        
                    <input 
                        className={
                            registerErrorField === 'password' || passwordError ?
                            classes.inputField + ' ' + classes.inputFieldInvalid :
                            classes.inputField
                        }
                        style={{
                            margin: '0 5px 0 0'
                        }}
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}
                        onKeyPress={(event) => { 
                            if(event.key === 'Enter') 
                                handleRegister() 
                        }}/>

                    <input 
                        className={
                            registerErrorField === 'password' || passwordError ?
                            classes.inputField + ' ' + classes.inputFieldInvalid :
                            classes.inputField
                        }
                        style={{
                            margin: '0 5px 0 5px'
                        }}
                        placeholder="Repeat password"
                        type={showPassword ? "text" : "password"}
                        value={repeatPassword}
                        onChange={(event) => {
                            setRepeatPassword(event.target.value)
                        }}
                        onKeyPress={(event) => { 
                            if(event.key === 'Enter') 
                                handleRegister() 
                        }}/>

                    <div 
                        onClick={() => {setShowPassword(!showPassword)}}
                        title="Show password"
                        className={classes.showPasswordContainer}>
                        <ViewIcon className={classes.showPasswordIcon}/>
                    </div>
                </div>

                <div style={{height: 15}}/>

                <div className={classes.fieldContainer}>

                    <div 
                        className={classes.captcha} 
                        dangerouslySetInnerHTML={{__html: captchaImage}}/>

                    <input 
                        className={
                            registerErrorField === 'captcha' ?
                            classes.inputField + ' ' + classes.inputFieldInvalid :
                            classes.inputField
                        }
                        placeholder="Captcha code on the left"
                        type="text"
                        value={captcha}
                        onChange={(event) => {
                            setCaptcha(event.target.value)
                        }}
                        onKeyPress={(event) => { 
                            if(event.key === 'Enter') 
                                handleRegister() 
                        }}/>

                </div>


                <div className={classes.forgotPasswordContainer}>
                    {
                        registerState === STATE_FAILED || passwordError ?
                        <p className={classes.errorMessage}>
                            { 
                                passwordError ?
                                'The two Password fields do not match' :
                                registerError 
                            }
                        </p> : null
                    }
                </div>

                <Button
                    className={classes.button}
                    isLoading={registerState === STATE_PROCESSING}
                    text={
                        registerState === STATE_PROCESSING ?
                        "Registering..." :
                        "Register"
                    }
                    onClick={handleRegister}/>

            </div>

        </PopupContainer>
    );
}

export default RegisterPopup;