import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';

import PlayIcon from '~/Assets/Vectors/PlayIcon';
import { useStyles } from './styles';
import ProfilePicture from '../ProfilePicture';

const DropItem = (props) => {
    const history = useHistory();
    const classes = useStyles();

    const containerRef = React.useRef();
    const [contentZoom, setContentZoom] = React.useState(1);
    const [isFullWidth, setIsFullWidth] = React.useState(true);
    const [hoveredOver, setHoveredOver] = React.useState(false);
    const [videoLoaded, setVideoLoaded] = React.useState(false);

    const creatorList = props.creatorList || []
    
    const handleItemClicked = () => {
        if(props.itemId)
            history.push(
                props.isDrop ?
                `/drops/${props.itemId}` :
                `/creations/${props.itemId}`
            )
    }

    const handleVideoLoaded = (event) => {
        setVideoLoaded(true);
        setIsFullWidth(!props.squareSize || event.target.videoWidth < event.target.videoHeight);
    }

    const handleImageLoaded = (event) => {
        setIsFullWidth(!props.squareSize || event.target.naturalWidth < event.target.naturalHeight);

        let itemDimensions = {
            width: 100, 
            height: event.target.naturalHeight * 100 / event.target.naturalWidth
        }

        if(_.isFunction(props.onMediaLoaded))
            props.onMediaLoaded(props.itemId, itemDimensions);
    }

    React.useEffect(() => {
        if(containerRef.current) {
            let zoomLevel = containerRef.current.clientWidth / 300
            setContentZoom(
                zoomLevel > 1 ? 1 :
                zoomLevel < 0.5 ? 0.5 :
                zoomLevel
            )
        }
    }, [props.squareSize, containerRef])

    return (
        <div 
            className={
                props.className ?
                classes.wrapperContainer + ' ' + props.className :
                classes.wrapperContainer
            }
            style={
                props.squareSize ? 
                {
                    width: props.squareSize,
                    minWidth: props.squareSize,
                } : {}
            }>
            <div 
                ref={containerRef}
                className={
                    props.squareSize ?
                    classes.squareContainer :
                    classes.container
                }
                style={
                    props.squareSize ? 
                    {
                        width: props.squareSize,
                        height: props.squareSize,
                        minWidth: props.squareSize,
                        minHeight: props.squareSize,
                    } : {}
                }
                onMouseEnter={() => { setHoveredOver(true) }}>

                {
                    videoLoaded ? null :
                    <img
                        className={
                            isFullWidth ?
                            classes.fullWidth :
                            classes.fullHeight
                        }
                        onLoad={handleImageLoaded}
                        src={props.imageUrl}
                        alt=""/> 
                }

                {
                    props.videoUrl && (!props.playOnHover || hoveredOver) ?
                    <video 
                        className={
                            isFullWidth ?
                            classes.fullWidth :
                            classes.fullHeight
                        } 
                        style={{display: videoLoaded ? 'block' : 'none'}}
                        onContextMenu={(e) => {e.preventDefault()}}
                        controlsList="nodownload"
                        disablePictureInPicture 
                        playsInline 
                        autoPlay 
                        muted
                        loop
                        onLoadedData={handleVideoLoaded}>
                        <source src={props.videoUrl} type="video/mp4"/>
                    </video> : null
                }

                {
                    (props.startTime && new Date(props.startTime) > Date.now()) &&
                    <div className={classes.upcomingTimeContainer}>
                        <div className={classes.upcomingTime}>
                            { `🔥 ${(new Date(props.startTime)).toDateString()}` }
                        </div>
                    </div>
                }

                {
                    props.playOnHover && !hoveredOver ?
                    <div className={classes.playOnHoverContainer}>
                        <PlayIcon className={classes.playOnHoverIcon}/>
                    </div> : null
                }

                <div 
                    className={classes.infoOverlayContainer}
                    style={{zoom: contentZoom}}>
                        
                    <div 
                        onClick={handleItemClicked}
                        className={
                            props.defaultOpen ?
                            classes.infoContainer :
                            classes.infoContainer + ' ' + classes.showOnHover
                        }>

                        <div className={classes.infoMarginContainer}>

                            <div className={classes.detailsContainer}>
                                <Link 
                                    to={
                                        props.isDrop ?
                                        `/drops/${props.itemId}` :
                                        `/creations/${props.itemId}`
                                    }
                                    className={classes.title}>
                                    {props.itemName}
                                </Link>
                                
                                
                                <div>
                                    <p className={classes.edition}>
                                        {
                                            props.isDrop ?
                                            `View all Artworks` :
                                            `Edition of ${props.releaseCount === 1 ? 'One' : props.releaseCount}`
                                        }
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {
                props.showAuthor &&
                <div className={classes.authorContainer}>

                    {
                        creatorList.map((creator) => 
                            <ProfilePicture 
                                key={`cip_${creator.name}`}
                                username={creator.username}
                                imageUrl={creator.profilePictureUrl}
                                style={{margin: '5px 5px 5px 0'}}
                                size={30}/>
                        )
                    }

                    <div style={{width: 5}}/>

                    <div className={classes.authorNameContainer}>
                        {
                            creatorList.map((creator, index) => 
                                <Link 
                                    key={`ci_${creator.name}`}
                                    to={`/profiles/${creator.username}/`}
                                    className={classes.author}>
                                    {
                                        index === creatorList.length - 1 ?
                                        creator.name : `${creator.name},`
                                    }
                                </Link>       
                            )
                        }
                    </div>

                </div>
            }
        </div>
    );
}

export default DropItem;