import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    wrapperContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 'min-content',
        height: 'min-content'
    },
    profileName: {
        fontFamily: Theme.Font.Primary,
        fontSize: 15,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        margin: '0 10px 10px 10px',
        cursor: 'default'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        margin: 10,
        width: 280,
        height: 280,
    },
    image: {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    },
    profilePictureContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        position: 'absolute',
        float: 'left',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        padding: 0,
    },
    profilePicture: {
        width: '20%',
        height: '20%',
        border: 'solid 1px black',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    },
    infoOverlayContainer: {
        display: 'flex',
        position: 'absolute',
        float: 'left',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        padding: 0,
        opacity: 0,
        transition: '0.2s ease',
        '&:hover': {
            opacity: 0.95
        }
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: '#EDEDED',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        padding: 0
    },
    collectorInfoContainer: {
        justifyContent: 'flex-end',
    },
    description: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: 15
    },
    viewProfile: {
        fontFamily: Theme.Font.Primary,
        fontSize: 15,
        fontWeight: 400,
        color: Theme.Palette.Primary.Foreground,
        margin: '15px 15px 30px 15px'
    }
});