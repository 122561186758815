import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
        height: 400,
        margin: 0
    },
    fullWidth: {
        objectFit: 'cover',
        minWidth: '100%',
        minHeight: '100%',
        zoom: 0.1
    },
    infoOverlayContainer: {
        display: 'flex',
        backgroundColor: '#0009',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        float: 'left',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        padding: 0,
    },
    author: {
        textAlign: 'center',
        fontFamily: Theme.Font.Primary,
        fontSize: 24,
        fontWeight: 400,
        color: Theme.TextColor.Lightest,
        margin: '0 50px',
    },
    name: {
        textAlign: 'center',
        fontFamily: Theme.Font.Secondary,
        fontSize: 60,
        fontWeight: 400,
        color: Theme.TextColor.Lightest,
        margin: '10px 50px',
    },
    description: {
        textAlign: 'center',
        fontFamily: Theme.Font.Primary,
        fontSize: 15,
        fontWeight: 200,
        color: Theme.TextColor.Lightest,
        margin: '0 50px',
    },
    '@media only screen and (max-width: 1024px)': {
        author: {
            fontSize: 18
        },
        name: {
            fontSize: 40,
        },
    },
});