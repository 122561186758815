import React from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';

const DropBanner = (props) => {
    const classes = useStyles();
    const [videoLoaded, setVideoLoaded] = React.useState(false);
    const [isVisible, setIsVisible] = React.useState(false);

    const handleVideoLoaded = () => {
        setIsVisible(true);
        setVideoLoaded(true);
    }

    const handleImageLoaded = () => {
        setIsVisible(true);
    }

    return (
        <Link 
            to={`/drops/${props.dropId}`}
            className={classes.container}
            style={{display: isVisible ? 'flex' : 'none'}}>

            {
                videoLoaded ? null :
                <img
                    className={classes.fullWidth}
                    onLoad={handleImageLoaded}
                    src={props.imageUrl}
                    alt=""/> 
            }

            {
                props.videoUrl ?
                <video 
                    key={props.videoUrl}
                    className={classes.fullWidth}
                    style={{display: videoLoaded ? 'block' : 'none'}}
                    onContextMenu={(e) => {e.preventDefault()}}
                    controlsList="nodownload"
                    disablePictureInPicture 
                    playsInline 
                    autoPlay 
                    muted
                    loop
                    onLoadedData={handleVideoLoaded}>
                    <source src={props.videoUrl} type="video/mp4"/>
                </video> : null
            }

            <div className={classes.infoOverlayContainer}>

                <p className={classes.author}>
                    { props.author }
                </p>

                <p className={classes.name}>
                    { props.name }
                </p>

                <p className={classes.description}>
                    { props.description }
                </p>

            </div>
        </Link>
    );
}

export default DropBanner;