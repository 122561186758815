import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        position: 'fixed',
        width: 'calc(100% - 40px)',
        height: 80,
        padding: '0 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'black',
        transition: '0.2s ease',
        zIndex: 1000
    },
    logo: {
        width: 150,
        margin: '7px 10px 10px 10px'
    },
    logoLink: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
    },
    menuContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    menuItem: {
        padding: 10,
        margin: '2px 10px 0 10px',
        fontFamily: Theme.Font.Secondary,
        fontSize: 17,
        fontWeight: 400,
        color: 'white',
        cursor: 'pointer',
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
        borderBottomColor: 'transparent',
        textDecoration: 'none',
        '&:hover': {
            color: Theme.Palette.Primary.Background,
            borderBottomColor: Theme.Palette.Primary.Background
        },
        transition: '0.2s ease'
    },
    mobileContainer: {
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        padding: 0
    },
    mobileLogoContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15
    },
    mobileArrow: {
        width: 12,
        height: 12,
        margin: '10px 5px 10px 15px',
        fill: 'transparent',
        stroke: Theme.TextColor.Lightest,
        strokeWidth: 4,
    },
    mobileMenuItemsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#EDEDED',
        padding: '20px 0',
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none', 
        '-moz-user-select': 'none', 
        '-ms-user-select': 'none', 
        userSelect: 'none',
        boxShadow: '0px 16px 50px 0px rgb(0 0 0 / 10%)',
        '-webkit-box-shadow': '0px 16px 50px 0px rgb(0 0 0 / 10%)',
        '-moz-box-shadow': '0px 16px 50px 0px rgba(0,0,0,0.1)'
    },
    mobileItem: {
        textAlign: 'center',
        color: Theme.TextColor.Dark,
        '&:hover': {
            color: Theme.TextColor.Dark + ' !important',
            borderBottomColor: 'transparent !important'
        },
    },
    mobileMenuDivider: {
        borderBottom: '1px solid #bbb',
        width: '100%',
        height: 1,
        margin: 10,
        alignSelf: 'center'
    },
    iconItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    mobileIconItemContainer: {
        justifyContent: 'center'
    },
    itemIcon: {
        height: 20,
        marginRight: 5
    },
    verificationBarContainer: {
        position: 'absolute',
        top: -25,
        left: 0,
        right: 0,
        padding: 0,
        margin: 0,
        height: 25,
        backgroundColor: '#A30000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    verificationBarText: {
        color: 'white',
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        }
    }
});