import React from 'react';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import PixelatedButton from '../PixelatedButton';

const CreationAuthenticityDetails = (props) => {
    const classes = useStyles();
    const creatorList = props.creators || [];
    const ownerData = props.owner || {};
    const releaseData = props.release || { number: 0, count: 0 };

    return (
        <div className={classes.container}>
            <div className={classes.contentBody}>
                <div className={classes.fieldsContainer}>
                    <div className={classes.attributeContainer}>
                        <p className={classes.attributeLabel}>
                            {
                                creatorList.length > 1 ?
                                'CREATORS:' : 'CREATOR:' 
                            }
                        </p>
                        
                        {
                            creatorList.map((creator, index) => 
                                <Link 
                                    key={`ci_${creator.name}`}
                                    to={`/profiles/${creator.username}/`}
                                    className={classes.attributeValue}>
                                    {
                                        index === creatorList.length - 1 ?
                                        creator.name : `${creator.name},`
                                    }
                                </Link>       
                            )
                        }
                    </div>

                    <div className={classes.attributeContainer}>
                        <p className={classes.attributeLabel}>
                            EDITION:
                        </p>

                        <Link 
                            to={`/authenticity/${props.creationId}/`}
                            className={classes.attributeValue}>
                            { `${releaseData.number} of ${releaseData.count}` }
                        </Link>
                    </div>

                    <div className={classes.attributeContainer}>
                        <p className={classes.attributeLabel}>
                            OWNER:
                        </p>

                        {
                            ownerData.username ?
                            <Link 
                                to={`/profiles/${ownerData.username}/`}
                                className={classes.attributeValue}>
                                { ownerData.name }
                            </Link> :
                            <div className={classes.attributeValue}>
                                { ownerData.name }
                            </div>
                        }
                    </div>

                    <PixelatedButton
                        text="View Proof of Authenticity"
                        style={{
                            width: 250,
                            height: 35,
                            marginTop: 10,
                            marginRight: 15
                        }}
                        foregroundColor='white'
                        href={`/authenticity/${props.creationId}/`}
                        secondary/>
                </div>

            </div>

        </div>
    );
}

export default CreationAuthenticityDetails;