import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 5,
        maxWidth: 1000
    },
    sectionTitle: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 23,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        margin: '10px 0 15px 0'
    },
    instructionText: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        textAlign: 'justify',
        margin: '5px 0'
    },
    priceField: {
        flex: 1,
        marginTop: 20
    },
    checkboxLabel: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        margin: '5px 0'
    },
    invalidateControls: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 20
    },
    checkbox: {
        marginRight: 7
    },
    checkboxContainer: {
        flex: 1
    },
    submitButton: {
        padding: '7px 15px'
    }
});