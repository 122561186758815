import React from 'react';
import Helpers from '~/helpers';
import Config from '~/config';

import PopupManager from '~/Auxilary/PopupManager';
import Button from '~/Components/Button';
import { useStyles } from './styles';
import _ from 'lodash';

const OfferSection = (props) => {

    const classes = useStyles();
    const [offerValue, setOfferValue] = React.useState('');

    const handleMakeOfferClicked = () => {
        if(_.isFunction(props.onMakeOfferClicked))
            props.onMakeOfferClicked(offerValue);
    }

    const handleBuyCrypto = () => {
        PopupManager.pushBuyCryptoPopup(
            props.userAddress.address, 
            props.userAddress.addressSignature
        )
    }

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Offer details
            </p>

            <p className={classes.purchaseDescription}>
                We will use your digital wallet to submit your offer. You'll be asked to confirm your offer amount with your digital wallet.
            </p>


            <div className={classes.offerDetailsContainer}>

                <input 
                    className={classes.offerValue}
                    value={offerValue}
                    min={0}
                    step={0.001}
                    onChange={event => {setOfferValue(event.target.value)}}
                    placeholder={`Offer amount in ${Config.Blockchain.COIN}`}
                    type="number"/>

                <p className={classes.priceUsd}>
                    $ {Helpers.formatUsdPrice(Helpers.getEthPrice() * offerValue)}
                </p>
            </div>

            <Button
                className={classes.purchaseButton}
                text={
                    props.isSendingOffer ?
                    'Sending offer...' :
                    'Make offer'
                }
                isLoading={props.isSendingOffer}
                onClick={handleMakeOfferClicked}
                inverted/>

            <p className={classes.whatAreYouPurchasing} onClick={handleBuyCrypto}>
                Not enough funds? <strong>Buy {Config.Blockchain.COIN}!</strong>
            </p>

        </div>
    );
}

export default OfferSection;