export const USER_LOGIN_START = 'USER_LOGIN_START';
export const USER_LOGIN_FINISHED = 'USER_LOGIN_FINISHED';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGIN_CLEAR = 'USER_LOGIN_CLEAR';

export const USER_DATA_FETCH_START = 'USER_DATA_FETCH_START';
export const USER_DATA_FETCH_FINISHED = 'USER_DATA_FETCH_FINISHED';
export const USER_DATA_FETCH_FAILED = 'USER_DATA_FETCH_FAILED';

export const USER_DATA_UPDATE_START = 'USER_DATA_UPDATE_START';
export const USER_DATA_UPDATE_FINISHED = 'USER_DATA_UPDATE_FINISHED';
export const USER_DATA_UPDATE_FAILED = 'USER_DATA_UPDATE_FAILED';

export const USER_PASSWORD_UPDATE_START = 'USER_PASSWORD_UPDATE_START';
export const USER_PASSWORD_UPDATE_FINISHED = 'USER_PASSWORD_UPDATE_FINISHED';
export const USER_PASSWORD_UPDATE_FAILED = 'USER_PASSWORD_UPDATE_FAILED';

export const USER_ABOUT_UPDATE_START = 'USER_ABOUT_UPDATE_START';
export const USER_ABOUT_UPDATE_FINISHED = 'USER_ABOUT_UPDATE_FINISHED';
export const USER_ABOUT_UPDATE_FAILED = 'USER_ABOUT_UPDATE_FAILED';

export const USER_CREATION_LIKE_STATE_CHANGED = 'USER_CREATION_LIKE_STATE_CHANGED';
export const USER_FOLLOWING_STATE_CHANGED = 'USER_FOLLOWING_STATE_CHANGED';

export const USER_REGISTER_START = 'USER_REGISTER_START';
export const USER_REGISTER_FINISHED = 'USER_REGISTER_FINISHED';
export const USER_REGISTER_FAILED = 'USER_REGISTER_FAILED';

export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_PROFILE_PICTURE_UPLOAD_START = 'USER_PROFILE_PICTURE_UPLOAD_START';
export const USER_PROFILE_PICTURE_UPLOAD_PROGRESS = 'USER_PROFILE_PICTURE_UPLOAD_PROGRESS';
export const USER_PROFILE_PICTURE_UPLOAD_FINISHED = 'USER_PROFILE_PICTURE_UPLOAD_FINISHED';
export const USER_PROFILE_PICTURE_UPLOAD_FAILED = 'USER_PROFILE_PICTURE_UPLOAD_FAILED';

export const USER_ABOUT_HEADER_PICTURE_UPLOAD_START = 'USER_ABOUT_HEADER_PICTURE_UPLOAD_START';
export const USER_ABOUT_HEADER_PICTURE_UPLOAD_PROGRESS = 'USER_ABOUT_HEADER_PICTURE_UPLOAD_PROGRESS';
export const USER_ABOUT_HEADER_PICTURE_UPLOAD_FINISHED = 'USER_ABOUT_HEADER_PICTURE_UPLOAD_FINISHED';
export const USER_ABOUT_HEADER_PICTURE_UPLOAD_FAILED = 'USER_ABOUT_HEADER_PICTURE_UPLOAD_FAILED';

export const USER_PROFILE_PREVIEW_UPLOAD_START = 'USER_PROFILE_PREVIEW_UPLOAD_START';
export const USER_PROFILE_PREVIEW_UPLOAD_PROGRESS = 'USER_PROFILE_PREVIEW_UPLOAD_PROGRESS';
export const USER_PROFILE_PREVIEW_UPLOAD_FINISHED = 'USER_PROFILE_PREVIEW_UPLOAD_FINISHED';
export const USER_PROFILE_PREVIEW_UPLOAD_FAILED = 'USER_PROFILE_PREVIEW_UPLOAD_FAILED';

export const USER_EMAIL_VERIFIED = 'USER_EMAIL_VERIFIED';