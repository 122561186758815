import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as UserActions from '~/Redux/User/actions';
import * as UserDataActions from '~/Redux/UserData/actions';
import * as API from '~/API';
import { STATE_DONE, STATE_NOT_PROCESSED, STATE_PROCESSING } from '~/Redux/states';

import { useStyles } from './styles';
import ProfilePicture from '~/Components/ProfilePicture';
import FileInputField from '~/Components/FileInputField';
import LabeledInputField from '~/Components/LabeledInputField';
import Button from '~/Components/Button';
import Helpers from '~/helpers';
import BlockchainWalletItem from './BlockchainWalletItem';
import Signature from '~/Services/signature';
import Config from '~/config';
import MetaMask from '~/Services/MetaMask';
import PopupManager from '~/Auxilary/PopupManager';
import Images from '~/Assets/images';

const ProfileSettings = () => {

    const classes = useStyles();    
    
    const dispatch = useDispatch();
    const userData = useSelector(state => state.User);
    const userAddressData = useSelector(state => state.UserData);

    const [addressesNeedReloading, setAddressesNeedReloading] = React.useState(true);
    const [initialValuesSet, setInitialValuesSet] = React.useState(false);
    const [fullName, setFullName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [storedEmail, setStoredEmail] = React.useState('');

    const [confirmOk, setConfirmOk] = React.useState(true);
    const [currentPassword, setCurrentPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [isConnecting, setIsConnecting] = React.useState(false);
    const [isEnabling2fa, setIsEnabling2fa] = React.useState(false);
    const [isPasswordChangedShown, setIsPasswordChangedShown] = React.useState(false);

    React.useEffect(
        () => {
            setConfirmOk(newPassword === confirmPassword)
        },
        [newPassword, confirmPassword]
    );

    React.useEffect(
        () => {
            if (!userData.data && userData.token && 
                userData.fetchDataState === STATE_NOT_PROCESSED) {       
                dispatch(UserActions.getData());
            }

            if(userData.data && !initialValuesSet) {
                setFullName(userData.data?.name);
                setEmail(userData.data?.email);
                setStoredEmail(userData.data?.email);
                setInitialValuesSet(true);
            }

            if(userData.passwordUpdateState === STATE_DONE && !isPasswordChangedShown) {
                setIsPasswordChangedShown(true);
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');

                PopupManager.pushMessagePopup(
                    "Password changed",
                    ["You have successfully changed your password. Please remember to use the new password in your next login!"],
                    "PASS"
                );
            }

            if (addressesNeedReloading || 
                (userData.fetchDataState === STATE_DONE &&
                userAddressData.userAddressListState === STATE_NOT_PROCESSED)) {
                dispatch(UserDataActions.getUserAddresses());
                setAddressesNeedReloading(false);
            }
        },
        [
            userData, 
            fullName, 
            email, 
            initialValuesSet, 
            userAddressData, 
            addressesNeedReloading, 
            isPasswordChangedShown,
            dispatch
        ]
    );

    const handleUploadProfilePicture = (file) => {
        dispatch(UserActions.uploadProfilePicture(file));
    }

    const handleUpdateData = () => {
        let fieldsToUpdate = {};

        if(userData.data && userData.data.name !== fullName)
            fieldsToUpdate.name = fullName;

        if(userData.data && userData.data.email !== email)
            fieldsToUpdate.email = email;

        setStoredEmail(email);
        dispatch(UserActions.updateData(fieldsToUpdate));
    }

    const handleChangePassword = () => {
        if(!confirmOk)
            return;

        setIsPasswordChangedShown(false);

        dispatch(
            UserActions.changePassword(
                currentPassword, 
                newPassword
            )
        );
    }

    const handleVerifyEmail = () => {
        PopupManager.pushVerificationPopup();
    }

    const handleConnectWallet = () => {
        setIsConnecting(true);

        MetaMask
            .startListening()
            .then(() => {
                Signature
                    .signAddressVerification(userData.username)
                    .then(signature => {
                        API.UserData
                        .postAddress(signature)
                        .then(() => {
                            dispatch(UserDataActions.getUserAddresses());
                            setIsConnecting(false);
                            PopupManager.pushMessagePopup(
                                'Wallet connected',
                                [ 'The wallet address has been successfully connected to this profile.' ], 
                                'PASS'
                            )
                        })
                        .catch((err) => {
                            setIsConnecting(false);
                            PopupManager.pushMessagePopup(
                                'Failed to connect wallet',
                                [ err ],
                                'FAIL'
                            )
                        })
                    })
                    .catch(() => {
                        setIsConnecting(false);
                    })
            })
            .catch(() => {
                setIsConnecting(false);
            })
    }

    const handleDisconnectWallet = (address) => {
        PopupManager.pushMessagePopup(
            'Disconnect wallet',
            [ `Are you sure you want to disconnect the wallet address form your profile? By doing so, you will detach the ownership of your collection from your profile.` ], 
            null, true, 'Yes', 'No', true
        )
        .then((confirmed) => {
            if(!confirmed)
                return;

            API.UserData
                .deleteAddress(address)
                .then(() => {
                    dispatch(UserDataActions.getUserAddresses());
                })
                .catch(() => {})
        })
    }

    const handle2faSettings = () => {
        setIsEnabling2fa(true);
        PopupManager
            .pushTwoFactorPopup(!userData.data?.twoFactorEnabled)
            .then(() => {
                setIsEnabling2fa(false);    
                dispatch(UserActions.getData());
            })
            .catch(() => {
                setIsEnabling2fa(false);
            })
    }

    return (
        <div className={classes.container}>

            <div className={classes.profilePictureContainer}>
                <ProfilePicture
                    imageUrl={userData.data?.profilePictureUrl}
                    size={150}/>
            </div>

            <div className={classes.fieldsContainer}>
                <p className={classes.title}>
                    Account Settings
                </p>

                <FileInputField
                    className={classes.fileInputField}
                    maxSize={1}
                    fileType="IMAGE"
                    text={
                        userData.profilePictureUploadState === STATE_PROCESSING ?
                        `Uploading Profile Picture: ${Math.round(userData.profilePictureUploadProgress * 100)}%` :
                        'Profile Picture'
                    }
                    onFileSelected={handleUploadProfilePicture}/>

                <input
                    className={classes.inputField}
                    style={{
                        borderColor: 
                            Helpers.getRequestErrorField(userData.updateDataError) === 'name' ?
                            'red' : undefined
                    }}
                    type="text"
                    value={fullName}
                    onChange={(event) => {setFullName(event.target.value)}}
                    placeholder="Full name"/>
                
                <LabeledInputField
                    className={classes.usernameField}
                    labelText={`${Config.Platform.DOMAIN}/profiles/`}
                    value={userData.username}
                    disabled
                    labelFirst/>

                <div className={classes.emailFieldContainer}>
                    <input
                        className={classes.inputField}
                        style={{
                            marginTop: 3,
                            backgroundImage: 
                                userData.data ? 
                                `url(${userData.data.verified ? Images.Verified : Images.Unverified})` :
                                `none`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '25px 25px',
                            backgroundPosition: 'calc(100% - 5px) 50%',
                            borderColor: Helpers.getRequestErrorField(userData.updateDataError) === 'email' ? 'red' : undefined,
                        }}
                        type="email"
                        value={email}
                        onChange={(event) => {setEmail(event.target.value)}}
                        placeholder="Email address" />

                    {
                        userData.data && !userData.data.verified &&
                        <Button
                            className={classes.verifyButton}
                            disabled={storedEmail !== email}
                            text="Verify"
                            onClick={handleVerifyEmail}
                            inverted/>
                    }
                </div>

                <div className={classes.buttonContainer}>

                    <Button
                        className={classes.updateButton}
                        text={
                            userData.updateDataState === STATE_PROCESSING ?
                            'Updating data...' : 'Update'
                        }
                        isLoading={userData.updateDataState === STATE_PROCESSING}
                        onClick={handleUpdateData}
                        inverted/>

                    <p className={classes.updateErrorMessage}>
                        { Helpers.getRequestErrorMessage(userData.updateDataError) }
                    </p>

                </div>

                <div style={{height: 30}}/>

                <p className={classes.smallTitle}>
                    Account Password
                </p>

                <input
                    className={classes.inputField}
                    style={{
                        marginBottom: 10,
                        borderColor: 
                            Helpers.getRequestErrorField(userData.passwordUpdateError) === 'currentPassword' ?
                            'red' : undefined
                    }}
                    value={currentPassword}
                    onChange={(event) => {setCurrentPassword(event.target.value)}}
                    type="password"
                    placeholder="Current password"/>

                <input
                    className={classes.inputField}
                    style={{
                        borderColor: 
                            Helpers.getRequestErrorField(userData.passwordUpdateError) === 'newPassword' ?
                            'red' : undefined
                    }}
                    value={newPassword}
                    onChange={(event) => {setNewPassword(event.target.value)}}
                    type="password"
                    placeholder="New password"/>

                <input
                    className={classes.inputField}
                    style={{backgroundColor: !confirmOk && '#f002' }}
                    value={confirmPassword}
                    onChange={(event) => {setConfirmPassword(event.target.value)}}
                    type="password"
                    placeholder="Repeat new password"/>

                <div className={classes.buttonContainer}>

                    <Button
                        className={classes.updateButton}
                        text={
                            userData.passwordUpdateState === STATE_PROCESSING ?
                            'Updating password...' : 'Change password'
                        }
                        isLoading={userData.passwordUpdateState === STATE_PROCESSING}
                        onClick={handleChangePassword}
                        inverted/>

                    <p className={classes.updateErrorMessage}>
                        { Helpers.getRequestErrorMessage(userData.passwordUpdateError) }
                    </p>

                </div>

                <div style={{height: 30}}/>

                <p className={classes.smallTitle}>
                    Two Factor Authentication (2FA)
                </p>

                <div className={classes.buttonContainer}>
                    <Button
                        className={classes.updateButton}
                        onClick={handle2faSettings}
                        isLoading={isEnabling2fa}
                        inverted={!userData.data?.twoFactorEnabled}
                        text={
                            !userData.data?.twoFactorEnabled && isEnabling2fa ?
                            "Enabling..." :
                            !userData.data?.twoFactorEnabled && !isEnabling2fa ?
                            "Enable 2FA" :
                            userData.data?.twoFactorEnabled && isEnabling2fa ?
                            "Disabling..." :
                            userData.data?.twoFactorEnabled && !isEnabling2fa ?
                            "Disable 2FA" : ''
                        }/>
                </div>

                <div style={{height: 30}}/>

                <p className={classes.smallTitle}>
                    Connected MetaMask Wallets
                </p>

                <div className={classes.blockchainWalletsContainer}>
                    {
                        userAddressData?.userAddressList &&
                        userAddressData.userAddressList.map(item => 
                            <BlockchainWalletItem 
                                key={item.address}
                                address={item.address}
                                onDisconnectClicked={handleDisconnectWallet}/>
                        )
                    }
                </div>

                <div className={classes.buttonContainer}>
                    <Button
                        className={classes.updateButton}
                        onClick={handleConnectWallet}
                        isLoading={isConnecting}
                        inverted
                        text={
                            isConnecting ?
                            "Connecting..." :
                            "Connect wallet"
                        }/>
                </div>

                <div style={{height: 50}}/>

            </div>

        </div>
    );
}

export default ProfileSettings;