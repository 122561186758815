import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import UI from './UI/reducers';
import User from './User/reducers';
import HomePage from './HomePage/reducers';
import Discover from './Discover/reducers';
import Creation from './Creation/reducers';
import Profile from './Profile/reducers';
import DropsPage from './DropsPage/reducers';
import DropDetails from './DropDetails/reducers';
import UserData from './UserData/reducers';
import Blockchain from './Blockchain/reducers';
import MintRequest from './MintRequest/reducers';

const appReducer = combineReducers({
    UI,
    User,
    HomePage,
    Discover,
    Creation,
    Profile,
    DropsPage,
    DropDetails,
    UserData,
    Blockchain,
    MintRequest
});

export const store = createStore(
    appReducer,
    {},
    applyMiddleware(thunk)
)