import React from 'react';
import Images from '~/Assets/images';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as UserActions from '~/Redux/User/actions';

import PixelatedButton from '../PixelatedButton';
import HeaderProfileMenu from '../HeaderProfileMenu';

import { useStyles } from './styles';
import PopupManager from '~/Auxilary/PopupManager';
import ArrowDown from '~/Assets/Vectors/ArrowDown';
import { Fragment } from 'react';

const MainHeader = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [mobileMenuVisible, setMobileMenuVisible] = React.useState(false);
    const userData = useSelector(state => state.User);

    const handleToggleMobileMenu = () => {
        setMobileMenuVisible(!mobileMenuVisible);
    }

    const handleProcessAction = (action) => {
        setMobileMenuVisible(false);
        switch(action) {
            case MainHeader.Actions.LOGIN:
                PopupManager.pushLogin();
                break;

            case MainHeader.Actions.REGISTER:
                PopupManager.pushRegister();
                break;

            case MainHeader.Actions.LOGOUT:
                dispatch(UserActions.logout());
                break;

            default:
        }
    }

    return (
        props.isMobile ?
        <div className={classes.container + ' ' + classes.mobileContainer}>

            <div className={classes.mobileLogoContainer}>
                <Link 
                    to="/"
                    className={classes.logoLink}
                    style={{margin: 0}}>
                    <img 
                        alt="" 
                        className={classes.logo}
                        style={{marginRight: 0}}
                        src={Images.Logo}/>
                </Link>

                <ArrowDown 
                    className={classes.mobileArrow}
                    onClick={handleToggleMobileMenu} />
            </div> 
            
            <div 
                className={classes.mobileMenuItemsContainer}
                style={{
                    display: mobileMenuVisible ? 'flex' : 'none'
                }}>
                {
                    MainHeader.Pages
                    .filter(page => 
                        (
                            page.show === MainHeader.PageVisibility.ALWAYS 
                            || 
                            (
                                props.isLoggedIn && page.show === MainHeader.PageVisibility.LOGGED_IN && page[props.userType]
                            ) 
                            ||
                            (
                                !props.isLoggedIn && page.show === MainHeader.PageVisibility.LOGGED_OUT
                            )
                        )
                        && 
                        !page.DESKTOP_ONLY
                    )
                    .map(page => 
                        page.action ?
                        <div
                            key={page.name}
                            onClick={ handleProcessAction.bind(null, page.action) }
                            className={classes.menuItem + ' ' + classes.mobileItem}>
                            { page.name }
                        </div> :
                        <Link 
                            key={page.name}
                            to={page.link}
                            className={classes.menuItem + ' ' + classes.mobileItem}
                            onClick={() => {setMobileMenuVisible(false)}}>
                            {
                                page.icon ? 
                                <div className={classes.iconItemContainer + ' ' + classes.mobileIconItemContainer}>
                                    <img
                                        alt="" 
                                        className={classes.itemIcon}
                                        src={page.icon}/>

                                    { page.name }
                                </div>
                                :
                                page.name
                            }
                        </Link>
                    )
                }
                
                <a 
                    href="https://ido.vast.app/"
                    className={classes.menuItem + ' ' + classes.mobileItem}
                    style={{marginRight: 20}}>
                    IDO Page
                </a>

                {
                    props.isLoggedIn &&
                    <Fragment>
                        <div className={classes.mobileMenuDivider}/>
                        {
                            MainHeader.MobilePages
                            .filter(page => page[props.userType])
                            .map(page => 
                                page.action ?
                                <div
                                    key={page.name}
                                    onClick={ handleProcessAction.bind(null, page.action) }
                                    className={classes.menuItem + ' ' + classes.mobileItem}>
                                    { page.name }
                                </div> :
                                <Link 
                                    key={page.name}
                                    to={page.link}
                                    className={classes.menuItem + ' ' + classes.mobileItem}
                                    onClick={() => {setMobileMenuVisible(false)}}>
                                    { page.name }
                                </Link>
                            )
                        }
                    </Fragment>
                }

            </div>

        </div>
        :
        <div 
            className={classes.container} 
            style={{
                top: (!userData?.data || userData?.data?.verified) ? 0 : 25
            }}>

            <div className={classes.verificationBarContainer}>
                <Link
                    to="/profile/settings" 
                    className={classes.verificationBarText}>Please verify your account in order to start building your collection!</Link>
            </div>

            <Link 
                to="/"
                className={classes.logoLink}>
                <img 
                    alt="" 
                    className={classes.logo}
                    src={Images.Logo}/>
            </Link>

            <div className={classes.menuContainer}>
                {
                    MainHeader.Pages
                    .filter(page => 
                        (
                            page.show === MainHeader.PageVisibility.ALWAYS 
                            || 
                            (
                                props.isLoggedIn && page.show === MainHeader.PageVisibility.LOGGED_IN && page[props.userType]
                            ) 
                            ||
                            (
                                !props.isLoggedIn && page.show === MainHeader.PageVisibility.LOGGED_OUT
                            )
                        )
                        && !page.MOBILE_ONLY
                    )
                    .map(page => 
                        page.action ?
                        <div
                            key={page.name}
                            onClick={ handleProcessAction.bind(null, page.action) }
                            className={classes.menuItem}>
                            { page.name }
                        </div> :
                        <Link 
                            key={page.name}
                            to={page.link}
                            className={classes.menuItem}>
                            {
                                page.icon ? 
                                <div className={classes.iconItemContainer}>
                                    <img
                                        alt="" 
                                        className={classes.itemIcon}
                                        src={page.icon}/>

                                    { page.name }
                                </div>
                                :
                                page.name
                            }
                        </Link>
                    )
                }

                <a 
                    href="https://ido.vast.app/"
                    className={classes.menuItem}
                    style={{marginRight: 20}}>
                    IDO Page
                </a>
                
                {
                    props.isLoggedIn ?
                    <HeaderProfileMenu
                        username={props.username}
                        profilePictureUrl={props.profilePictureUrl}
                        role={props.userType}
                        onLogout={() => { 
                            handleProcessAction(MainHeader.Actions.LOGOUT)
                        }}/> :
                    <PixelatedButton
                        text="SIGN UP"
                        style={{
                            width: 140,
                            height: 35,
                            fontSize: 12,
                            margin: '0 20px'
                        }}
                        darkBackground
                        borderColor="#333"
                        foregroundColor={'white'}
                        onClick={() => { 
                            handleProcessAction(MainHeader.Actions.REGISTER)
                        }}/>
                }
            </div>

        </div>
    );
}

MainHeader.PageVisibility = Object.freeze({
    ALWAYS: 'ALWAYS',
    LOGGED_OUT: 'LOGGED_OUT',
    LOGGED_IN: 'LOGGED_IN'
});

MainHeader.Actions = Object.freeze({
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    REGISTER: 'REGISTER'
});

MainHeader.Pages = Object.freeze([
    {
        name: '🔥 DROPS',
        link: '/drops/',
        show: MainHeader.PageVisibility.ALWAYS,
    },
    {
        name: 'Discover',
        link: '/discover/',
        show: MainHeader.PageVisibility.ALWAYS
    },
    {
        name: 'Activity',
        link: '/discover/activity/',
        show: MainHeader.PageVisibility.ALWAYS
    },
    {
        name: 'Sign Up',
        action: MainHeader.Actions.REGISTER,
        show: MainHeader.PageVisibility.LOGGED_OUT,
        MOBILE_ONLY: true
    },
    {
        name: 'Login',
        action: MainHeader.Actions.LOGIN,
        show: MainHeader.PageVisibility.LOGGED_OUT,
    },
    {
        name: 'Upload Creations',
        link: '/profile/upload-creations',
        show: MainHeader.PageVisibility.LOGGED_IN,
        CREATOR: true,
        DESKTOP_ONLY: true
    },
    {
        name: 'Become a Creator',
        link: '/profile/become-creator',
        show: MainHeader.PageVisibility.LOGGED_IN,
        COLLECTOR: true,
        DESKTOP_ONLY: true
    },
    {
        name: 'Manage Collection',
        link: '/profile/manage-collection',
        show: MainHeader.PageVisibility.LOGGED_IN,
        COLLECTOR: true,
        CREATOR: true,
        DESKTOP_ONLY: true
    }
]);

MainHeader.MobilePages = Object.freeze([
    {
        name: 'Received Offers',
        link: `/profile/received-offers`,
        COLLECTOR: true,
        CREATOR: true
    },
    {
        name: 'Sent Offers',
        link: `/profile/sent-offers`,
        COLLECTOR: true,
        CREATOR: true
    },
    {
        name: 'Edit about section',
        link: `/profile/about/`,
        COLLECTOR: true,
        CREATOR: true
    },
    {
        name: 'Manage Drops',
        link: '/admin/drops',
        ADMIN: true
    },    
    {
        name: 'Creator requests',
        link: '/admin/creator-requests',
        ADMIN: true
    },   
    {
        name: 'Settings',
        link: `/profile/settings/`,
        COLLECTOR: true,
        CREATOR: true,
        ADMIN: true
    },
    {
        name: 'Logout',
        action: MainHeader.Actions.LOGOUT,
        COLLECTOR: true,
        CREATOR: true,
        ADMIN: true
    }
])

export default MainHeader;