import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {},
    title: {
        fontFamily: Theme.Font.Secondary,
        color: Theme.TextColor.Dark,
        fontSize: 28,
        margin: '0 0 5px 0'
    },
    price: {
        fontFamily: Theme.Font.Secondary,
        color: Theme.TextColor.Dark,
        fontWeight: 300,
        fontSize: 28,
        margin: '10px 0 10px 0',
        '& strong': {
            fontWeight: 400
        }
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    offerButton: {
        width: '100%',
        fontSize: 15,
        color: Theme.TextColor.Dark,
        fontWeight: 400,
        padding: 7
    },
    purchaseButton: {
        width: '100%',
        fontSize: 16,
        fontWeight: 400,
        padding: 10,
        margin: '20px 0 10px 0'
    },
    iconsContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '20px 0 20px 0'
    },
    divider: {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
        margin: '20px 0'
    }
});