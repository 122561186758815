import _ from "lodash";
import API from 'API/api';
import Config from "./config";

class Helpers {

    static getElapsedTimeString(to, from, endString = ' ago', lessThanMinuteString = 'just now') {

        if(_.isUndefined(from))
            from = Date.now();

        let differenceMinutes = Math.floor(
            Math.abs(
                new Date(to).getTime() - 
                new Date(from).getTime()
            ) / 60000
        );

        let differenceInUnits = {
            year: Math.floor(differenceMinutes / (60 * 24 * 365)),
            month: Math.floor(differenceMinutes / (60 * 24 * 30)),
            day: Math.floor(differenceMinutes / (60 * 24)),
            hour: Math.floor(differenceMinutes / 60),
            minute: differenceMinutes
        }

        for(let unit in differenceInUnits) {

            let valueForUnit = differenceInUnits[unit];
            let unitString = unit + (valueForUnit > 1 ? 's' : '');

            if(valueForUnit > 0)
                return `${valueForUnit} ${unitString}${endString}`;
        }

        return lessThanMinuteString;
    }
    
    static stringToHex(value) {
        return value
            .split('')
            .map(character => character.charCodeAt(0).toString(16))
            .join('');
    }

    static getTruncatedAddress(address) {
        if(!address)
            address = '0x0000000000000000000000000000000000000000';
            
        return address.substr(0, 10) + '...' + address.substr(-10);
    }

    static setEthPrice(value) {
        window.___ETH_USD_price = value;
    }

    static getEthPrice() {
        return window.___ETH_USD_price || 0;
    }

    static async updateCoinPrice() {
        let priceData = await API.get('/home/coin-prices');
        let coinData = priceData.find(item => item.symbol === Config.Blockchain.COIN);
        
        if(coinData)
            Helpers.setEthPrice(coinData.price)
    }

    static formatEthPrice(value) {
        let numberValue = parseFloat(value);
        numberValue = 
            Number.isNaN(numberValue) || !Number.isFinite(numberValue) ? 
            0 : numberValue;

        return numberValue.toFixed(4);
    }

    static formatUsdPrice(value) {
        let numberValue = parseFloat(value);
        numberValue = 
            Number.isNaN(numberValue) || !Number.isFinite(numberValue) ? 
            0 : numberValue;

        return numberValue.toFixed(2);
    }

    static weiToEth(value) {
        return (
            value ?
            parseFloat(window.ethers.utils.formatEther(value)) :
            0
        )
    }

    static getRequestErrorMessage(response) {
        if(!response || !_.isString(response))
            return response;

        const splitResponse = response.split(':');
        
        if(splitResponse.length > 0 && !splitResponse[0].includes(' '))
            return splitResponse.slice(1, splitResponse.length).join(':')
        else
            return response;
    }

    static getRequestErrorField(response) {
        if(!response || !_.isString(response))
            return response;

        const splitResponse = response.split(':');
        
        if(splitResponse.length > 0 && !splitResponse[0].includes(' '))
            return splitResponse[0];
        else
            return response;
    }

    static isSameAddress(addressA, addressB) {
        return !!(
            addressA && addressB && 
            addressA.toLowerCase() === addressB.toLowerCase()
        )
    }
}

export default Helpers;