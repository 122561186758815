import API from './api';

class UserData {
    
    static async getOwnedCreations() {
        return API.get( 
            `/user-data/owned-creations`, 
            true
        );
    }

    static async getOffers(incoming, status) {
        let queryParams = 
            API.buildQueryParamString(
                {
                    incoming,
                    status
                }
            );

        return API.get(
            `/user-data/offers${queryParams}`,
            true
        );
    }

    static async acceptOffer(offerId, signatureData) {
        return API.post(
            `/user-data/offers/${offerId}/accept`, 
            { signatureData },
            true
        );
    }

    static async declineOffer(offerId) {
        return API.post(
            `/user-data/offers/${offerId}/decline`, 
            { },
            true
        );
    }

    static async getSales(purchases) {
        let queryParams = 
            API.buildQueryParamString(
                {
                    purchases
                }
            );

        return API.get(
            `/user-data/sales${queryParams}`, 
            true
        );
    }

    static async setPrice(creationId, acceptOffers, signatureData) {
        return API.post(
            `/user-data/owned-creations/${creationId}/price`, 
            {
                acceptOffers,
                signatureData
            },
            true
        );
    }

    static async getAddresses() {
        return API.get(
            `/user-data/addresses`, 
            true
        );
    }
    
    static async postAddress(signatureData) {
        return API.post(
            `/user-data/addresses`, 
            { signatureData },
            true
        );
    }

    static async deleteAddress(address) {
        return API.delete(
            `/user-data/addresses/${address}`, 
            true
        );
    }

    static async listMedia() {
        return API.get(
            `/user-data/media`, 
            true
        );
    }

    static async getMedia(mediaId) {
        return API.get(
            `/user-data/media/${mediaId}`, 
            true
        );
    }
}

export default UserData;