import React from 'react';
import { useStyles } from './styles';
import Images from '~/Assets/images';

const PopupContainer = (props) => {

    const classes = useStyles();

    return (
        props.visible ?
        <div className={classes.backdrop}>
            <div className={classes.messageContainer}>
                <div 
                    className={classes.loader}
                    style={{ backgroundImage: `url(${Images.Loader}`}}/>

                <p className={classes.loadingText}>
                    Loading data...
                </p>
            </div>
        </div>:
        null
    );
}

export default PopupContainer;