import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    pageContainer: {
        minHeight: '100vh',
        padding: '100px 100px 0px 100px'
    },
    pageBody: {
        flex: 1,
        padding: '10px 60px 0px 60px'
    },
    topSection: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 50
    },
    previewContainer: {
        flex: 7,
        padding: '10px'
    },
    infoContainer: {
        flex: 5,
        padding: '0 20px 0 20px'
    },
    tableContainer: {
        padding: '0px 120px',
        margin: '50px 0'
    },
    noActivity: {
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        textAlign: 'center',
        margin: 50,
        color: Theme.TextColor.Dark
    },
    '@media only screen and (max-width: 1024px)': {
        pageContainer: {
            padding: '80px 20px 0px 20px'
        }
    }
});