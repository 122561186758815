import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Config from '~/config';

import * as UserDataActions from '~/Redux/UserData/actions';
import { STATE_NOT_PROCESSED } from '~/Redux/states';
import { useHistory } from 'react-router-dom';

import CreationTableHeader from '~/Components/CreationTableHeader';
import SettingsCreationItem from '~/Components/SettingsCreationItem';
import NoContentInfoBox from '~/Components/NoContentInfoBox';

import Images from '~/Assets/images';
import { useStyles } from './styles';
import MetaMask from '~/Services/MetaMask';

const OnSale = () => {

    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const userData = useSelector(state => state.UserData);

    const [dataReloadRequired, setDataReloadRequired] = React.useState(true);
    const [unavailableResources, setUnavailableResources] = React.useState([]);

    React.useEffect(
        () => {
            if (userData.ownedCreationsState === STATE_NOT_PROCESSED || dataReloadRequired) {
                dispatch(UserDataActions.getOwnedCreations());
                setDataReloadRequired(false);
                setUnavailableResources(MetaMask.getUnavailableResources('CREATIONS'));
            }
        }, 
        [ userData, dataReloadRequired, dispatch ]
    );

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Manage your collection
            </p>

            <div style={{height: 30}}/>

            {
                userData.ownedCreations && 
                userData.ownedCreations.length > 0 ? 
                <Fragment>
                    <CreationTableHeader
                        itemStyle={classes.headerItem}
                        headerItems={[
                            { text: 'Creation', flex: 5},
                            { text: 'Status', flex: 2},
                            { text: 'Price / Bid', flex: 3}
                        ]}/>
                    
                    <div className={classes.itemsContainer}>
                        {
                            userData.ownedCreations
                                .map(creationData =>
                                    <SettingsCreationItem
                                        key={creationData.creationId}
                                        creationId={creationData.creationId}
                                        name={creationData.name}
                                        previewUrl={creationData?.media?.image?.url}
                                        edition={creationData?.release?.number}
                                        status={Config.CreationStatus[creationData.status] || 'Unknown'}
                                        price={
                                            creationData.status === 'AUCTION' ?
                                            creationData.auction?.lastBidAmount :
                                            creationData.price
                                        
                                        }
                                        descriptionFlex={5}
                                        statusFlex={2}
                                        priceColumnFlex={3}
                                        onManageClicked={
                                            !unavailableResources.includes(creationData.creationId) ?
                                            (creationToManage) => {
                                                history.push(`/profile/manage-collection/${creationToManage}`)
                                            }
                                            : null
                                        }/>
                            )
                        }
                    </div>
                </Fragment> :
                <NoContentInfoBox
                    icon={Images.EmptyGallery}
                    text={'You have not collected any creations yet'}/>
            }

            <div style={{height: 50}}/>

        </div>
    );
}

export default OnSale;