import React, { Fragment } from 'react';
import PopupManager from '~/Auxilary/PopupManager';
import Button from '~/Components/Button';
import LabeledInputField from '~/Components/LabeledInputField';
import Config from '~/config';
import Helpers from '~/helpers';
import MetaMask from '~/Services/MetaMask';
import { useStyles } from './styles';

const AuctionCreation = (props) => {

    const classes = useStyles();
    const [auctionTime, setAuctionTime] = React.useState('');
    const [priceEth, setPriceEth] = React.useState('');
    const [priceUsd, setPriceUsd] = React.useState(0); 
    const [canAuction, setCanAuction] = React.useState(false); 

    React.useEffect(
        () => {
            let isValidTime = 
                (auctionTime === parseInt(auctionTime).toString()) && 
                (parseInt(auctionTime) > 0) &&
                (parseInt(auctionTime) <= Config.Platform.MAX_AUCTION_TIME_DAYS * 24);

            let isValidValue = priceEth === parseFloat(priceEth).toString();
            setCanAuction(isValidTime && isValidValue)
        },
        [auctionTime, priceEth, setCanAuction]
    )

    const handlePriceChanged = (value) => {
        setPriceEth(value);
        setPriceUsd(value * Helpers.getEthPrice());
    }

    const handleAuctionClicked = () => {

        let ethAmount = window.ethers.utils.parseEther(String(priceEth));

        MetaMask
            .getMarketContract()
            .auctionToken(props.tokenId, parseInt(auctionTime) * 60 * 60, ethAmount.toHexString())
            .then(() => {
                PopupManager.pushMessagePopup(
                    'Transaction sent', 
                    ['The transaction for creating an auction for your token has been sent. Please give the platform a couple of minutes to synchronize with the blockchain!'], 
                    'PASS'
                )
            })
            .catch((error) => {
                PopupManager.pushMessagePopup(
                    'Auction failed',
                    [ 
                        `Failed to create an auction for your token. ${
                            error.error?.message?.split('execution reverted: ').join('') ||
                            error.message.split('MetaMask Tx Signature: ').join('')
                        }`
                    ],
                    'FAIL'
                );
            })
    }

    const handleCancelAuctionClicked = () => {
        MetaMask
            .getMarketContract()
            .cancelAuction(props.tokenId)
            .then(() => {
                PopupManager.pushMessagePopup(
                    'Transaction sent', 
                    ['The transaction for cancelling your auction has been sent. Please give the platform a couple of minutes to synchronize with the blockchain!'], 
                    'PASS'
                )
            })
            .catch((error) => {
                PopupManager.pushMessagePopup(
                    'Cancellation failed',
                    [ 
                        `Failed to cancel the auction for your token. ${
                            error.error?.message?.split('execution reverted: ').join('') ||
                            error.message.split('MetaMask Tx Signature: ').join('')
                        }`
                    ],
                    'FAIL'
                );
            })
    }

    return (
        <div className={classes.container}>

            <p className={classes.sectionTitle}>
                Auction this creation
            </p>

            {
                props.isInAuction ?
                <Fragment>
                    <p className={classes.instructionText}>
                        This creation is currently in auction. While the creation is in auction, you cannot set its price, or gift it. If you haven't received any bids yet, you can cancel this auction below.
                    </p>

                    <div className={classes.invalidateControls}>
                        <div style={{flex: 1, alignItems: 'center'}}>
                        {
                            props.lastBidAmount &&
                            <p className={classes.inputLabel} style={{margin: 0}}>
                                Last bid: <strong>{
                                    Helpers.weiToEth(props.lastBidAmount) ? 
                                    `${Helpers.formatEthPrice(Helpers.weiToEth(props.lastBidAmount))} ${Config.Blockchain.COIN}` :
                                    `--`
                                }</strong>
                            </p>
                        }
                        </div>
                        <Button
                            className={classes.submitButton}
                            disabled={!!Helpers.weiToEth(props.lastBidAmount)}
                            onClick={handleCancelAuctionClicked}
                            text="Cancel auction"/>
                    </div>
                </Fragment>
                :
                <Fragment>
                    <p className={classes.instructionText}>
                        This tab enables you to auction your creation. In an auction, you can set the auction time, as well as the starting price. Collectors will start bidding at the starting price, and may continue to outbid each other until the specified time period runs out. 
                    </p>

                    <p className={classes.instructionText}>
                        Once the auction is finished, either you or the frontrunning collector should close the auction in order to finallize the transfer. 
                    </p>

                    <p className={classes.inputLabel}>
                        Starting price:
                    </p>

                    <LabeledInputField
                        className={classes.priceField}
                        onChange={handlePriceChanged}
                        placeholder={`Starting price in ${Config.Blockchain.COIN}`}
                        labelText={`$ ${Helpers.formatUsdPrice(priceUsd)}`}
                        value={priceEth}/>

                    <p className={classes.inputLabel}>
                        {`Auction time in hours. Max: ${Config.Platform.MAX_AUCTION_TIME_DAYS * 24} hours (${Config.Platform.MAX_AUCTION_TIME_DAYS} days)`}
                    </p>

                    <input
                        className={classes.inputField}
                        onChange={(event) => {setAuctionTime(event.target.value)}}
                        type="number"
                        min="1"
                        placeholder="Auction time in hours"
                        value={auctionTime}/>

                    <div className={classes.invalidateControls}>
                        <div style={{flex: 1}}/>
                        <Button
                            className={classes.submitButton}
                            disabled={!canAuction}
                            onClick={handleAuctionClicked}
                            text="Auction creation"/>
                    </div>
                </Fragment>
            }

        </div>
    );
}

export default AuctionCreation;