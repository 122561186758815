import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    pageContainer: {
        flex: 1,
        backgroundColor: 'white',
        padding: '120px 20px 0px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    contentBody: {
        maxWidth: 900,
        '& p': {
            fontFamily: Theme.Font.Primary,
            lineHeight: 1.5,
            fontSize: 15
        },
        '& li': {
            fontFamily: Theme.Font.Primary,
            lineHeight: 1.5,
            fontSize: 15
        },
        '& strong': {
            marginTop: 20
        }
    }
});