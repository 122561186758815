import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    container: {
        aspectRatio: 1,
        margin: '5px 15px'
    },
    image: {
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        border: '1px solid black',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    },
    imageHoverBorder: {
        border: '1px solid black',
        transition: '0.2s ease',
        '&:hover': {
            borderColor: 'white'
        }
    }
});