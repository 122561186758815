import React, { Fragment } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as UserActions from '~/Redux/User/actions';
import { STATE_NOT_PROCESSED } from '~/Redux/states';

import MainHeader from '~/Components/MainHeader';
import ProfilePicture from '~/Components/ProfilePicture';
import ProfileSettings from './ProfileSettings';
import ProfileAbout from './ProfileAbout';
import OnSale from './OnSale';
import Offers from './Offers';
import MintRequest from './MintRequest';
import SaleHistory from './SaleHistory';
import PurchaseHistory from './PurchaseHistory';
import ManageCreation from './ManageCreation';
import MintRequestDetails from './MintRequestDetails';

import { useStyles } from './styles';
import YourCreations from './YourCreations';
import MediaDetails from './MediaDetails';
import ManageDrops from './ManageDrops';
import EditDrop from './EditDrop';
import CreatorRequests from './CreatorRequests';
import CreatorRequest from './CreatorRequest';
import CreatorRequestsDetails from './CreatorRequestsDetails';
import ManageContract from './ManageContract';
import ManageFeatured from './ManageFeatured';

const SettingsPage = () => {

    const classes = useStyles();
    const { page, resourceId } = useParams();

    const dispatch = useDispatch();
    const userData = useSelector(state => state.User);
    const pageWidth = useSelector(state => state.UI.pageWidth);

    React.useEffect(
        () => {
            if (!userData.data && userData.token && 
                userData.fetchDataState === STATE_NOT_PROCESSED) {       
                dispatch(UserActions.getData());
            }
        },
        [userData, dispatch]
    )

    if(userData && !userData.token)
        return <Redirect to='/'/>

    return (
        <Fragment>
            
            <MainHeader
                username={userData.data?.username}
                profilePictureUrl={userData.data?.profilePictureUrl}
                userType={userData.data?.role}
                isLoggedIn={!!userData.data}
                isMobile={pageWidth < 800}/>

            <div className={classes.pageContainer}>
                    
                <div className={classes.sideMenuContainer}>

                    <div style={{height: 30}}/>

                    <ProfilePicture
                        username={userData.data?.username}
                        imageUrl={userData.data?.profilePictureUrl}
                        highlightOnHover
                        size={60}/>

                    <div className={classes.menuContainer}>

                        {
                            SettingsPage.MenuItems
                                .filter(item => item[userData.data?.role])
                                .map((item, index) =>
                                    item.isDivider ?
                                    <div 
                                        key={`div_${index}`}
                                        className={classes.menuDividerItem}/> 
                                    :
                                    <Link 
                                        className={
                                            item.value === page ?
                                            classes.menuItem + ' ' + classes.menuItemSelected : 
                                            classes.menuItem
                                        }
                                        key={`div_${item.url}`}
                                        to={item.url}>
                                        { item.text }
                                    </Link>
                                )
                        }

                        {
                            userData.data?.role !== 'ADMIN' &&
                            <Link 
                                className={classes.menuItem}
                                to={`/profiles/${userData.username}`}>
                                View Your Profile
                            </Link>

                        }
                    
                    </div>

                </div>
                    
                <div style={{height: 30}}/>

                <div className={classes.contentsPage}>
                    

                    {
                        page === 'manage-creations' && !resourceId ?
                        <YourCreations/>
                        :
                        page === 'manage-creations' && resourceId ?
                        <MediaDetails mediaId={resourceId}/>
                        :
                        page === 'manage-collection' && !resourceId ?
                        <OnSale/>
                        :
                        page === 'manage-collection' && resourceId ?
                        <ManageCreation creationId={resourceId}/>
                        :
                        page === 'received-offers' ?
                        <Offers showReceived/>
                        :
                        page === 'sent-offers' ?
                        <Offers/>
                        :
                        page === 'purchase-history' ?
                        <PurchaseHistory/>
                        :
                        page === 'sale-history' ?
                        <SaleHistory/>
                        :
                        page === 'upload-creations' ?
                        <MintRequest/>
                        :
                        page === 'upload-creation' ?
                        <MintRequestDetails/>
                        :
                        page === 'settings' ?
                        <ProfileSettings/>
                        :
                        page === 'become-creator' ?
                        <CreatorRequest/>
                        :
                        page === 'drops' && !resourceId ?
                        <ManageDrops/>
                        :
                        page === 'drops' && resourceId ?
                        <EditDrop dropId={resourceId}/>
                        :
                        page === 'creator-requests' && !resourceId ?
                        <CreatorRequests/>
                        :
                        page === 'creator-requests' && resourceId ?
                        <CreatorRequestsDetails username={resourceId}/> 
                        :
                        page === 'manage-contract' ?
                        <ManageContract/> 
                        :
                        page === 'manage-featured' ?
                        <ManageFeatured/> 
                        :
                        null
                    }

                    {
                        page === 'about' &&
                        <ProfileAbout/>
                    }

                </div>

            </div>

        </Fragment>
    );
}

SettingsPage.MenuItems = [
    {
        text: 'Your Creations',
        value: 'manage-creations',
        url: '/profile/manage-creations',
        CREATOR: true
    },
    {
        text: 'Manage collection',
        value: 'manage-collection',
        url: '/profile/manage-collection',
        COLLECTOR: true,
        CREATOR: true
    },
    {
        text: 'Upload creations',
        value: 'upload-creations',
        url: '/profile/upload-creations',
        CREATOR: true
    },
    {
        text: 'Received Offers',
        value: 'received-offers',
        url: '/profile/received-offers',
        COLLECTOR: true,
        CREATOR: true
    },
    {
        text: 'Sent Offers',
        value: 'sent-offers',
        url: '/profile/sent-offers',
        COLLECTOR: true,
        CREATOR: true
    },
    {
        text: 'Sale History',
        value: 'sale-history',
        url: '/profile/sale-history',
        COLLECTOR: true,
        CREATOR: true
    },    
    {
        text: 'Purchase History',
        value: 'purchase-history',
        url: '/profile/purchase-history',
        COLLECTOR: true,
        CREATOR: true
    },
    {
        text: 'Manage Drops',
        value: 'drops',
        url: '/admin/drops',
        ADMIN: true
    },
    {
        text: 'Creator requests',
        value: 'creator-requests',
        url: '/admin/creator-requests',
        ADMIN: true
    },
    {
        text: 'Manage Contract',
        value: 'manage-contract',
        url: '/admin/manage-contract',
        ADMIN: true
    },
    {
        text: 'Manage Featured',
        value: 'manage-featured',
        url: '/admin/manage-featured',
        ADMIN: true
    },
    {
        isDivider: true,
        COLLECTOR: true,
        CREATOR: true,
        ADMIN: true
    },
    {
        text: 'About',
        value: 'about',
        url: '/profile/about',
        COLLECTOR: true,
        CREATOR: true
    },
    {
        text: 'Account Settings',
        value: 'settings',
        url: '/profile/settings',
        COLLECTOR: true,
        CREATOR: true,
        ADMIN: true
    },
    {
        text: 'Become a Creator',
        value: 'become-creator',
        url: '/profile/become-creator',
        COLLECTOR: true
    }
]

export default SettingsPage;