import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        width: 500,
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 30,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        textAlign: 'center',
        margin: '25px 0'
    },
    infoMessage: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        margin: '10px 20px'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 20
    },
    button: {
        width: 150,
        padding: 10,
        fontSize: 15,
        margin: '5px 10px'
    },
    inputField: {   
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        padding: 10,
        border: '1px solid #ccc',
        margin: '3px 20px',
        resize: 'vertical'
    },
    checkboxContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '15px 20px'
    },
    checkbox: {
        marginRight: 10
    },
    checkboxLabel: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300
    },
    '@media only screen and (max-width: 500px)': {
        container: {
            width: '100%'
        }
    },
});