import React from 'react';
import { useStyles } from './styles';

const LabeledIcon = (props) => {
    const classes = useStyles();

    return (
        <div 
            className={ 
                props.clickable ? 
                classes.container + ' ' + classes.clickable :
                classes.container
            }
            onClick={props.onClick}>
            { 
                !props.icon ? null :
                React.createElement(
                    props.icon,
                    { 
                        className: 
                            props.active ?    
                            classes.icon + ' ' + classes.activeIcon :
                            classes.icon
                    }
                )
            }

            <p className={classes.text}>
                {props.text}
            </p>
        </div>
    );
}

export default LabeledIcon;