const Theme = {
    Palette: {
        Primary: {
            // Foreground: '#E65B54',
            // Background: '#EAE8DD'
            Foreground: '#000000',
            Background: '#F7F7F7'
        },
        Secondary: {
            Foreground: '#000000',
            Background: '#F5F5F5'
        },
    },
    Background: {
        Normal: '#F7F7F7',
        Light: '#F5F5F5',
        Lighter: '#EDEDED',
        Lightest: '#F5F5F5'
    },
    TextColor: {
        Dark: '#303030',
        Light: '#808080',
        Lighter: '#aeaeae',
        Lightest: '#e5e5e5'
    },
    Font: {
        Primary: 'Noto Sans TC, sans-serif',
        Secondary: 'Terminal Grotesque, sans-serif'
    }
}

export default Theme;