import React from 'react';
import _ from 'lodash';

import PopupContainer from '../PopupContainer';
import Button from '~/Components/Button';

import { useStyles } from './styles';
import Images from '~/Assets/images';

const MessagePopup = (props) => {

    const classes = useStyles();

    const handleClose = (confirm) => {
        if(_.isFunction(props.onClose))
            props.onClose(confirm);
    }

    return (
        <PopupContainer
            isOpen={props.isOpen}
            onClose={handleClose.bind(null, false)}
            backdropColor="#0009">

            <div className={classes.container}>
                <div className={classes.titleContainer}>

                    {
                        props.icon &&
                        <img
                            alt=""
                            className={classes.titleIcon}
                            src={
                                props.icon === 'PASS' ?
                                Images.Verified :
                                props.icon === 'FAIL' ?
                                Images.Unverified :
                                ''
                            }/>
                    }
                    
                    <p className={classes.title}>
                        { props.title }
                    </p>
                </div>

                {
                    props.messageLines.map((text, index) => 
                        <p 
                            key={`line_${index}`}
                            className={classes.infoMessage}>
                            {text}
                        </p>
                    )
                }

                <div className={classes.buttonContainer}>

                    {
                        props.isPrompt && 
                        <Button
                            className={classes.button}
                            inverted={props.primaryConfirm}
                            text={props.acceptText || 'OK'}
                            onClick={handleClose.bind(null, true)}/>
                    }

                    <Button
                        className={classes.button}
                        text={props.denyText || 'OK'}
                        secondary={props.denySecondary}
                        onClick={handleClose.bind(null, false)}/>


                </div>

            </div>

        </PopupContainer>
    );
}

export default MessagePopup;