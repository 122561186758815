import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as CreationActions from '~/Redux/Creation/actions';
import { STATE_DONE, STATE_NOT_PROCESSED } from '~/Redux/states';

import { useStyles } from './styles';
import CreationMediaView from '~/Components/CreationMediaView';
import UserCollections from '~/Components/UserCollections';
import MetamaskInstructions from '~/Components/MetamaskInstructions';
import SetPrice from './SetPrice';
import InvalidateApproval from './InvalidateApproval';
import GiftCreation from './GiftCreation';
import MetaMask from '~/Services/MetaMask';
import { useHistory } from 'react-router-dom';
import Config from '~/config';
import AuctionCreation from './AuctionCreation';

const ManageCreation = (props) => {

    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const creationData = useSelector(state => state.Creation.creationData);
    const creationDataState = useSelector(state => state.Creation.creationDataState);
    const blockchainState = useSelector(state => state.Blockchain.stateChangedTrigger);

    const [dataReloadRequired, setDataReloadRequired] = React.useState(true);
    const [invalidationIgnored, setInvalidationIgnored] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState(0);

    const [isLoadingData, setIsLoadingData] = React.useState(true);
    const [isProcessingTransaction, setIsProcessingTransaction] = React.useState(false);
    const [isMetamaskEnabled, setIsMetamaskEnabled] = React.useState(false);
    const [isInNetwork, setIsInNetwork] = React.useState(false);
    const [isInAddress, setIsInAddress] = React.useState(false);
    const [isApprovedMarket, setIsApprovedMarket] = React.useState(false);
    const [isApprovalRequested, setIsApprovalRequested] = React.useState(false);
    const [invalidationRequired, setInvalidationRequired] = React.useState(false);
    const [isApprovingMarket, setIsApprovingMarket] = React.useState(false);
    const [isInvalidatingApproval, setIsInvalidatingApproval] = React.useState(false);

    React.useEffect(
        () => {
            if (creationDataState === STATE_NOT_PROCESSED || dataReloadRequired ||
               (creationData && props.creationId !== creationData.creationId)) {
                dispatch(CreationActions.getCreationData(props.creationId));
                setDataReloadRequired(false);
            }
        }, 
        [props.creationId, creationData, creationDataState, dataReloadRequired, dispatch]
    )

    React.useEffect(
        () => {
            if (creationDataState === STATE_DONE) {
            
                if(creationData.status === 'AUCTION')
                    setCurrentTab(2);

                if(MetaMask.getMarketContract() && isInNetwork) {
                    MetaMask
                        .getMarketContract()
                        .tokenApprovalId(creationData.blockchain.tokenId)
                        .then(approvalId => {
                            setInvalidationRequired(
                                approvalId.toString() === creationData.blockchain.lastApprovalId.toString()
                            )
                        })
                        .catch(() => {})
                }

                setIsProcessingTransaction(MetaMask.isProcessingTransaction())
                setIsMetamaskEnabled(MetaMask.isAvailable());
                setIsInNetwork(MetaMask.isInNetwork(creationData.blockchain.network)); 
                setIsInAddress(MetaMask.isCurrentAddress(creationData.blockchain.ownerAddress));
                setIsApprovedMarket(MetaMask.isMarketApproved());
                setIsLoadingData(MetaMask.isLoading());
            }
        }, 
        [creationData, creationDataState, blockchainState, isInNetwork, dispatch]
    )

    const handleGiftCreation = (address) => {
        setIsProcessingTransaction(true);
        MetaMask
            .getMarketContract()
            .gift(
                address,
                creationData?.blockchain?.tokenId
            )
            .then(txData => {
                MetaMask.addPendingTransaction(
                    txData.hash, 
                    'Gift Creation', 
                    'CREATIONS', 
                    creationData?.creationId
                );

                history.push(`/profile/manage-collection`);
            })
            .catch(() => {});
    }

    const handleApproveMarket = () => {
        setIsApprovingMarket(true);
        MetaMask
            .getTokenContract()
            .setApprovalForAll(
                Config.Blockchain.MARKET,
                true
            )
            .then(txData => {
                MetaMask.addPendingTransaction(txData.hash);
                setIsApprovingMarket(false);
            })
            .catch(() => {
                setIsApprovingMarket(false);
            });
    }

    const handleInvalidateApproval = () => {
        setIsInvalidatingApproval(true);
        MetaMask
            .getMarketContract()
            .invalidateApproval(
                creationData?.blockchain?.tokenId
            )
            .then(txData => {
                MetaMask.addPendingTransaction(txData.hash);
                setIsApprovalRequested(true);
                setIsInvalidatingApproval(false);
            })
            .catch(() => {
                setIsInvalidatingApproval(false);
            });
    }

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Manage creation
            </p>

            <div style={{height: 30}}/>

            <div className={classes.bodyContainer}>
            
                <div className={classes.purchaseContainer}>
                {
                    !isMetamaskEnabled || !isInNetwork || !isInAddress || isLoadingData || (isProcessingTransaction || (isApprovalRequested && !isApprovedMarket)) || !isApprovedMarket ?
                    <MetamaskInstructions
                        isEnabled={isMetamaskEnabled}
                        isInNetwork={isInNetwork}
                        isInAddress={isInAddress}
                        isLoadingData={isLoadingData}
                        isProcessingTransaction={isProcessingTransaction || (isApprovalRequested && !isApprovedMarket)}
                        isApprovedMarket={isApprovedMarket}
                        onApproveClicked={handleApproveMarket}
                        isApproving={isApprovingMarket}
                        requiredNetwork={MetaMask.getNetworkNameById(creationData?.blockchain?.network)}
                        requiredAddress={creationData?.blockchain?.ownerAddress}/>
                    :
                    <Fragment>
                        <UserCollections
                            collectionItems={[
                                'Sale settings',
                                'Gift creation',
                                'Auction creation'
                            ]}
                            disabled={creationData?.status === 'AUCTION'}
                            style={{margin: '15px 5px'}}
                            onSelectionChanged={setCurrentTab}
                            defaultItem={currentTab}/>

                        {
                            currentTab === 0 &&
                            <Fragment>
                                {
                                    invalidationRequired &&
                                    <InvalidateApproval
                                        onIgnoreChanged={setInvalidationIgnored}
                                        onInvalidateClicked={handleInvalidateApproval}
                                        isInvalidatingApproval={isInvalidatingApproval}/> 
                                }
                        
                                <SetPrice
                                    creationId={creationData?.creationId}
                                    currentPrice={creationData?.price || ''}
                                    tokenId={creationData?.blockchain?.tokenId}
                                    approvalId={creationData?.blockchain?.approvalId}
                                    disabled={invalidationRequired && !invalidationIgnored}/>
                            </Fragment>
                        }

                        {
                            currentTab === 1 &&
                            <GiftCreation onGiftClicked={handleGiftCreation}/>
                        }

                        {
                            currentTab === 2 &&
                            <AuctionCreation 
                                tokenId={creationData?.blockchain?.tokenId}
                                isInAuction={creationData?.status === 'AUCTION'}
                                lastBidAmount={creationData?.auction.lastBidAmount}/>
                        }
                    </Fragment>
                }
                </div>

                <div className={classes.previewContainer}>

                    <p className={classes.creationTitle}>
                        {creationData?.name}
                    </p>

                    <div className={classes.descriptionContainer}>

                        <div className={classes.descriptionAuthor}>
                            {creationData?.creators.map(item => item.name).join(', ')}
                        </div>

                        <div className={classes.descriptionEdition}>
                            Edition: {creationData?.release.number || 0} of {creationData?.release.count || 0}
                        </div>

                    </div>

                    <CreationMediaView
                        imageUrl={creationData?.media.image.url}
                        videoUrl={creationData?.media.video.url}
                        isVideo={!!creationData?.media.video.url}/>

                </div>

            </div>

            <div style={{height: 50}}/>

        </div>
    );
}

export default ManageCreation;