import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    overlay: {
        position: 'absolute',
        top: 0, 
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: Theme.Background.Normal,
        opacity: 0.5
    }
});