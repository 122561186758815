import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';

import PlayIcon from '~/Assets/Vectors/PlayIcon';
import { useStyles } from './styles';

const MediaItem = (props) => {
    const history = useHistory();
    const classes = useStyles();

    const containerRef = React.useRef();
    const [contentZoom, setContentZoom] = React.useState(1);
    const [isFullWidth, setIsFullWidth] = React.useState(true);
    const [hoveredOver, setHoveredOver] = React.useState(false);
    const [videoLoaded, setVideoLoaded] = React.useState(false);
    
    const handleItemClicked = () => {
        if(props.mediaId)
            history.push(`/profile/manage-creations/${props.mediaId}`)
    }

    const handleVideoLoaded = (event) => {
        setVideoLoaded(true);
        setIsFullWidth(!props.squareSize || event.target.videoWidth < event.target.videoHeight);
    }

    const handleImageLoaded = (event) => {
        setIsFullWidth(!props.squareSize || event.target.naturalWidth < event.target.naturalHeight);

        let itemDimensions = {
            width: 100, 
            height: event.target.naturalHeight * 100 / event.target.naturalWidth
        }

        if(_.isFunction(props.onMediaLoaded))
            props.onMediaLoaded(props.mediaId, itemDimensions);
    }

    React.useEffect(() => {
        if(containerRef.current) {
            let zoomLevel = containerRef.current.clientWidth / 300
            setContentZoom(
                zoomLevel > 1 ? 1 :
                zoomLevel < 0.5 ? 0.5 :
                zoomLevel
            )
        }
    }, [containerRef, props.squareSize])

    return (
        <div 
            className={
                props.className ?
                classes.wrapperContainer + ' ' + props.className :
                classes.wrapperContainer
            }
            style={
                props.squareSize ? 
                {
                    width: props.squareSize,
                    minWidth: props.squareSize,
                } : {}
            }>
            <div 
                ref={containerRef}
                className={
                    props.squareSize ?
                    classes.squareContainer :
                    classes.container
                }
                style={
                    props.squareSize ? 
                    {
                        width: props.squareSize,
                        height: props.squareSize,
                        minWidth: props.squareSize,
                        minHeight: props.squareSize,
                    } : {}
                }
                onMouseEnter={() => { setHoveredOver(true) }}>

                {
                    videoLoaded ? null :
                    <img
                        className={
                            isFullWidth ?
                            classes.fullWidth :
                            classes.fullHeight
                        }
                        onLoad={handleImageLoaded}
                        src={props.imageUrl}
                        alt=""/> 
                }

                {
                    props.videoUrl && (!props.playOnHover || hoveredOver) ?
                    <video 
                        className={
                            isFullWidth ?
                            classes.fullWidth :
                            classes.fullHeight
                        } 
                        style={{display: videoLoaded ? 'block' : 'none'}}
                        onContextMenu={(e) => {e.preventDefault()}}
                        controlsList="nodownload"
                        disablePictureInPicture 
                        playsInline 
                        autoPlay 
                        muted
                        loop
                        onLoadedData={handleVideoLoaded}>
                        <source src={props.videoUrl} type="video/mp4"/>
                    </video> : null
                }

                {
                    props.playOnHover && !hoveredOver ?
                    <div className={classes.playOnHoverContainer}>
                        <PlayIcon className={classes.playOnHoverIcon}/>
                    </div> : null
                }

                <div 
                    className={classes.infoOverlayContainer}
                    style={{zoom: contentZoom}}>
                        
                    <div 
                        onClick={handleItemClicked}
                        className={
                            props.defaultOpen ?
                            classes.infoContainer :
                            classes.infoContainer + ' ' + classes.showOnHover
                        }>

                        <div className={classes.infoMarginContainer}>

                            <div className={classes.detailsContainer}>
                                <Link 
                                    to={`/profile/manage-creations/${props.mediaId}`}
                                    className={classes.title}>
                                    {props.itemName}
                                </Link>
                                
                                
                                <div>
                                    <p className={classes.edition}>
                                        {
                                            props.editions > 1 ?
                                            `${props.editions} editions` :
                                            `One Edition`
                                        }
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {
                <div className={classes.statusContainer}>
                    <div className={classes.status}>
                        { props.status }
                    </div>
                </div>
            }
        </div>
    );
}

export default MediaItem;