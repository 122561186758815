import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        margin: '15px 10px 10px 10px',
        padding: '10px 0',
        borderBottomColor: Theme.TextColor.Lightest
    },
    headerItem: {
        flex: 1,
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        margin: 0,
        fontSize: 15,
        fontWeight: 400,
        '& p': {
            margin: 0
        }
    }
});