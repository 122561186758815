import * as API from '~/API';
import LocalStorage from '~/API/local_storage';

import { 
    CREATION_LIKE_STATE_CHANGED,
    CREATION_OWNER_FOLLOWING_STATE_CHANGED 
} from '../Creation/types';

import { 
    PROFILE_FOLLOWING_STATE_CHANGED 
} from '../Profile/types';

import {
    USER_LOGIN_START,
    USER_LOGIN_FINISHED,
    USER_LOGIN_FAILED,
    USER_LOGIN_CLEAR,
    USER_DATA_FETCH_START,
    USER_DATA_FETCH_FINISHED,
    USER_DATA_FETCH_FAILED,
    USER_DATA_UPDATE_START,
    USER_DATA_UPDATE_FINISHED,
    USER_DATA_UPDATE_FAILED,
    USER_PASSWORD_UPDATE_START,
    USER_PASSWORD_UPDATE_FINISHED,
    USER_PASSWORD_UPDATE_FAILED,
    USER_ABOUT_UPDATE_START,
    USER_ABOUT_UPDATE_FINISHED,
    USER_ABOUT_UPDATE_FAILED,
    USER_FOLLOWING_STATE_CHANGED,
    USER_CREATION_LIKE_STATE_CHANGED,
    USER_REGISTER_START,
    USER_REGISTER_FINISHED,
    USER_REGISTER_FAILED,
    USER_LOGOUT,
    USER_PROFILE_PICTURE_UPLOAD_START,
    USER_PROFILE_PICTURE_UPLOAD_PROGRESS,
    USER_PROFILE_PICTURE_UPLOAD_FINISHED,
    USER_PROFILE_PICTURE_UPLOAD_FAILED,
    USER_ABOUT_HEADER_PICTURE_UPLOAD_START,
    USER_ABOUT_HEADER_PICTURE_UPLOAD_PROGRESS,
    USER_ABOUT_HEADER_PICTURE_UPLOAD_FINISHED,
    USER_ABOUT_HEADER_PICTURE_UPLOAD_FAILED,
    USER_PROFILE_PREVIEW_UPLOAD_START,
    USER_PROFILE_PREVIEW_UPLOAD_PROGRESS,
    USER_PROFILE_PREVIEW_UPLOAD_FINISHED,
    USER_PROFILE_PREVIEW_UPLOAD_FAILED,
    USER_EMAIL_VERIFIED
} from './types';

export const login = (username, password, code) => {
    return (dispatch) => {
        
        dispatch({ type: USER_LOGIN_START });

        API.User.login(username, password, code)
        .then(data => {
            dispatch({ type: USER_LOGIN_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_LOGIN_FAILED, payload: error });
        })
    };  
};

export const clearLoginError = () => {
    return (dispatch) => {
        dispatch({ type: USER_LOGIN_CLEAR });
    };  
};

export const setUserData = (userData) => {
    return (dispatch) => {
        dispatch({ type: USER_LOGIN_FINISHED, payload: userData });
    };  
};

export const setUserVerified = () => {
    return (dispatch) => {
        dispatch({ type: USER_EMAIL_VERIFIED });
    };  
};

export const logout = () => {
    return (dispatch) => {
        LocalStorage.clear();
        dispatch({ type: USER_LOGOUT });
    }
}

export const getData = () => {
    return (dispatch) => {
        
        dispatch({ type: USER_DATA_FETCH_START });

        API.User.get()
        .then(data => {
            dispatch({ type: USER_DATA_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_DATA_FETCH_FAILED, payload: error });
        })
    };  
};

export const updateData = (fields) => {
    return (dispatch) => {
        
        dispatch({ type: USER_DATA_UPDATE_START });

        API.User.update(fields)
        .then(data => {
            dispatch({ type: USER_DATA_UPDATE_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_DATA_UPDATE_FAILED, payload: error });
        })
    };  
};

export const changePassword = (currentPassword, newPassword) => {
    return (dispatch) => {
        
        dispatch({ type: USER_PASSWORD_UPDATE_START });

        API.User.changePassword(currentPassword, newPassword)
        .then(data => {
            dispatch({ type: USER_PASSWORD_UPDATE_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_PASSWORD_UPDATE_FAILED, payload: error });
        })
    };  
};

export const updateAbout = (body, links) => {
    return (dispatch) => {
        
        dispatch({ type: USER_ABOUT_UPDATE_START });

        API.User.updateAbout(body, links)
        .then(data => {
            dispatch({ type: USER_ABOUT_UPDATE_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_ABOUT_UPDATE_FAILED, payload: error });
        })
    };  
};

export const setFollowing = (username, follow) => {
    return (dispatch) => {
        API.Profiles.setFollowing(username, follow)
        .then(data => {
            dispatch({ type: USER_FOLLOWING_STATE_CHANGED, payload: data });
            dispatch({ type: PROFILE_FOLLOWING_STATE_CHANGED, payload: data });
            dispatch({ type: CREATION_OWNER_FOLLOWING_STATE_CHANGED, payload: data });
        })
        .catch(() => {})
    };  
};

export const setLikeCreation = (creationId, like) => {
    return (dispatch) => {
        API.Creations.setLike(creationId, like)
        .then((data) => {
            dispatch({ type: CREATION_LIKE_STATE_CHANGED, payload: data });
            dispatch({ type: USER_CREATION_LIKE_STATE_CHANGED, payload: data });
        })
    };  
};

export const register = (username, password, email, name, captchaId, captcha, isCreator, invitationCode) => {
    return (dispatch) => {
        
        dispatch({ type: USER_REGISTER_START });

        API.User.register(username, password, email, name, captchaId, captcha, isCreator, invitationCode)
        .then(data => {
            dispatch({ type: USER_REGISTER_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_REGISTER_FAILED, payload: error });
        })
    };  
};

export const uploadProfilePicture = (file) => {
    return (dispatch) => {
        
        dispatch({ type: USER_PROFILE_PICTURE_UPLOAD_START });

        API.User.uploadProfilePicture(file, (progress) => {
            dispatch({ type: USER_PROFILE_PICTURE_UPLOAD_PROGRESS, payload: progress });
        })
        .then(data => {
            dispatch({ type: USER_PROFILE_PICTURE_UPLOAD_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_PROFILE_PICTURE_UPLOAD_FAILED, payload: error });
        })
    };  
};

export const uploadHeaderPicture = (file) => {
    return (dispatch) => {
        
        dispatch({ type: USER_ABOUT_HEADER_PICTURE_UPLOAD_START });

        API.User.uploadHeaderPicture(file, (progress) => {
            dispatch({ type: USER_ABOUT_HEADER_PICTURE_UPLOAD_PROGRESS, payload: progress });
        })
        .then(data => {
            dispatch({ type: USER_ABOUT_HEADER_PICTURE_UPLOAD_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_ABOUT_HEADER_PICTURE_UPLOAD_FAILED, payload: error });
        })
    };  
};

export const uploadProfilePreview = (file) => {
    return (dispatch) => {
        
        dispatch({ type: USER_PROFILE_PREVIEW_UPLOAD_START });

        API.User.uploadProfilePreview(file, (progress) => {
            dispatch({ type: USER_PROFILE_PREVIEW_UPLOAD_PROGRESS, payload: progress });
        })
        .then(data => {
            dispatch({ type: USER_PROFILE_PREVIEW_UPLOAD_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_PROFILE_PREVIEW_UPLOAD_FAILED, payload: error });
        })
    };  
};