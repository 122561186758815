import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        padding: '10px 0',
        borderBottomColor: Theme.TextColor.Lightest
    },
    userData: {
        flex: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    editionData: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    detailsData: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    detailsDataRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    actionData: {
        flex: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    actionDataStatus: {
        flex: 2,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    actionDataView: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: 15
    },
    usersName: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        margin: 0,
        fontSize: 12,
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        },
    },
    textField: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        margin: 2,
        fontSize: 12,
        fontWeight: 300,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    boldText: {
        fontWeight: 400
    },
    dateText: {
        fontSize: 10
    },
    editionField: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        margin: 2,
        fontSize: 12,
        fontWeight: 300,
        textDecoration: 'none',
        '&:hover': {
            color: Theme.Palette.Primary.Foreground,
            textDecoration: 'none'
        }
    },
    '@media only screen and (max-width: 1024px)': {
        actionDataView: {
            display: 'none'
        }
    },
});