import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    wrapperContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: 10
    },
    container: {
        position: 'relative',
        height: 'min-content'
    },
    squareContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
    },
    fullWidth: {
        width: '100%'
    },
    fullHeight: {
        height: '100%'
    },
    infoOverlayContainer: {
        display: 'flex',
        position: 'absolute',
        float: 'left',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        padding: 0,
    },
    showOnHover: {
        transition: '0.5s ease',
        opacity: 0,
        '&:hover': {
            opacity: 1
        }
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 100%)',
        width: '100%',
        height: '100%',
        padding: 0,
        cursor: 'pointer'
    },
    infoMarginContainer: {
        margin: '25px 20px',
    },
    title: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 18,
        fontWeight: 400,
        margin: '0 10px 0 0',
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'white'
    },
    statusContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0px 3px'
    },
    status: {
        fontFamily: Theme.Font.Primary,
        fontSize: 13,
        fontWeight: 300,
        margin: '10px 0',
        cursor: 'pointer',
        textDecoration: 'none',
        color: Theme.TextColor.Dark
    },
    detailsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        margin: '30px 0 0 0'
    },
    edition: {
        fontFamily: Theme.Font.Primary,
        fontSize: 11,
        fontWeight: 300,
        border: '1px solid white',
        color: 'white',
        width: 'max-content',
        padding: '4px 8px 5px 8px',
        margin: 0
    },
    playOnHoverContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        position: 'absolute',
        float: 'left',
        width: '100%',
        height: '100%',
        padding: 0,
        left: 0,
        top: 0,
    },
    playOnHoverIcon: {
        width: 25,
        height: 25,
        fill: 'white',
        margin: 10
    },
});