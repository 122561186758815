import React, { useEffect } from 'react';
import { useStyles } from './styles';
import _ from 'lodash';

const PopupContainer = (props) => {

    const classes = useStyles();
    const [animationRender, setAnimationRender] = React.useState(true);

    useEffect(() => {
        setAnimationRender(false);
    }, [])

    const handleBackdropClick = () => {
        if(props.onBackdropClicked)
            props.onBackdropClicked();

        if(props.disableClosing)
            return;

        setAnimationRender(true);
        setTimeout(
            () => {
                if(_.isFunction(props.onClose))
                    props.onClose();
            }, 
            200
        );
    }

    const handleContentContainerClick = (event) => {
        event.stopPropagation();
    }

    return (
        <div 
            className={
                !props.isOpen || (animationRender && props.isOpen) ?
                classes.backdrop + ' ' + classes.backdropHidden :
                classes.backdrop
            } 
            style={{
                backgroundColor: props.backdropColor
            }}
            onClick={handleBackdropClick}>
            <div 
                className={
                    !props.isOpen || (animationRender && props.isOpen) ?
                    classes.container + ' ' + classes.containerHidden :
                    classes.container
                }
                style={{
                    width: props.width
                }}
                onClick={handleContentContainerClick}>

                { props.children }

            </div>
        </div>
    );
}

export default PopupContainer;