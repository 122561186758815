import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as UserActions from '~/Redux/User/actions';
import * as DropsPageActions from '~/Redux/DropsPage/actions';
import { STATE_NOT_PROCESSED, STATE_PROCESSING } from '~/Redux/states';

import MainHeader from '~/Components/MainHeader';
import DropItem from '~/Components/DropItem';

import { useStyles } from './styles';
import Carousel from '~/Components/Carousel';
import Images from '~/Assets/images';
import NoContentInfoBox from '~/Components/NoContentInfoBox';
import Footer from '~/Components/Footer';

const DropsPage = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const userData = useSelector(state => state.User);
    const dropsPage = useSelector(state => state.DropsPage);

    const pageWidth = useSelector(state => state.UI.pageWidth);
    
    const [profileItemSize, setProfileItemSize] = React.useState(1);

    React.useEffect(
        () => {
            if (!userData.data && userData.token && 
                userData.fetchDataState === STATE_NOT_PROCESSED) {
                dispatch(UserActions.getData());
            }
        },
        [userData, dispatch]
    );

    React.useEffect(
        () => {
            if (dropsPage.contents === null && dropsPage.contentsState !== STATE_PROCESSING)
                dispatch(DropsPageActions.getDropsPageContents());
        },
        [dropsPage, dispatch]
    );

    React.useEffect(
        () => {
            const containerMargins = pageWidth > 800 ? 100 : 20;
            const itemMargins = 10;

            if(pageWidth < 400)
                setProfileItemSize((pageWidth - containerMargins - 2 * itemMargins) / 1);
            else if(400 <= pageWidth && pageWidth < 800)
                setProfileItemSize((pageWidth - containerMargins - 4 * itemMargins) / 2);
            else if(800 <= pageWidth)
                setProfileItemSize((pageWidth - containerMargins - 6 * itemMargins) / 3);
        },
        [pageWidth]
    )

    return (
        <Fragment>
            
            <MainHeader
                username={userData.data?.username}
                profilePictureUrl={userData.data?.profilePictureUrl}
                userType={userData.data?.role}
                isLoggedIn={!!userData.data}
                isMobile={pageWidth < 800}/>

            <div className={classes.pageContainer}>

                <div className={classes.dropsTitleContainer}>
                    <div className={classes.sectionTitle}>
                        🔥 DROPS
                    </div>
                </div>

                <div className={classes.liveDropsContainer}>
                    {
                        dropsPage.contents?.liveDrops.map(item =>
                            <DropItem
                                isDrop
                                key={item.dropId}
                                itemId={item.dropId}
                                itemName={item.name}
                                imageUrl={item.preview.imageUrl}
                                videoUrl={item.preview.videoUrl}
                                creatorList={item.creators}
                                defaultOpen
                                showAuthor={pageWidth > 800}
                                squareSize={profileItemSize}/>  
                        )
                    }
                </div>


                {
                    dropsPage.contents?.liveArtworks &&
                    dropsPage.contents?.liveArtworks.length > 0 &&
                    <Carousel
                        className={classes.carousel}
                        title="ARTWORKS"
                        itemWidth={pageWidth > 1024 ? 350 : 200}
                        viewAllUrl="">
                        {
                            dropsPage.contents?.liveArtworks
                                .map(item =>
                                    <DropItem
                                        key={item.creationId}
                                        itemId={item.creationId}
                                        itemName={item.name}
                                        imageUrl={item.media.image.url}
                                        videoUrl={item.media.video.url}
                                        creatorList={[item.creator]}
                                        releaseCount={item.release.count}
                                        showAuthor
                                        squareSize={pageWidth > 1024 ? 350 : 200}/>           
                                )
                        }
                    </Carousel>
                }

                {
                    dropsPage.contents?.upcomingDrops &&
                    dropsPage.contents?.upcomingDrops.length > 0 &&
                    <Carousel
                        className={classes.carousel}
                        title="UPCOMING DROPS"
                        itemWidth={pageWidth > 1024 ? 350 : 200}
                        viewAllUrl="">
                        {
                            dropsPage.contents?.upcomingDrops
                                .map(item =>
                                    <DropItem
                                        key={item.dropId}
                                        itemId={item.dropId}
                                        itemName={item.name}
                                        imageUrl={item.preview.imageUrl}
                                        videoUrl={item.preview.videoUrl}
                                        creatorList={item.creators}
                                        startTime={item.startTime}
                                        isDrop
                                        showAuthor
                                        squareSize={pageWidth > 1024 ? 350 : 200}/>           
                                )
                        }
                    </Carousel>
                }

                {
                    dropsPage.contents?.pastArtworks &&
                    dropsPage.contents?.pastArtworks.length > 0 &&
                    <Carousel
                        className={classes.carousel}
                        title="PAST ARTWORKS"
                        itemWidth={pageWidth > 1024 ? 350 : 200}>
                        {
                            dropsPage.contents?.pastArtworks
                                .map(item =>
                                    <DropItem
                                        key={item.creationId}
                                        itemId={item.creationId}
                                        itemName={item.name}
                                        imageUrl={item.media.image.url}
                                        videoUrl={item.media.video.url}
                                        creatorList={[item.creator]}
                                        releaseCount={item.release.count}
                                        showAuthor
                                        squareSize={pageWidth > 1024 ? 350 : 200}/>           
                                )
                        }
                    </Carousel>
                }

                {
                    !dropsPage.contents?.liveDrops?.length &&
                    !dropsPage.contents?.liveArtworks?.length &&
                    !dropsPage.contents?.upcomingDrops?.length &&
                    !dropsPage.contents?.pastArtworks?.length &&
                    <NoContentInfoBox
                        icon={Images.EmptyGallery}
                        style={{minHeight: 300}}
                        text={`No drops currently available on the platform`}/>
                }

            </div>
            <Footer/>
        </Fragment>
    );
}

export default DropsPage;