import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    backdrop: {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 10000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '0.2s ease',
    },
    backdropHidden: {
        opacity: 0
    },
    container: {
        boxShadow: '0px 16px 50px 0px rgb(0 0 0 / 15%)',
        '-webkit-box-shadow': '0px 16px 50px 0px rgb(0 0 0 / 15%)',
        '-moz-box-shadow': '0px 16px 50px 0px rgba(0,0,0,0.15)',
        backgroundColor: '#EDEDED',
        transition: '0.2s ease',
        opacity: 1
    },
    '@media only screen and (max-width: 500px)': {
        container: {
            width: '100%'
        }
    },
});