import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #ccc',
        margin: '3px 0',
        marginRight: 15,
        borderRadius: 10,
        height: 28,
        overflow: 'hidden'
    },
    buttonItem: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Light,
        backgroundColor: '#eee',
        padding: '4px 8px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ddd'
        }
    },
    leftButton: {
        borderRight: '1px solid #ccc',
    },
    rightButton: {
        borderLeft: '1px solid #ccc',
    },
    deleteButton: {
        backgroundColor: '#fa7e75',
        color: 'white',
        '&:hover': {
            backgroundColor: '#ff4f42'
        }
    },
    textItem: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        padding: '4px 10px'
    }
});