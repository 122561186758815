import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        width: 500,
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 30,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        textAlign: 'center',
        margin: '25px 0'
    },
    inputField: {
        flex: 1,
        border: `1px solid ${Theme.TextColor.Light}`,
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        padding: 10,
        color: Theme.TextColor.Dark,
        margin: '10px 20px'
    },
    inputFieldInvalid: {
        borderColor: 'red'
    },
    forgotPasswordContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: '5px 20px'
    },
    forgotPasswordLabel: {
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        fontWeight: 300,
        color: Theme.TextColor.Light,
        cursor: 'pointer',
        margin: 5,
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    button: {
        flex: 1,
        padding: 12,
        fontSize: 15,
        margin: '10px 20px 30px 20px'
    },
    errorMessage: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        fontWeight: 300,
        color: '#f00',
        cursor: 'default',
        margin: '0 20px 10px 20px'
    },
    infoMessage: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 15,
        fontWeight: 300,
        textAlign: 'justify',
        color: Theme.TextColor.Dark,
        cursor: 'default',
        margin: '0 20px 15px 20px'
    },
    '@media only screen and (max-width: 500px)': {
        container: {
            width: '100%'
        }
    },
});