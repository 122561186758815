import Helpers from '~/helpers';
import MetaMask from './MetaMask';

class Signature {

    static async signAddressVerification(username) {
        let message = {
            type: 'VERIFICATION',
            address: MetaMask.getCurrentAddress(),
            username
        }

        return Signature.signMessage(
            JSON.stringify(message), 
            message.type,
            false
        );
    }

    static async signOffer(tokenId, amount) {
        let buyer = await MetaMask.getCurrentAddress();
        let ethAmount = window.ethers.utils.parseEther(amount.toString());
        let message = {
            type: 'OFFER',
            tokenId,
            value: ethAmount.toHexString(),
            buyer
        }

        return Signature.signMessage(
            JSON.stringify(message), 
            message.type,
            false
        );
    }

    static async signSaleApproval(buyer, tokenId, amount, approvalId, deadline) {
        let ethAmount = window.ethers.utils.parseEther(String(amount));
        let buyerAddress = buyer || '0x0000000000000000000000000000000000000000';

        let parameters = [
            { type: 'address', value: buyerAddress},
            { type: 'uint256', value: tokenId },
            { type: 'uint256', value: ethAmount.toHexString() },
            { type: 'uint256', value: approvalId },
            { type: 'uint32', value: deadline }
        ];

        let encodedMessage = Signature.packParametrs(parameters);
        let signatureObjct = await Signature.signMessage(
            encodedMessage, 
            'SALE',
            true
        );

        return {
            parameters,
            ...signatureObjct
        };
    }

    static async signMessage(message, type, isApproval) {

        const provider = new window.ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        
        let messageHash;
        let signature;

        if(isApproval) {
            messageHash = window.ethers.utils.keccak256(message);
            let binaryMessageHash = window.ethers.utils.arrayify(messageHash);
            signature = await signer.signMessage(binaryMessageHash);
        }
        else {
            signature = await signer.signMessage(message);
        }

        signature = signature.substr(2);
        const r = '0x' + signature.slice(0, 64)
        const s = '0x' + signature.slice(64, 128)
        const v = '0x' + signature.slice(128, 130)
        const vDecimal = parseInt(v, 16)

        if(vDecimal !== 27 && vDecimal !== 28)
            throw new Error(`Invalid 'v' signature parameter`);

        return {
            type,
            message,
            messageHash,
            signature: {
                signer: await signer.getAddress(),
                v: vDecimal,
                r,
                s
            }
        }
    }

    static packParametrs(parameters) {

        const abiCoder = new window.ethers.utils.AbiCoder();
        const TypeSizes = {
            'address': 20,
            'uint256': 32,
            'uint32': 4
        };
    
        let encodedParameters = '0x';
    
        for(let parameter of parameters) {
    
            let typeSize = 1;
    
            if(parameter.type === 'string')
                typeSize = 2 * parameter.value.length;
            else if(TypeSizes[parameter.type])
                typeSize = TypeSizes[parameter.type];
            else
                throw new Error(`Unsupported type ${parameter.type}`);
    
            let encodedValue = 
                parameter.type === 'string' ?
                Helpers.stringToHex(parameter.value) :
                abiCoder.encode([parameter.type], [parameter.value]).substr(2);
    
            encodedParameters += encodedValue.substr(-2 * typeSize);
        }
    
        return encodedParameters;
    }
}

export default Signature;