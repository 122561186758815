import Config from '~/config';

import {
    UI_PAGE_SCROLL_POSITION_CHANGED,
    UI_WINDOW_SIZE_CHANGED,
    UI_PAGE_SIZE_CHANGED,
    UI_POPUP_STATE_CHANGED
} from './types';

const INITIAL_STATE = {
    isPageScrollEnd: false,
    pageScrollPosition: 0,
    windowWidth: 1,
    windowHeight: 1,
    pageWidth: 1,
    pageHeight: 1,
    currentPopupStateChange: 0
}

const UIReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case UI_PAGE_SCROLL_POSITION_CHANGED:
            return UIReducers.pageScrollPositionChanged(state, action.payload);

        case UI_WINDOW_SIZE_CHANGED:
            return UIReducers.windowSizeChanged(state, action.payload);

        case UI_PAGE_SIZE_CHANGED:
            return UIReducers.windowPageChanged(state, action.payload);

        case UI_POPUP_STATE_CHANGED:
            return UIReducers.triggerPopupStateChange(state);

        default:
            return state;
    }
}

class UIReducers {

    static pageScrollPositionChanged(state, data) {

        if(state.pageScrollPosition === data)
            return state;

        const isPageScrollEnd = 
            state.pageHeight - state.windowHeight - data < 
            Config.UI.PAGE_SCROLL_END_THRESHOLD;

        return { 
            ...state, 
            pageScrollPosition: data,
            isPageScrollEnd
        }
    }

    static windowSizeChanged(state, data) {

        if (state.windowWidth === data.width && 
            state.windowHeight === data.height)
            return state;

        return { 
            ...state, 
            windowWidth: data.width,
            windowHeight: data.height
        }
    }

    static windowPageChanged(state, data) {

        if (state.pageWidth === data.width && 
            state.pageHeight === data.height)
            return state;

        return { 
            ...state, 
            pageWidth: data.width,
            pageHeight: data.height
        }
    }

    static triggerPopupStateChange(state) {
        return { 
            ...state, 
            currentPopupStateChange: state.currentPopupStateChange + 1
        }
    }
}

export default UIReducer;