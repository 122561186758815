export const DISCOVER_FETCH_START = 'DISCOVER_FETCH_START';
export const DISCOVER_FETCH_FINISHED = 'DISCOVER_FETCH_FINISHED';
export const DISCOVER_FETCH_FAILED = 'DISCOVER_FETCH_FAILED';

export const DISCOVER_SUGGESTIONS_FETCH_START = 'DISCOVER_SUGGESTIONS_FETCH_START';
export const DISCOVER_SUGGESTIONS_FETCH_FINISHED = 'DISCOVER_SUGGESTIONS_FETCH_FINISHED';
export const DISCOVER_SUGGESTIONS_FETCH_FAILED = 'DISCOVER_SUGGESTIONS_FETCH_FAILED';

export const SEARCH_FETCH_START = 'SEARCH_FETCH_START';
export const SEARCH_FETCH_FINISHED = 'SEARCH_FETCH_FINISHED';
export const SEARCH_FETCH_FAILED = 'SEARCH_FETCH_FAILED';