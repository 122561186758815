import * as API from '~/API';

import { 
    USER_DATA_CREATIONS_FETCH_FAILED, 
    USER_DATA_CREATIONS_FETCH_FINISHED, 
    USER_DATA_CREATIONS_FETCH_START, 
    USER_DATA_ADDRESS_FETCH_FAILED, 
    USER_DATA_ADDRESS_FETCH_FINISHED, 
    USER_DATA_ADDRESS_FETCH_START, 
    USER_DATA_MEDIA_FETCH_FAILED, 
    USER_DATA_MEDIA_FETCH_FINISHED, 
    USER_DATA_MEDIA_FETCH_START, 
    USER_DATA_MEDIA_DETAILS_FETCH_FAILED, 
    USER_DATA_MEDIA_DETAILS_FETCH_FINISHED, 
    USER_DATA_MEDIA_DETAILS_FETCH_START, 
    USER_DATA_OFFERS_FETCH_FAILED, 
    USER_DATA_OFFERS_FETCH_FINISHED, 
    USER_DATA_OFFERS_FETCH_START, 
    USER_DATA_SALES_FETCH_FAILED, 
    USER_DATA_SALES_FETCH_FINISHED, 
    USER_DATA_SALES_FETCH_START 
} from './types';

export const getOwnedCreations = () => {
    return (dispatch) => {
        dispatch({ type: USER_DATA_CREATIONS_FETCH_START });

        API.UserData.getOwnedCreations()
        .then(data => {
            dispatch({ type: USER_DATA_CREATIONS_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_DATA_CREATIONS_FETCH_FAILED, payload: error });
        })
    };  
};

export const getUserAddresses = () => {
    return (dispatch) => {
        dispatch({ type: USER_DATA_ADDRESS_FETCH_START });

        API.UserData.getAddresses()
        .then(data => {
            dispatch({ type: USER_DATA_ADDRESS_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_DATA_ADDRESS_FETCH_FAILED, payload: error });
        })
    };  
};

export const getOffers = (incoming, status) => {
    return (dispatch) => {
        dispatch({ type: USER_DATA_OFFERS_FETCH_START, payload: {incoming, status} });

        API.UserData.getOffers(incoming, status)
        .then(data => {
            dispatch({ type: USER_DATA_OFFERS_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_DATA_OFFERS_FETCH_FAILED, payload: error });
        })
    };  
};

export const getSales = (purchases) => {
    return (dispatch) => {
        dispatch({ type: USER_DATA_SALES_FETCH_START, payload: {purchases} });

        API.UserData.getSales(purchases)
        .then(data => {
            dispatch({ type: USER_DATA_SALES_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_DATA_SALES_FETCH_FAILED, payload: error });
        })
    };  
};

export const listMedia = () => {
    return (dispatch) => {
        dispatch({ type: USER_DATA_MEDIA_FETCH_START });

        API.UserData.listMedia()
        .then(data => {
            dispatch({ type: USER_DATA_MEDIA_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_DATA_MEDIA_FETCH_FAILED, payload: error });
        })
    };  
};

export const getMedia = (mediaId) => {
    return (dispatch) => {
        
        dispatch({ type: USER_DATA_MEDIA_DETAILS_FETCH_START });

        API.UserData.getMedia(mediaId)
        .then(data => {
            dispatch({ type: USER_DATA_MEDIA_DETAILS_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: USER_DATA_MEDIA_DETAILS_FETCH_FAILED, payload: error });
        })
    };  
};