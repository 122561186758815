import React from 'react';
import { Link } from 'react-router-dom';
import Images from '~/Assets/images';

import MetaMask from '~/Services/MetaMask';
import Helpers from '~/helpers';

import { useStyles } from './styles';

const AuthenticityInfo = (props) => {

    const classes = useStyles();
    const tokenDetails = MetaMask.getTokenDetails();

    return (
        <div className={ props.className }>

            <div className={classes.boxContainer}>

                <div className={classes.titleContainer}>
                    <p className={classes.title}>
                        { props.title }
                    </p>
                </div>

                <div className={classes.attributeContainer}>
                    <p className={classes.attributeLabel}>
                        {
                            props.creators.length > 1 ?
                            'Creators:' : 'Creator:' 
                        }
                    </p>
                    
                    {
                        props.creators.map((creator, index) => 
                            <Link 
                                key={`ci_${creator.name}`}
                                to={`/profiles/${creator.username}/`}
                                className={classes.attributeValueLink}>
                                {
                                    index === props.creators.length - 1 ?
                                    creator.name : `${creator.name},`
                                }
                            </Link>       
                        )
                    }
                </div>

                <div className={classes.attributeContainer}>
                    <p className={classes.attributeLabel}>
                        Edition:
                    </p>

                    <p className={classes.attributeValue}>
                        { `${props.releaseData.number} of ${props.releaseData.count}` }
                    </p>
                </div>

                <div className={classes.attributeContainer}>
                    <p className={classes.attributeLabel}>
                        Created:
                    </p>

                    <p className={classes.attributeValue}>
                        { new Date(props.createdAt).toDateString() }
                    </p>
                </div>

                <div className={classes.networkContainer}>

                    <img
                        alt=""
                        className={classes.networkIcon}
                        src={Images.Polygon}/>

                    <p className={classes.title}>
                        { tokenDetails?.networkName || 'Polygon'}
                    </p>
                </div>

                <div className={classes.attributeContainer}>
                    <p className={classes.attributeLabel}>
                        Token Name:
                    </p>

                    <p className={classes.attributeValue}>
                        { tokenDetails?.name || 'Unknown'}
                    </p>
                </div>

                <div className={classes.attributeContainer}>
                    <p className={classes.attributeLabel}>
                        ID:
                    </p>

                    <p className={classes.attributeValue}>
                        #{ props.blockchainData.tokenId }
                    </p>
                </div>

                <div className={classes.attributeContainer}>
                    <p className={classes.attributeLabel}>
                        Contract ID:
                    </p>

                    <p className={classes.attributeValue}>
                        { Helpers.getTruncatedAddress(props.blockchainData.contract) }
                    </p>
                </div>

                <div className={classes.attributeContainer}>
                    <p className={classes.attributeLabel}>
                        Creator ID:
                    </p>

                    <p className={classes.attributeValue}>
                        { Helpers.getTruncatedAddress(props.blockchainData.creatorAddress) }
                    </p>
                </div>

                <div style={{height: 30}}/>


                <a
                    href={
                        tokenDetails?.explorerUrl ?
                        `${tokenDetails?.explorerUrl}/token/${props.blockchainData.contract}?a=${props.blockchainData.tokenId}` : 
                        '#'
                    }
                    className={classes.proofLink}>
                    View on blockchain
                </a>

            </div>
        
        </div>
    );
}

export default AuthenticityInfo;