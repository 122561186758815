import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as UserDataActions from '~/Redux/UserData/actions';
import { STATE_NOT_PROCESSED } from '~/Redux/states';

import { useStyles } from './styles';
import CreationDescription from '~/Components/CreationDescription';
import CreationMediaView from '~/Components/CreationMediaView';
import TableHeader from '~/Views/AuthenticityPage/TableHeader';
import OwnershipHistoryTableItem from '~/Components/OwnershipHistoryTableItem';
import Button from '~/Components/Button';
import PopupManager from '~/Auxilary/PopupManager';
import Config from '~/config';
import LabeledInputField from '~/Components/LabeledInputField';
import MetaMask from '~/Services/MetaMask';
import _ from 'lodash';
import Helpers from '~/helpers';

const MediaDetails = (props) => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const userData = useSelector(state => state.UserData);
    
    const [dataReloadRequired, setDataReloadRequired] = React.useState(true);
    const [directPurchasePrice, setDirectPurchasePrice] = React.useState('');
    const [isSettingPrice, setIsSettingPrice] = React.useState(false);

    React.useEffect(
        () => {
            if (userData.mediaDetailsState === STATE_NOT_PROCESSED || dataReloadRequired) {
                dispatch(UserDataActions.getMedia(props.mediaId));
                setDataReloadRequired(false);
            }
        }, 
        [ props.mediaId, userData, dataReloadRequired, dispatch ]
    );

    const handlePublishEditions = () => {
        PopupManager
            .pushMintReleasesPopup(
                userData.mediaDetails.blockchain.mediaId,
                userData.mediaDetails.release.count - userData.mediaDetails.release.number,
                userData.mediaDetails.blockchain.creatorAddress,
                userData.mediaDetails.blockchain.network
            )
    }

    const handleSetDirectPurchasePrice = () => {
        MetaMask
            .startListening()
            .then(() => {

                setIsSettingPrice(true);

                let creatorAddress = userData?.mediaDetails?.blockchain?.creatorAddress
                if(!Helpers.isSameAddress(creatorAddress, MetaMask.getCurrentAddress())) {
                    PopupManager.pushMessagePopup(
                        'Switch account',
                        [ 
                            `The selected MetaMask account is not the creator's address. Please select the following address in MetaMask in order to continue:`,
                            creatorAddress
                        ]
                    );
        
                    setIsSettingPrice(false);
                    return;
                }

                MetaMask
                    .getMarketContract()
                    .setDirectPurchasePrice(
                        userData?.mediaDetails?.blockchain?.mediaId,
                        window.ethers.utils.parseEther(directPurchasePrice.toString())
                    )
                    .then(() => {
                        setIsSettingPrice(false);
                        PopupManager.pushMessagePopup(
                            'Transaction sent', 
                            ['The transaction for setting the default price has been sent. Please give the platform a couple of minutes to synchronize with the blockchain!'], 
                            'PASS'
                        )
                    })
                    .catch((error) => {
                        setIsSettingPrice(false);
                        PopupManager.pushMessagePopup(
                            'Transaction failed',
                            [ 
                                `Failed to set the default price for this creation. ${
                                    error.error?.message?.split('execution reverted: ').join('') ||
                                    error.message.split('MetaMask Tx Signature: ').join('')
                                }`
                            ],
                            'FAIL'
                        );
                    })
            })
    }

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                { userData.mediaDetails?.name }
            </p>

            <div className={classes.mainSection}>

                <div className={classes.infoSection}>

                    <CreationDescription
                        hideTitle
                        text={userData.mediaDetails?.description} />

                </div>

                <CreationMediaView
                    className={classes.mediaPreview}
                    imageUrl={userData.mediaDetails?.media.image.url}
                    videoUrl={userData.mediaDetails?.media.video.url}
                    isVideo={!!userData.mediaDetails?.media.video.url}/>

            </div>

            <p className={classes.tableTitle}>
                Publication status
            </p>

            <div className={classes.publicationStatusContainer}>
                <p className={classes.tableNote}>
                    <strong>{userData.mediaDetails?.release.number} out of {userData.mediaDetails?.release.count}</strong> editions have currently been published.
                </p>

                {
                    userData.mediaDetails?.release.number < userData.mediaDetails?.release.count &&
                    <Button
                        className={classes.publishButton}
                        text="Publish remaining editions"
                        onClick={handlePublishEditions}/>
                }
            </div>

            <p className={classes.tableTitle}>
                Default price
            </p>

            <div className={classes.publicationStatusContainer}>
                <p className={classes.tableNote}>
                    By setting the default price, collectors will be able to buy any edition of this creation at that price without additional intervention from your side. The price is set for all editions of this creation at once. To remove the default price, set it to zero.
                </p>

                <div className={classes.defaultPriceContainer}>
                    <LabeledInputField
                        className={classes.inputField}
                        labelText="Default Price"
                        placeholder={`The default price in ${Config.Blockchain.COIN} for all releases`}
                        type="text"
                        min="0"
                        value={directPurchasePrice}
                        onChange={setDirectPurchasePrice}
                        labelFirst />

                    <Button
                        className={classes.publishButton}
                        text={
                            isSettingPrice ? 
                            "Setting price..." :
                            "Set Default Price"
                        }
                        disabled={!_.isFinite(parseFloat(directPurchasePrice)) || parseFloat(directPurchasePrice) < 0}
                        isLoading={isSettingPrice}
                        onClick={handleSetDirectPurchasePrice}/>
                </div>
            </div>

            <p className={classes.tableTitle}>
                Published Editions
            </p>

            <TableHeader
                headerItems={[ 'EDITION', 'OWNER', 'DATE ISSUED', 'DETAILS' ]}/>

            <div className={classes.tableItemContainer}>
                {
                    userData.mediaDetails && 
                    userData.mediaDetails.editions &&
                    userData.mediaDetails.editions.length > 0 ?
                    userData.mediaDetails.editions.map(item => 
                        <OwnershipHistoryTableItem
                            creationId={item.creationId}
                            name={item.owner.name}
                            username={item.owner.username}
                            profilePictureUrl={item.owner.profilePictureUrl}
                            blockchainData={item.blockchain}
                            event={`#${item.release.number}`}
                            transactionDate={item.createdAt}
                            transactionLink="/" />
                    ) 
                    : null
                }
            </div>

            <div style={{height: 50}}/>

        </div>
    );
}

export default MediaDetails;