import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #ccc',
        cursor: 'pointer'
    },
    inputText: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: 0,
        padding: 10,
        backgroundColor: Theme.Background.Lightest
    },
    inputButton: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: 0,
        padding: 10,
        backgroundColor: '#eee',
        borderLeft: '1px solid #ccc'
    },
    fileInputFiled: {
        display: 'none'
    }
});