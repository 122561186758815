import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    pageContainer: {
        padding: '100px 80px 0px 80px'
    },
    suggestionDescription: {
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        color: Theme.TextColor.Light,
        margin: 10
    },
    suggestionContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        overflow: 'auto hidden',
        margin: '0 10px',
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    activityContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    activityList: {
        margin: '20px 0px 0 0px'
    },
    filterContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    filterLeftContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    searchContainer: {
        marginTop: 20,
        height: 40,
        display: 'flex',
        flexDirection: 'row'
    },
    searchButton: {
        padding: '5px 15px'
    },
    searchField: {   
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        padding: 10,
        border: '1px solid #ccc',
        margin: 0,
        minWidth: 250
    },
    profileContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: '20px 0px 0 0px'
    },
    '@media only screen and (max-width: 1024px)': {
        pageContainer: {
            padding: '80px 10px 0 10px',
        }
    },
    '@media only screen and (max-width: 800px)': {
        filterContainer: {
            alignItems: 'center',
            flexDirection: 'column'
        },
    },
});