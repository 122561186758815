import React from 'react';

const PlayIcon = (props) => {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg" 
            version="1.1"
            viewBox="0 0 500 500">
            <path d="m 67.570938,410.21995 c -0.57202,-0.0858 -2.444092,-0.36122 -4.160157,-0.61195 -3.984329,-0.58215 -10.095506,-2.51834 -14.352546,-4.54729 -15.946846,-7.60046 -27.447202,-22.74364 -30.635796,-40.33992 -0.840296,-4.63719 -0.840296,-223.3395 0,-227.97668 4.156073,-22.93535 22.052995,-40.83227 44.988342,-44.98834 4.637183,-0.8403 223.339489,-0.8403 227.976669,0 20.15384,3.65204 36.65043,17.9606 43.00652,37.30237 2.52651,7.68825 2.46405,6.69208 2.63762,42.06976 0.0844,17.20908 -0.13729,7.27027 -0.25426,32.99046 25.96429,-18.34174 11.52138,-8.22632 48.42168,-33.8192 11.89805,-8.25173 39.11416,-27.14057 60.48023,-41.97521 21.36601,-14.83463 38.91693,-26.90245 39.00201,-26.81738 0.0851,0.0851 0.10768,67.2071 0.0501,149.16004 -0.0614,87.42421 -0.0165,92.4682 0.004,149.30437 -27.89317,-19.32623 -29.88702,-20.83219 -50.86268,-35.29565 -27.91467,-19.24817 -61.00403,-42.08875 -73.5319,-50.75686 -12.5279,-8.66813 -22.86158,-15.76022 -22.96373,-15.76022 -0.10215,0 -0.25766,14.55535 -0.34558,32.34522 -0.17389,35.19326 -0.11258,34.21938 -2.63769,41.90329 -5.50058,16.73838 -18.58216,29.81998 -35.32054,35.32053 -8.27195,2.71834 1.80761,2.50177 -120.01024,2.57884 -60.74872,0.0384 -110.920224,0 -111.492245,-0.0862 z"/>
        </svg>
    )
}

export default PlayIcon;