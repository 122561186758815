import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container:{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        margin: '0 10px',
        maxWidth: 700
    },
    profilePictureContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginRight: 20
    },
    fieldsContainer: {
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '0 10px',
        marginRight: 20
    },
    title: {
        fontFamily: Theme.Font.Primary,
        fontSize: 40,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '0 0 10px 0'
    },
    smallTitle: {
        fontFamily: Theme.Font.Primary,
        fontSize: 30,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '20px 0 10px 0'
    },
    fileInputField: {
        margin: '10px 0'
    },
    usernameField: {
        fontSize: 14,
        margin: '5px 0'
    },
    inputField: {   
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        padding: 10,
        border: '1px solid #ccc',
        margin: '3px 0'
    },
    buttonContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    updateButton: {
        padding: '10px 15px',
        marginTop: 10,
        fontSize: 15
    },
    emailFieldContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        margin: '10px 0 0 0'
    },
    verifyButton: {
        padding: '10px 15px',
        margin: '3px 0 3px 10px',
        fontSize: 15
    },
    updateErrorMessage: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        textAlign: 'right',
        fontSize: 12,
        fontWeight: 300,
        color: '#f00',
    },
    blockchainWalletsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10
    },
    '@media only screen and (max-width: 800px)': {
        container: {
            flexDirection: 'column',
            width: '100%',
            margin: 20
        },
        profilePictureContainer: {
            margin: '10px 0 30px 0',
            alignSelf: 'center'
        },
        fieldsContainer: {
            marginRight: 0
        }
    }
});