import TokenContract from "./TokenContract";
import MarketContract from "./MarketContract";
import { store } from '~/Redux'
import { triggerStateChange } from '~/Redux/Blockchain/actions';
import Config from "~/config";

class MetaMask {

    constructor() {
        this.listening = false;
        this.mutex = false;
        this.listenStartedCallback = null;

        if(window.___METAMASK_LISTENER_REF)
            clearInterval(window.___METAMASK_LISTENER_REF);

        window.___METAMASK_LISTENER_REF = setInterval(
            () => { 
                this._stateChangeListener().catch((error) => {
                    this.mutex = false;
                    if(this.listenStartedCallback) {
                        this.listenStartedCallback(error);
                        this.listenStartedCallback = null;
                    }
                })
            }, 
            1000
        );

        this._stateChangeListener().catch((err) => {
            console.log('_stateChangeListener', err)
            this.mutex = false;
        })

        this.lastAvailable = null;
        this.lastNetwork = null;
        this.lastAddress = null;
        this.lastMarketApproved = false;

        this.tokenContract = null;
        this.marketContract = null;

        window.MetaMask = this;
    }

    async _stateChangeListener() {
        if(!this.listening || this.mutex)
            return;

        await this.enable();

        if(!this.isAvailable())
            return;

        this.mutex = true;
        let stateChanged = await this._updateTxStatus();


        let isAvailable = this.isAvailable();
        let currentNetwork = window.ethereum.chainId;
        let currentAddress = window.ethereum.selectedAddress;

        if(this.lastAvailable !== isAvailable) {
            this.lastAvailable = isAvailable;
            stateChanged = true;
        }

        if(this.lastNetwork !== currentNetwork) {
            this.lastNetwork = currentNetwork;
            stateChanged = true;

            this.updateContractNetwork();
        }

        if(this.lastAddress !== currentAddress) {
            this.lastAddress = currentAddress;
            stateChanged = true;
        }

        if(this.lastAddress && this.isInNetwork(Config.Blockchain.NETWORK)) {
            try {
                let currentMarketApproved = await this
                    .getTokenContract()
                    .isApprovedForAll(
                        this.lastAddress, 
                        Config.Blockchain.MARKET
                    );

                if(this.lastMarketApproved !== currentMarketApproved) {
                    this.lastMarketApproved = currentMarketApproved;
                    stateChanged = true;
                }
            }
            catch(ex) {}
        }

        if(stateChanged) {
            triggerStateChange()(store.dispatch);
        }

        if(this.listenStartedCallback) {
            this.listenStartedCallback();
            this.listenStartedCallback = null;
        }

        this.mutex = false;
    }

    async startListening() {
        if(this.listening)
            return Promise.resolve();

        return new Promise((resolve, reject) => {
            this.listenStartedCallback = (error) => {
                if(error)
                    reject(error)
                else
                    resolve();
            }

            this.listening = true;
        })
    }

    async enable() {
        let enabled = await window.ethereum.request({method: 'eth_requestAccounts'})
        this.initContracts();
        return enabled;
    }

    isAvailable() {
        return !!(
            window.ethereum && 
            window.ethereum.isMetaMask &&
            window.ethereum.selectedAddress
        );
    }

    isLoading() {
        return this.lastNetwork === null;
    }

    getNetwork() {
        return this.parseNetworkId(this.lastNetwork);
    }

    isInNetwork(network) {
        let isInNetwork =
            this.parseNetworkId(this.lastNetwork) ===
            this.parseNetworkId(network)

        if(!isInNetwork && this.isAvailable()) {
            window
                .ethereum
                .request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: `0x${this.parseNetworkId(network).toString(16)}`}]
                })
                .catch(error => {
                    if(error.code === 4902) {
                        window
                            .ethereum
                            .request({
                                method: 'wallet_addEthereumChain', 
                                params: [Config.Blockchain.NETWORK_DATA]
                            })
                            .catch()
                    }
                });
        }
        return isInNetwork
    }

    getCurrentAddress() {
        return this.lastAddress;
    }

    isCurrentAddress(address) {
        return (
            (this.getCurrentAddress() || '').toLowerCase() ===
            (address || '').toLowerCase()
        )
    }

    getProvider() {
        if(!this.isAvailable())
            return null;
            
        return new window.ethers.providers.Web3Provider(window.ethereum);
    }

    async getBalance() {
        if(!this.lastAddress )
            return 0;

        let provider = this.getProvider();
        let balance = await provider?.getBalance(this.lastAddress);

        return (
            balance ?
            window.ethers.utils.formatEther(balance) : 
            0
        );
    }

    getTokenContract() {
        return this.tokenContract;
    }    

    getMarketContract() {
        return this.marketContract;
    }    

    isMarketApproved() {
        return this.lastMarketApproved;
    }

    initContracts() {
        if(!this.isAvailable() || (this.tokenContract && this.marketContract))
            return;

        const signer = this.getProvider().getSigner()

        if(!this.tokenContract)
            this.tokenContract = new window.ethers.Contract(
                Config.Blockchain.TOKEN, 
                TokenContract.abi, 
                signer
            );

        if(!this.marketContract)
            this.marketContract = new window.ethers.Contract(
                Config.Blockchain.MARKET, 
                MarketContract.abi, 
                signer
            );
    }

    updateContractNetwork() {
        if(!this.isAvailable())
            return;

        const signer = this.getProvider().getSigner()

        this.tokenContract = new window.ethers.Contract(
            Config.Blockchain.TOKEN, 
            TokenContract.abi, 
            signer
        );

        this.marketContract = new window.ethers.Contract(
            Config.Blockchain.MARKET, 
            MarketContract.abi, 
            signer
        );
    }

    getNetworkNameById(networkId) {
        let networkName = {
            1: 'Ethereum Mainnet',
            3: 'Ropsten Testnet',
            4: 'Rinkeby Testnet',
            137: 'Polygon'
        }

        let parsedNetworkId = this.parseNetworkId(networkId);
        return networkName[parsedNetworkId] || 'Unknown';
    }

    parseNetworkId(networkId) {
        return parseInt(
            networkId, 
            String(networkId).startsWith('0x') ? 16 : 10
        );
    }

    addPendingTransaction(hash, description, resourceType, resourceId, resourceHideInterval) {
        let pendingTx = []
        let unavailableResources = []

        try{
            pendingTx = JSON.parse(window.localStorage.__NFTMP_PENDING_TX);
        }
        catch(ex) {}

        try{
            unavailableResources = JSON.parse(window.localStorage.__NFTMP_UNAVAIL_RES);
        }
        catch(ex) {}

        pendingTx.push({
            hash, 
            description, 
            expires: Date.now() + 120000
        });

        unavailableResources.push({
            resourceType, 
            hash, 
            resourceId, 
            expires: Date.now() + (resourceHideInterval || 30000)
        });

        window.localStorage.__NFTMP_PENDING_TX = JSON.stringify(pendingTx);
        window.localStorage.__NFTMP_UNAVAIL_RES = JSON.stringify(unavailableResources);

        triggerStateChange()(store.dispatch);
    }

    removePendingTransaction(hash) {
        try{
            let pendingTx = JSON.parse(window.localStorage.__NFTMP_PENDING_TX);
            pendingTx = pendingTx.filter(tx => tx.hash !== hash);
            window.localStorage.__NFTMP_PENDING_TX = JSON.stringify(pendingTx);
        }
        catch(ex) {}
    }

    removeExpiredUnavailableResources() {
        try{
            let unavailableResources = JSON.parse(window.localStorage.__NFTMP_UNAVAIL_RES);
            unavailableResources = unavailableResources.filter(tx => tx.expires > Date.now());
            window.localStorage.__NFTMP_UNAVAIL_RES = JSON.stringify(unavailableResources);
        }
        catch(ex) {}
    }

    getUnavailableResources(resourceType) {
        try{
            let unavailableResources = JSON.parse(window.localStorage.__NFTMP_UNAVAIL_RES);
            return unavailableResources
                .filter(resource => resource.resourceType === resourceType)
                .filter(resource => resource.expires > Date.now())
                .map(resource => resource.resourceId)
        }
        catch(ex) {
            return []
        }
    }

    getPendingTransactions() {
        try{
            return JSON.parse(window.localStorage.__NFTMP_PENDING_TX);
        }
        catch(ex) {
            return [];
        }
    }

    isProcessingTransaction() {
        return this.getPendingTransactions().length > 0;
    }

    async _updateTxStatus() {
        let pendingTx = this.getPendingTransactions();
        let provider = this.getProvider();
        let txRemoved = false;

        for(let tx of pendingTx) {
            if(tx.expires < Date.now()) {
                this.removePendingTransaction(tx.hash);
                txRemoved = true;
                continue;
            }

            let txData = await provider.getTransaction(tx.hash)
            if(txData.confirmations > 0) {
                this.removePendingTransaction(tx.hash);
                txRemoved = true;
            }
        }

        this.removeExpiredUnavailableResources();

        return txRemoved;
    }

    getTokenDetails() {
        let explorerUrl = Config.Blockchain.NETWORK_DATA.blockExplorerUrls[0] || '';

        if(explorerUrl.charAt(explorerUrl.length - 1) === '/')
            explorerUrl = explorerUrl.substr(0, explorerUrl.length - 1);

        return {
            name: Config.Platform.NAME,
            networkId: Config.Blockchain.NETWORK,
            networkName: Config.Blockchain.NETWORK_DATA.chainName,
            explorerUrl
        };
    }
}

export default new MetaMask();