import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        borderLeftStyle: 'solid',
        borderLeftWidth: 1,
        borderLeftColor: Theme.TextColor.Lightest
    },
    userData: {
        flex: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    usersName: {
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 400,
        textDecoration: 'none',
        color: Theme.TextColor.Dark,
        '&:hover': {
            color: Theme.Palette.Primary.Foreground
        }
    },
    commentDate: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        textAlign: 'right'
    },
    bodyContainer: {
        flex: 1,
        margin: '0 0 0 65px'
    },
    commentBody: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: 0
    },
    replyContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    showReplyBoxButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        marginRight: 10,
        width: 'max-content',
        fontFamily: Theme.Font.Primary,
        fontSize: 10,
        fontWeight: 300,
        textDecoration: 'none',
        color: Theme.TextColor.Light,
        cursor: 'pointer',
        '&:hover': {
            color: Theme.Palette.Primary.Foreground,
            textDecoration: 'underline'
        }
    },
    commentBox: {
        flex: 1,
        resize: 'vertical',
        minHeight: 50,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: Theme.TextColor.Lighter,
        fontFamily: Theme.Font.Primary,
        fontWeight: 300,
        fontSize: 15,
        padding: 10,
        width: 'auto'
    },
    buttonHolder: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    commentButton: {
        padding: '8px 13px',
        margin: '10px 0'
    },
    arrowIcon: {
        width: 8,
        height: 8,
        margin: '5px',
        fill: 'transparent',
        strokeWidth: 2,
        stroke: Theme.TextColor.Dark
    }
});