import React from 'react';
import * as API from '~/API';
import Helpers from '~/helpers';
import _ from 'lodash';

import PopupContainer from '../PopupContainer';
import Button from '~/Components/Button';

import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';

const CreateDropPopup = (props) => {

    const classes = useStyles();
    const history = useHistory();

    const [isRequesting, setIsRequesting] = React.useState(false);
    const [requestError, setRequestError] = React.useState(null);
    const [dropName, setDropName] = React.useState('');

    const handleClose = (loginOk) => {
        if(_.isFunction(props.onClose))
            props.onClose(!!loginOk);
    }

    const handleCreateDrop = () => {
        if(isRequesting)
            return;
        
        setRequestError(null);
        setIsRequesting(true);

        API.Drop
            .create(dropName)
            .then(data => {
                setIsRequesting(false);
                props.onClose(true);
                history.push(`/admin/drops/${data.dropId}`)
            })
            .catch(err => {
                setIsRequesting(false);
                setRequestError(err);
            })
    }

    return (
        <PopupContainer
            isOpen={props.isOpen}
            onClose={handleClose}
            backdropColor="#0009">

            <div className={classes.container}>
                <p className={classes.title}>
                    Create Drop
                </p>

                    <input 
                        className={
                            Helpers.getRequestErrorField(requestError) === 'name' ?
                            classes.inputField + ' ' + classes.inputFieldInvalid :
                            classes.inputField
                        }
                        placeholder="Drop name"
                        type="text"
                        value={dropName}
                        onChange={(event) => {
                            setDropName(event.target.value)
                        }}
                        onKeyPress={(event) => { 
                            if(event.key === 'Enter') 
                                handleCreateDrop() 
                        }}
                        autoFocus/>

                    {
                        requestError &&
                        <p className={classes.errorMessage}>
                            { Helpers.getRequestErrorMessage(requestError) }
                        </p>
                    }

                    <Button
                        className={classes.button}
                        isLoading={isRequesting}
                        text={
                            isRequesting ?
                            "Creating..." :
                            "Create"
                        }
                        onClick={handleCreateDrop}/>

            </div>

        </PopupContainer>
    );
}

export default CreateDropPopup;