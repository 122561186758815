import { 
    USER_DATA_CREATIONS_FETCH_FAILED, 
    USER_DATA_CREATIONS_FETCH_FINISHED, 
    USER_DATA_CREATIONS_FETCH_START, 
    USER_DATA_ADDRESS_FETCH_FAILED, 
    USER_DATA_ADDRESS_FETCH_FINISHED, 
    USER_DATA_ADDRESS_FETCH_START, 
    USER_DATA_MEDIA_FETCH_FAILED, 
    USER_DATA_MEDIA_FETCH_FINISHED, 
    USER_DATA_MEDIA_FETCH_START, 
    USER_DATA_MEDIA_DETAILS_FETCH_FAILED, 
    USER_DATA_MEDIA_DETAILS_FETCH_FINISHED, 
    USER_DATA_MEDIA_DETAILS_FETCH_START, 
    USER_DATA_OFFERS_FETCH_FAILED, 
    USER_DATA_OFFERS_FETCH_FINISHED, 
    USER_DATA_OFFERS_FETCH_START, 
    USER_DATA_SALES_FETCH_FAILED, 
    USER_DATA_SALES_FETCH_FINISHED, 
    USER_DATA_SALES_FETCH_START 
} from './types';

import {
    STATE_NOT_PROCESSED,
    STATE_PROCESSING,
    STATE_DONE,
    STATE_FAILED
} from '../states'

const INITIAL_STATE = {
    creationId: null,
    
    ownedCreations: null,
    ownedCreationsState: STATE_NOT_PROCESSED,
    ownedCreationsError: null,
    
    userAddressList: null,
    userAddressListState: STATE_NOT_PROCESSED,
    userAddressListError: null,

    offers: null,
    showReceivedOffers: false,
    offerStatus: null,
    offersState: STATE_NOT_PROCESSED,
    offersError: null,

    sales: null,
    showPurchaseSales: false,
    salesState: STATE_NOT_PROCESSED,
    salesError: null,

    mediaList: null,
    mediaListState: STATE_NOT_PROCESSED,
    mediaListError: null,

    currentMediaId: null,
    mediaDetails: null,
    mediaDetailsState: STATE_NOT_PROCESSED,
    mediaDetailsError: null,    
}

const UserDataReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case USER_DATA_CREATIONS_FETCH_START:
            return OwnedCreationsReducers.fetchStart(state);
            
        case USER_DATA_CREATIONS_FETCH_FINISHED:
            return OwnedCreationsReducers.fetchFinished(state, action.payload);

        case USER_DATA_CREATIONS_FETCH_FAILED:
            return OwnedCreationsReducers.fetchFailed(state, action.payload);

        case USER_DATA_ADDRESS_FETCH_START:
            return UserAddressReducers.fetchStart(state);
            
        case USER_DATA_ADDRESS_FETCH_FINISHED:
            return UserAddressReducers.fetchFinished(state, action.payload);

        case USER_DATA_ADDRESS_FETCH_FAILED:
            return UserAddressReducers.fetchFailed(state, action.payload);
        
            
        case USER_DATA_MEDIA_FETCH_START:
            return UserMediaListReducers.fetchStart(state);
            
        case USER_DATA_MEDIA_FETCH_FINISHED:
            return UserMediaListReducers.fetchFinished(state, action.payload);

        case USER_DATA_MEDIA_FETCH_FAILED:
            return UserMediaListReducers.fetchFailed(state, action.payload);

        case USER_DATA_MEDIA_DETAILS_FETCH_START:
            return UserMediaDetailsReducers.fetchStart(state);
            
        case USER_DATA_MEDIA_DETAILS_FETCH_FINISHED:
            return UserMediaDetailsReducers.fetchFinished(state, action.payload);

        case USER_DATA_MEDIA_DETAILS_FETCH_FAILED:
            return UserMediaDetailsReducers.fetchFailed(state, action.payload);


        case USER_DATA_OFFERS_FETCH_START:
            return OfferReducers.fetchStart(state, action.payload);
            
        case USER_DATA_OFFERS_FETCH_FINISHED:
            return OfferReducers.fetchFinished(state, action.payload);

        case USER_DATA_OFFERS_FETCH_FAILED:
            return OfferReducers.fetchFailed(state, action.payload);


        case USER_DATA_SALES_FETCH_START:
            return SaleReducers.fetchStart(state, action.payload);
            
        case USER_DATA_SALES_FETCH_FINISHED:
            return SaleReducers.fetchFinished(state, action.payload);

        case USER_DATA_SALES_FETCH_FAILED:
            return SaleReducers.fetchFailed(state, action.payload);

        default:
            return state;
    }
}

class OwnedCreationsReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            ownedCreationsState: STATE_PROCESSING,
            ownedCreationsError: null
        }
    }

    static fetchFinished(state, data) {
        return { 
            ...state, 
            ownedCreations: data,
            ownedCreationsState: STATE_DONE
        }
    }

    static fetchFailed(state, data) {
        return { 
            ...state, 
            ownedCreationsState: STATE_FAILED,
            ownedCreationsError: data
        }
    }
}

class UserAddressReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            userAddressListState: STATE_PROCESSING,
            userAddressListError: null
        }
    }

    static fetchFinished(state, data) {
        return { 
            ...state, 
            userAddressList: data,
            userAddressListState: STATE_DONE
        }
    }

    static fetchFailed(state, data) {
        return { 
            ...state, 
            userAddressListState: STATE_FAILED,
            userAddressListError: data
        }
    }
}

class OfferReducers {

    static fetchStart(state, data) {
        return { 
            ...state, 
            showReceivedOffers: data.incoming,
            offerStatus: data.status,
            offersState: STATE_PROCESSING,
            offersError: null
        }
    }

    static fetchFinished(state, data) {
        return { 
            ...state, 
            offers: data,
            offersState: STATE_DONE
        }
    }

    static fetchFailed(state, data) {
        return { 
            ...state, 
            offersState: STATE_FAILED,
            offersError: data
        }
    }
}

class SaleReducers {

    static fetchStart(state, data) {
        return { 
            ...state, 
            showPurchaseSales: data.purchases,
            salesState: STATE_PROCESSING,
            salesError: null
        }
    }

    static fetchFinished(state, data) {
        return { 
            ...state, 
            sales: data,
            salesState: STATE_DONE
        }
    }

    static fetchFailed(state, data) {
        return { 
            ...state, 
            salesState: STATE_FAILED,
            salesError: data
        }
    }
}

class UserMediaListReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            mediaListState: STATE_PROCESSING,
            mediaListError: null
        }
    }

    static fetchFinished(state, data) {
        return { 
            ...state, 
            mediaList: data,
            mediaListState: STATE_DONE
        }
    }

    static fetchFailed(state, data) {
        return { 
            ...state, 
            mediaListState: STATE_FAILED,
            mediaListError: data
        }
    }
}

class UserMediaDetailsReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            mediaDetailsState: STATE_PROCESSING,
            mediaDetailsError: null
        }
    }

    static fetchFinished(state, data) {
        return { 
            ...state, 
            mediaDetails: data,
            currentMediaId: data.mediaId,
            mediaDetailsState: STATE_DONE
        }
    }

    static fetchFailed(state, data) {
        return { 
            ...state, 
            mediaDetailsState: STATE_FAILED,
            mediaDetailsError: data
        }
    }
}

export default UserDataReducer;