import './App.css';
import { store } from '~/Redux';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import UIEventMonitor from './Auxilary/UIEventMonitor';
import ScrollToTop from './Auxilary/ScrollToTop';
import Config from './config';

import HomePage from './Views/HomePage';
import CreationDetails from './Views/CreationDetails';
import ProfileDetails from './Views/ProfileDetails';
import Discover from './Views/Discover';
import PopupInjector from './Auxilary/PopupInjector';
import AuthenticityPage from './Views/AuthenticityPage';
import SettingsPage from './Views/SettingsPage';
import DropDetails from './Views/DropDetails';
import DropsPage from './Views/DropsPage';
import Helpers from './helpers';
import TOS from './Views/TOS';
import FAQ from './Views/FAQ';
import PrivacyPolicy from './Views/PrivacyPolicy';
import Giveaway from './Views/Giveaway';

function App() {

	Helpers.updateCoinPrice()

	return (
        <Provider store={store}>
			<UIEventMonitor>
				<BrowserRouter basename={Config.Server.APP_BASE_URL}>
					<ScrollToTop/>
					<Switch>

						<Route
							path="/drops/:dropId"
							component={DropDetails}/>

						<Route
							path="/drops"
							component={DropsPage}/>

						<Route
							path="/profile/:page/:resourceId"
							component={SettingsPage}/>

						<Route
							path="/profile/:page"
							component={SettingsPage}/>

						<Route
							path="/admin/:page/:resourceId"
							component={SettingsPage}/>

						<Route
							path="/admin/:page"
							component={SettingsPage}/>

						<Route
							path="/authenticity/:creationId"
							component={AuthenticityPage}/>

						<Route
							path="/discover/:discoverPage"
							component={Discover}/>

						<Route
							path="/discover"
							component={Discover}/>

						<Route
							path="/profiles/:username/:requestedPage"
							component={ProfileDetails}/>

						<Route
							path="/profiles/:username"
							component={ProfileDetails}/>

						<Route
							path="/creations/:creationId"
							component={CreationDetails}/>

						<Route
							path="/termsofservice"
							component={TOS}/>

						<Route
							path="/privacypolicy"
							component={PrivacyPolicy}/>

						<Route
							path="/faq"
							component={FAQ}/>

						<Route
							path="/giveaway"
							component={Giveaway}/>

						<Route path="/profile">
							<Redirect to="/profile/settings"/>
						</Route>

						<Route
							path="/"
							component={HomePage}/>
					</Switch>
					<PopupInjector/>
				</BrowserRouter>
			</UIEventMonitor>
		</Provider>
	);
}

export default App;
