import React from 'react';
import Helpers from '~/helpers';
import Config from '~/config';

import PopupManager from '~/Auxilary/PopupManager';
import Button from '~/Components/Button';
import { useStyles } from './styles';
import _ from 'lodash';

const BidSection = (props) => {

    const classes = useStyles();
    const [offerValue, setOfferValue] = React.useState('');
    const [minPrice, setMinPrice] = React.useState(0);
    const [isValidBidValue, setIsValidBidValue] = React.useState(false);

    React.useEffect(
        () => {
            let lowestValue =
                Helpers.weiToEth(props.creationData.auction.lastBidAmount) ?
                Helpers.weiToEth(props.creationData.auction.lastBidAmount) * 1.05 :
                Helpers.weiToEth(props.creationData.auction.startPrice)

            setMinPrice(Math.ceil(lowestValue * 10000) / 10000);
        },
        [offerValue, props.creationData]
    )

    React.useEffect(
        () => {
            setIsValidBidValue(
                _.isFinite(parseFloat(offerValue)) &&
                parseFloat(offerValue) >= minPrice
            );
        },
        [offerValue, minPrice]
    )

    const handleBidClicked = () => {
        if(_.isFunction(props.onBidClicked))
            props.onBidClicked(
                props.creationData?.blockchain?.tokenId, 
                offerValue
            );
    }

    const handleBuyCrypto = () => {
        PopupManager.pushBuyCryptoPopup(
            props.userAddress.address, 
            props.userAddress.addressSignature
        )
    }

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Bidding details
            </p>

            <p className={classes.purchaseDescription}>
                We will use your digital wallet to submit your bid. You'll be asked to confirm your offer amount with your digital wallet.
            </p>

            <div className={classes.priceDetailsContainer}>
                <p className={classes.paymentAmountLabel}>
                    Minimum Bid Amount:
                </p>

                <p 
                    className={classes.price}
                    onClick={() => {setOfferValue(Helpers.formatEthPrice(minPrice))}}>
                    {Helpers.formatEthPrice(minPrice)} {Config.Blockchain.COIN}
                </p>
            </div>

            <div className={classes.offerDetailsContainer}>

                <input 
                    className={classes.offerValue}
                    value={offerValue}
                    min={minPrice}
                    step={0.001}
                    onChange={event => {setOfferValue(event.target.value)}}
                    placeholder={`Bid amount in ${Config.Blockchain.COIN}`}
                    type="number"/>

                <p className={classes.priceUsd}>
                    $ {Helpers.formatUsdPrice(Helpers.getEthPrice() * offerValue)}
                </p>
            </div>

            <Button
                className={classes.purchaseButton}
                text={
                    props.isSendingOffer ?
                    'Sending bid...' :
                    'Bid'
                }
                isLoading={props.isSendingOffer}
                disabled={!isValidBidValue}
                onClick={handleBidClicked}
                inverted/>

            <p className={classes.whatAreYouPurchasing} onClick={handleBuyCrypto}>
                Not enough funds? <strong>Buy {Config.Blockchain.COIN}!</strong>
            </p>

        </div>
    );
}

export default BidSection;