export const CREATION_DATA_FETCH_START = 'CREATION_DATA_FETCH_START';
export const CREATION_DATA_FETCH_FINISHED = 'CREATION_DATA_FETCH_FINISHED';
export const CREATION_DATA_FETCH_FAILED = 'CREATION_DATA_FETCH_FAILED';

export const CREATION_COMMENTS_FETCH_START = 'CREATION_COMMENTS_FETCH_START';
export const CREATION_COMMENTS_FETCH_FINISHED = 'CREATION_COMMENTS_FETCH_FINISHED';
export const CREATION_COMMENTS_FETCH_FAILED = 'CREATION_COMMENTS_FETCH_FAILED';

export const CREATION_ACTIVITY_FETCH_START = 'CREATION_ACTIVITY_FETCH_START';
export const CREATION_ACTIVITY_FETCH_FINISHED = 'CREATION_ACTIVITY_FETCH_FINISHED';
export const CREATION_ACTIVITY_FETCH_FAILED = 'CREATION_ACTIVITY_FETCH_FAILED';

export const CREATION_EDITIONS_FETCH_START = 'CREATION_EDITIONS_FETCH_START';
export const CREATION_EDITIONS_FETCH_FINISHED = 'CREATION_EDITIONS_FETCH_FINISHED';
export const CREATION_EDITIONS_FETCH_FAILED = 'CREATION_EDITIONS_FETCH_FAILED';

export const CREATION_LIKE_STATE_CHANGED = 'CREATION_LIKE_STATE_CHANGED';
export const CREATION_OWNER_FOLLOWING_STATE_CHANGED = 'CREATION_OWNER_FOLLOWING_STATE_CHANGED';
