import React from 'react';
import Helpers from '~/helpers';
import Config from '~/config';

import ProfilePicture from '../ProfilePicture';

import { Link } from 'react-router-dom';
import { useStyles } from './styles';

const ActivityItem = (props) => {
    const classes = useStyles();

    const getActivityText = (username, name, creationId, metadata) => {
        switch(props.activity) {
            case 'CREATE':
                return (
                    <div className={classes.activityTextContainer}>
                        {
                            username ?
                            <Link 
                                to={`/profiles/${username}/`}
                                className={classes.activityLink}>
                                {name}
                            </Link> :
                            <p 
                                to={`/profiles/${username}/`}
                                className={classes.activityLink}>
                                {name}
                            </p>
                        }
                        <p className={classes.activityText}>published</p>
                        <Link 
                            to={`/creations/${creationId}/`}
                            className={classes.activityLink}>
                            new creation
                        </Link>
                    </div>
                );

            case 'COMMENT':
                return (
                    <div className={classes.activityTextContainer}>
                        <Link 
                            to={`/creations/${creationId}/`}
                            className={classes.activityLink}>
                            Comment made
                        </Link>
                        <p className={classes.activityText}>by</p>
                        {
                            username ?
                            <Link 
                                to={`/profiles/${username}/`}
                                className={classes.activityLink}>
                                {name}
                            </Link> :
                            <p 
                                to={`/profiles/${username}/`}
                                className={classes.activityLink}>
                                {name}
                            </p>
                        }
                    </div>
                );

            case 'LIKE':
                return (
                    <div className={classes.activityTextContainer}>
                        <Link 
                            to={`/creations/${creationId}/`}
                            className={classes.activityLink}>
                            Liked
                        </Link>
                        <p className={classes.activityText}>by</p>
                        {
                            username ?
                            <Link 
                                to={`/profiles/${username}/`}
                                className={classes.activityLink}>
                                {name}
                            </Link> :
                            <p 
                                to={`/profiles/${username}/`}
                                className={classes.activityLink}>
                                {name}
                            </p>
                        }
                    </div>
                );

            case 'OFFER':
                return (
                    <div className={classes.activityTextContainer}>
                        <Link 
                            to={`/creations/${creationId}/`}
                            className={classes.activityLink}>
                            Offer made
                        </Link>
                        <p className={classes.activityText}>by</p>
                        {
                            username ?
                            <Link 
                                to={`/profiles/${username}/`}
                                className={classes.activityLink}>
                                {name}
                            </Link> :
                            <p 
                                to={`/profiles/${username}/`}
                                className={classes.activityLink}>
                                {name}
                            </p>
                        }
                        <p className={classes.activityText}>for</p>
                        <p className={classes.activityText}>
                            {Helpers.formatEthPrice(Helpers.weiToEth(metadata.amount))} {Config.Blockchain.COIN} (${Helpers.formatUsdPrice(metadata.coinPrice * Helpers.weiToEth(metadata.amount))})
                        </p>
                    </div>
                );
            
            case 'PURCHASE':
                return (
                    <div className={classes.activityTextContainer}>
                        <Link 
                            to={`/creations/${creationId}/`}
                            className={classes.activityLink}>
                            Purchase made
                        </Link>
                        <p className={classes.activityText}>by</p>
                        {
                            username ?
                            <Link 
                                to={`/profiles/${username}/`}
                                className={classes.activityLink}>
                                {name}
                            </Link> :
                            <p 
                                to={`/profiles/${username}/`}
                                className={classes.activityLink}>
                                {name}
                            </p>
                        }
                        <p className={classes.activityText}>for</p>
                        <p className={classes.activityText}>
                            {Helpers.formatEthPrice(Helpers.weiToEth(metadata.amount))} {Config.Blockchain.COIN} (${Helpers.formatUsdPrice(metadata.coinPrice * Helpers.weiToEth(metadata.amount))})
                        </p>
                    </div>
                );

            case 'GIFT':
                return (
                    <div className={classes.activityTextContainer}>
                        <Link 
                            to={`/creations/${creationId}/`}
                            className={classes.activityLink}>
                            Gifted
                        </Link>
                        <p className={classes.activityText}>by</p>
                        {
                            username ?
                            <Link 
                                to={`/profiles/${username}/`}
                                className={classes.activityLink}>
                                {name}
                            </Link> :
                            <p 
                                to={`/profiles/${username}/`}
                                className={classes.activityLink}>
                                {name}
                            </p>
                        }
                    </div>
                );

            default:
                return (
                    <div className={classes.activityTextContainer}></div>
                );
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.childContainer}>
                { props.children }
            </div>

            <div className={classes.activityContainer}>

                <ProfilePicture
                    imageUrl={props.profilePictureUrl}
                    username={props.username}
                    style={{margin: 4}}
                    size={30}/>

                <div className={classes.activityDetails}>

                    { 
                        getActivityText(
                            props.username,
                            props.name,
                            props.creationId,
                            props.metadata
                        ) 
                    }

                    <p className={classes.timeText}>
                        {
                            Helpers.getElapsedTimeString(props.createdAt)
                        }
                    </p>

                </div>

            </div>

        </div>
    );
}

export default ActivityItem;