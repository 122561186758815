import {
    PROFILE_FETCH_START,
    PROFILE_FETCH_FINISHED,
    PROFILE_FETCH_FAILED,
    PROFILE_CREATIONS_FETCH_START,
    PROFILE_CREATIONS_FETCH_FINISHED,
    PROFILE_CREATIONS_FETCH_FAILED,
    PROFILE_COLLECTION_FETCH_START,
    PROFILE_COLLECTION_FETCH_FINISHED,
    PROFILE_COLLECTION_FETCH_FAILED,
    PROFILE_FOLLOWING_STATE_CHANGED,
    PROFILE_FOLLOWER_FETCH_START,
    PROFILE_FOLLOWER_FETCH_FINISHED,
    PROFILE_FOLLOWER_FETCH_FAILED,
    PROFILE_FOLLOWING_FETCH_START,
    PROFILE_FOLLOWING_FETCH_FINISHED,
    PROFILE_FOLLOWING_FETCH_FAILED,
} from './types';

import {
    STATE_NOT_PROCESSED,
    STATE_PROCESSING,
    STATE_DONE,
    STATE_FAILED
} from '../states'

const INITIAL_STATE = {
    username: null,

    data: null,
    dataState: STATE_NOT_PROCESSED,

    creations: [],
    creationsState: STATE_NOT_PROCESSED,

    collection: [],
    collectionState: STATE_NOT_PROCESSED,

    followers: [],
    followersState: STATE_NOT_PROCESSED,
    followersLoaded: false,

    following: [],
    followingState: STATE_NOT_PROCESSED,
    followingLoaded: false,
}

const ProfileReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case PROFILE_FETCH_START:
            return ProfileDataReducers.fetchStart(state);
            
        case PROFILE_FETCH_FINISHED:
            return ProfileDataReducers.fetchFinished(state, action.payload);

        case PROFILE_FETCH_FAILED:
            return ProfileDataReducers.fetchFailed(state);

        case PROFILE_FOLLOWING_STATE_CHANGED:
            return ProfileDataReducers.updateFollowState(state, action.payload);


        case PROFILE_CREATIONS_FETCH_START:
            return ProfileCreationReducers.fetchStart(state);
            
        case PROFILE_CREATIONS_FETCH_FINISHED:
            return ProfileCreationReducers.fetchFinished(state, action.payload);

        case PROFILE_CREATIONS_FETCH_FAILED:
            return ProfileCreationReducers.fetchFailed(state);


        case PROFILE_COLLECTION_FETCH_START:
            return ProfileCollectionReducers.fetchStart(state);
            
        case PROFILE_COLLECTION_FETCH_FINISHED:
            return ProfileCollectionReducers.fetchFinished(state, action.payload);

        case PROFILE_COLLECTION_FETCH_FAILED:
            return ProfileCollectionReducers.fetchFailed(state);


        case PROFILE_FOLLOWER_FETCH_START:
            return ProfileFollowerReducers.fetchStart(state);
            
        case PROFILE_FOLLOWER_FETCH_FINISHED:
            return ProfileFollowerReducers.fetchFinished(state, action.payload);

        case PROFILE_FOLLOWER_FETCH_FAILED:
            return ProfileFollowerReducers.fetchFailed(state);   
            
            
        case PROFILE_FOLLOWING_FETCH_START:
            return ProfileFollowingReducers.fetchStart(state);
            
        case PROFILE_FOLLOWING_FETCH_FINISHED:
            return ProfileFollowingReducers.fetchFinished(state, action.payload);

        case PROFILE_FOLLOWING_FETCH_FAILED:
            return ProfileFollowingReducers.fetchFailed(state);   


        default:
            return state;
    }
}

class ProfileDataReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            data: null,
            dataState: STATE_PROCESSING
        }
    }

    static fetchFinished(state, data) {

        let isExistingProfile = 
            state.username === data.username;

        return { 
            ...state, 
            username: data.username,
            
            data: data,
            dataState: STATE_DONE,

            creations: isExistingProfile ? state.creations : [],
            creationsState: isExistingProfile ? state.creationsState : STATE_NOT_PROCESSED,

            collection: isExistingProfile ? state.collection : [],
            collectionState: isExistingProfile ? state.collectionState : STATE_NOT_PROCESSED,

            followers: isExistingProfile ? state.followers : [],
            followersState: isExistingProfile ? state.followersState : STATE_NOT_PROCESSED,
            followersLoaded: isExistingProfile ? state.followersLoaded : false,

            following: isExistingProfile ? state.following : [],
            followingState: isExistingProfile ? state.followingState : STATE_NOT_PROCESSED,
            followingLoaded: isExistingProfile ? state.followingLoaded : false,
        }
    }

    static fetchFailed(state) {
        return { 
            ...state, 
            dataState: STATE_FAILED
        }
    }

    static updateFollowState(state, data) {
        if(state.username !== data.username)
            return state;

        let followers = state.data.stats.followers + (data.isFollowing ? 1 : -1);
        followers = followers < 0 ? 0 : followers;

        return {
            ...state,
            data: {
                ...state.data,
                stats: {
                    ...state.data.stats,
                    followers
                }
            }
        }
    }
}

class ProfileCreationReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            creationsState: STATE_PROCESSING
        }
    }

    static fetchFinished(state, data) {
        return { 
            ...state, 
            creations: [
                ...state.creations, 
                ...data
            ],
            creationsState: STATE_DONE
        }
    }

    static fetchFailed(state) {
        return { 
            ...state, 
            creationsState: STATE_FAILED
        }
    }
}

class ProfileCollectionReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            collectionState: STATE_PROCESSING
        }
    }

    static fetchFinished(state, data) {
        return { 
            ...state, 
            collection: [
                ...state.collection, 
                ...data
            ],
            collectionState: STATE_DONE
        }
    }

    static fetchFailed(state) {
        return { 
            ...state, 
            collectionState: STATE_FAILED
        }
    }
}

class ProfileFollowerReducers {

    static fetchStart(state) {
        if(state.followersLoaded)
            return state;

        return { 
            ...state, 
            followersState: STATE_PROCESSING
        }
    }

    static fetchFinished(state, data) {
        return { 
            ...state, 
            followers: [
                ...state.followers,
                ...data
            ],
            followersLoaded: data.length < 20,
            followersState: STATE_DONE
        }
    }

    static fetchFailed(state) {
        return { 
            ...state, 
            followersState: STATE_FAILED
        }
    }
}

class ProfileFollowingReducers {

    static fetchStart(state) {
        if(state.followingLoaded)
            return state;

        return { 
            ...state, 
            followingState: STATE_PROCESSING
        }
    }

    static fetchFinished(state, data) {
        return { 
            ...state, 
            following: [
                ...state.following,
                ...data
            ],
            followingLoaded: data.length < 20,
            followingState: STATE_DONE
        }
    }

    static fetchFailed(state) {
        return { 
            ...state, 
            followingState: STATE_FAILED
        }
    }
}

export default ProfileReducer;