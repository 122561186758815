import React from 'react';

import * as API from '~/API';

import PopupManager from '~/Auxilary/PopupManager';
import Button from '~/Components/Button';
import SettingsStateItem from './SettingsStateItem';

import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';

const CreatorRequestsDetails = (props) => {

    const classes = useStyles();    
    const history = useHistory();

    const [initialValuesSet, setInitialValuesSet] = React.useState(false);
    const [isUpdatingData, setIsUpdatingData] = React.useState(false);
    const [isApproving, setIsApproving] = React.useState(false);
    const [isDenying, setIsDenying] = React.useState(false);
    const [userData, setUserData] = React.useState(null);
    const [isEmailVerified, setIsEmailVerified] = React.useState(false);
    const [isLinksProvided, setIsLinksProvided] = React.useState(false);
    const [isWalletConnected, setIsWalletConnected] = React.useState(false);

    React.useEffect(
        () => {
            if (!initialValuesSet) {
                setInitialValuesSet(true);
                API.Admin
                    .getUser(props.username)
                    .then(data => {
                        setUserData(data);
                        setIsEmailVerified(data.verified);
                        setIsLinksProvided(data.about?.links?.length > 0);
                        setIsWalletConnected(!!data.address);
                    })
                    .catch(() => {
                        history.push('/admin/creator-requests')
                    })
            }
        },
        [props.username, initialValuesSet, history]
    )

    const handleApprove = () => {
        setIsUpdatingData(true);
        setIsApproving(true);
        PopupManager
            .pushCreatorRequestApprovePopup(userData.address)
            .then(response => {
                if(response) {
                    API.Admin
                        .updateCreatorRequest(
                            props.username,
                            true,
                            false,
                            'Approved'
                        )
                        .then(() => {
                            history.push('/admin/creator-requests');
                            setIsUpdatingData(false);
                            setIsApproving(false);
                        })
                        .catch(() => {
                            setIsUpdatingData(false);
                            setIsApproving(false);
                        })
                }
                else {
                    setIsUpdatingData(false);
                    setIsApproving(false);
                }
            })
            .catch(() => {
                setIsUpdatingData(false);
                setIsApproving(false);
            })
    }

    const handleDeny = () => {
        setIsUpdatingData(true);
        setIsDenying(true);
        PopupManager
            .pushCreatorRequestDenyPopup()
            .then(response => {
                if(response) {
                    API.Admin
                        .updateCreatorRequest(
                            props.username,
                            false,
                            response.denyRequests,
                            response.comments
                        )
                        .then(() => {
                            history.push('/admin/creator-requests');
                            setIsUpdatingData(false);
                            setIsDenying(false);
                        })
                        .catch(() => {
                            setIsUpdatingData(false);
                            setIsDenying(false);
                        })
                }
                else {
                    setIsUpdatingData(false);
                    setIsDenying(false);
                }
            })
            .catch(() => {
                setIsUpdatingData(false);
                setIsDenying(false);
            })
    }

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                {
                    userData && `${userData.name}'s Request`
                }
            </p>

            <p className={classes.label}>
                The following checks represent the requesting user's profile state. It is recommended that all the checks are a pass before approving the Request.
            </p>

            <SettingsStateItem
                isPass={isEmailVerified}
                description="Has verified email address"/>

            <SettingsStateItem
                isPass={isLinksProvided}
                description="Provided links to social media accounts"/>

            <SettingsStateItem
                isPass={isWalletConnected}
                description="Connect your blockchain wallet to your profile"/>
            

            <div style={{height: 20}}/>

            <p className={classes.label}>
                Provided link to examples of best, past work:
            </p>

            <a 
                href={userData?.creatorRequest?.bestWork}
                className={classes.inputField}>
                { userData?.creatorRequest?.bestWork }
            </a>

            <p className={classes.label}>
                Provided link to three artworks planned to be published:
            </p>

            <a 
                href={userData?.creatorRequest?.worksToPublish}
                className={classes.inputField}>
                { userData?.creatorRequest?.worksToPublish }
            </a>

            <p className={classes.label}>
                User's achievements and awards:
            </p>

            <textarea
                disabled 
                style={{
                    minHeight: 150
                }}
                className={classes.inputField}
                value={userData?.creatorRequest?.achievements}/>

            <div className={classes.buttonContainer}>

                <p className={classes.updateErrorMessage}>

                </p>

                <Button
                    className={classes.updateButton}
                    style={{marginRight: 20}}
                    text={
                        isUpdatingData ?
                        'Approving...' : 
                        'Approve'
                    }
                    disabled={isUpdatingData}
                    isLoading={isApproving}
                    onClick={handleApprove}/>

                <Button
                    className={classes.updateButton}
                    text={
                        isUpdatingData ?
                        'Denying...' : 
                        'Deny'
                    }
                    disabled={isUpdatingData}
                    isLoading={isDenying}
                    onClick={handleDeny}
                    secondary/>

            </div>

            <div style={{height: 50}}/>

        </div>
    );
}


export default CreatorRequestsDetails;