import { 
    BLOCKCHAIN_STATE_CHANGED
} from './types';

const INITIAL_STATE = {
    stateChangedTrigger: false,
}

const BlockchainReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case BLOCKCHAIN_STATE_CHANGED:
            return {
                ...state,
                stateChangedTrigger: !state.stateChangedTrigger
            };
    
        default:
            return state;
    }
}

export default BlockchainReducer;