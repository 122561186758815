import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        width: 500,
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 26,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        textAlign: 'center',
        margin: '25px 0'
    },
    noUsers: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        textAlign: 'center',
        color: Theme.TextColor.Dark,
        margin: 30
    },
    followerListContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20,
        maxHeight: 400,
        overflow: 'hidden auto'
    },
    buttonContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        padding: 10,
        fontSize: 12,
        width: 100,
        margin: '10px 20px 30px 20px'
    },
    errorMessage: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        fontWeight: 300,
        color: '#f00',
        cursor: 'default',
        margin: 5
    },
    '@media only screen and (max-width: 500px)': {
        container: {
            width: 375
        }
    },
});