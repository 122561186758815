import React from 'react';
import Images from '~/Assets/images';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';

const ProfilePicture = (props) => {
    const classes = useStyles();
    const imageElement =                 
        <div 
            alt="" 
            title={props.username}
            style={{
                borderWidth: props.borderWidth || 1,
                backgroundImage: `url(${props.imageUrl || Images.ProfilePicturePlaceholder})`
            }}
            className={
                props.highlightOnHover ?
                classes.image + ' ' + classes.imageHoverBorder :
                classes.image
            }/>

    return (
        <div 
            className={classes.container}
            style={{
                ...props.style,
                width: props.size || 50,
                height: props.size || 50,
            }}>
            {
                props.username ?
                <Link to={`/profiles/${props.username}/`}>{imageElement}</Link> :
                imageElement
            }
        </div>
    );
}

export default ProfilePicture;