import React, { Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Config from '~/config';

import * as CreationActions from '~/Redux/Creation/actions';
import * as UserActions from '~/Redux/User/actions';
import { STATE_DONE, STATE_NOT_PROCESSED } from '~/Redux/states';

import MainHeader from '~/Components/MainHeader';
import CreationInfo from '~/Components/CreationInfo';
import ProfileHeader from '~/Components/ProfileHeader';
import CommentSection from '~/Components/CommentSection';
import CreationMediaView from '~/Components/CreationMediaView';
import CreationDetailTabs from '~/Components/CreationDetailTabs';
import CreationTableHeader from '~/Components/CreationTableHeader';
import CreationTableActivityItem from '~/Components/CreationTableActivityItem';
import CreationTableEditionActivityItem from '~/Components/CreationTableEditionActivityItem';
import CreationTableAdditionalDetailsList from '~/Components/CreationTableAdditionalDetailsList';

import { useStyles } from './styles';
import PopupManager from '~/Auxilary/PopupManager';
import LoadingCover from '~/Popups/LoadingCover';
import Footer from '~/Components/Footer';
import MetaMask from '~/Services/MetaMask';

const CreationDetails = () => {

    const classes = useStyles();
    const history = useHistory()
    const { creationId } = useParams();
    
    const [dataReloadRequired, setDataReloadRequired] = React.useState(true);
    const [isFollowingProfile, setIsFollowingProfile] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState(0);

    const dispatch = useDispatch();

    const userData = useSelector(state => state.User);

    const creationData = useSelector(state => state.Creation.creationData);
    const creationDataState = useSelector(state => state.Creation.creationDataState);

    const creationEditions = useSelector(state => state.Creation.editions);
    const creationEditionsState = useSelector(state => state.Creation.editionsState);

    const creationComments = useSelector(state => state.Creation.comments);
    const creationCommentsState = useSelector(state => state.Creation.commentsState);

    const creationActivity = useSelector(state => state.Creation.activity);
    const creationActivityState = useSelector(state => state.Creation.activityState);
    
    const pageWidth = useSelector(state => state.UI.pageWidth);

    const handleTabChanged = (tabIndex) => {
        setSelectedTab(tabIndex);

        if(tabIndex === 1 && creationEditionsState === STATE_NOT_PROCESSED)
            dispatch(CreationActions.getCreationEditions(creationId));
    }

    React.useEffect(
        () => {
            if (dataReloadRequired) {
                setDataReloadRequired(false);
                dispatch(CreationActions.getCreationData(creationId));
            }
            
            if(creationDataState === STATE_DONE && creationCommentsState === STATE_NOT_PROCESSED) {
                dispatch(CreationActions.getCreationComments(creationId));
            }

            if(creationDataState === STATE_DONE && creationActivityState === STATE_NOT_PROCESSED) {
                dispatch(CreationActions.getCreationActivity(creationId));
            }
        }, 
        [
            creationId, 
            creationData, 
            creationDataState, 
            dataReloadRequired,
            creationCommentsState, 
            creationActivityState, 
            dispatch
        ]
    )

    React.useEffect(
        () => {
            if (!userData.data && userData.token && 
                userData.fetchDataState === STATE_NOT_PROCESSED) {
                dispatch(UserActions.getData());
            }
        },
        [userData, dispatch]
    )

    React.useEffect(
        () => {
            setIsFollowingProfile(
                creationData ? 
                userData.data?.following.includes(creationData.owner?.username) : 
                false
            )
        },
        [userData, creationData]
    )  

    const creationLiked = !!userData.data?.likes?.find(
        creationId => creationId === creationData?.creationId
    )

    const handlePurchase = () => {
        if(userData?.data?.verified)
            PopupManager.pushPurchasePopup(creationData, true);
        else if(userData?.data)
            PopupManager.pushMessagePopup(
                'Verification required',
                ['In order to purchase a creation, your email address must be verified.', 'Do you want to verify your email address now?'],
                null, true, 'Yes', 'No'
            ).then(confirm => {
                if(confirm)
                    history.push('/profile/settings')
            })
        else
            PopupManager.pushLogin();
    }

    const handleMakeOffer = () => {
        if(userData?.data?.verified)
            PopupManager.pushPurchasePopup(creationData, false);
        else if(userData?.data)
            PopupManager.pushMessagePopup(
                'Verification required',
                ['In order to make an offer on a creation, your email address must be verified.', 'Do you want to verify your email address now?'],
                null, true, 'Yes', 'No'
            ).then(confirm => {
                if(confirm)
                    history.push('/profile/settings')
            })
        else
            PopupManager.pushLogin();
    }

    const handleBid = () => {
        if(userData?.data?.verified)
            PopupManager.pushPurchasePopup(creationData, false, null, true);
        else if(userData?.data)
            PopupManager.pushMessagePopup(
                'Verification required',
                ['In order to make an offer on a creation, your email address must be verified.', 'Do you want to verify your email address now?'],
                null, true, 'Yes', 'No'
            ).then(confirm => {
                if(confirm)
                    history.push('/profile/settings')
            })
        else
            PopupManager.pushLogin();
    }

    const handleFinishAuction = () => {
        MetaMask
            .startListening()
            .then(() => {

            MetaMask
                .getMarketContract()
                ?.resolve(creationData?.blockchain?.tokenId)
                .then(() => {
                    PopupManager.pushMessagePopup(
                        'Transaction sent', 
                        ['The transaction for resolving this token auction has been sent. Please give the platform a couple of minutes to synchronize with the blockchain!'], 
                        'PASS'
                    )
                })
                .catch(error => {
                    PopupManager.pushMessagePopup(
                        'Auction finish failed',
                        [ 
                            `Failed to resolve the auction for this token. ${
                                error.error?.message?.split('execution reverted: ').join('') ||
                                error.message.split('MetaMask Tx Signature: ').join('')
                            }`
                        ],
                        'FAIL'
                    );
                })
            })
    }

    const handleLike = () => {
        if(userData?.data?.verified)
            dispatch(
                UserActions.setLikeCreation(
                    creationData.creationId,
                    !creationLiked
                )
            )
        else if(userData?.data)
            PopupManager.pushMessagePopup(
                'Verification required',
                ['In order to like a creation, your email address must be verified.', 'Do you want to verify your email address now?'],
                null, true, 'Yes', 'No'
            ).then(confirm => {
                if(confirm)
                    history.push('/profile/settings')
            })
        else
            PopupManager.pushLogin();
    }

    return (
        <Fragment>
            <LoadingCover visible={creationDataState !== STATE_DONE}/>
            
            <MainHeader
                username={userData.data?.username}
                profilePictureUrl={userData.data?.profilePictureUrl}
                userType={userData.data?.role}
                isLoggedIn={!!userData.data}
                isMobile={pageWidth < 800}/>

            {
                creationData ?
                <div className={classes.pageContainer}>

                    <ProfileHeader
                        username={creationData.owner.username}
                        profileName={creationData.owner.name}
                        profilePictureUrl={creationData.owner.profilePictureUrl}
                        currentPage={
                            creationData.creators.find(
                                creator => creationData.owner.username === creator.username
                            ) ?
                            Config.UI.ProfilePages.CREATIONS :
                            Config.UI.ProfilePages.COLLECTION
                        }
                        followers={creationData.owner.stats.followers}
                        following={creationData.owner.stats.following}
                        views={creationData.owner.stats.views}
                        userType={creationData.owner.role}
                        isFollowing={isFollowingProfile}
                        isSelf={creationData.owner.username === userData?.username}
                        onFollowClicked={() => {
                            if(userData?.data?.verified)
                                dispatch(
                                    UserActions.setFollowing(
                                        creationData.owner.username, 
                                        !isFollowingProfile
                                    )
                                )
                            else if(userData?.data)
                                PopupManager.pushMessagePopup(
                                    'Verification required',
                                    ['In order to follow another user, your email address must be verified.', 'Do you want to verify your email address now?'],
                                    null, true, 'Yes', 'No'
                                ).then(confirm => {
                                    if(confirm)
                                        history.push('/profile/settings')
                                })
                            else
                                PopupManager.pushLogin(); 
                            
                        }} />

                    <div className={classes.pageBody}>

                        <div className={classes.topSection}>
                        
                            <CreationMediaView 
                                className={classes.previewContainer}
                                imageUrl={creationData.media.image.url}
                                videoUrl={creationData.media.video.url}
                                isVideo={!!creationData.media.video.url}
                                videoControls/>

                            <CreationInfo 
                                className={classes.infoContainer}
                                creationId={creationData.creationId}
                                name={creationData.name}
                                description={creationData.description}
                                creators={creationData.creators}
                                owner={creationData.owner}
                                release={creationData.release}
                                detailsList={creationData.details}
                                directPurchasePrice={creationData.directPurchasePrice}
                                price={creationData.price}
                                status={creationData.status}
                                auction={creationData.auction}
                                likes={creationData.stats.likesCount}
                                comments={creationData.stats.commentCount || 0}
                                views={creationData.stats.views}
                                tokenId={creationData.blockchain.tokenId}
                                contract={creationData.blockchain.contract}
                                isOwner={creationData.owner.username === userData.data?.username}
                                liked={creationLiked}
                                onPurchaseClick={handlePurchase}
                                onMakeOfferClick={handleMakeOffer}
                                onBidClick={handleBid}
                                onFinishAuctionClick={handleFinishAuction}
                                onLikeClicked={handleLike}/>

                        </div>

                        <CreationDetailTabs
                            tabList={[
                                'Recent Activity',
                                'Edition Activity',
                                'Additional Details',
                            ]}
                            onTabChanged={handleTabChanged}/>

                        <div className={classes.tableContainer}>
                            {
                                selectedTab === 0 && 
                                creationActivityState === STATE_DONE &&
                                creationActivity.length > 0 ?
                                <Fragment>
                                    <CreationTableHeader
                                        headerItems={[
                                            { text: 'Collector', flex: 2},
                                            { text: 'Activity', flex: 3},
                                            { text: 'Date', flex: 1},
                                        ]}/>

                                    {
                                        creationActivity.map(activity =>
                                            <CreationTableActivityItem
                                                key={activity.activityId}
                                                name={activity.user.name}
                                                username={activity.user.username}
                                                profilePictureUrl={activity.user.profilePictureUrl}
                                                activity={activity.action}
                                                metadata={activity.metadata}
                                                createdAt={activity.createdAt}/>
                                        )
                                    }
                                </Fragment> :

                                selectedTab === 0 ?
                                <p className={classes.noActivity}>This creation has no activity yet</p> :

                                selectedTab === 1 ?
                                <Fragment>
                                    <CreationTableHeader
                                        headerItems={[
                                            { text: 'Owner', flex: 2},
                                            { text: 'Edition', flex: 1},
                                            { text: 'Details', flex: 2},
                                            { text: 'Buy / Make an Offer', flex: 2}
                                        ]}/>

                                    {
                                        creationEditions ?
                                        creationEditions.map(edition =>
                                            <CreationTableEditionActivityItem
                                                key={edition.creationId}    
                                                name={edition.owner.name}
                                                username={edition.owner.username}
                                                profilePictureUrl={edition.owner.profilePictureUrl}
                                                edition={edition.release.number}
                                                editionId={edition.creationId}
                                                price={edition.price}
                                                createdAt={edition.createdAt}/>
                                        )
                                        : null
                                    }
                                </Fragment> :

                                selectedTab === 2 ? 
                                <CreationTableAdditionalDetailsList
                                    detailsList={creationData.details}/> :

                                null
                            }
                        </div>

                        {
                            creationCommentsState === STATE_DONE ?
                            <CommentSection
                                comments={creationComments} 
                                creator={creationData.owner.username}
                                self={userData?.data && userData.username}
                                isAdmin={userData?.data && userData.data.role === 'ADMIN'}
                                onPostComment={(replyTo, body) => {
                                    if(userData?.data?.verified)
                                        dispatch(CreationActions.postComment(creationId, body, replyTo));
                                    else if(userData?.data)
                                        PopupManager.pushMessagePopup(
                                            'Verification required',
                                            ['In order to comment on a creation, your email address must be verified.', 'Do you want to verify your email address now?'],
                                            null, true, 'Yes', 'No'
                                        ).then(confirm => {
                                            if(confirm)
                                                history.push('/profile/settings')
                                        })
                                    else
                                        PopupManager.pushLogin();
                                }}
                                onDeleteComment={(commentId) => {
                                    dispatch(CreationActions.deleteComment(creationId, commentId));
                                }} />
                            : null
                        }
                        
                    </div>
                </div>
                : null
            }
            <Footer/>
        </Fragment>
    );
}

export default CreationDetails;