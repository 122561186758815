import React from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';
import Images from '~/Assets/images';

const DiscoverItem = (props) => {
    const classes = useStyles();

    const valueSeed = (props.url || '')
        .split('')
        .reduce((value, item) => value += item.charCodeAt(0), 0);

    const colorPair = DiscoverItem.ColorPairs[valueSeed % DiscoverItem.ColorPairs.length];

    return (
        <div className={classes.container}>

            <Link 
                to={props.url}
                className={classes.imageContainer}>
            
                <div 
                    alt=""
                    className={classes.largeImage}
                    style={{
                        background: 
                            props.largeImage ? 
                            `url('${props.largeImage}')` :
                            `linear-gradient(${valueSeed}deg, ${colorPair.a} ${20 + valueSeed % 20}%, ${colorPair.b})`
                    }}/>

                <div 
                    alt=""
                    style={{
                        background: `url('${props.smallImage || Images.ProfilePicturePlaceholder}')`
                    }}
                    className={classes.smallImage}/>

            </Link>

            <p className={classes.text}>
                {props.name}
            </p>

        </div>
    );
}

DiscoverItem.ColorPairs = [
    {a: '#FC766AFF', b: '#5B84B1FF'}, 
    {a: '#42EADDFF', b: '#CDB599FF'}, 
    {a: '#00A4CCFF', b: '#F95700FF'}, 
    {a: '#0063B2FF', b: '#ADEFD1FF'}, 
    {a: '#606060FF', b: '#D6ED17FF'},
    {a: '#ED2B33FF', b: '#D85A7FFF'},
    {a: '#2C5F2DFF', b: '#97BC62FF'},
    {a: '#00539CFF', b: '#EEA47FFF'},
    {a: '#0063B2FF', b: '#9CC3D5FF'},
    {a: '#101820FF', b: '#FEE715FF'},
    {a: '#2BAE66FF', b: '#FCF6F5FF'}
];

export default DiscoverItem;