import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    pageContainer: {
        padding: '80px 0px 0px 0px'
    },
    activityContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    activityList: {
        margin: '50px 100px 0 100px'
    },
    carousel: {
        margin: '80px 0 80px 80px'
    },
    '@media only screen and (max-width: 1024px)': {
        activityList: {
            margin: '50px 10px 0 10px'
        },
        carousel: {
            margin: '40px 0 0 20px'
        },
    },
});