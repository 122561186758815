import React from 'react';
import Images from '~/Assets/images';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';

const ProfileItem = (props) => {

    const history = useHistory();
    const classes = useStyles();

    const valueSeed = (props.username || '')
        .split('')
        .reduce((value, item) => value += item.charCodeAt(0), 0);
    
    const colorPair = ProfileItem.ColorPairs[valueSeed % ProfileItem.ColorPairs.length];

    const handleItemClicked = () => {
        if(props.username)
            history.push(`/profiles/${props.username}/`)
    }

    return (
        <div className={classes.wrapperContainer}>
            <div 
                className={classes.container}
                onClick={handleItemClicked}
                style={
                    props.squareSize ? 
                    {
                        width: props.squareSize,
                        height: props.squareSize,
                        background: 
                            props.backgroundImage ? 'none' :
                            `linear-gradient(${valueSeed}deg, ${colorPair.a} ${20 + valueSeed % 20}%, ${colorPair.b})`
                    } : 
                    {
                        background: 
                            props.backgroundImage ? 'none' :
                            `linear-gradient(${valueSeed}deg, ${colorPair.a} ${20 + valueSeed % 20}%, ${colorPair.b})`
                    }
                }>

                <div
                    className={classes.image}
                    style={{ backgroundImage: `url(${props.backgroundImage})` }}/> 

                <div className={classes.profilePictureContainer}>
                    <div
                        className={classes.profilePicture}
                        style={{ 
                            backgroundImage: `url(${props.profilePictureUrl || Images.ProfilePicturePlaceholder}`
                        }}/>
                </div>

                <div className={classes.infoOverlayContainer}>
                    <div 
                        className={
                            props.isCreator ?
                            classes.infoContainer :
                            classes.infoContainer + ' ' + classes.collectorInfoContainer
                        }>

                        <p className={classes.description}>
                            { props.description }
                        </p>

                        <p className={classes.viewProfile}>
                            {
                                props.isCreator ?
                                'View Gallery >' :
                                'View Collection >'
                            }
                        </p>

                    </div>
                </div>
            </div>

            <p className={classes.profileName}>
                {props.name}
            </p>
        </div>
    );
}

ProfileItem.ColorPairs = [
    {a: '#FC766AFF', b: '#5B84B1FF'}, 
    {a: '#42EADDFF', b: '#CDB599FF'}, 
    {a: '#00A4CCFF', b: '#F95700FF'}, 
    {a: '#0063B2FF', b: '#ADEFD1FF'}, 
    {a: '#606060FF', b: '#D6ED17FF'},
    {a: '#ED2B33FF', b: '#D85A7FFF'},
    {a: '#2C5F2DFF', b: '#97BC62FF'},
    {a: '#00539CFF', b: '#EEA47FFF'},
    {a: '#0063B2FF', b: '#9CC3D5FF'},
    {a: '#101820FF', b: '#FEE715FF'},
    {a: '#2BAE66FF', b: '#FCF6F5FF'}
];

export default ProfileItem;