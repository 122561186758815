import React, { Fragment } from 'react';

import { useSelector } from 'react-redux';

import LabeledInputField from '~/Components/LabeledInputField';
import Button from '~/Components/Button';

import { useStyles } from './styles';
import MetaMask from '~/Services/MetaMask';
import Helpers from '~/helpers';
import MetamaskInstructions from '~/Components/MetamaskInstructions';
import Config from '~/config';

const ManageContract = () => {

    const classes = useStyles();    
    const blockchainState = useSelector(state => state.Blockchain.stateChangedTrigger);

    const [isUpdating, setIsUpdating] = React.useState(ManageContract.Fields.NONE);
    const [saleCommission, setSaleCommission] = React.useState('');
    const [commissionAddress, setCommissionAddress] = React.useState('');
    const [creatorCommission, setCreatorCommission] = React.useState('');
    const [auctionCommission, setAuctionCommission] = React.useState('');

    const [isLoadingData, setIsLoadingData] = React.useState(false);
    const [isProcessingTransaction, setIsProcessingTransaction] = React.useState(false);
    const [isMetamaskEnabled, setIsMetamaskEnabled] = React.useState(false);
    const [selectedAddress, setSelectedAddress] = React.useState('');
    const [isInNetwork, setIsInNetwork] = React.useState(false);
    const [isOwnerAddress, setIsOwnerAddress] = React.useState(false);
    const [ownerAddress, setOwnerAddress] = React.useState('');

    const handleUpdateSaleCommission = () => {
        setIsUpdating(isUpdating | ManageContract.Fields.SALE_COMMISSION);

        MetaMask
            .getMarketContract()
            .setSaleCommission(parseInt((saleCommission || 0) * 1000))
            .then(() => {
                setIsUpdating(isUpdating & ~ManageContract.Fields.SALE_COMMISSION);
            })
            .catch(() => {
                setIsUpdating(isUpdating & ~ManageContract.Fields.SALE_COMMISSION);
            })
    }
    
    const handleUpdateCommissionAddress = () => {
        setIsUpdating(isUpdating | ManageContract.Fields.COMMISSION_ADDRESS);

        MetaMask
            .getMarketContract()
            .setCommissionCollectorAddress(commissionAddress)
            .then(() => {
                setIsUpdating(isUpdating & ~ManageContract.Fields.COMMISSION_ADDRESS);
            })
            .catch(() => {
                setIsUpdating(isUpdating & ~ManageContract.Fields.COMMISSION_ADDRESS);
            })
    }

    const handleUpdateCreatorCommission = () => {
        setIsUpdating(isUpdating | ManageContract.Fields.CREATOR_COMMISSION);

        MetaMask
            .getMarketContract()
            .setCreatorCommission(parseInt((creatorCommission || 0) * 1000))
            .then(() => {
                setIsUpdating(isUpdating & ~ManageContract.Fields.CREATOR_COMMISSION);
            })
            .catch(() => {
                setIsUpdating(isUpdating & ~ManageContract.Fields.CREATOR_COMMISSION);
            })
    }

    const handleUpdateAuctionCommission = () => {
        setIsUpdating(isUpdating | ManageContract.Fields.AUCTION_COMMISSION);

        MetaMask
            .getMarketContract()
            .setAuctionCommission(parseInt((auctionCommission || 0) * 1000))
            .then(() => {
                setIsUpdating(isUpdating & ~ManageContract.Fields.AUCTION_COMMISSION);
            })
            .catch(() => {
                setIsUpdating(isUpdating & ~ManageContract.Fields.AUCTION_COMMISSION);
            })
    }

    React.useEffect(
        () => {
            setIsInNetwork(MetaMask.isInNetwork(Config.Blockchain.NETWORK)); 
            setIsProcessingTransaction(MetaMask.isProcessingTransaction());
            setSelectedAddress(MetaMask.getCurrentAddress());
            setIsMetamaskEnabled(MetaMask.isAvailable());
            setIsLoadingData(MetaMask.isLoading());
        },
        [blockchainState]
    );

    React.useEffect(
        () => {
            if(MetaMask.getMarketContract() && isMetamaskEnabled && isInNetwork) {
                MetaMask
                    .getMarketContract()
                    .owner()
                    .then(address => {
                        setOwnerAddress(address)
                    })
                    .catch(() => {})

                MetaMask
                    .getMarketContract()
                    .saleCommission()
                    .then(value => {
                        setSaleCommission(
                            value ?
                            parseInt(value) / 1000 :
                            0
                        )
                    })
                    .catch(() => {})

                MetaMask
                    .getMarketContract()
                    .commissionCollector()
                    .then(value => {
                        setCommissionAddress(value)
                    })
                    .catch(() => {})

                MetaMask
                    .getMarketContract()
                    .creatorCommission()
                    .then(value => {
                        setCreatorCommission(
                            value ?
                            parseInt(value) / 1000 :
                            0
                        )
                    })
                    .catch(() => {})

                MetaMask
                    .getMarketContract()
                    .auctionCommission()
                    .then(value => {
                        setAuctionCommission(
                            value ?
                            parseInt(value) / 1000 :
                            0
                        )
                    })
                    .catch(() => {})
            }
        },
        [isInNetwork, isMetamaskEnabled]
    );

    React.useEffect(
        () => {
            setIsOwnerAddress(
                Helpers.isSameAddress(
                    ownerAddress, 
                    selectedAddress
                )
            );
        },
        [ownerAddress, selectedAddress]
    )

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Manage Contract
            </p>

            <p className={classes.infoMessage}>
                This page enables administrators to set various contract configurations, such as fees and collection address. This configuration is directly loaded in the contract and requres the owner address to be selected in MetaMask.
            </p>

            <p className={classes.warningMessage}>
                CAUTION: Please make sure the configuration you set is correct! Changes take effect immediately.
            </p>

            <div style={{height: 10}}/>

            {
                !isMetamaskEnabled || !isInNetwork || !isOwnerAddress ?
                <MetamaskInstructions
                    isEnabled={isMetamaskEnabled}
                    isInNetwork={isInNetwork}
                    isInAddress={isOwnerAddress}
                    accountError={
                        !isOwnerAddress ?
                        `The selected address is not that of the contract owner. Make sure the selected address is:` :
                        null
                    }
                    requiredAddress={ownerAddress}
                    isLoadingData={isLoadingData}
                    isProcessingTransaction={isProcessingTransaction}
                    requiredNetwork={MetaMask.getNetworkNameById(Config.Blockchain.NETWORK)}/> 
                :
                <Fragment>
                    <p className={classes.smallTitle}>
                        Sale commission fee
                    </p>

                    <p className={classes.infoMessage}>
                        The field below sets the sale commission fee as a percentage. The sale commission fee is taken from every sale and sent to the commission collection address.
                    </p>

                    <LabeledInputField
                        className={classes.inputField}
                        labelText="Sale commission (%)"
                        placeholder="Commission percentage"
                        type="text"
                        min="0"
                        value={saleCommission}
                        onChange={setSaleCommission}
                        labelFirst />

                    <div className={classes.buttonContainer}>

                        <Button
                            className={classes.updateButton}
                            text={
                                isUpdating & ManageContract.Fields.SALE_COMMISSION ?
                                'Sending Transaction...' : 'Update'
                            }
                            disabled={isUpdating}
                            isLoading={isUpdating & ManageContract.Fields.SALE_COMMISSION}
                            onClick={handleUpdateSaleCommission}
                            inverted/>

                        <p className={classes.updateErrorMessage}>

                        </p>

                    </div>

                    <div style={{height: 30}}/>

                    <p className={classes.smallTitle}>
                        Commission collection address
                    </p>

                    <p className={classes.infoMessage}>
                        The following field sets the address to which all the fees are sent. Setting this address to a zero address will disable fee collection.
                    </p>

                    <LabeledInputField
                        className={classes.inputField}
                        labelText="Commission Address"
                        placeholder="Address"
                        type="text"
                        min="0"
                        value={commissionAddress}
                        onChange={setCommissionAddress}
                        labelFirst />

                    <div className={classes.buttonContainer}>

                        <Button
                            className={classes.updateButton}
                            text={
                                isUpdating & ManageContract.Fields.COMMISSION_ADDRESS ?
                                'Sending Transaction...' : 'Update'
                            }
                            disabled={isUpdating}
                            isLoading={isUpdating & ManageContract.Fields.COMMISSION_ADDRESS}
                            onClick={handleUpdateCommissionAddress}
                            inverted/>

                        <p className={classes.updateErrorMessage}>

                        </p>

                    </div>


                    <div style={{height: 30}}/>

                    <p className={classes.smallTitle}>
                        Creator commission
                    </p>

                    <p className={classes.infoMessage}>
                        The following field will set the sale percentage sent to the creator of the token every time a sale is made. If this field is greater than zero, the creator will keep getting a sale commission on future sales of their creation.
                    </p>

                    <LabeledInputField
                        className={classes.inputField}
                        labelText="Creator commission (%)"
                        placeholder="Commission percentage"
                        type="text"
                        min="0"
                        value={creatorCommission}
                        onChange={setCreatorCommission}
                        labelFirst />

                    <div className={classes.buttonContainer}>

                        <Button
                            className={classes.updateButton}
                            text={
                                isUpdating & ManageContract.Fields.CREATOR_COMMISSION ?
                                'Sending Transaction...' : 'Update'
                            }
                            disabled={isUpdating}
                            isLoading={isUpdating & ManageContract.Fields.CREATOR_COMMISSION}
                            onClick={handleUpdateCreatorCommission}
                            inverted/>

                        <p className={classes.updateErrorMessage}>

                        </p>

                    </div>


                    <div style={{height: 30}}/>

                    <p className={classes.smallTitle}>
                        Auction commission
                    </p>

                    <p className={classes.infoMessage}>
                        Use the field below to set the auction commission percentage. If this field is greater than zero, the specified percentage will be taken as a commission from the closing amount of an auction.
                    </p>

                    <LabeledInputField
                        className={classes.inputField}
                        labelText="Auction commission (%)"
                        placeholder="Commission percentage"
                        type="text"
                        min="0"
                        value={auctionCommission}
                        onChange={setAuctionCommission}
                        labelFirst />

                    <div className={classes.buttonContainer}>

                        <Button
                            className={classes.updateButton}
                            text={
                                isUpdating & ManageContract.Fields.AUCTION_COMMISSION ?
                                'Sending Transaction...' : 'Update'
                            }
                            disabled={isUpdating}
                            isLoading={isUpdating & ManageContract.Fields.AUCTION_COMMISSION}
                            onClick={handleUpdateAuctionCommission}
                            inverted/>

                        <p className={classes.updateErrorMessage}>

                        </p>

                    </div>
                </Fragment>
            }

            <div style={{height: 30}}/>

        </div>
    );
}

ManageContract.Fields = {
    NONE: 0,
    SALE_COMMISSION: 1,
    COMMISSION_ADDRESS: 2,
    CREATOR_COMMISSION: 4,
    AUCTION_COMMISSION: 8
}

export default ManageContract;