import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as HomePageActions from '~/Redux/HomePage/actions';
import * as UserActions from '~/Redux/User/actions';
import { STATE_DONE, STATE_NOT_PROCESSED } from '~/Redux/states';

import MainHeader from '~/Components/MainHeader';
import DropBanner from '~/Components/DropBanner';
import Carousel from '~/Components/Carousel';
import ProfileItem from '~/Components/ProfileItem';
import CreationItemList from '~/Components/CreationItemList';
import PixelatedButton from '~/Components/PixelatedButton';
import CollectionItem from '~/Components/CollectionItem';

import { useStyles } from './styles';
import LoadingCover from '~/Popups/LoadingCover';
import Footer from '~/Components/Footer';
import IDOBar from '~/Components/IDOBar';

const HomePage = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const userData = useSelector(state => state.User);
    const contents = useSelector(state => state.HomePage.contents);
    const contentsState = useSelector(state => state.HomePage.contentsState);
    const bannerDrop = useSelector(state => state.HomePage.bannerDrop);
    const pageWidth = useSelector(state => state.UI.pageWidth);

    const [dataReloadRequired, setDataReloadRequired] = React.useState(true);
    const [itemSize, setItemSize] = React.useState(300);

    React.useEffect(
        () => {
            if (!userData.data && userData.token && 
                userData.fetchDataState === STATE_NOT_PROCESSED) {
                dispatch(UserActions.getData());
            }
        },
        [userData, dispatch]
    )

    React.useEffect(
        () => {
            if(dataReloadRequired) {
                setDataReloadRequired(false);
                dispatch(HomePageActions.getHomePageContents());
            }
        }, 
        [dataReloadRequired, dispatch]
    );

    React.useEffect(
        () => {
            if(pageWidth < 500)
                setItemSize(300);
            else if (pageWidth > 600)
                setItemSize(600 * 300 / 500);
            else
                setItemSize(pageWidth * 300 / 500);
        }, 
        [pageWidth, setItemSize]
    );

    return (
        <Fragment>
            <LoadingCover visible={contentsState !== STATE_DONE}/>
            
            <MainHeader
                username={userData.data?.username}
                profilePictureUrl={userData.data?.profilePictureUrl}
                userType={userData.data?.role}
                isLoggedIn={!!userData.data}
                isMobile={pageWidth < 800}/>

            <div className={classes.pageContainer}>

                {
                    bannerDrop &&
                    <DropBanner
                        dropId={bannerDrop.dropId}
                        author={bannerDrop.artist?.name}
                        name={bannerDrop.name}
                        description={bannerDrop.banner?.description}
                        imageUrl={bannerDrop.banner?.imageUrl}
                        videoUrl={bannerDrop.banner?.videoUrl} />
                }

                {
                    !!(bannerDrop?.banner?.advastId) &&
                    (
                        pageWidth <= 600 ?
                        <iframe 
                            title="adVAST"
                            src={`https://vastamweb.s3.amazonaws.com/advast-blast/mobileHero.html?channel=${bannerDrop.banner?.advastId}`}
                            frameBorder="0" 
                            style={{
                                overflow: 'hidden',
                                width: 300,
                                height: 250,
                                display: 'block',
                                margin: 'auto auto',
                                marginTop: 40
                            }}
                            allowFullScreen></iframe>
                        :
                        <iframe 
                            title="adVAST"
                            src={`https://vastamweb.s3.amazonaws.com/advast/webview/embededadvast.html?channel=${bannerDrop.banner?.advastId}`} 
                            frameBorder="0" 
                            style={{
                                overflow: 'hidden',
                                width: pageWidth < 1024 ? (pageWidth - 40) : (pageWidth - 400),
                                height: pageWidth < 1024 ? (pageWidth * 3 / 4 - 40) : (pageWidth * 3 / 5 - 400),
                                display: 'block',
                                margin: 'auto auto',
                                marginTop: 40
                            }} 
                            allowFullScreen></iframe>
                    )
                }

                {
                    contents && <IDOBar/>
                }

                {
                    contents && contents.activity ?
                    <div className={classes.activityContainer}>
                        <CreationItemList
                            className={classes.activityList}
                            itemList={contents.activity}
                            isActivityList
                            columns={Math.floor(pageWidth / 500)} /> 

                        <PixelatedButton
                            href="/discover/activity"
                            text="SHOW MORE"
                            style={{
                                margin: '10px 0 30px 0', 
                                padding: 10,
                                alignSelf: 'center',
                                width: 150,
                                height: 25,
                                color: 'white',
                                fontSize: 15
                            }}/>
                    </div>
                    : null
                }

                {
                    contents && contents.featuredCreations ?
                    <Carousel
                        className={classes.carousel}
                        title="Featured Creations"
                        itemWidth={itemSize + 20}
                        isMobile={pageWidth < 650}
                        viewAllUrl="/discover/artworks">
                        {
                            contents.featuredCreations
                                .map(item =>
                                    <CollectionItem
                                        key={item.creationId}
                                        creationId={item.creationId}
                                        creationName={item.name}
                                        creatorList={item.creators}
                                        releaseNumber={item.release.number}
                                        releaseCount={item.release.count}
                                        status={item.status}
                                        price={item.price}
                                        directPurchasePrice={item.directPurchasePrice}
                                        imageUrl={item.media.image.url}
                                        videoUrl={item.media.video.url} 
                                        playOnHover={!!item.media.video.url}
                                        squareSize={itemSize} />
                                )
                        }
                    </Carousel> : null
                }

                {
                    contents && contents.popularCollectors ?
                    <Carousel
                        className={classes.carousel}
                        title="Popular Collectors"
                        itemWidth={itemSize + 20}
                        isMobile={pageWidth < 650}
                        viewAllUrl="/discover/collectors">
                        {
                            contents.popularCollectors
                                .map(item =>
                                    <ProfileItem
                                        key={item.username}
                                        username={item.username}
                                        name={item.name}
                                        profilePictureUrl={item.profilePictureUrl}
                                        description={item.preview.description}
                                        backgroundImage={item.preview.backgroundImageUrl}
                                        isCreator={item.isCreator}
                                        squareSize={itemSize} />         
                                )
                        }
                    </Carousel> : null
                }

                {
                    contents && contents.featuredCreators ?
                    <Carousel
                        className={classes.carousel}
                        title="Featured Creators"
                        itemWidth={itemSize + 20}
                        isMobile={pageWidth < 650}
                        viewAllUrl="/discover/creators">
                        {
                            contents.featuredCreators
                                .map(item =>
                                    <ProfileItem
                                        key={item.username}
                                        username={item.username}
                                        name={item.name}
                                        profilePictureUrl={item.profilePictureUrl}
                                        description={item.preview.description}
                                        backgroundImage={item.preview.backgroundImageUrl}
                                        isCreator={item.isCreator}
                                        squareSize={itemSize} />         
                                )
                        }
                    </Carousel> : null
                }

                {
                    contents && contents.popularCreations ?
                    <Carousel
                        className={classes.carousel}
                        title="Popular Creations"
                        itemWidth={itemSize + 20}
                        isMobile={pageWidth < 650}
                        viewAllUrl="/discover/artwork">
                        {
                            contents.popularCreations
                                .map(item =>
                                    <CollectionItem
                                        key={item.creationId}
                                        creationId={item.creationId}
                                        creationName={item.name}
                                        creatorList={item.creators}
                                        releaseNumber={item.release.number}
                                        releaseCount={item.release.count}
                                        status={item.status}
                                        price={item.price}
                                        directPurchasePrice={item.directPurchasePrice}
                                        imageUrl={item.media.image.url}
                                        videoUrl={item.media.video.url} 
                                        playOnHover={!!item.media.video.url}
                                        squareSize={itemSize} />
                                )
                        }
                    </Carousel> : null
                }

            </div>
            <Footer/>
        </Fragment>
    );
}

export default HomePage;