import LocalStorage from '~/API/local_storage';
import {
    USER_LOGIN_START,
    USER_LOGIN_FINISHED,
    USER_LOGIN_FAILED,
    USER_LOGIN_CLEAR,
    USER_DATA_FETCH_START,
    USER_DATA_FETCH_FINISHED,
    USER_DATA_FETCH_FAILED,
    USER_DATA_UPDATE_START,
    USER_DATA_UPDATE_FINISHED,
    USER_DATA_UPDATE_FAILED,
    USER_PASSWORD_UPDATE_START,
    USER_PASSWORD_UPDATE_FINISHED,
    USER_PASSWORD_UPDATE_FAILED,
    USER_ABOUT_UPDATE_START,
    USER_ABOUT_UPDATE_FINISHED,
    USER_ABOUT_UPDATE_FAILED,
    USER_FOLLOWING_STATE_CHANGED,
    USER_CREATION_LIKE_STATE_CHANGED,
    USER_REGISTER_START,
    USER_REGISTER_FINISHED,
    USER_REGISTER_FAILED,
    USER_LOGOUT,
    USER_PROFILE_PICTURE_UPLOAD_START,
    USER_PROFILE_PICTURE_UPLOAD_PROGRESS,
    USER_PROFILE_PICTURE_UPLOAD_FINISHED,
    USER_PROFILE_PICTURE_UPLOAD_FAILED,
    USER_ABOUT_HEADER_PICTURE_UPLOAD_START,
    USER_ABOUT_HEADER_PICTURE_UPLOAD_PROGRESS,
    USER_ABOUT_HEADER_PICTURE_UPLOAD_FINISHED,
    USER_ABOUT_HEADER_PICTURE_UPLOAD_FAILED,
    USER_PROFILE_PREVIEW_UPLOAD_START,
    USER_PROFILE_PREVIEW_UPLOAD_PROGRESS,
    USER_PROFILE_PREVIEW_UPLOAD_FINISHED,
    USER_PROFILE_PREVIEW_UPLOAD_FAILED,
    USER_EMAIL_VERIFIED
} from './types';

import {
    STATE_NOT_PROCESSED,
    STATE_PROCESSING,
    STATE_DONE,
    STATE_FAILED
} from '../states'

const INITIAL_STATE = {
    token: LocalStorage.getToken(),
    username: LocalStorage.getUsername(),

    data: null,

    loginState: STATE_NOT_PROCESSED,
    loginError: null,

    fetchDataState: STATE_NOT_PROCESSED,
    fetchDataError: null,

    updateDataState: STATE_NOT_PROCESSED,
    updateDataError: null,

    registerState: STATE_NOT_PROCESSED,
    registerError: null,

    profilePictureUploadState: STATE_NOT_PROCESSED,
    profilePictureUploadProgress: 1,
    profilePictureUploadError: null,

    aboutHeaderUploadState: STATE_NOT_PROCESSED,
    aboutHeaderUploadProgress: 1,
    aboutHeaderUploadError: null,

    profilePreviewUploadState: STATE_NOT_PROCESSED,
    profilePreviewUploadProgress: 1,
    profilePreviewUploadError: null,

    userAboutError: null,
    userAboutState: STATE_NOT_PROCESSED,

    passwordUpdateError: null,
    passwordUpdateState: STATE_NOT_PROCESSED,
}

const UserReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case USER_LOGIN_START:
            return UserLoginReducers.loginStart(state);
            
        case USER_LOGIN_FINISHED:
            return UserLoginReducers.loginFinished(state, action.payload);

        case USER_LOGIN_FAILED:
            return UserLoginReducers.loginFailed(state, action.payload);

        case USER_LOGIN_CLEAR:
            return UserLoginReducers.loginClear(state);

        case USER_DATA_FETCH_START:
            return UserFetchDataReducers.fetchStart(state);
            
        case USER_DATA_FETCH_FINISHED:
            return UserFetchDataReducers.fetchFinished(state, action.payload);

        case USER_DATA_FETCH_FAILED:
            return UserFetchDataReducers.fetchFailed(state, action.payload);
    

        case USER_DATA_UPDATE_START:
            return UserUpdateDataReducers.updateStart(state);
            
        case USER_DATA_UPDATE_FINISHED:
            return UserUpdateDataReducers.updateFinished(state, action.payload);

        case USER_DATA_UPDATE_FAILED:
            return UserUpdateDataReducers.updateFailed(state, action.payload);

        case USER_FOLLOWING_STATE_CHANGED:
            return UserUpdateDataReducers.followingStateChanged(state, action.payload);

        case USER_CREATION_LIKE_STATE_CHANGED:
            return UserUpdateDataReducers.creationLikeStateChanged(state, action.payload);

        case USER_EMAIL_VERIFIED:
            return UserUpdateDataReducers.userEmailVerified(state);

        case USER_LOGOUT:
            return UserUpdateDataReducers.logout(state);


        case USER_ABOUT_UPDATE_START:
            return UserAboutUpdateReducers.updateStart(state);
            
        case USER_ABOUT_UPDATE_FINISHED:
            return UserAboutUpdateReducers.updateFinished(state, action.payload);

        case USER_ABOUT_UPDATE_FAILED:
            return UserAboutUpdateReducers.updateFailed(state, action.payload);

            
        case USER_PASSWORD_UPDATE_START:
            return UserUpdatePasswordReducers.updateStart(state);
            
        case USER_PASSWORD_UPDATE_FINISHED:
            return UserUpdatePasswordReducers.updateFinished(state, action.payload);

        case USER_PASSWORD_UPDATE_FAILED:
            return UserUpdatePasswordReducers.updateFailed(state, action.payload);


        case USER_REGISTER_START:
            return UserRegisterReducers.registerStart(state);
            
        case USER_REGISTER_FINISHED:
            return UserRegisterReducers.registerFinished(state, action.payload);

        case USER_REGISTER_FAILED:
            return UserRegisterReducers.registerFailed(state, action.payload);


        case USER_PROFILE_PICTURE_UPLOAD_START:
            return UserProfilePictureUploadReducers.uploadStart(state);
            
        case USER_PROFILE_PICTURE_UPLOAD_FINISHED:
            return UserProfilePictureUploadReducers.uploadFinished(state, action.payload);

        case USER_PROFILE_PICTURE_UPLOAD_PROGRESS:
            return UserProfilePictureUploadReducers.uploadProgress(state, action.payload);

        case USER_PROFILE_PICTURE_UPLOAD_FAILED:
            return UserProfilePictureUploadReducers.uploadFinished(state, action.payload);


        case USER_ABOUT_HEADER_PICTURE_UPLOAD_START:
            return UserAboutHeaderPictureUploadReducers.uploadStart(state);
            
        case USER_ABOUT_HEADER_PICTURE_UPLOAD_FINISHED:
            return UserAboutHeaderPictureUploadReducers.uploadFinished(state, action.payload);

        case USER_ABOUT_HEADER_PICTURE_UPLOAD_PROGRESS:
            return UserAboutHeaderPictureUploadReducers.uploadProgress(state, action.payload);

        case USER_ABOUT_HEADER_PICTURE_UPLOAD_FAILED:
            return UserAboutHeaderPictureUploadReducers.uploadFinished(state, action.payload);

            
        case USER_PROFILE_PREVIEW_UPLOAD_START:
            return UserProfilePreviewUploadReducers.uploadStart(state);
            
        case USER_PROFILE_PREVIEW_UPLOAD_FINISHED:
            return UserProfilePreviewUploadReducers.uploadFinished(state, action.payload);

        case USER_PROFILE_PREVIEW_UPLOAD_PROGRESS:
            return UserProfilePreviewUploadReducers.uploadProgress(state, action.payload);

        case USER_PROFILE_PREVIEW_UPLOAD_FAILED:
            return UserProfilePreviewUploadReducers.uploadFinished(state, action.payload);
            

        default:
            return state;
    }
}

class UserLoginReducers {

    static loginStart(state) {
        LocalStorage.clear();
        return { 
            ...state, 
            data: null,
            token: null,
            username: null,
            loginState: STATE_PROCESSING
        }
    }

    static loginFinished(state, data) {
        LocalStorage.setCredentials({
            token: data.token,
            username: data.username
        });

        return { 
            ...state, 
            data,
            token: data.token,
            username: data.username,
            loginState: STATE_DONE
        }
    }

    static loginFailed(state, error) {
        return { 
            ...state, 
            loginError: error,
            loginState: STATE_FAILED
        }
    }

    static loginClear(state) {
        return { 
            ...state, 
            loginError: '',
            loginState: STATE_NOT_PROCESSED
        }
    }
}

class UserFetchDataReducers {

    static fetchStart(state) {
        return { 
            ...state, 
            fetchDataState: STATE_PROCESSING
        }
    }

    static fetchFinished(state, data) {

        let decodedToken = LocalStorage.getDecodedToken();

        if(decodedToken?.data?.role && decodedToken?.data?.role !== data.role) {
            LocalStorage.clear();
            return { 
                ...INITIAL_STATE,
                token: LocalStorage.getToken(),
                username: LocalStorage.getUsername(),
            }
        }
        else {
            return { 
                ...state, 
                data,
                fetchDataState: STATE_DONE
            }
        }
    }

    static fetchFailed(state, error) {
        LocalStorage.clear();
        return { 
            ...state, 
            data: null,
            token: null,
            username: null,
            fetchDataError: error,
            fetchDataState: STATE_FAILED
        }
    }
}

class UserUpdateDataReducers {

    static updateStart(state) {
        return { 
            ...state, 
            updateDataState: STATE_PROCESSING
        }
    }

    static updateFinished(state, data) {
        return { 
            ...state, 
            data: {
                ...state.data,
                ...data
            },
            updateDataState: STATE_DONE,
            updateDataError: null
        }
    }

    static updateFailed(state, error) {
        return { 
            ...state, 
            updateDataError: error,
            updateDataState: STATE_FAILED
        }
    }

    static followingStateChanged(state, data) {

        let followingList = 
            data.isFollowing ?
            [...state.data.following, data.username] :
            state.data.following.filter(username => username !== data.username);

        return {
            ...state,
            data: {
                ...state.data,
                following: followingList,
                stats: {
                    ...state.data.stats,
                    following: followingList.length
                }
            }
        }
    }

    static creationLikeStateChanged(state, data) {
        
        if(!state.data)
            return state;

        let likes =
            data.liked ?
            [...state.data.likes, data.creationId] :
            state.data.likes.filter(creationId => data.creationId !== creationId);

        return {
            ...state,
            data: {
                ...state.data,
                likes
            }
        }
    }

    static logout(state) {
        return {
            ...state,
            
            token: null,
            username: null,
        
            data: null,
        
            loginState: STATE_NOT_PROCESSED,
            loginError: null,
        
            fetchDataState: STATE_NOT_PROCESSED,
            fetchDataError: null,
        
            updateDataState: STATE_NOT_PROCESSED,
            updateDataError: null,

            registerState: STATE_NOT_PROCESSED,
            registerError: null
        }
    }

    static userEmailVerified(state) {
        return {
            ...state,
            data: {
                ...state.data,
                verified: true
            }
        }
    }
}

class UserRegisterReducers {

    static registerStart(state) {
        LocalStorage.clear();
        return { 
            ...state, 
            data: null,
            token: null,
            username: null,
            registerError: null,
            registerState: STATE_PROCESSING
        }
    }

    static registerFinished(state, data) {
        LocalStorage.setCredentials({
            token: data.token,
            username: data.username
        });

        return { 
            ...state, 
            data,
            token: data.token,
            username: data.username,
            registerError: null,
            registerState: STATE_DONE
        }
    }

    static registerFailed(state, error) {
        return { 
            ...state, 
            registerError: error,
            registerState: STATE_FAILED
        }
    }
}

class UserUpdatePasswordReducers {

    static updateStart(state) {
        return { 
            ...state, 
            passwordUpdateError: null,
            passwordUpdateState: STATE_PROCESSING
        }
    }

    static updateFinished(state) {
        return { 
            ...state, 
            passwordUpdateError: null,
            passwordUpdateState: STATE_DONE
        }
    }

    static updateFailed(state, error) {
        return { 
            ...state, 
            passwordUpdateError: error,
            passwordUpdateState: STATE_FAILED
        }
    }
}

class UserProfilePictureUploadReducers {

    static uploadStart(state) {
        return { 
            ...state, 
            profilePictureUploadProgress: 0,
            profilePictureUploadState: STATE_PROCESSING,
            profilePictureUploadError: null
        }
    }

    static uploadFinished(state, data) {
        return { 
            ...state, 
            data: {
                ...state.data,
                profilePictureUrl: data.profilePictureUrl + `?num=${Math.round(Math.random() * 10000)}`
            },
            profilePictureUploadProgress: 1,
            profilePictureUploadState: STATE_DONE
        }
    }

    static uploadProgress(state, data) {
        return { 
            ...state, 
            profilePictureUploadProgress: data,
            profilePictureUploadState: STATE_PROCESSING
        }
    }

    static uploadFailed(state, error) {
        return { 
            ...state, 
            profilePictureUploadProgress: 1,
            profilePictureUploadState: STATE_FAILED,
            profilePictureUploadError: error
        }
    }
}

class UserAboutHeaderPictureUploadReducers {

    static uploadStart(state) {
        return { 
            ...state, 
            aboutHeaderUploadProgress: 0,
            aboutHeaderUploadState: STATE_PROCESSING,
            aboutHeaderUploadError: null
        }
    }

    static uploadFinished(state, data) {
        return { 
            ...state, 
            data: {
                ...state.data,
                about: {
                    ...state.data.about,
                    headerUrl: data.headerUrl + `?num=${Math.round(Math.random() * 10000)}`
                }
            },
            aboutHeaderUploadProgress: 1,
            aboutHeaderUploadState: STATE_DONE
        }
    }

    static uploadProgress(state, data) {
        return { 
            ...state, 
            aboutHeaderUploadProgress: data,
            aboutHeaderUploadState: STATE_PROCESSING
        }
    }

    static uploadFailed(state, error) {
        return { 
            ...state, 
            aboutHeaderUploadProgress: 1,
            aboutHeaderUploadState: STATE_FAILED,
            aboutHeaderUploadError: error
        }
    }
}

class UserProfilePreviewUploadReducers {

    static uploadStart(state) {
        return { 
            ...state, 
            profilePreviewUploadProgress: 0,
            profilePreviewUploadState: STATE_PROCESSING,
            profilePreviewUploadError: null
        }
    }

    static uploadFinished(state, data) {
        return { 
            ...state, 
            data: {
                ...state.data,
                preview: {
                    ...state.data.preview,
                    backgroundImageUrl: data.backgroundImageUrl + `?num=${Math.round(Math.random() * 10000)}`
                }  
            },
            profilePreviewUploadProgress: 1,
            profilePreviewUploadState: STATE_DONE
        }
    }

    static uploadProgress(state, data) {
        return { 
            ...state, 
            profilePreviewUploadProgress: data,
            profilePreviewUploadState: STATE_PROCESSING
        }
    }

    static uploadFailed(state, error) {
        return { 
            ...state, 
            profilePreviewUploadProgress: 1,
            profilePreviewUploadState: STATE_FAILED,
            profilePreviewUploadError: error
        }
    }
}
class UserAboutUpdateReducers {

    static updateStart(state) {
        return { 
            ...state, 
            userAboutState: STATE_PROCESSING,
            userAboutError: null
        }
    }

    static updateFinished(state, data) {
        return { 
            ...state, 
            data: {
                ...state.data,
                about: data.about
            },
            userAboutState: STATE_DONE
        }
    }

    static updateFailed(state, error) {
        return { 
            ...state, 
            userAboutState: STATE_FAILED,
            userAboutError: error
        }
    }
}

export default UserReducer;