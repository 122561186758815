import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import PixelatedButton from '../PixelatedButton';
import PopupManager from '~/Auxilary/PopupManager';
import { useStyles } from './styles';

const ProfileDetails = (props) => {
    const classes = useStyles();

    const handleFollowClicked = () => {
        if(_.isFunction(props.onFollowClicked))
            props.onFollowClicked();
    }

    const handleFollowersClicked = () => {
        PopupManager.pushFollowerPopup(props.username, false);
    }

    const handleFollowingClicked = () => {
        PopupManager.pushFollowerPopup(props.username, true);
    }

    return (
        props.username !== null ?
        <div className={classes.container}>
            <Link 
                to={`/profiles/${props.username}/`}
                className={classes.profileName}>
                { props.profileName || props.username }
            </Link>

            <div className={classes.detailContainer}>
                <div 
                    className={classes.detailText}
                    onClick={handleFollowersClicked}>
                    { props.followers || 0 } followers
                </div>

                <div className={classes.detailSpacer}>•</div>

                <div 
                    className={classes.detailText}
                    onClick={handleFollowingClicked}>
                    { props.following || 0 } following
                </div>
                
                <div className={classes.detailSpacer}>•</div>

                <div className={classes.detailTextSimple}>
                    { props.views || 0 } views
                </div>
            </div>

            {
                props.isSelf ? null :
                <PixelatedButton
                    style={{
                        width: 140, 
                        height: 30,
                        marginBottom: 5
                    }}
                    onClick={handleFollowClicked}
                    secondary={props.isFollowing}
                    foregroundColor={ props.isFollowing ? '#bbb' : 'white'}
                    text={props.isFollowing ? 'Unfollow' : 'Follow'}/>
            }
        </div> :
        <div className={classes.container}>
            <div className={classes.profileName}>
                { props.profileName || props.username }
            </div>
        </div>
    );
}

export default ProfileDetails;