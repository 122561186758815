import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {},
    title: {
        fontFamily: Theme.Font.Primary,
        color: Theme.TextColor.Dark,
        fontSize: 20,
        fontWeight: 400,
        margin: '20px 0 10px 0'
    },
    text: {
        fontFamily: Theme.Font.Primary,
        color: Theme.TextColor.Dark,
        fontSize: 15,
        fontWeight: 300,
        lineHeight: 1.8
    },
    showMoreButton: {
        fontFamily: Theme.Font.Primary,
        color: Theme.Palette.Primary.Foreground,
        fontSize: 15,
        fontWeight: 300,
        marginLeft: 5,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    }
});