import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        width: 500,
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 30,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        textAlign: 'center',
        margin: '25px 0'
    },
    bodyContents: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        margin: '0px 20px'
    },
    infoMessage: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        textAlign: 'justify',
        color: '#333',
        margin: '10px 0 0 0'
    },
    label: {
        fontFamily: Theme.Font.Primary,
        fontSize: 16,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '15px 0 5px 0'
    },
    inputField: {   
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        padding: 10,
        border: '1px solid #ccc',
        margin: '3px 0',
        resize: 'vertical'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 20
    },
    button: {
        width: 150,
        padding: 10,
        fontSize: 15,
        margin: '5px 10px'
    },
    '@media only screen and (max-width: 500px)': {
        container: {
            width: '100%'
        }
    },
});