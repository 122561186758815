import React, { Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Config from '~/config';

import * as UserActions from '~/Redux/User/actions';
import * as ProfileActions from '~/Redux/Profile/actions';
import { STATE_DONE, STATE_NOT_PROCESSED } from '~/Redux/states';

import CreationItemList from '~/Components/CreationItemList';
import UserCollections from '~/Components/UserCollections';
import ProfileHeader from '~/Components/ProfileHeader';
import MainHeader from '~/Components/MainHeader';

import { useStyles } from './styles';
import NoContentInfoBox from '~/Components/NoContentInfoBox';
import Images from '~/Assets/images';
import AboutSection from '~/Components/AboutSection';
import LoadingCover from '~/Popups/LoadingCover';
import Footer from '~/Components/Footer';
import PopupManager from '~/Auxilary/PopupManager';

const ProfileDetails = () => {

    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch();
    
    const { username } = useParams();
    const { requestedPage } = useParams();
    
    const [dataReloadRequired, setDataReloadRequired] = React.useState(true);
    const [pageItems, setPageItems] = React.useState([]);
    const [isLoadingItems, setIsLoadingItems] = React.useState(true);
    const [visibleItemCount, setVisibleItemCount] = React.useState(Config.UI.PAGINATION_COUNT);
    const [isFollowingProfile, setIsFollowingProfile] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(null);

    const userData = useSelector(state => state.User);
    const shouldLoadMore = useSelector(state => state.UI.isPageScrollEnd);

    const profileData = useSelector(state => state.Profile.data);
    const profileDataState = useSelector(state => state.Profile.dataState);

    const profileCreations = useSelector(state => state.Profile.creations);
    const profileCreationsState = useSelector(state => state.Profile.creationsState);

    const profileCollection = useSelector(state => state.Profile.collection);
    const profileCollectionState = useSelector(state => state.Profile.collectionState);

    const pageWidth = useSelector(state => state.UI.pageWidth);
      
    React.useEffect(
        () => {
            if(requestedPage)
                setCurrentPage(requestedPage.toUpperCase());
            else
            if(profileData?.role === Config.User.Roles.CREATOR)
                setCurrentPage(Config.UI.ProfilePages.CREATIONS)
            else
                setCurrentPage(Config.UI.ProfilePages.ABOUT)
        },
        [profileData, requestedPage]
    );

    React.useEffect(
        () => {
            if (dataReloadRequired || (profileData && profileData.username !== username)) {
                setDataReloadRequired(false);
                dispatch(ProfileActions.getProfileData(username));
                setPageItems([]);
            }
        }, 
        [
            username, 
            profileData,
            dataReloadRequired, 
            dispatch
        ]
    );

    React.useEffect(
        () => {
            if (currentPage === Config.UI.ProfilePages.CREATIONS &&
                profileCreationsState === STATE_NOT_PROCESSED) {
                dispatch(ProfileActions.getCreations(username))
            }
            else
            if (currentPage === Config.UI.ProfilePages.CREATIONS &&
                profileCreationsState === STATE_DONE) {
                setPageItems(profileCreations);
            }

            if (currentPage === Config.UI.ProfilePages.COLLECTION &&
                profileCollectionState === STATE_NOT_PROCESSED) {
                dispatch(ProfileActions.getCollection(username))
            }
            else
            if (currentPage === Config.UI.ProfilePages.COLLECTION &&
                profileCollectionState === STATE_DONE) {
                setPageItems(profileCollection);
            }
        },
        [
            currentPage, 
            profileCreationsState, 
            profileCreations, 
            profileCollectionState, 
            profileCollection,
            username,
            dispatch
        ]
    )

    React.useEffect(
        () => {
            if (pageItems.length > 0 && !isLoadingItems && shouldLoadMore) {
                let itemsRemaining = 
                    pageItems.length - visibleItemCount;

                if(itemsRemaining > 0) {
                    setVisibleItemCount(
                        visibleItemCount + 
                        (
                            itemsRemaining > Config.UI.PAGINATION_COUNT ? 
                            Config.UI.PAGINATION_COUNT : 
                            itemsRemaining
                        )
                    );
                }
            }
        },
        [
            pageItems, 
            isLoadingItems, 
            shouldLoadMore,
            visibleItemCount
        ]
    )

    React.useEffect(
        () => {
            if (!userData.data && userData.token && 
                userData.fetchDataState === STATE_NOT_PROCESSED) {
                dispatch(UserActions.getData());
            }
        },
        [userData, dispatch]
    )

    React.useEffect(
        () => {
            setIsFollowingProfile(
                profileData ? 
                userData.data?.following.includes(profileData.username) : 
                false
            )
        },
        [userData, profileData]
    )  

    return (
        <Fragment>
            <LoadingCover visible={profileDataState !== STATE_DONE}/>
            
            <MainHeader
                username={userData.data?.username}
                profilePictureUrl={userData.data?.profilePictureUrl}
                userType={userData.data?.role}
                isLoggedIn={!!userData.data}
                isMobile={pageWidth < 800}/>

            {
                profileData ?
                <div className={classes.pageContainer}>

                    <ProfileHeader
                        username={profileData.username}
                        profileName={profileData.name}
                        profilePictureUrl={profileData.profilePictureUrl}
                        currentPage={currentPage}
                        followers={profileData.stats.followers}
                        following={profileData.stats.following}
                        views={profileData.stats.views}
                        userType={profileData.role}
                        isFollowing={isFollowingProfile}
                        isSelf={profileData.username === userData?.username}
                        onFollowClicked={() => { 
                            if(userData?.data?.verified)
                                dispatch(
                                    UserActions.setFollowing(
                                        profileData.username, 
                                        !isFollowingProfile
                                    )
                                )
                            else if(userData?.data)
                                PopupManager.pushMessagePopup(
                                    'Verification required',
                                    ['In order to follow another user, your email address must be verified.', 'Do you want to verify your email address now?'],
                                    null, true, 'Yes', 'No'
                                ).then(confirm => {
                                    if(confirm)
                                        history.push('/profile/settings')
                                })
                            else
                                PopupManager.pushLogin(); 
                        }} />

                    {
                        currentPage !== Config.UI.ProfilePages.ABOUT ?
                        <UserCollections
                            collectionItems={['All']}
                            defaultItem={0}
                            onSelectionChanged={() => {}}/>
                        : null
                    }

                    {
                        currentPage === Config.UI.ProfilePages.ABOUT ?
                        (
                            profileData?.about?.body ?
                            <AboutSection
                                headerUrl={profileData.about.headerUrl}
                                body={profileData.about.body}
                                links={profileData.about.links}/>
                            :
                            <NoContentInfoBox
                                icon={Images.EmptyAbout}
                                text={
                                    `${profileData.name} has not updated their about page yet`
                                }/>
                        )
                        :

                        pageItems.length > 0 ?
                        <CreationItemList 
                            className={classes.activityList}
                            itemList={pageItems.slice(0, visibleItemCount)}
                            columns={Math.floor(pageWidth / 500)}
                            onLoadingItemsStateChanged={setIsLoadingItems}/> 
                        :
                        <NoContentInfoBox
                            icon={Images.EmptyGallery}
                            text={
                                currentPage === Config.UI.ProfilePages.CREATIONS ?
                                `${profileData.name} has not published any artwork yet` :
                                `${profileData.name} has not collected any artwork yet`
                            }/>
                    }

                </div>
                : null
            }
            <Footer/>
        </Fragment>
    );
}

export default ProfileDetails;