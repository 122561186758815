import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 'min-content'
    },
    header: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    title: {
        fontFamily: Theme.Font.Secondary,
        fontSize: 30,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: 0
    },
    viewAllText: {
        fontFamily: Theme.Font.Secondary,
        fontSize: 16,
        fontWeight: 300,
        color: Theme.TextColor.Light,
        margin: '0 15px 6px 0',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
            color: Theme.Palette.Primary.Foreground,
        }
    },
    buttonContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginRight: 60
    },
    button: {
        width: 30,
        height: 30,
        margin: '0 5px',
        cursor: 'pointer',
        opacity: 0.7,
        fill: Theme.Palette.Primary.Foreground,
        '&:hover': {
            opacity: 1
        }
    },
    buttonDisabled: {
        fill: Theme.TextColor.Lightest,
        cursor: 'default',
        opacity: 1
    },
    carouselContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'scroll',
        scrollBehavior: 'smooth',
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    '@media only screen and (max-width: 1024px)': {
        title: {
            fontSize: 20
        },
        viewAllText: {
            fontSize: 10
        },
        button: {
            width: 25,
            height: 25
        }
    },
});