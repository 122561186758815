import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        margin: '0px 15px',
        padding: '3px 0',
    },
    eventContainer: {
        flex: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    userDataContainer: {
        flex: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    dateContainer: {
        flex: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    transactionLinkContainer: {
        flex: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
        textDecoration: 'none'
    },
    textField: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        margin: 2,
        fontSize: 12,
        fontWeight: 300,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    linkedField: {
        color: Theme.TextColor.Dark,
        fontFamily: Theme.Font.Primary,
        margin: 0,
        fontSize: 12,
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        },
    },
});