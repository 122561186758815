import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Helpers from '~/helpers';
import Config from '~/config';
import _ from 'lodash';

import PlayIcon from '~/Assets/Vectors/PlayIcon';
import { useStyles } from './styles';

const CollectionItem = React.memo((props) => {
    const history = useHistory();
    const classes = useStyles();

    const containerRef = React.useRef();
    const [contentZoom, setContentZoom] = React.useState(1);
    const [isFullWidth, setIsFullWidth] = React.useState(true);
    const [hoveredOver, setHoveredOver] = React.useState(false);
    const [videoLoaded, setVideoLoaded] = React.useState(false);

    const creatorList = props.creatorList || []
    
    const handleItemClicked = () => {
        if(props.creationId)
            history.push(`/creations/${props.creationId}`)
    }

    const handleVideoLoaded = (event) => {
        setVideoLoaded(true);
        setIsFullWidth(!props.squareSize || event.target.videoWidth < event.target.videoHeight);
    }

    const handleImageLoaded = (event) => {
        setIsFullWidth(!props.squareSize || event.target.naturalWidth < event.target.naturalHeight);

        let itemDimensions = {
            width: 100, 
            height: event.target.naturalHeight * 100 / event.target.naturalWidth
        }

        if(_.isFunction(props.onMediaLoaded))
            props.onMediaLoaded(props.creationId, itemDimensions);
    }

    React.useEffect(() => {
        if(containerRef.current) {
            let zoomLevel = containerRef.current.clientWidth / 300
            setContentZoom(
                zoomLevel > 1 ? 1 :
                zoomLevel < 0.5 ? 0.5 :
                zoomLevel
            )
        }
    }, [containerRef])

    return (
        <div 
            ref={containerRef}
            className={
                props.squareSize ?
                classes.squareContainer :
                classes.container
            }
            style={
                props.squareSize ? 
                {
                    width: props.squareSize,
                    height: props.squareSize,
                    minWidth: props.squareSize,
                    minHeight: props.squareSize,
                } : {}
            }
            onMouseEnter={() => { setHoveredOver(true) }}>

            {
                videoLoaded ? null :
                <img
                    className={
                        isFullWidth ?
                        classes.fullWidth :
                        classes.fullHeight
                    }
                    onLoad={handleImageLoaded}
                    src={props.imageUrl}
                    alt=""/> 
            }

            {
                props.videoUrl && (!props.playOnHover || hoveredOver) ?
                <video 
                    className={
                        isFullWidth ?
                        classes.fullWidth :
                        classes.fullHeight
                    } 
                    onContextMenu={(e) => {e.preventDefault()}}
                    style={{display: videoLoaded ? 'block' : 'none'}}
                    controlsList="nodownload"
                    disablePictureInPicture 
                    playsInline 
                    autoPlay 
                    muted
                    loop
                    onLoadedData={handleVideoLoaded}>
                    <source src={props.videoUrl} type="video/mp4"/>
                </video> : null
            }

            {
                props.playOnHover && !hoveredOver ?
                <div className={classes.playOnHoverContainer}>
                    <PlayIcon className={classes.playOnHoverIcon}/>
                </div> : null
            }

            <div 
                className={classes.infoOverlayContainer}
                style={{zoom: contentZoom}}>
                    
                <div 
                    onClick={handleItemClicked}
                    className={classes.infoContainer}>

                    <div className={classes.infoMarginContainer}>

                        <Link 
                            to={`/creations/${props.creationId}`}
                            className={classes.title}>
                            {props.creationName}
                        </Link>

                        <div className={classes.authorContainer}>
                            {
                                creatorList.map((creator, index) => 
                                    <Link 
                                        key={`ci_${creator.name}`}
                                        to={`/profiles/${creator.username}/`}
                                        className={classes.author}>
                                        {
                                            index === creatorList.length - 1 ?
                                            creator.name : `${creator.name},`
                                        }
                                    </Link>       
                                )
                            }
                        </div>

                        <div className={classes.detailsContainer}>
                            <div className={classes.leftDetailsContainer}>
                                {
                                    props.status === 'AUCTION' ?
                                    <p 
                                        className={classes.edition}
                                        style={{marginBottom: 10}}>
                                        In Auction
                                    </p> :

                                    props.price ?
                                    <p className={classes.priceText}>
                                        {Helpers.formatEthPrice(Helpers.weiToEth(props.price))} {Config.Blockchain.COIN}
                                        <strong>
                                            (${Helpers.formatUsdPrice(Helpers.getEthPrice() * Helpers.weiToEth(props.price))})
                                        </strong>
                                    </p> :

                                    props.directPurchasePrice ?
                                    <p className={classes.priceText}>
                                        {Helpers.formatEthPrice(Helpers.weiToEth(props.directPurchasePrice))} {Config.Blockchain.COIN}
                                        <strong>
                                            (${Helpers.formatUsdPrice(Helpers.getEthPrice() * Helpers.weiToEth(props.directPurchasePrice))})
                                        </strong>
                                    </p> :

                                    Helpers.weiToEth(props.price) === 0 ?
                                    <p 
                                        className={classes.edition}
                                        style={{marginBottom: 10}}>
                                        Accepting offers
                                    </p> :
                                    null
                                }

                                <p className={classes.viewDetails}>
                                    {'View details >'}
                                </p>
                            </div>
                            
                            <p className={classes.edition}>
                                Edition {props.releaseNumber} of {props.releaseCount}
                            </p>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
})

export default CollectionItem;