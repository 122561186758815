import React, { Fragment } from 'react';
import ActivityItem from '../ActivityItem';
import CollectionItem from '../CollectionItem';

import { useStyles } from './styles';
import _ from 'lodash';

const CreationItemList = (props) => {
    const classes = useStyles();
    
    const itemsToLoadRef = React.useRef([]);
    const [, setItemsToLoad] = React.useState([]);

    const loadedItemsRef = React.useRef([]);
    const [, setLoadedItems] = React.useState([]);
    
    const isLoadingItemsRef = React.useRef(false);

    const handleItemLoaded = React.useCallback(
        (itemId, dimensions) => {
            let itemsToLoadCopy = [...itemsToLoadRef.current];
            let item = itemsToLoadCopy.find(item => item.id === itemId);

            let isSourceListItem = props.itemList.find(listItem => 
                listItem.creationId === itemId || listItem.creation?.creationId === itemId
            )

            if(!item || !item.component || !isSourceListItem)
                return;

            itemsToLoadCopy.splice(itemsToLoadCopy.indexOf(item), 1);

            itemsToLoadRef.current = itemsToLoadCopy;
            setItemsToLoad(itemsToLoadCopy);

            if(_.isFunction(props.onLoadingItemsStateChanged)) {

                if(isLoadingItemsRef.current && itemsToLoadRef.current.length === 0) {
                    isLoadingItemsRef.current = false;
                    setTimeout(() => {
                        props.onLoadingItemsStateChanged(false);
                    }, 2000);
                }

                if(!isLoadingItemsRef.current && itemsToLoadRef.current.length > 0) {
                    isLoadingItemsRef.current = true;
                    setTimeout(() => {
                        props.onLoadingItemsStateChanged(true);
                    }, 2000);
                }
            }

            if(!loadedItemsRef.current.find(existingItem => existingItem.itemId === itemId)) {
                loadedItemsRef.current = [
                    ...loadedItemsRef.current,
                    {
                        itemId,
                        dimensions,
                        component: item.component
                    }
                ]
            }

            setLoadedItems(loadedItemsRef.current);
        },
        [props]
    )

    const getVisibleItems = (items, columnCount) => {
        if(columnCount === 0)
            return null;

        if(columnCount === 1) {
            return (
                <div className={classes.column}>
                    { items.map(item => item.component) }
                </div>
            )
        } 

        let columns = [];
        let columnHeights = [];

        for(let i=0; i<columnCount; i++) {
            columns.push([]);
            columnHeights[i] = 0;
        }

        for(let item of items) {
            let nextColumn = 0;
            let minHeight = Number.MAX_SAFE_INTEGER;

            for(let i=0; i<columnCount; i++) {
                if(columnHeights[i] < minHeight) {
                    minHeight = columnHeights[i];
                    nextColumn = i;
                }
            }

            columns[nextColumn].push(item.component);
            columnHeights[nextColumn] += item.dimensions.height;
        }

        return columns.map((column, index) => 
            <div 
                key={`col_${index}`}
                className={classes.column}>
                { column }
            </div>
        );
    }

    React.useEffect(
        () => {
            if(!props.itemList)
                return;

            const newLoadedItemList = 
                loadedItemsRef.current.filter(item => 
                    props.itemList.find(listItem => 
                        listItem.creationId === item.itemId || listItem.creation?.creationId === item.itemId
                    )
                );

            if(JSON.stringify(newLoadedItemList) !== JSON.stringify(loadedItemsRef.current)) {
                loadedItemsRef.current = newLoadedItemList;
            }

            let itemComponents = [];

            if(props.isActivityList) {
                itemComponents = props.itemList
                    .filter(item => !itemsToLoadRef.current.find(loadingItem => loadingItem.id === item.creation.creationId))
                    .filter(item => !loadedItemsRef.current.find(loadedItem => loadedItem.itemId === item.creation.creationId))
                    .map(item => 
                        ({
                            id: item.creation.creationId,
                            component: 
                                <ActivityItem
                                    key={item.activityId}
                                    username={item.user.username}
                                    name={item.user.name}
                                    profilePictureUrl={item.user.profilePictureUrl}
                                    creationId={item.creation.creationId}
                                    activity={item.action}
                                    metadata={item.metadata}
                                    createdAt={item.createdAt}>
                                    
                                    <CollectionItem
                                        creationId={item.creation.creationId}
                                        creationName={item.creation.name}
                                        creatorList={[item.creation.creator]}
                                        imageUrl={item.creation.media.image.url}
                                        videoUrl={item.creation.media.video.url}
                                        playOnHover={!!item.creation.media.video.url}
                                        status={item.status}
                                        price={item.creation.price}
                                        directPurchasePrice={item.creation.directPurchasePrice}
                                        releaseNumber={item.creation.release.number}
                                        releaseCount={item.creation.release.count}
                                        onMediaLoaded={handleItemLoaded} />
                                        
                                </ActivityItem>
                        })
                    )
            }
            else {
                itemComponents = props.itemList
                    .filter(item => !itemsToLoadRef.current.find(loadingItem => loadingItem.id === item.creationId))
                    .filter(item => !loadedItemsRef.current.find(loadedItem => loadedItem.itemId === item.creationId))
                    .map(item => 
                        ({
                            id: item.creationId,
                            component: 
                                <CollectionItem
                                    key={item.creationId}
                                    creationId={item.creationId}
                                    creationName={item.name}
                                    creatorList={
                                        item.creators ? item.creators : 
                                        item.creator ? [item.creator] :
                                        item.owner ? [item.owner]: 
                                        []
                                    }
                                    imageUrl={item.media.image.url}
                                    videoUrl={item.media.video.url}
                                    playOnHover={!!item.media.video.url}
                                    price={item.price}
                                    status={item.status}
                                    directPurchasePrice={item.directPurchasePrice}
                                    releaseNumber={item.release.number}
                                    releaseCount={item.release.count}
                                    onMediaLoaded={handleItemLoaded} />
                        })
                    )
            }

            itemsToLoadRef.current = [
                ...itemsToLoadRef.current,
                ...itemComponents
            ];

            setItemsToLoad(itemComponents)
        }, 
        [props.itemList, props.isActivityList, handleItemLoaded]
    )

    return (
        <Fragment>
            <div 
                className={
                    props.className ? 
                    classes.container + ' ' + props.className :    
                    classes.container}>

                { 
                    getVisibleItems(
                        loadedItemsRef.current, 
                        props.columns === 0 ? 1 : props.columns || 3
                    ) 
                }

            </div>
            
            <div className={classes.loadingArea}>
                { itemsToLoadRef.current.map(item => item.component) }
            </div>
        </Fragment>
    );
}

export default CreationItemList;