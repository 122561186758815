import _ from 'lodash';
import axios from 'axios';
import Config from '~/config';
import LocalStorage from './local_storage';

class API {

    static async get(endpoint, useToken) {
        
        return (
            axios
                .get(
                    Config.Server.API_BASE_URL + endpoint,
                    {
                        headers: useToken ? { token: LocalStorage.getToken() } : {}
                    }
                )
                .then(response => response.data.result)
                .catch(error => Promise.reject(error.response?.data?.message || error.message))
        );
    }
    
    static async post(endpoint, data, useToken) {
        return (
            axios
                .post(
                    Config.Server.API_BASE_URL + endpoint,
                    data,
                    {
                        headers: useToken ? { token: LocalStorage.getToken() } : {}
                    }
                )
                .then(response => response.data.result)
                .catch(error => Promise.reject(error.response?.data?.message || error.message))
        );
    }

    static async patch(endpoint, data, useToken) {
        return (
            axios
                .patch(
                    Config.Server.API_BASE_URL + endpoint,
                    data,
                    {
                        headers: useToken ? { token: LocalStorage.getToken() } : {}
                    }
                )
                .then(response => response.data.result)
                .catch(error => Promise.reject(error.response?.data?.message || error.message))
        );
    }

    static async delete(endpoint, useToken) {
        return (
            axios
                .delete(
                    Config.Server.API_BASE_URL + endpoint,
                    { 
                        headers: useToken ? { token: LocalStorage.getToken() } : {}
                    }
                )
                .then(response => response.data.result)
                .catch(error => Promise.reject(error.response?.data?.message || error.message))
        );
    }

    static async upload(endpoint, fields, progressCallback, useToken) {

        let data = new FormData();

        for(let field in fields)
            data.append(field, fields[field]);

        return (
            axios
                .post(
                    Config.Server.API_BASE_URL + endpoint,
                    data,
                    { 
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            token: useToken ? LocalStorage.getToken() : undefined,
                        },
                        onUploadProgress: (progressEvent) => {
                            if(_.isFunction(progressCallback))
                                progressCallback(progressEvent.loaded / progressEvent.total)
                        }
                    }
                )
                .then(response => response.data.result)
                .catch(error => Promise.reject(error.response?.data?.message || error.message))
        );
    }

    static buildQueryParamString(paramsObject) {
        let queryParams = '';

        for(let param in paramsObject) {
        
            if(_.isUndefined(paramsObject[param]))
                continue;
        
            queryParams += 
                (queryParams ? '&' : '?') + 
                `${param}=${paramsObject[param]}`;
        }

        return queryParams;
    }
}

export default API;