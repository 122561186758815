import React from 'react';
import MetaMask from '~/Services/MetaMask';
import { Link } from 'react-router-dom';

import ProfilePicture from '~/Components/ProfilePicture';
import { useStyles } from './styles';

const OwnershipHistoryTableItem = (props) => {
    const classes = useStyles();
    const tokenDetails = MetaMask.getTokenDetails();

    return (
        <div className={classes.container}>

            <div className={classes.eventContainer}>
                <Link
                    to={`/creations/${props.creationId}`} 
                    style={{marginLeft: 15}}
                    className={classes.linkedField}>
                    {props.event}
                </Link>
            </div>

            <div className={classes.userDataContainer}>
                <ProfilePicture
                    style={{
                        margin: '5px 15px 5px 0'
                    }}
                    username={props.username}
                    imageUrl={props.profilePictureUrl} 
                    size={30}/>

                {
                    props.username ?
                    <Link 
                        to={`/profiles/${props.username}/`}
                        className={classes.linkedField}>
                        { props.name }
                    </Link> :
                    <div className={classes.linkedField}>
                        { props.name }
                    </div>
                }
            </div>

            <div className={classes.dateContainer}>
                <p className={classes.textField}>
                    {new Date(props.transactionDate).toDateString()}
                </p>
            </div>

            <div className={classes.transactionLinkContainer}>
                <a
                    href={
                        tokenDetails?.explorerUrl ?
                        `${tokenDetails?.explorerUrl}/tx/${props.blockchainData.txid}` : 
                        '#'
                    }
                    className={classes.linkedField}>
                    View on blockchain
                </a>
            </div>
        </div>
    );
}

export default OwnershipHistoryTableItem;