import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        width: 800,
        display: 'flex',
        flexDirection: 'row'
    },
    previewContainer: {
        flex: 1,
        padding: 20,
        borderRight: '1px solid #eee'
    },
    purchaseContainer: {
        flex: 1,
        padding: 20
    },
    title: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 22,
        fontWeight: 400,
        color: Theme.TextColor.Dark,
        margin: '5px 0'
    },
    descriptionContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        margin: '10px 0'
    },
    descriptionAuthor: {
        flex: 1,
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark
    },
    descriptionEdition: {
        fontFamily: Theme.Font.Primary,
        fontSize: 14,
        fontWeight: 300,
        color: Theme.TextColor.Dark
    },
    '@media only screen and (max-width: 800px)': {
        container: {
            width: 500
        },
        previewContainer: {
            display: 'none'
        }
    },
    '@media only screen and (max-width: 500px)': {
        container: {
            width: '100%'
        }
    },
});