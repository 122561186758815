import React from 'react';
import Signature from '~/Services/signature';
import PopupManager from '~/Auxilary/PopupManager';
import UserData from '~/API/user_data';
import Helpers from '~/helpers';
import Config from '~/config';

import Button from '~/Components/Button';
import LabeledInputField from '~/Components/LabeledInputField';

import { useStyles } from './styles';

const SetPrice = (props) => {

    const classes = useStyles();
    const [priceEth, setPriceEth] = React.useState(0);
    const [priceUsd, setPriceUsd] = React.useState(0); 
    const [allowOffers, setAllowOffers] = React.useState(false); 
    const [isSettingPrice, setIsSettingPrice] = React.useState(false); 

    const handlePriceChanged = (value) => {
        setPriceEth(value);
        setPriceUsd(value * Helpers.getEthPrice());
    }

    const handleAllowOffersChanged = (target) => {
        setAllowOffers(target.target.checked);
    }

    const handleSetPrice = () => {
        setIsSettingPrice(true);

        Signature
            .signSaleApproval(null, props.tokenId, priceEth, props.approvalId, 0)
            .then(signatureData => {
                UserData.setPrice(props.creationId, allowOffers, signatureData)
                .then((data) => {
                    PopupManager.pushMessagePopup(
                        'Price set',
                        [ `The price for this creation has been updated successfully. Users of the platform will now be able to purchase this creation for ${Helpers.formatEthPrice(priceEth)} ${Config.Blockchain.COIN}.`],
                        'PASS'
                    );

                    setIsSettingPrice(false);
                })
                .catch(() => {
                    setIsSettingPrice(false);
                })
            })
            .catch(() => {
                setIsSettingPrice(false);
            });
    }

    React.useEffect(
        () => {
            let currentPriceEth = Helpers.weiToEth(props.currentPrice);
            setPriceEth(currentPriceEth);
            setPriceUsd(currentPriceEth * Helpers.getEthPrice());
        },
        [props.currentPrice]
    );

    return (
        <div className={classes.container} style={{opacity: props.disabled ? 0.5 : 1}}>

            <p className={classes.sectionTitle}>
                Set creation price
            </p>

            <p className={classes.instructionText}>
                In the field below, you can set the price at which you want to sell this creation. Collectors will be able to purchase this creation at the specified price without any aditional intervention from your side.
            </p>

            <p className={classes.instructionText}>
                If you're willing to accept offers below the price you set, check the box below the price field. Offers will require additional approval from your side.
            </p>

            <p style={{marginTop: 20}} className={classes.instructionText}>
                {`All payments are done in ${Config.Blockchain.COIN}.`}
            </p>

            <LabeledInputField
                className={classes.priceField}
                onChange={handlePriceChanged}
                placeholder={`Sale price in ${Config.Blockchain.COIN}`}
                disabled={props.disabled}
                labelText={`$ ${Helpers.formatUsdPrice(priceUsd)}`}
                value={priceEth}/>

            <div className={classes.invalidateControls}>
                <div className={classes.checkboxContainer}>
                    <input 
                        className={classes.checkbox}
                        onChange={handleAllowOffersChanged}
                        value={allowOffers}
                        disabled={props.disabled}
                        type="checkbox" 
                        id="allow-offers"/>
                    
                    <label 
                        className={classes.checkboxLabel} 
                        htmlFor="allow-offers">
                        Allow offers for prices lower than this
                    </label>
                </div>

                <Button
                    className={classes.submitButton}
                    onClick={handleSetPrice}
                    disabled={props.disabled}
                    isLoading={isSettingPrice}
                    text={
                        isSettingPrice ?
                        'Setting price...' :
                        'Set price'
                    }/>

            </div>
        </div>
    );
}

export default SetPrice;