import Config from '~/config';
import API from './api';
import LocalStorage from './local_storage';

class User {
    
    static async login(username, password, code) {
        let userData = 
            await API.post(
                `/users/login`,
                {
                    username,
                    password,
                    code
                }
            );

        LocalStorage.setCredentials(userData);
        return userData;
    }

    static async get() {
        return API.get(
            `/users/${LocalStorage.getUsername()}`, 
            true
        );
    }

    static async update(fields) {
        return API.patch(
            `/users/${LocalStorage.getUsername()}`,
            fields,
            true
        );
    }

    static async updateAbout(body, links) {
        return API.patch(
            `/users/${LocalStorage.getUsername()}/about`,
            {
                body, 
                links
            },
            true
        );
    }

    static async register(username, password, email, name, captchaId, captcha, isCreator, invitationCode) {
        return API.post(
            `/users`,
            {
                username,
                password,
                email,
                name,
                captchaId,
                captcha,
                role: isCreator ? Config.User.Roles.CREATOR : Config.User.Roles.COLLECTOR,
                code: isCreator ? invitationCode : undefined
            });
    }

    static async getCaptcha() {
        return API.get(`/users/captcha`);
    }

    static async getStatus(username) {
        return API.get(`/users/${username}/status`);
    }

    static async changePassword(currentPassword, newPassword) {
        return API.patch(
            `/users/${LocalStorage.getUsername()}/password`,
            {
                currentPassword,
                newPassword
            },
            true
        );
    }

    static async requestPasswordReset(email) {
        return API.post(
            `/users/request-password-reset`,
            { email }
        );
    }

    static async resetPasswordWithCode(email, code, newPassword) {
        return API.post(
            `/users/perform-password-reset`,
            {
                email,
                code,
                newPassword
            }
        );
    }

    static async requestEmailVerification() {
        return API.post(
            `/users/${LocalStorage.getUsername()}/verify/request`,
            {},
            true
        );
    }

    static async verifyEmailWithCode(code) {
        return API.post(
            `/users/${LocalStorage.getUsername()}/verify`,
            { code },
            true
        );
    }

    static async uploadProfilePicture(file, progressCallback) {
        return API.upload(
            `/users/${LocalStorage.getUsername()}/profile-picture`,
            { file },
            progressCallback,
            true
        );
    }

    static async uploadHeaderPicture(file, progressCallback) {
        return API.upload(
            `/users/${LocalStorage.getUsername()}/about/header-picture`,
            { file },
            progressCallback,
            true
        );
    }

    static async uploadProfilePreview(file, progressCallback) {
        return API.upload(
            `/users/${LocalStorage.getUsername()}/profile-preview-picture`,
            { file },
            progressCallback,
            true
        );
    }

    static async submitCreatorRequest(bestWork, worksToPublish, achievements) {
        return API.post(
            `/users/${LocalStorage.getUsername()}/creator-request`,
            { bestWork, worksToPublish, achievements },
            true
        );
    }

    static async generate2faSecret() {
        return API.get(
            `/users/${LocalStorage.getUsername()}/2fa/generate-secret`, 
            true
        );
    }

    static async update2faSettings(isActive, code, secret) {
        return API.post(
            `/users/${LocalStorage.getUsername()}/2fa`, 
            { isActive, code, secret },
            true
        );
    }

    static async subscribeToNewsletter(email) {
        return API.post(
            `/users/newsletter`, 
            { email },
            false
        );
    }
}

export default User;