import React from 'react';
import _ from 'lodash';

import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import Images from '~/Assets/images';

const PixelatedButton = (props) => {
    const history = useHistory();
    const classes = useStyles({
        secondary: props.secondary, 
        inverted: props.inverted,
        borderColor: props.borderColor || 'black',
        foregroundColor: props.foregroundColor || 'black',
        pixelSize: 3,
        darkBackground: props.darkBackground
    });

    const handleClick = () => {
        if(props.isLoading || props.disabled)
            return;

        if(props.href)
            history.push(props.href);
        
        if(_.isFunction(props.onClick))
            props.onClick();
    }

    return (
        <div 
            className={
                (props.isLoading || props.disabled ? classes.loadingContainer : classes.container) + 
                (props.className ? ` ${props.className}` : '')
            }
            onClick={handleClick}
            style={{
                ...props.style,
                opacity: props.disabled ? 0.5 : 1
            }}>

            <div className={classes.horizontalBg}></div>
            <div className={classes.verticalBg}></div>
            <div className={classes.centerBg}></div>
            
            <div className={classes.horizontalFg}></div>
            <div className={classes.verticalFg}></div>


            <div className={classes.bodyContainer}>
                {
                    props.isLoading ?
                    <div 
                        className={classes.loader}
                        style={{ backgroundImage: `url(${Images.Loader}`}}/> : 
                    null
                }

                { props.text }
            </div>

        </div>
    );
}

export default PixelatedButton;