import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: '1px solid #ccc',
        backgroundColor: '#eee'
    },
    input: {
        flex: 1,
        width: '100%',
        fontFamily: Theme.Font.Primary,
        fontSize: 'inherit',
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        border: 'none',
        outline: 'none',
        padding: 10,
        margin: 0,
        '&:disabled': {
            backgroundColor: '#fafafa'
        }
    },
    label: {
        fontFamily: Theme.Font.Primary,
        fontSize: 'inherit',
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        margin: '5px 10px'
    }
});