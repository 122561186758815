import React from 'react';
import { useStyles } from './styles';

const CreationDetailsList = (props) => {
    const classes = useStyles();
    const detailsList = props.detailsList || [];

    return (
        <div className={classes.container}>

            <p className={classes.title}>
                Additional Details:
            </p>

            <ul className={classes.list}>
                { 
                    detailsList.map(item => 
                        <li 
                            key={item}
                            className={classes.listItem} 
                            dangerouslySetInnerHTML={{__html: item}}/>    
                    )
                }
            </ul>

        </div>
    );
}

export default CreationDetailsList;