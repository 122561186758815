import React from 'react';

const CarouselArrowRight = (props) => {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg" 
            version="1.1"
            viewBox="168.618 84.932 189.401 341.113"
            onClick={props.onClick}>
            <polygon points="194.284 84.932 244.144 84.932 244.177 124.129 281.629 124.129 281.629 161.772 320.138 161.772 320.138 200.305 358.019 200.305 358.019 311.919 320.113 311.919 320.113 349.709 281.969 349.709 281.969 388.042 243.385 388.042 243.385 426.045 168.618 426.045 168.618 387.826 206.552 387.826 206.552 349.483 244.655 349.483 244.655 311.699 281.584 311.699 281.584 198.983 243.907 198.983 243.907 161.279 206.541 161.279 206.541 124.326 169.017 124.326 169.017 85.006"/>
        </svg>
    )
}

export default CarouselArrowRight;