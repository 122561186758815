import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        fontWeight: 300,
        padding: '10px 200px',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        fontFamily: Theme.Font.Primary,
        fontSize: 26
    },
    commentBox: {
        flex: 1,
        resize: 'vertical',
        minHeight: 100,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: Theme.TextColor.Lighter,
        fontFamily: Theme.Font.Primary,
        fontWeight: 300,
        fontSize: 15,
        padding: 10,
        width: 'auto'
    },
    buttonHolder: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    commentButton: {
        padding: '8px 13px',
        margin: '10px 0'
    },
    commentContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 30
    },    
    '@media only screen and (max-width: 1024px)': {
        container: {
            padding: '10px 40px',
        }
    },
    '@media only screen and (max-width: 800px)': {
        container: {
            padding: 20,
        }
    },
});