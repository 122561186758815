import React from 'react';
import _ from 'lodash';

import Button from '~/Components/Button';
import { useStyles } from './styles';

const BlockchainWalletItem = (props) => {

    const classes = useStyles();    
    
    const handleDisconnectClicked = () => {
        if(_.isFunction(props.onDisconnectClicked))
            props.onDisconnectClicked(props.address)
    }

    return (
        <div className={classes.container}>

            <p className={classes.address}>
                { props.address }
            </p>

            <Button
                className={classes.disconnectButton}
                text="Disconnect"
                onClick={handleDisconnectClicked}
                secondary/>

        </div>
    );
}

export default BlockchainWalletItem;