import React from 'react';
import SocialMediaLink from '../SocialMediaLink';
import Images from '~/Assets/images';

import { useStyles } from './styles';

const AboutSection = (props) => {
    const classes = useStyles();

    return (
        <div 
            className={ 
                props.className ? 
                classes.container + ' ' + props.className :
                classes.container
            }>
            
            <img
                className={classes.banner}
                src={props.headerUrl}
                alt=""/>


            <div className={classes.bodyContainer}>

                <div className={classes.textContainer}>
                    {
                        props.body
                            .split('\n')
                            .filter(paragraph => paragraph.length > 0)
                            .map((paragraph, index) => 
                                <p 
                                    key={`p_${index}`}
                                    className={classes.bodyText}>
                                    {paragraph}
                                </p>
                            )
                    }

                </div>

                <div className={classes.linksContainer}>
                    {
                        props.links.map(item => 
                            <SocialMediaLink
                                key={item.url}
                                url={item.url}
                                icon={Images.Icons[item.icon] || Images.Icons.Website} />
                        )
                    }
                </div>

            </div>
        </div>
    );
}

export default AboutSection;