import React from 'react';
import _ from 'lodash';

import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import Images from '~/Assets/images';

const Button = (props) => {
    const history = useHistory();
    const classes = useStyles({
        secondary: props.secondary, 
        inverted: props.inverted
    });

    const handleClick = () => {
        if(props.isLoading || props.disabled)
            return;

        if(props.href)
            history.push(props.href);
        
        if(_.isFunction(props.onClick))
            props.onClick();
    }

    return (
        <button 
            className={
                (props.isLoading || props.disabled ? classes.loadingContainer : classes.container) + 
                (props.className ? ` ${props.className}` : '')
            }
            onClick={handleClick}
            style={{
                ...props.style,
                opacity: props.disabled ? 0.5 : 1
            }}>

            {
                props.isLoading ?
                <div 
                    className={classes.loader}
                    style={{ backgroundImage: `url(${Images.Loader}`}}/> : 
                null
            }

            { props.text }
        </button>
    );
}

export default Button;