import React from 'react';

import Button from '~/Components/Button';
import Images from '~/Assets/images';
import { useStyles } from './styles';

const MintRequestItem = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.container}>

            <div 
                className={classes.descriptionContainer}
                style={{flex: props.descriptionFlex}}>
                <div 
                    className={classes.itemPreview}
                    style={{
                        background: 
                            props.previewUrl ? 
                            `url('${props.previewUrl}')` :
                            `url('${Images.EmptyGallery}')`
                    }}>
                </div>

                <div className={classes.column}>

                    <p className={classes.itemName}>
                        {props.name}
                    </p>

                    <p className={classes.itemEdition}>
                        Editions: {props.editions}
                    </p>

                </div>
            </div>

            {
                props.statusFlex &&
                <div 
                    className={classes.column}
                    style={{
                        flex: props.statusFlex, 
                        flexDirection: 'row', 
                        alignItems: 'center'
                    }}>

                    {
                        props.status?.endsWith('...') &&
                        <img
                            alt=""
                            src={Images.Loader}
                            style={{width: 20, height: 20, marginRight: 10}}/>
                    }

                    <p className={classes.status}>
                        { props.status }
                    </p>

                </div>
            }

            <div 
                className={classes.columnHorizontal}
                style={{flex: props.priceColumnFlex}}>

                {
                    props.onMintClicked &&
                    <Button
                        className={classes.button}
                        onClick={props.onMintClicked.bind(null, props.mintRequestId)}
                        text="Publish Creation"/>
                }

                {
                    props.onDeleteClicked &&
                    <Button
                        className={classes.button}
                        secondary
                        onClick={props.onDeleteClicked.bind(null, props.mintRequestId)}
                        text="Delete"/>
                }

            </div>

        </div>
    );
}

export default MintRequestItem;