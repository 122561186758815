import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        width: 400,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20
    },
    title: {
        fontFamily: Theme.Font.Primary,
        fontSize: 30,
        fontWeight: 300,
        color: Theme.TextColor.Dark,
        textAlign: 'center',
        margin: '25px 0'
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: 10
    },
    shareIcon: {
        width: 50,
        height: 50,
        opacity: 0.8,
        '&:hover': {
            opacity: 1
        }
    },
    '@media only screen and (max-width: 500px)': {
        container: {
            width: '100%'
        }
    },
});