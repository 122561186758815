import * as API from '~/API';

import {
    MINT_REQUEST_LIST_FETCH_START,
    MINT_REQUEST_LIST_FETCH_FINISHED,
    MINT_REQUEST_LIST_FETCH_FAILED,
    MINT_REQUEST_FETCH_START,
    MINT_REQUEST_FETCH_FINISHED,
    MINT_REQUEST_FETCH_FAILED,
} from './types';

export const listMintRequests = () => {
    return (dispatch) => {
        
        dispatch({ type: MINT_REQUEST_LIST_FETCH_START });

        API.MintRequest.list()
        .then(data => {
            dispatch({ type: MINT_REQUEST_LIST_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: MINT_REQUEST_LIST_FETCH_FAILED, payload: error });
        })
    };  
};

export const getMintRequest = (mintRequestId) => {
    return (dispatch) => {
        
        dispatch({ type: MINT_REQUEST_FETCH_START });

        API.MintRequest.get(mintRequestId)
        .then(data => {
            dispatch({ type: MINT_REQUEST_FETCH_FINISHED, payload: data });
        })
        .catch(error => {
            dispatch({ type: MINT_REQUEST_FETCH_FAILED, payload: error });
        })
    };  
};
