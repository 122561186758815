import React from 'react';

const CommentIcon = (props) => {
    return (
        <svg
            className={ props.className }
            xmlns="http://www.w3.org/2000/svg" 
            version="1.1"
            viewBox="0 -257 1850 1850">
            <g transform="matrix(1,0,0,-1,22.779661,1168.7797)">
                <path
                    d="M 1792,640 Q 1792,466 1672,318.5 1552,171 1346,85.5 1140,0 896,0 826,0 751,8 553,-167 291,-234 q -49,-14 -114,-22 -17,-2 -30.5,9 -13.5,11 -17.5,29 v 1 q -3,4 -0.5,12 2.5,8 2,10 -0.5,2 4.5,9.5 l 6,9 q 0,0 7,8.5 7,8.5 8,9 7,8 31,34.5 24,26.5 34.5,38 10.5,11.5 31,39.5 20.5,28 32.5,51 12,23 27,59 15,36 26,76 Q 181,228 90.5,359 0,490 0,640 0,770 71,888.5 142,1007 262,1093 q 120,86 286,136.5 166,50.5 348,50.5 244,0 450,-85.5 206,-85.5 326,-233 Q 1792,814 1792,640 z" />
            </g>
        </svg>
    )
}

export default CommentIcon;