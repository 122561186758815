import API from './api';

class MintRequest {
    
    static async create(name, description, editions, file, progressCallback) {
        return API.upload(
            `/mint-request`,
            { name, description, editions, file },
            progressCallback,
            true
        );
    }

    static async list() {
        return API.get(
            `/mint-request`,
            true
        );
    }

    static async get(mintRequestId) {
        return API.get(
            `/mint-request/${mintRequestId}`, 
            true
        );
    }

    static async delete(mintRequestId) {
        return API.delete(
            `/mint-request/${mintRequestId}`, 
            true
        );
    }
}

export default MintRequest;