import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Config from '~/config';

import * as UserActions from '~/Redux/User/actions';
import * as CreationActions from '~/Redux/Creation/actions';
import { STATE_DONE, STATE_NOT_PROCESSED } from '~/Redux/states';

import MainHeader from '~/Components/MainHeader';
import AuthenticityInfo from './AuthenticityInfo';
import CreationMediaView from '~/Components/CreationMediaView';
import TableHeader from './TableHeader';
import OwnershipHistoryTableItem from '~/Components/OwnershipHistoryTableItem';

import { useStyles } from './styles';
import Footer from '~/Components/Footer';

const AuthenticityPage = () => {

    const classes = useStyles();
    const { creationId } = useParams();

    const dispatch = useDispatch();

    const userData = useSelector(state => state.User);

    const creationData = useSelector(state => state.Creation.creationData);
    const creationDataState = useSelector(state => state.Creation.creationDataState);

    const creationEditions = useSelector(state => state.Creation.editions);
    const creationEditionsState = useSelector(state => state.Creation.editionsState);
    
    const pageWidth = useSelector(state => state.UI.pageWidth);

    React.useEffect(
        () => {
            if (!userData.data && userData.token && 
                userData.fetchDataState === STATE_NOT_PROCESSED) {
                dispatch(UserActions.getData());
            }
        },
        [userData, dispatch]
    )

    React.useEffect(
        () => {
            if (creationDataState === STATE_NOT_PROCESSED || 
               (creationData && creationId !== creationData.creationId)) {
                dispatch(CreationActions.getCreationData(creationId));
            }
            else if(creationEditionsState === STATE_NOT_PROCESSED) {
                dispatch(CreationActions.getCreationEditions(creationId));
            }
        }, 
        [creationId, creationData, creationDataState, creationEditionsState, dispatch]
    )

    return (
        <Fragment>
            
            <MainHeader
                username={userData.data?.username}
                profilePictureUrl={userData.data?.profilePictureUrl}
                userType={userData.data?.role}
                isLoggedIn={!!userData.data}
                isMobile={pageWidth < 800}/>

            {
                creationData ?
                <div className={classes.pageContainer}>

                    <p className={classes.title}>
                        Proof of Authenticity
                    </p>

                    <p className={classes.description}>
                        {`${Config.Platform.NAME} verifies the uniqueness and authenticity of every digital creation on the blockchain.`}
                    </p>

                    <div className={classes.infoContainer}>

                        <AuthenticityInfo
                            className={classes.authenticityInfo}
                            title={creationData.name}
                            creators={creationData.creators}
                            releaseData={creationData.release}
                            blockchainData={creationData.blockchain}
                            createdAt={creationData.createdAt}/>

                        <CreationMediaView
                            className={classes.mediaView}
                            imageUrl={creationData.media.image.url}
                            videoUrl={creationData.media.video.url}
                            isVideo={!!creationData.media.video.url}
                            videoControls/>

                    </div>

                    <p className={classes.tableTitle}>
                        Ownership
                    </p>

                    <TableHeader
                        headerItems={[ 'EDITION', 'OWNER', 'DATE ISSUED', 'DETAILS' ]}/>

                    <OwnershipHistoryTableItem
                        creationId={creationData.creationId}
                        name={creationData.owner.name}
                        username={creationData.owner.username}
                        profilePictureUrl={creationData.owner.profilePictureUrl}
                        blockchainData={creationData.blockchain}
                        event={`#${creationData.release.number}`}
                        transactionDate={new Date()}
                        transactionLink="/" />

                    <div style={{height: 30}}/>

                    <p className={classes.tableTitle}>
                        Editions
                    </p>

                    <p className={classes.tableNote}>
                        {`* ${creationEditions?.length} out of ${creationData?.release.count} editions have currently been issued.`}
                    </p>

                    <TableHeader
                        headerItems={[ 'EDITION', 'OWNER', 'DATE ISSUED', 'DETAILS' ]}/>

                    <div className={classes.tableItemContainer}>
                        {
                            creationEditionsState === STATE_DONE ?
                            creationEditions.map(item => 
                                <OwnershipHistoryTableItem
                                    key={item.creationId}
                                    creationId={item.creationId}
                                    name={item.owner.name}
                                    username={item.owner.username}
                                    profilePictureUrl={item.owner.profilePictureUrl}
                                    blockchainData={item.blockchain}
                                    event={`#${item.release.number}`}
                                    transactionDate={item.createdAt}
                                    transactionLink="/" />
                            ) 
                            : null
                        }
                    </div>

                    <div style={{height: 30}}/>

                </div> :
                null
            }
            <Footer/>
        </Fragment>
    );
}

export default AuthenticityPage;