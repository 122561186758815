import React from 'react';
import Helpers from '~/helpers';
import Config from '~/config';

import PixelatedButton from '../../PixelatedButton';

import { useStyles } from './styles';

const DirectPurchaseActions = ({status, isOwner, owner, price, directPurchasePrice, onDirectPurchase}) => {
    const classes = useStyles();

    return (                
        !isOwner && owner.username !== null &&
        status === 'ACCEPTING_OFFERS' && 
        !Helpers.weiToEth(price) &&
        Helpers.weiToEth(directPurchasePrice) ?
        <div className={classes.container}>

            <p className={classes.price}>
                {Helpers.formatEthPrice(Helpers.weiToEth(directPurchasePrice))} {Config.Blockchain.COIN} (
                <strong>${Helpers.formatUsdPrice(Helpers.getEthPrice() * Helpers.weiToEth(directPurchasePrice))}</strong>
                )
            </p>

            <PixelatedButton
                text="PURCHASE"
                style={{
                    width: 200,
                    height: 45,
                    marginRight: 15
                }}
                foregroundColor='#69BD45'
                onClick={onDirectPurchase}
                inverted/>

        </div>
        :
        null
    );
}

export default DirectPurchaseActions;