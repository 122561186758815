import { createUseStyles } from 'react-jss';
import Theme from '~/theme';

export const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'min-content',
        marginRight: 20
    },
    imageContainer: {
        width: 85,
        height: 85,
        position: 'relative'
    },
    largeImage: {
        width: '100%',
        height: '100%',
        border: '1px solid black',
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center center !important',
        backgroundSize: 'cover !important',
        transition: '0.2s ease',
        '&:hover': {
            opacity: 0.5
        }
    },
    smallImage: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '30%',
        height: '30%',
        border: '1px solid black',
        opacity: '1 !important',
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center center !important',
        backgroundSize: 'cover !important',
        margin: '0 0 -2px 0'
    },
    text: {
        fontFamily: Theme.Font.Primary,
        fontSize: 12,
        fontWeight: 400,
        color: Theme.TextColor.Light,
        textAlign: 'center',
        margin: '8px 0 0 0',
        cursor: 'default',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minHeight: 30,
        maxHeight: 30
    },
    '@media only screen and (max-width: 800px)': {
        imageContainer: {
            width: 65,
            height: 65,
        },
        text: {
            fontSize: 11
        }
    },
});